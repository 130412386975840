import { useTranslation } from "hooks";
import { ServiceInstance } from "models/activities/activity";
import { AccordionV2 } from "components/accordionV2";
import { MonthOrder, getMonthName, groupByYearAndMonth } from "./helpers";
import { ServiceBoxV2 } from "./ServiceBoxV2";

type Props = {
  unfinishedActivityServices: ServiceInstance[];
  customerNumber: string;
  showUnfinishedServices: boolean;
};

export default function UnfinishedActivitiesList({
  unfinishedActivityServices,
  customerNumber,
  showUnfinishedServices,
}: Props) {
  const { t: translate } = useTranslation();
  const { groupedActivities, yearsOrder } = groupByYearAndMonth(
    unfinishedActivityServices
  );

  return (
    <span>
      {showUnfinishedServices ? (
        yearsOrder.map((yearInstance) =>
          yearInstance.monthsOrder.map((monthInstance: MonthOrder) => (
            <AccordionV2
              key={`${monthInstance.month}-${monthInstance.year}_unfinished`}
              title={translate("UNFINISHED_ACTIVITIES", [
                translate(getMonthName(monthInstance.month)),
                monthInstance.year.toString(),
              ])}
              isInitiallyOpen
              lengthIndicator={
                groupedActivities[yearInstance.year][
                  Number(monthInstance.month)
                ].length
              }
              className="unfinished-activities-accordion empty pb-sm m-0"
            >
              {groupedActivities[yearInstance.year][monthInstance.month].map(
                (unfinishedActivityService: ServiceInstance) => (
                  <div
                    key={unfinishedActivityService.service_box_id}
                    className="w-100"
                  >
                    <ServiceBoxV2
                      service={unfinishedActivityService}
                      customerNumber={customerNumber}
                    />
                  </div>
                )
              )}
            </AccordionV2>
          ))
        )
      ) : (
        <div className="d-flex h-100 align-items-center flex-direction-column justify-content-around">
          {yearsOrder.map((yearInstance) =>
            yearInstance.monthsOrder
              .slice(0, 2)
              .map((monthInstance: MonthOrder) => (
                <div
                  key={`${monthInstance.month}-${monthInstance.year}`}
                  className="d-flex w-100"
                >
                  <span className="fw-semibold mr-sm">
                    {translate("UNFINISHED_ACTIVITIES", [
                      translate(getMonthName(monthInstance.month)),
                      monthInstance.year.toString(),
                    ])}
                  </span>
                  <span className="text-gray">
                    (
                    {
                      groupedActivities[yearInstance.year][monthInstance.month]
                        .length
                    }
                    )
                  </span>
                </div>
              ))
          )}
        </div>
      )}
    </span>
  );
}
