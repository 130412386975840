import { useState } from "react";
import { Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { DropdownV9 } from "components/dropdown/DropdownV9";
import { useTranslation } from "hooks/use-translate";
import { PowerOfAttorney } from "models/customerSettings/customerSettingsPowerofAttorney";
import { ServiceLine } from "models/customerSettings/customerSettingsServiceLine";
import { PersonDetails } from "models/profile";
import { RootState } from "state";
import { resetOpenCreateModalFor } from "state/customerSettings/customerSettingsSlice";
import {
  createPowerOfAttorneyCallerWrapper,
  fetchPowerOfAttorneyCallerWrapper,
  updatePowerOfAttorneyCallerWrapper,
} from "state/customerSettings/customerSettingsThunk";
import Modal from "components/modal";
import { PersonPicker } from "components/people";
import { TextArea } from "components/textarea";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";

const EMPTY = "N/A";
const PENDING = "Pending";
const ACTIVE = "Active";

type CustomerPagePowerOfAttorneyModalProps = {
  customerId: string;
  openModal: boolean;
  setOpenModal: (arg: boolean) => void;
  serviceLine?: ServiceLine;
  onChange: (arg: PowerOfAttorney) => void;
  poaToUpdate?: PowerOfAttorney;
  statusOptions: { value: number; text: string }[];
};

export default function CustomerPagePowerOfAttorneyModal({
  customerId,
  openModal,
  setOpenModal,
  serviceLine,
  onChange,
  poaToUpdate,
  statusOptions,
}: CustomerPagePowerOfAttorneyModalProps) {
  const { t: translate, language } = useTranslation();
  const dispatch = useDispatch();
  const serviceLines = useSelector(
    (state: RootState) => state.settings.serviceLines.data
  );
  const { currentUser } = useSelector((state: RootState) => state.users);
  const sources = useSelector(
    (state: RootState) => state.settings.powerOfAttorney.sources
  );
  const types = useSelector(
    (state: RootState) => state.settings.powerOfAttorney.types
  );
  const sourceOptions: { text: string; value: string }[] = sources.map((e) => ({
    text: e.title,
    value: e.id.toString(),
  }));
  const typeOptions: { text: string; value: string }[] = types.map((e) => ({
    text: e.title,
    value: e.id.toString(),
  }));
  const powerOfattorneyStatuses = useSelector(
    (state: RootState) => state.settings.powerOfAttorney.statuses
  );

  const [selectedSourceId, setSelectedSourceId] = useState("");
  const [selectedTypeId, setSelectedTypeId] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");
  const [comment, setComment] = useState("");
  const [status, setStatus] = useState<number>(poaToUpdate?.status.id ?? -1);
  const [selectedServiceLine, setSelectedServiceLine] = useState<ServiceLine>();

  const serviceLineOptions = serviceLines.map((service) => {
    return {
      text:
        language && language === "EN"
          ? service.serviceline
          : service.serviceline_se,
      value: service.id.toString(),
    };
  });

  const updateWhenEmpty =
    poaToUpdate &&
    (poaToUpdate.powerofattorneysource.title === EMPTY ||
      poaToUpdate.powerofattorneytype.title === EMPTY);

  const disableModal = () => {
    if (poaToUpdate) {
      if (updateWhenEmpty) {
        return (
          (poaToUpdate.powerofattorneysource.title === EMPTY &&
            !selectedSourceId) ||
          (poaToUpdate.powerofattorneytype.title === EMPTY && !selectedTypeId)
        );
      }
      return status === poaToUpdate.status.id;
    }
    return !(
      selectedSourceId &&
      selectedTypeId &&
      selectedUserId &&
      selectedServiceLine
    );
  };

  const statusOptionsLimited =
    updateWhenEmpty &&
    ((poaToUpdate.powerofattorneysource.title === EMPTY && !selectedSourceId) ||
      (poaToUpdate.powerofattorneytype.title === EMPTY && !selectedTypeId))
      ? statusOptions.filter((e) => e.text !== ACTIVE)
      : statusOptions;
  const nextText = poaToUpdate ? translate("UPDATE") : translate("CREATE_NEW");
  const title = poaToUpdate
    ? translate("UPDATE_POA_HEADER")
    : translate("CREATE_POA_HEADER");

  const createPowerOfAttorney = () => {
    const powerOfAttorney = {
      customer_number: customerId,
      employee_id: selectedUserId,
      created_by: currentUser.graphId,
      modified_by: currentUser.graphId,
      powerofattorneytype: types.find(
        (e) => e.id.toString() === selectedTypeId
      ),
      powerofattorneysource: sources.find(
        (e) => e.id.toString() === selectedSourceId
      ),
      serviceline: selectedServiceLine,
      comment: comment || "",
      imported: false,
      path: "",
      status: powerOfattorneyStatuses.find((e) => e.title === ACTIVE),
    } as PowerOfAttorney;
    onChange(powerOfAttorney);
    dispatch(createPowerOfAttorneyCallerWrapper(powerOfAttorney));
  };

  const updatePowerOfAttorney = async () => {
    if (poaToUpdate) {
      const powerOfAttorney = {
        ...poaToUpdate,
        modified_by: currentUser.graphId,
      } as PowerOfAttorney;
      const mappedStatus = powerOfattorneyStatuses.find((e) => e.id === status);
      const mappedSource = sources.find(
        (e) => e.id.toString() === selectedSourceId
      );
      const mappedType = types.find((e) => e.id.toString() === selectedTypeId);
      if (mappedStatus) {
        powerOfAttorney.status = mappedStatus;
        if (poaToUpdate.status.title === PENDING) {
          powerOfAttorney.created_by = currentUser.graphId;
        }
      }
      if (mappedSource) {
        powerOfAttorney.powerofattorneysource = mappedSource;
      }
      if (mappedType) {
        powerOfAttorney.powerofattorneytype = mappedType;
      }
      await dispatch(updatePowerOfAttorneyCallerWrapper(powerOfAttorney));
      dispatch(
        fetchPowerOfAttorneyCallerWrapper({
          customerId,
          ...(serviceLine && { serviceLineId: serviceLine.id }),
        })
      );
    }
  };

  const onSelectedPersonChange = (
    email: string,
    personDetails?: PersonDetails
  ) => {
    if (personDetails && personDetails.userPrincipalName) {
      setSelectedUserId(personDetails.userPrincipalName);
    } else if (email) {
      setSelectedUserId(email);
    }
  };

  const onSetOpenModal = (open: boolean) => {
    setOpenModal(open);
    dispatch(resetOpenCreateModalFor());
  };

  return (
    <Modal
      isOpen={openModal}
      onDismiss={() => onSetOpenModal(false)}
      size="medium"
      header={<ModalHeader headerTitleText={title} />}
      footer={
        <ModalFooter
          onCancel={() => onSetOpenModal(false)}
          onSave={() => {
            onSetOpenModal(false);
            if (poaToUpdate) {
              updatePowerOfAttorney();
            } else {
              createPowerOfAttorney();
            }
          }}
          labelSubmit={nextText}
          labelCancel="CANCEL"
          isDisabled={disableModal()}
        />
      }
    >
      <Row className="p-md">
        {((poaToUpdate && poaToUpdate.powerofattorneysource.title === EMPTY) ||
          !poaToUpdate) && (
          <>
            <Row className="fw-bold">{translate("SOURCE")} *</Row>
            <Row className="mb-lg">
              <DropdownV9
                className="px-0"
                placeholder={translate("SELECT_SOURCE")}
                selectedOptions={[
                  poaToUpdate?.powerofattorneysource.title === EMPTY
                    ? ""
                    : poaToUpdate?.powerofattorneysource.id.toString() ||
                      selectedSourceId,
                ]}
                options={sourceOptions}
                onOptionSelect={(e, { optionValue }) => {
                  setSelectedSourceId(optionValue ?? "");
                }}
              />
            </Row>
          </>
        )}
        {((poaToUpdate && poaToUpdate.powerofattorneytype.title === EMPTY) ||
          !poaToUpdate) && (
          <>
            <Row className="fw-bold">{translate("TYPE")} *</Row>
            <Row className="mb-lg">
              <DropdownV9
                className="px-0"
                placeholder={translate("SELECT_TYPE")}
                selectedOptions={[
                  poaToUpdate?.powerofattorneytype.title === EMPTY
                    ? ""
                    : poaToUpdate?.powerofattorneytype.id.toString() ||
                      selectedTypeId,
                ]}
                options={typeOptions}
                onOptionSelect={(e, { optionValue }) => {
                  setSelectedTypeId(optionValue ?? "");
                }}
              />
            </Row>
          </>
        )}

        {!poaToUpdate && (
          <>
            <Row className="fw-bold">{translate("SERVICE")} *</Row>
            <Row className="mb-lg">
              <DropdownV9
                className="px-0"
                placeholder={translate("SELECT_SERVICE")}
                selectedOptions={[selectedServiceLine?.serviceline ?? ""]}
                options={serviceLineOptions}
                onOptionSelect={(_, { optionValue }) => {
                  const selectedSL = serviceLines.find(
                    (sLine) => sLine.id.toString() === optionValue
                  );
                  setSelectedServiceLine(selectedSL);
                }}
              />
            </Row>
          </>
        )}

        {!poaToUpdate && (
          <>
            <Row className="fw-bold">{translate("USER")} *</Row>
            <Row className="mb-lg">
              <PersonPicker
                className="me-sm px-0"
                selectionMode="single"
                placeholder={translate("SEARCH_PERSON")}
                selectedUsers={
                  selectedUserId
                    ? [{ id: selectedUserId, name: undefined }]
                    : []
                }
                onPersonChange={(email, _, person) => {
                  onSelectedPersonChange(email, person);
                }}
              />
            </Row>
          </>
        )}
        {!poaToUpdate && (
          <>
            <Row className="fw-bold">{translate("COMMENT")}</Row>
            <Row className="p-md">
              <TextArea
                rows={7}
                className=""
                placeholder="Enter comment"
                defaultValue={comment}
                onChange={(e) => setComment(e.target.value)}
                required
              />
            </Row>
          </>
        )}
        {poaToUpdate && (
          <>
            <Row className="fw-bold">{translate("STATUS")} *</Row>
            <Row className="p-md">
              <DropdownV9
                className="p-0"
                placeholder="Pending"
                onOptionSelect={(_, { optionValue }) => {
                  if (optionValue) {
                    setStatus(parseInt(optionValue, 10));
                  }
                }}
                options={statusOptionsLimited}
                selectedOptions={[poaToUpdate.status.id.toString()]}
              />
            </Row>
          </>
        )}
      </Row>
    </Modal>
  );
}
