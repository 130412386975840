import { differenceInDays } from "date-fns";

import { useTranslation } from "hooks/use-translate";
import { DateFormat } from "libs/date/date-format";
import { isDateValid } from "libs/date/valid-date";
import { Deal, DealOption } from "models/deals/deal";
import { PriorityIcon } from "components/icon/PriorityIcon";
import { differenceInDaysByHours } from "libs/date/difference-in-days-by-hours";

type StageCardContentProps = {
  setIsPanelMounted: (isPanelMounted: boolean) => void;
  deal: Deal;
  dealOptions: DealOption[];
};

export function StageCardContent({
  setIsPanelMounted,
  deal,
  dealOptions,
}: StageCardContentProps) {
  const { t: translate, dateFormatter } = useTranslation();

  const isProactiveHighlight =
    deal.contact && (deal.aterkommande_aktiviteter || deal.aktivitet);

  const days = differenceInDays(new Date(), deal.entered_current_stage_date);

  const dealStatusDescription = dealOptions
    .find((option) => option.name === "deal_status")
    ?.options.find((option) => option.value === deal.deal_status);

  const getCloseDateColor = (closeDate: Date) => {
    if (!closeDate) {
      return "";
    }

    const closeDateToTodayDiff = differenceInDaysByHours(new Date(), closeDate);

    if (closeDateToTodayDiff >= 0) {
      return " text-color-red";
    }

    return "";
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setIsPanelMounted(true);
      }}
      className={`deal${isProactiveHighlight ? " proactive-highlight" : ""}`}
    >
      <div>
        <div className="d-flex">
          <div data-testid="deal-name" className="deal-name fpx-14">
            {deal.dealname}
          </div>

          {deal.dealtype === "existingbusiness" && (
            <div className="ml-auto mr-xs text-color-white badge bg-primary py-xxs px-sm border-radius-10 h-100">
              {translate("CUSTOMER")}
            </div>
          )}
        </div>

        {days > 0 && (
          <div data-testid="deal-days" className="text-color-gray-700">
            {days} {translate("DAYS_LC")}
          </div>
        )}

        {dealStatusDescription && (
          <div className="text-color-gray-700">
            {dealStatusDescription?.label}
          </div>
        )}
      </div>

      <div className="d-flex align-items-center mt-sm">
        {isDateValid(deal.closedate) && deal.closedate ? (
          <div className="text-color-gray-700">
            {translate("ESTIMATED_CLOSEDATE")}:
            <span className={`ml-xs${getCloseDateColor(deal.closedate)}`}>
              {dateFormatter(deal.closedate, DateFormat.YearDayMonth)}
            </span>
          </div>
        ) : null}

        <span className="ml-auto mr-xs">
          <PriorityIcon priority={deal.hs_priority} />
        </span>
      </div>
    </div>
  );
}
