import { useContext, useState } from "react";
import { TableCellLayout } from "@fluentui/react-components";

import { useTranslation } from "hooks";
import { useAppDispatch } from "state/use-app-redux";
import { appendError } from "state/notifications";
import { updateCompanyInfo } from "state/offer/companyThunks";
import { DropdownV9 } from "components/dropdown/DropdownV9";
import ElementLoadingOverlay from "components/overlay/ElementLoadingOverlay/ElementLoadingOverlay";
import { CustomerManagement } from "models/customerManagement";
import { CompanyInformation } from "models/offer/Company";
import { DynamicPropertyOption } from "models/deals/deal";
import { ManageCustomersContext } from "./ManageCustomersContext";

const DEFAULT_INDEX = -1;

interface ChosenClientProgramCellProps {
  rowData: CustomerManagement;
  chosenProgramProperties: DynamicPropertyOption[];
}

export default function ChosenClientProgramCell({
  rowData,
  chosenProgramProperties,
}: ChosenClientProgramCellProps) {
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();

  const { customers, setCustomers, assignee, lockedRows } = useContext(
    ManageCustomersContext
  );

  const [isLoading, setIsLoading] = useState(false);

  const chosenClientProgramOptions = chosenProgramProperties
    .sort((a, b) => a.displayOrder - b.displayOrder)
    .map((property) => ({
      text: property.label,
      value: property.value,
    }));

  const selectChosenClientProgram = (
    customerNumber: string,
    value?: string
  ) => {
    if (!value) {
      return;
    }

    setIsLoading(true);

    const company: Partial<CompanyInformation> = {
      chosen_program_new_sales_manager: value,
    };

    dispatch(updateCompanyInfo({ customerNumber, company }))
      .then(() => {
        const customerIndex = findCustomerIndex();
        if (customerIndex !== DEFAULT_INDEX) {
          const updatedCustomers = [...customers];
          updatedCustomers[customerIndex] = {
            ...updatedCustomers[customerIndex],
            chosen_client_program: value,
          };
          setCustomers(updatedCustomers);
        }
      })
      .catch((error) =>
        dispatch(
          appendError(
            "MANAGE_CUSTOMERS.FAILED_TO_UPDATE_CLIENT_PROGRAM",
            error as Error
          )
        )
      )
      .finally(() => setIsLoading(false));
  };

  const findCustomerIndex = () => {
    let customerIndex = DEFAULT_INDEX;
    customers.find((customer, index) => {
      if (customer.customer_number === rowData.customer_number) {
        customerIndex = index;
        return true;
      }
      return false;
    });
    return customerIndex;
  };

  const isCustomerLocked = lockedRows.has(findCustomerIndex());
  const assigneeIsSalesPerson = rowData.salesperson_graph_id === assignee?.id;

  const renderClientProgram = () => {
    const program = chosenProgramProperties.find(
      (prop) => prop.value === rowData.chosen_client_program
    );

    if (!program && (isCustomerLocked || !assigneeIsSalesPerson)) {
      return (
        <span className="text-gray body-italic">
          {translate("MANAGE_CUSTOMERS.CLIENT_PROGRAM.EMPTY")}
        </span>
      );
    }
    if (program && !assigneeIsSalesPerson) {
      return <span className="text-gray">{program.label}</span>;
    }
    return (
      <>
        {isLoading && <ElementLoadingOverlay />}
        <DropdownV9
          appearance="underline"
          placeholder={translate("MANAGE_CUSTOMERS.CLIENT_PROGRAM.PLACEHOLDER")}
          className={`chosen-client-program-ddl no-bottom-border fit-content-min no-overflow${
            rowData.chosen_client_program
              ? ""
              : " chosen-client-program-ddl-empty"
          }`}
          selectedOptions={
            rowData.chosen_client_program ? [rowData.chosen_client_program] : []
          }
          options={chosenClientProgramOptions}
          onOptionSelect={(e, data) => {
            e.stopPropagation();
            selectChosenClientProgram(
              rowData.customer_number,
              data.optionValue
            );
          }}
          onClick={(e) => e.stopPropagation()}
          disabled={!assigneeIsSalesPerson || isLoading}
        />
      </>
    );
  };

  return <TableCellLayout truncate>{renderClientProgram}</TableCellLayout>;
}
