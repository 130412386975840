import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Add16Regular } from "@fluentui/react-icons";

import { Button } from "components/button";
import { TextArea } from "components/textarea";
import { useTranslation } from "hooks/use-translate";
import { Debounce } from "libs/debounce";
import { BooleanWithText, FormQuestion } from "models/activities/activity";
import { setFormQuestionAnswer } from "state/activities/actions";
import "./YearlyEvaluationKYCForm.scss";
import { Radio, RadioGroup } from "@fluentui/react-components";

type YearlyEvaluationKYCFormRowProps = {
  question: FormQuestion;
  index: number;
};

export function YearlyEvaluationKYCFormRow({
  question,
  index,
}: YearlyEvaluationKYCFormRowProps) {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const [showComment, setShowComment] = useState(false);
  const [answer, setAnswer] = useState(question.answer);

  const updateAnswer = (answerValue: string) => {
    if (question) {
      setAnswer({
        ...question,
        value: answerValue,
      });
      if (answerValue === "false") {
        setShowComment(true);
      }

      dispatch(
        setFormQuestionAnswer(
          {
            value: answerValue,
            comment: (question?.answer as BooleanWithText)?.comment ?? "",
          },
          question.question_id
        )
      );
    }
  };

  const debounceUpdateComment = new Debounce(300);
  const updateComment = (comment: string) => {
    if (question && (question?.answer as BooleanWithText).value) {
      debounceUpdateComment.fire(async () => {
        dispatch(
          setFormQuestionAnswer(
            {
              value: (question?.answer as BooleanWithText).value,
              comment,
            },
            question.question_id
          )
        );
      });
    }
  };

  return (
    <Row className="px-lg py-sm align-items-center">
      <Col md={1}>{index + 1}</Col>
      <Col md={6}>{question.question}</Col>
      <Col md={5}>
        <div className="d-flex">
          <RadioGroup
            layout="horizontal"
            onChange={(_, option) => {
              if (option) {
                updateAnswer(option.value);
              }
            }}
          >
            <Radio value="true" label={translate("YES")} />
            <Radio value="false" label={translate("NO")} />
          </RadioGroup>

          {!showComment && (
            <div className="d-flex mb-xs px-0">
              <Button
                variant="outline-outline"
                className="justify-content-start primary-colored-text"
                onClick={() => setShowComment(true)}
              >
                <div className="d-flex">
                  <Add16Regular className="d-flex align-items-center" />
                  <span className="mx-md fw-bold">
                    {translate("KYC_ADD_COMMENT")}
                  </span>
                </div>
              </Button>
            </div>
          )}
        </div>
        {showComment && (
          <div className="my-sm">
            <span>
              {translate("COMMENT")}
              {answer === "false" ? <span> *</span> : null}
            </span>
            <TextArea
              rows={1}
              maxLength={50}
              onChange={(e) => updateComment(e.target.value)}
              placeholder={translate("KYC_COMMENT_PLACEHOLDER")}
            />
          </div>
        )}
      </Col>
    </Row>
  );
}
