import React from "react";
import "./accordion.scss";
import { AccordionContent } from "./components/AccordionBody";
import { AccordionHeader } from "./components/AccordionHeader";
import { AccordionProvider } from "./AccordionContextProvider";

interface IAccordionCustom {
  title: string | React.ReactNode;
  children: React.ReactNode;
  loadingStatus?: string;
  isInitiallyOpen?: boolean;
  subtitles?: string[];
}

export function Accordion({
  title,
  children,
  loadingStatus,
  isInitiallyOpen = false,
  subtitles = [],
}: IAccordionCustom) {
  return (
    <AccordionProvider isInitiallyOpen={isInitiallyOpen}>
      <div className="accordion-custom-item">
        <AccordionHeader
          title={title}
          loadingStatus={loadingStatus}
          subtitles={subtitles}
        />
        <AccordionContent loadingStatus={loadingStatus}>
          {children}
        </AccordionContent>
      </div>
    </AccordionProvider>
  );
}
