import { useSelector } from "react-redux";

import { RootState } from "state";
import { useSorting } from "hooks/use-sort-column";
import { useTranslation } from "hooks/use-translate";
import { ServiceDescription } from "models/serviceDescription";
import { Breadcrumb, BreadcrumbItem } from "components/breadcrumb";
import { SortIcon } from "components/icon";
import { LayoutBody, LayoutHeader } from "components/layout/Layout";
import { ShimmerBlock, ShimmerCell } from "components/shimmer";
import { LinkMultiple20Regular } from "@fluentui/react-icons";

const SortServiceCode = (s: ServiceDescription) => s.Code;
const SortServiceProcess = (s: ServiceDescription) => s.Processbeskrivning;

// NOT USED ANYWHERE
export default function ServiceDescriptionsView() {
  const services = useSelector(
    (state: RootState) => state.servicesDescriptions
  );
  const { t: translate } = useTranslation();

  const { sorting, toggleSortSelector } = useSorting<ServiceDescription>(
    (s) => s.Processbeskrivning
  );

  const breadcrumbs: BreadcrumbItem[] = [
    {
      key: "help",
      text: translate("HELP"),
    },
    {
      key: "assignment-description",
      text: "Uppdragsbeskrivning",
    },
  ];

  const categories = services.data.reduce((groups, service) => {
    if (groups[service.Category]) {
      groups[service.Category].push(service);
    } else {
      groups[service.Category] = [service];
    }
    return groups;
  }, {} as { [index: string]: ServiceDescription[] });

  const renderShimmerTableBody = () => {
    return [1, 0.5, 0.25, 0.125].map((opacity) => (
      <>
        <tr className="group-header" style={{ opacity }}>
          <td colSpan={5} />
        </tr>

        <tr style={{ opacity }}>
          <td>
            <ShimmerCell />
          </td>
          <td>
            <ShimmerCell />
          </td>
          <td>
            <ShimmerBlock />
          </td>
          <td>
            <ShimmerCell />
          </td>
          <td>
            <ShimmerCell />
          </td>
        </tr>
      </>
    ));
  };

  return (
    <>
      <LayoutHeader>
        <span />
      </LayoutHeader>
      <LayoutBody>
        <Breadcrumb items={breadcrumbs} />
        <table className="sticky hover-effect">
          <thead>
            <tr>
              <th style={{ width: "15%" }}>Tjänst</th>
              <th onClick={() => toggleSortSelector(SortServiceProcess, true)}>
                Process{" "}
                <SortIcon
                  show={sorting.selector === SortServiceProcess}
                  descending={sorting.isDescendingOrder}
                />
              </th>
              <th style={{ width: "2em" }} />
              <th style={{ width: "50%" }}>Beskrivning</th>
              <th
                onClick={() => toggleSortSelector(SortServiceCode, true)}
                style={{ width: "6em" }}
              >
                Kod{" "}
                <SortIcon
                  show={sorting.selector === SortServiceCode}
                  descending={sorting.isDescendingOrder}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(categories)
              .sort((c1, c2) => (c1 < c2 ? -1 : 1))
              .map((cKey) => {
                const categoryServices = categories[cKey];
                const servicesGroups = categoryServices.reduce(
                  (groups, service) => {
                    if (groups[service.Service]) {
                      groups[service.Service].push(service);
                    } else {
                      groups[service.Service] = [service];
                    }
                    return groups;
                  },
                  {} as { [index: string]: ServiceDescription[] }
                );
                const serviceKeys = Object.keys(servicesGroups);

                return (
                  <>
                    <tr className="group-header">
                      <td colSpan={5}>
                        <h3>{cKey}</h3>
                      </td>
                    </tr>

                    {serviceKeys.map((sKey) => {
                      const services = servicesGroups[sKey];

                      return (
                        <>
                          {services.sort(sorting.sort).map((cs, index) => {
                            return (
                              <tr>
                                <td>
                                  <b>{index === 0 ? cs.Service : ""}</b>
                                </td>
                                <td
                                  dangerouslySetInnerHTML={{
                                    __html: cs.Processbeskrivning || "",
                                  }}
                                />
                                <td>
                                  {cs.Instruktioner && (
                                    <a
                                      href={cs.Instruktioner.Url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <LinkMultiple20Regular />
                                    </a>
                                  )}
                                </td>
                                <td
                                  dangerouslySetInnerHTML={{
                                    __html: cs["Process SE"] || "",
                                  }}
                                />
                                <td>{cs.Code}</td>
                              </tr>
                            );
                          })}
                        </>
                      );
                    })}
                  </>
                );
              })}
            {services.isLoading && renderShimmerTableBody()}
          </tbody>
        </table>
      </LayoutBody>
    </>
  );
}
