import React, { useMemo } from "react";
import { Button, Field } from "@fluentui/react-components";
import { DayOfWeek } from "@fluentui/react-calendar-compat";

import { useTranslation } from "hooks";
import { DatePickerV9 } from "./DatePickerV9";
import "./DateRange.scss";

type DateRangeKey = "start" | "end";

interface DateSelection {
  placeholder?: string;
  label?: string;
  date: Date | null;
  onSelectDate: (date: Date | null) => void;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
}

export interface DateRangeSelection {
  start?: DateSelection;
  end?: DateSelection;
}

export type DateRangeProps = React.HTMLProps<HTMLDivElement> & {
  dateFormatter: (date: Date) => string;
  dateRange?: DateRangeSelection;
  label?: string;
  firstDayOfWeek?: DayOfWeek;
  clearable?: boolean;
  allowTextInput?: boolean;
  showGoToToday?: boolean;
  onClearDateRange?: () => void;
};

export function DateRange({
  dateFormatter,
  dateRange,
  label,
  firstDayOfWeek = DayOfWeek.Monday,
  clearable = true,
  allowTextInput,
  showGoToToday,
  onClearDateRange,
  ...rest
}: DateRangeProps) {
  const { t: translate } = useTranslation();

  if (!label) {
    // eslint-disable-next-line no-param-reassign
    label = translate("DATE_RANGE");
  }

  const onSelectDate = (dateRangeKey: DateRangeKey, date: Date | null) => {
    dateRange?.[dateRangeKey]?.onSelectDate(date);
  };

  const clearDateRange = () => {
    dateRange?.start?.onSelectDate(null);
    dateRange?.end?.onSelectDate(null);

    onClearDateRange?.();
  };

  const sharedDatePickerProps = useMemo(
    () => ({
      dateFormatter,
      firstDayOfWeek,
      allowTextInput,
      showGoToToday,
    }),
    [dateFormatter, firstDayOfWeek, allowTextInput, showGoToToday]
  );

  return (
    <div {...rest} className={`date-range ${rest.className ?? ""}`}>
      <div className="date-range-header">
        <span className="fw-semibold">{label}</span>
        {clearable && (
          <Button
            appearance="transparent"
            className="unset-min p-0"
            onClick={clearDateRange}
            disabled={rest.disabled}
          >
            {translate("CLEAR")}
          </Button>
        )}
      </div>
      <div className="date-range-body">
        <Field
          label={{
            children: dateRange?.start?.label || translate("START"),
            className: "fpx-12",
          }}
        >
          <DatePickerV9
            {...sharedDatePickerProps}
            selectedDate={dateRange?.start?.date ?? null}
            onSelectDate={(date) => onSelectDate("start", date ?? null)}
            placeholder={
              dateRange?.start?.placeholder || translate("SELECT_START_DATE")
            }
            disabled={rest.disabled || dateRange?.start?.disabled}
            minDate={dateRange?.start?.minDate}
            maxDate={dateRange?.end?.date || dateRange?.start?.maxDate}
            initialPickerDate={dateRange?.end?.date || undefined}
          />
        </Field>
        <Field
          label={{
            children: dateRange?.end?.label || translate("END"),
            className: "fpx-12",
          }}
        >
          <DatePickerV9
            {...sharedDatePickerProps}
            selectedDate={dateRange?.end?.date ?? null}
            onSelectDate={(date) => onSelectDate("end", date ?? null)}
            placeholder={
              dateRange?.end?.placeholder || translate("SELECT_END_DATE")
            }
            disabled={rest.disabled || dateRange?.end?.disabled}
            minDate={dateRange?.start?.date ?? dateRange?.end?.minDate}
            maxDate={dateRange?.end?.maxDate}
            initialPickerDate={dateRange?.start?.date || undefined}
          />
        </Field>
      </div>
    </div>
  );
}
