import {
  ArrowLeft20Regular,
  ArrowRight20Regular,
  Warning20Regular,
} from "@fluentui/react-icons";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { RHFInput } from "components/input/RHFInput";
import Switch from "components/switch";
import VerticalStepper from "components/verticalStepper";
import {
  MAX_CHAR_FIRST_NAME,
  MAX_CHAR_LAST_NAME,
} from "constants/maxCharConsts";
import { useTranslation } from "hooks";
import {
  generatePlaceholderEmail,
  isPlaceholderEmail,
} from "libs/generate-placeholder-email";
import { isValidEmail } from "libs/is-valid-email";
import { isValidPhone } from "libs/is-valid-phone";
import { getDigitsFromString } from "libs/number-format";
import { DealContact } from "models/deals/deal";
import { RootState } from "state";
import { appendToastMessage } from "state/notifications";
import {
  createNewContact,
  deleteContact,
  searchContact,
} from "state/sales/actions";
import { useAppDispatch } from "state/use-app-redux";
import { BusinessOpportunityWizardRouteHelper } from "../BusinessOpportunityWizardRoutes";
import { CreateBusinessOpportunityContext } from "../CreateBusinessOpportinityContext";
import { useRoutingForCreateBusinessOpportunity } from "../StepperHelper";

type ContactsListProps = {
  contact?: DealContact;
  customerContacts: DealContact[];
  setShowContactsList?: (show: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
};

export default function CreateEditContact({
  contact,
  customerContacts,
  setShowContactsList,
  setIsLoading,
}: ContactsListProps) {
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { updateNewDeal, updateNewCustomer, newCustomer } = useContext(
    CreateBusinessOpportunityContext
  );
  const { isNewCustomer } = useSelector((state: RootState) => state.sales);

  const { data: companyInfoData } = useSelector(
    (state: RootState) => state.offers.companyInfo
  );

  const initContactFormState = {
    isEnteringEmail: false,
    isLoadingContact: false,
    isExistingContact: false,
    isEmailFilled: false,
    existingContactError: "",
  };

  const [contactFormState, setContactFormState] =
    useState(initContactFormState);

  const {
    isEnteringEmail,
    isLoadingContact,
    isExistingContact,
    isEmailFilled,
    existingContactError,
  } = contactFormState;

  const routing = useRoutingForCreateBusinessOpportunity(
    location.pathname,
    isNewCustomer
  );

  useEffect(() => {
    setIsLoading(isLoadingContact);
  }, [isLoadingContact, setIsLoading]);

  const contactCreateEditTranslation = contact
    ? translate("UPDATE_CONTACT")
    : translate("CREATE_CONTACT");

  const {
    register,
    formState: { errors, isValid },
    setValue,
    getValues,
    reset,
    trigger,
  } = useForm<DealContact>({
    mode: "onTouched",
  });

  const setContactExistsError = (
    existingContact: DealContact,
    isCustomerContact?: DealContact
  ) => {
    if (isCustomerContact) {
      setContactFormState((prev) => ({
        ...prev,
        existingContactError: translate(
          "CREATE.NEW.DEAL.CONTACT_EXISTS_CUSTOMER"
        ),
        isExistingContact: true,
      }));
    } else {
      setValue("id", existingContact.id);
    }
  };

  const fetchContact = async (email: string) => {
    if (!isValidEmail(email)) {
      return;
    }
    setContactFormState((prev) => ({
      ...prev,
      isEmailFilled: true,
    }));
    if (email === contact?.email) {
      setContactFormState((prev) => ({
        ...prev,
        isLoadingContact: false,
      }));
      return;
    }
    setContactFormState((prev) => ({
      ...prev,
      isLoadingContact: true,
    }));
    try {
      const newContact = !contact;
      const fetchedContacts = await dispatch(searchContact(email));
      const contactFound = fetchedContacts && fetchedContacts.length > 0;

      if (contactFound) {
        const existingContact = {
          ...fetchedContacts[0],
          lastname: fetchedContacts[0].lastName,
          firstname: fetchedContacts[0].firstName,
          mobilephone: fetchedContacts[0].mobile,
          phone: fetchedContacts[0].phone,
          id: fetchedContacts[0].id,
        };
        const isCustomerContact = customerContacts.find(
          (customerContact) => customerContact.id === existingContact.id
        );
        if (newContact) {
          reset(existingContact);
          trigger();
        }

        setContactExistsError(existingContact, isCustomerContact);
      } else {
        if (newContact) {
          setValue("firstname", "");
          setValue("lastname", "");
          setValue("mobilephone", "");
          setValue("phone", "");
          trigger();
        }
        setContactFormState((prev) => ({
          ...prev,
          isExistingContact: false,
        }));
      }
    } finally {
      setContactFormState((prev) => ({
        ...prev,
        isLoadingContact: false,
        isEnteringEmail: false,
      }));
    }
  };

  useEffect(() => {
    reset(contact);
  }, [contact, reset]);

  const firstNameOptions = register("firstname", {
    required: translate("FIRSTNAME_REQUIRED"),
  });

  const lastNameOptions = register("lastname", {
    required: translate("LASTNAME_REQUIRED"),
  });

  const phoneOptions = register("phone", {
    validate: {
      isValid: (value) => {
        if (!value) {
          return true;
        }

        return isValidPhone(value) || translate("PHONE_VALIDATION_MESSAGE");
      },
    },
  });

  const emailOption = register("email", {
    required: translate("ENTER_EMAIL_EXAMPLE"),
    validate: {
      isValid: (value) => {
        if (!value) {
          return true;
        }

        return isValidEmail(value) || translate("VALID_EMAIL");
      },
    },
  });

  const createOrUpdateContact = async () => {
    const formData = getValues();
    setContactFormState((prev) => ({
      ...prev,
      isLoadingContact: true,
    }));

    const customerNumber = companyInfoData?.customer_number;
    if (contact && customerNumber && formData.id !== contact.id) {
      await dispatch(deleteContact(customerNumber, contact.email, false));
    }

    try {
      const response = await dispatch(
        createNewContact(
          {
            email: formData.email,
            firstName: formData.firstname,
            lastName: formData.lastname,
            phone: formData.phone,
            ...(contact && { id: contact.id }),
            organization: isNewCustomer
              ? newCustomer.CompanyRegistrationNumber
              : companyInfoData?.org_number,
            ...(formData.id && { id: formData.id }),
          },
          true
        )
      );
      const contactMessage = contact
        ? "UPDATE_CONTACT_SUCCESS"
        : "CREATE_CONTACT_SUCCESS";
      dispatch(appendToastMessage(contactMessage, "success"));
      if (response) {
        updateNewDeal({
          contact: { ...formData },
          first_name: formData.firstname,
          last_name: formData.lastname,
          mobile: formData.mobilephone,
          phone: formData.phone,
          contact_email: formData.email,
        });

        updateNewCustomer({
          FirstName: formData.firstname,
          LastName: formData.lastname,
          Telephone: formData.phone,
          Mobile: formData.mobilephone,
          ElectronicMailaddress: formData.email,
        });
        if (isNewCustomer) {
          navigate(
            BusinessOpportunityWizardRouteHelper.getBusinessOpportunityBusinessAreaRoute()
          );
        }
      }
    } catch (error) {
      dispatch(
        appendToastMessage("FAILED_TO_CREATE_OR_UPDATE_CONTACT", "error")
      );
    } finally {
      setContactFormState((prev) => ({
        ...prev,
        isLoadingContact: false,
      }));
      if (setShowContactsList) {
        setShowContactsList(true);
      }
    }
  };

  const handleEmailToggle = (isChecked?: boolean) => {
    const email = isChecked ? generatePlaceholderEmail() : "";
    setContactFormState((prev) => ({
      ...prev,
      isEmailFilled: isChecked || false,
    }));
    if (isChecked) {
      setContactFormState((prev) => ({
        ...prev,
        isExistingContact: false,
      }));
    }
    setValue("email", email, {
      shouldValidate: true,
    });
  };

  const renderEmailForm = () => (
    <Row className="mb-md pr-xl">
      <Col md={4}>
        <label className="fw-semibold">{translate("EMAIL")} *</label>
        <RHFInput
          type="email"
          register={emailOption}
          disabled={isLoadingContact || isPlaceholderEmail(getValues("email"))}
          required
          placeholder="email@example.com"
          errorMessage={
            isExistingContact ? (
              <>
                <Warning20Regular className="mr-xs" /> {existingContactError}
              </>
            ) : (
              errors.email?.message
            )
          }
          onBlur={() => {
            fetchContact(getValues("email"));
          }}
          onChange={({ target }) => {
            setContactFormState((prev) => ({
              ...prev,
              isExistingContact: false,
            }));
            setValue("email", target.value, {
              shouldValidate: true,
            });
          }}
          onKeyDown={() => {
            setContactFormState((prev) => ({
              ...prev,
              isEnteringEmail: true,
            }));
          }}
        />
      </Col>

      <Col xs={12}>
        <Switch
          checked={isPlaceholderEmail(getValues("email"))}
          disabled={isLoadingContact}
          label={translate("CONTACT_MISSING_EMAIL")}
          onToggleMethod={(e, { checked }) => handleEmailToggle(checked)}
        />
      </Col>
    </Row>
  );

  const renderContactForm = () => (
    <Row className="mb-md">
      <Col lg={4}>
        <label className="fw-semibold">{translate("FIRST_NAME")} *</label>
        <RHFInput
          disabled={isLoadingContact || isEnteringEmail}
          placeholder={translate("FIRST_NAME")}
          errorMessage={errors.firstname?.message}
          register={firstNameOptions}
          maxChar={MAX_CHAR_FIRST_NAME}
          value={getValues("firstname")}
          onChange={({ target }) => {
            setValue("firstname", target.value, {
              shouldValidate: true,
            });
          }}
        />
      </Col>

      <Col lg={4}>
        <label className="fw-semibold">{translate("LAST_NAME")} *</label>
        <RHFInput
          placeholder={translate("LAST_NAME")}
          disabled={isLoadingContact || isEnteringEmail}
          errorMessage={errors.lastname?.message}
          register={lastNameOptions}
          maxChar={MAX_CHAR_LAST_NAME}
          value={getValues("lastname")}
          onChange={({ target }) => {
            setValue("lastname", target.value, {
              shouldValidate: true,
            });
          }}
        />
      </Col>

      <Col lg={4}>
        <label className="fw-semibold">{translate("PHONE_NUM")}</label>
        <RHFInput
          type="text"
          disabled={isLoadingContact || isEnteringEmail}
          placeholder={translate("PHONE_NUM")}
          errorMessage={errors.phone?.message}
          register={phoneOptions}
          onChange={({ target }) => {
            setValue("phone", getDigitsFromString(target.value), {
              shouldValidate: true,
            });
          }}
        />
      </Col>
    </Row>
  );

  const contactSteps = [
    {
      key: "1",
      label: translate("EMAIL_VERIFICATION"),
      circleLabel: "A",
      component: renderEmailForm(),
      state: {
        highlighted: !getValues("email") || !isValidEmail(getValues("email")),
        active: !getValues("email"),
        disabled: isLoadingContact,
      },
    },
    {
      key: "2",
      label: translate("CONTACT_PERSON_DETAILS"),
      circleLabel: "B",
      component: getValues("email") ? (
        renderContactForm()
      ) : (
        <span className="text-gray fs-italic p-sm">
          {translate("EMAIL_VERIFY_INFO")}
        </span>
      ),
      state: {
        highlighted: contact
          ? !!getValues("email") && isValidEmail(getValues("email"))
          : isEmailFilled,
        active: contact ? !!getValues("email") : isEmailFilled,
        disabled: isLoadingContact,
      },
    },
  ];

  const onBackButtonClicked = () => {
    if (setShowContactsList) {
      setShowContactsList?.(true);
    }
    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
  };

  return (
    <div className="h-100 d-flex flex-column">
      <Col md={12} className="mt-sm flex-1">
        <VerticalStepper steps={contactSteps} />
      </Col>
      <div className="pt-sm d-flex justify-content-between ">
        <Button
          variant="outline-primary"
          onClick={() => onBackButtonClicked()}
          disabled={isLoadingContact}
        >
          <div className="d-flex">
            <ArrowLeft20Regular />
            <span className="px-sm">
              {isNewCustomer
                ? translate("BACK_TO_CUSTOMER_DETAILS")
                : translate("BACK_TO_CONTACTS")}
            </span>
          </div>
        </Button>
        <Button
          onClick={createOrUpdateContact}
          disabled={
            !isValid || isExistingContact || isLoadingContact || isEnteringEmail
          }
        >
          <div className="d-flex">
            <span className="px-sm">
              {isNewCustomer
                ? translate("CHOOSE_BUSINESS_AREA")
                : contactCreateEditTranslation}
            </span>
            <ArrowRight20Regular />
          </div>
        </Button>
      </div>
    </div>
  );
}
