import { startOfMonth } from "date-fns";
import { Col, Row } from "react-bootstrap";
import { Warning20Regular } from "@fluentui/react-icons";
import { useTranslation } from "../../../../hooks/use-translate";
import { ServiceInstance } from "../../../../models/activities/activity";
import { Customer } from "../../../../models/customer";
import ServiceBoxList from "./ServiceBoxList";
import "./ServicesPerMonthColumn.scss";
import { TranslationKey } from "../../../../i18n";
import { getMonthName } from "../../../../libs/date/month-name";

const MAX_COMPLETED_SERVICES = 3;
const MAX_INCOMPLETE_SERVICES_EXPANDED = 2;
const MAX_INCOMPLETE_SERVICES = 1;

type ServicesPerMonthColumnProps = {
  services: ServiceInstance[];
  customer: Customer;
  yearMonth: Date;
  completed: boolean;
  expanded: boolean;
  allEmpty?: boolean;
  showDivider?: boolean;
};

export default function ServicesPerMonthColumn({
  services,
  customer,
  yearMonth,
  completed,
  expanded,
  allEmpty = false,
  showDivider = true,
}: ServicesPerMonthColumnProps) {
  const { t: translate } = useTranslation();
  const CurrentMonthStart = startOfMonth(new Date());
  const backgroundColorClass =
    yearMonth.getMonth() === CurrentMonthStart.getMonth()
      ? "bg-currentMonthColumn"
      : "bg-monthColumn";

  const maxServices = () => {
    if (completed) {
      return MAX_COMPLETED_SERVICES;
    }
    if (expanded && !showDivider) {
      return MAX_INCOMPLETE_SERVICES_EXPANDED;
    }
    return MAX_INCOMPLETE_SERVICES;
  };

  let altMessage = completed
    ? translate("NO_COMPLETED_ACTIVITIES_FOR_GIVEN_MONTH", [
        translate(
          getMonthName(yearMonth.getMonth() + 1).toUpperCase() as TranslationKey
        ),
      ])
    : translate("NO_INCOMPLETE_ACTIVITIES_FOR_GIVEN_MONTH", [
        translate(
          getMonthName(yearMonth.getMonth() + 1).toUpperCase() as TranslationKey
        ),
      ]);
  if (allEmpty) {
    altMessage = translate("NO_ACTIVITIES_FOR_GIVEN_MONTH", [
      translate(
        getMonthName(yearMonth.getMonth() + 1).toUpperCase() as TranslationKey
      ),
    ]);
  }

  return (
    <Col className={`mx-xs ${backgroundColorClass}`}>
      <Row
        className={` px-sm cursor-pointer ${
          completed ? "pt-sm " : "pb-sm"
        } d-flex  ${backgroundColorClass}  ${
          !showDivider ? "mt-sm" : ""
        } align-items-start h-100`}
      >
        <Col>
          {!completed && showDivider && (
            <Row className="mb-sm mt-xs divider-border" />
          )}
          {services.length ? (
            <ServiceBoxList
              servicesList={services}
              maxNumberServices={maxServices()}
              customer={customer}
              expanded={expanded}
              completed={completed}
            />
          ) : (
            <Row>
              <Col md={12}>
                {allEmpty && completed ? (
                  <>
                    <Warning20Regular />

                    <label className="ml-xs transform-y-2">{altMessage}</label>
                  </>
                ) : (
                  <label>{altMessage}</label>
                )}
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Col>
  );
}
