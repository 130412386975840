import { Col, Row } from "react-bootstrap";
import { Warning16Regular } from "@fluentui/react-icons";

type CustomerRowNoActivitiesReasonProps = {
  message: string;
  testid: string;
};
export default function CustomerRowNoActivitiesReason({
  message,
  testid,
}: CustomerRowNoActivitiesReasonProps) {
  return (
    <Row className="bg-light mb-xs">
      <Col>
        <p className="m-0 p-2" data-testid={testid}>
          <Warning16Regular /> {message}
        </p>
      </Col>
    </Row>
  );
}
