import React, { createContext, useMemo, useState } from "react";
import "./section.scss";

export type SectionProps = {
  children: any;
  noBottomBorder?: boolean;
} & React.HTMLAttributes<HTMLElement>;

export const SectionContext = createContext<{
  isContentExpanded: boolean;
  setIsContentExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  isContentExpanded: false,
  setIsContentExpanded: () => {},
});

export default function Section({
  children,
  noBottomBorder = false,
}: SectionProps) {
  const [isContentExpanded, setIsContentExpanded] = useState(false);

  const initContextState = useMemo(
    () => ({
      isContentExpanded,
      setIsContentExpanded,
    }),
    [isContentExpanded]
  );

  const sectionCssClasses = noBottomBorder
    ? "section no-bottom-border"
    : "section";
  return (
    <SectionContext.Provider value={initContextState}>
      <section className={sectionCssClasses}>{children}</section>
    </SectionContext.Provider>
  );
}
