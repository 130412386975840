import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addMonths, endOfMonth, startOfMonth } from "date-fns";

import { generateMonths } from "libs/date/generate-month";
import { RootState } from "state";
import { AppDispatch } from "state/use-app-redux";
import {
  ActivitiesTimelineWindow,
  fetchActivitiesServicesForCustomersV2,
  setActivitiesTimelineWindow2,
} from "state/activities/actions";
import { Customer } from "models/customer";
import { UserSnapshot } from "models/user";
import { useStickyState } from "hooks";
import { TimespanFilter } from "views/activities/components/filters/service-filters";
import ServiceTableContent from "./ServiceTableContent";
import ServiceTableHeader from "./ServiceTableHeader";
import ChangeAssigneeModal from "./ChangeAssigneeModal";
import "./AssignmentManagementV2.scss";
import {
  isServiceInFutureMonth,
  isServiceInTimelineWindow,
  TOGGLE_EXPAND_BUTTON,
} from "./helpers";
import { useServicesFilter } from "./AssignmentManagementContext";

export interface TimelineMonths {
  id: number;
  name: string;
}

type Props = {
  customers: Customer[];
  isTimelineLoading: boolean;
  isFullLoading: boolean;
  selectedActivityFilterUsers: UserSnapshot[];
  isCollapsedView: boolean;
  openCustomerPanel: (customer: Customer) => void;
  incompleteFilterEnabled?: boolean;
};

export default function ServicesTable({
  customers,
  isTimelineLoading,
  isFullLoading,
  selectedActivityFilterUsers,
  isCollapsedView,
  openCustomerPanel,
  incompleteFilterEnabled = false,
}: Props) {
  const dispatch: AppDispatch = useDispatch();

  const {
    timelineWindow2,
    unfinishedActivities: {
      data: unfinishedActivities,
      isLoading: isUnfinishedActivitiesLoading,
    },
  } = useSelector((state: RootState) => state.activities);

  const [isExpanded, setIsExpanded] = useStickyState<boolean>(
    TOGGLE_EXPAND_BUTTON,
    false
  );

  const [timeline, setTimeline] = useState<ActivitiesTimelineWindow>();
  const [selectionModeActive, setSelectionModeActive] = useState(false);
  const [filteredCustomers, setFilteredCustomers] = useState<Customer[]>([]);
  const [selectedCustomerNumbers, setSelectedCustomerNumbers] = useState<
    string[]
  >([]);
  const [showModal, setShowModal] = useState(false);

  const isLoading = isTimelineLoading || isUnfinishedActivitiesLoading;

  const customersToDisplay = isLoading ? customers : filteredCustomers;

  const assignee =
    selectedActivityFilterUsers.length === 1
      ? selectedActivityFilterUsers[0]
      : undefined;

  const prevCustomersRef = useRef(filteredCustomers);

  const timelineFilter = useMemo(
    () => new TimespanFilter(timelineWindow2),
    [timelineWindow2]
  );

  const { servicesFilter } = useServicesFilter();

  const onMoveTimeWindow = async (addTimelineMonths: number) => {
    const previousFirst = startOfMonth(timelineWindow2[0]);
    const previousLast = endOfMonth(
      timelineWindow2[timelineWindow2.length - 1]
    );

    const newFirst = addMonths(previousFirst, addTimelineMonths);
    const newLast = addMonths(previousLast, addTimelineMonths);

    const months = generateMonths(newFirst, newLast);

    const newTimeline = await dispatch(
      setActivitiesTimelineWindow2({ months, fetchActivities: false })
    ).unwrap();

    setTimeline(newTimeline);
  };

  const isOnlyOrderChanged = (
    oldCustomers: Customer[],
    newCustomers: Customer[]
  ) => {
    if (oldCustomers.length !== newCustomers.length) {
      return false;
    }

    const oldCustomerNumbers = oldCustomers
      .map((customer) => customer.customer_number)
      .sort();
    const newCustomerNumbers = newCustomers
      .map((customer) => customer.customer_number)
      .sort();

    return oldCustomerNumbers.every(
      (id, index) => id === newCustomerNumbers[index]
    );
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      if (timeline) {
        dispatch(fetchActivitiesServicesForCustomersV2(timeline));
      }
    }, 1000);

    return () => {
      clearTimeout(delay);
    };
  }, [timeline, dispatch]);

  useEffect(() => {
    if (!isOnlyOrderChanged(prevCustomersRef.current, filteredCustomers)) {
      resetSelectedCustomerNumbers();
    }

    prevCustomersRef.current = filteredCustomers;
  }, [filteredCustomers]);

  useEffect(() => {
    prevCustomersRef.current = filteredCustomers;
  }, [filteredCustomers]);

  useEffect(() => {
    const newDisplayedCustomers = customers.filter((customer) => {
      const customerServices = servicesFilter(customer.customer_number);
      const unfinishedCustomerServices = servicesFilter(
        customer.customer_number,
        unfinishedActivities
      );

      if (
        customerServices.length === 0 &&
        unfinishedCustomerServices.length === 0
      ) {
        return false;
      }

      if (incompleteFilterEnabled) {
        const hasIncompleteServicesInTimeWindow = customerServices.some(
          (service) =>
            service.activities.every((activity) =>
              timelineFilter.check(activity, service)
            ) && !service.isCompleted()
        );
        if (hasIncompleteServicesInTimeWindow) {
          return true;
        }

        const hasUnfinishedActivities = unfinishedCustomerServices.some(
          (service) => {
            return (
              !isServiceInTimelineWindow(timelineWindow2, service) &&
              !isServiceInFutureMonth(timelineWindow2, service)
            );
          }
        );
        return hasUnfinishedActivities;
      }

      return true;
    });

    setFilteredCustomers(newDisplayedCustomers);
  }, [
    customers,
    servicesFilter,
    incompleteFilterEnabled,
    unfinishedActivities,
    timelineFilter,
    timelineWindow2,
  ]);

  const isAllRowsChecked = () => {
    if (!selectionModeActive) {
      return;
    }

    if (
      !customers.length ||
      selectedCustomerNumbers.length === filteredCustomers.length
    ) {
      return true;
    }
    if (selectedCustomerNumbers.length) {
      return "mixed";
    }
    return false;
  };

  const handleAllRowsChecked = () => {
    if (!selectionModeActive) {
      return;
    }

    setSelectedCustomerNumbers(
      isAllRowsChecked() === true
        ? []
        : filteredCustomers.map((customer) => customer.customer_number)
    );
  };

  const resetSelectedCustomerNumbers = () => setSelectedCustomerNumbers([]);

  return (
    <div className="services-table2 position-relative min-width-1400">
      <ServiceTableHeader
        selectedActivityFilterUsers={selectedActivityFilterUsers}
        filteredMonths={timelineWindow2}
        moveTimeWindow={onMoveTimeWindow}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        isCollapsedView={isCollapsedView}
        isTimelineLoading={isTimelineLoading}
        selectionModeActive={selectionModeActive}
        setSelectionModeActive={setSelectionModeActive}
        checkbox={isLoading ? undefined : isAllRowsChecked()}
        handleCheckbox={handleAllRowsChecked}
        resetSelectedCustomerNumbers={resetSelectedCustomerNumbers}
        counter={customersToDisplay.length}
        selectionCounter={
          selectionModeActive ? selectedCustomerNumbers.length : undefined
        }
        openChangeAssigneeModal={() => setShowModal(true)}
      />

      <ServiceTableContent
        filteredMonths={timelineWindow2}
        customers={customersToDisplay}
        selectedCustomerNumbers={selectedCustomerNumbers}
        setSelectedCustomerNumbers={setSelectedCustomerNumbers}
        isCollapsedView={isCollapsedView}
        showCompleted={!incompleteFilterEnabled}
        isTimelineLoading={isTimelineLoading}
        isFullLoading={isFullLoading}
        isExpanded={isExpanded}
        openPanel={openCustomerPanel}
        showCheckbox={selectionModeActive}
      />

      {showModal && assignee && (
        <ChangeAssigneeModal
          onCancel={() => setShowModal(false)}
          assignee={assignee}
          selectedCustomerNumbers={selectedCustomerNumbers}
          resetSelectedCustomerNumbers={resetSelectedCustomerNumbers}
        />
      )}
    </div>
  );
}
