/**
 * Checks whether a string is a valid Swedish
 * Social Security Number (personnummer) using checksum
 *
 * Note: this is somewhat simplified because it does not take into account
 * that the date of the number is valid (e.g. '000000-0000' does return as true).
 *
 * @param ssn The string to check.
 * @param mustIncludeCentury Whether the century must be included in the SSN.
 * @returns Whether the string is a valid SSN.
 */
export function isValidSwedishSSN(ssn: string, mustIncludeCentury = true) {
  const stripped = ssn.replace(/\D/g, "");

  if (mustIncludeCentury && stripped.length !== 12) {
    return false;
  }

  const split = stripped.split("").reverse().slice(0, 10);

  if (split.length !== 10) {
    return false;
  }

  // Luhn's algorithm https://en.wikipedia.org/wiki/Luhn_algorithm
  const sum = split
    .map((n: string) => {
      return Number(n);
    })
    .reduce((previous, current, index) => {
      let tempCurrent = current;

      if (index % 2) tempCurrent *= 2;
      if (tempCurrent > 9) tempCurrent -= 9;

      return previous + tempCurrent;
    });

  return sum % 10 === 0;
}
