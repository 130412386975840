import { Spinner } from "@fluentui/react-components";

import { Dropdown } from "components/dropdown";
import { SpinnerSize } from "components/spinner";
import { useTranslation } from "hooks";
import { ActivityAppendix } from "models/activities/ActivityAppendix";
import { useGetAppendicesFactsQuery } from "services/activitiesApi";

type AdditionalTaxDocumentNameDropdownProps = {
  index: number;
  value: ActivityAppendix;
  onChange: (value: ActivityAppendix, index: number) => void;
  disabledOptions?: ActivityAppendix[];
  disabled?: boolean;
};

export function AdditionalTaxDocumentNameDropdown({
  index,
  value,
  onChange,
  disabledOptions,
  disabled = false,
}: AdditionalTaxDocumentNameDropdownProps) {
  const { t } = useTranslation();

  const { data: appendicesFacts, isLoading: isAppendicesFactsLoading } =
    useGetAppendicesFactsQuery();

  if (isAppendicesFactsLoading || !appendicesFacts) {
    return <Spinner size={SpinnerSize.ExtraSmall} />;
  }

  const handleOnChange = (selectedId: string) => {
    if (checkIsAlreadySelected(disabledOptions, selectedId)) {
      return;
    }

    // Find correct ActivityAppendix based on selected id
    const changedAppendixFact = appendicesFacts.find((appendixFact) => {
      return appendixFact.id === selectedId;
    });
    if (!changedAppendixFact) {
      return;
    }

    onChange(changedAppendixFact, index);
  };

  const checkIsAlreadySelected = (
    haystack?: ActivityAppendix[],
    needleId?: string
  ) => {
    if (!haystack || !needleId) {
      return false;
    }

    if (needleId === value.id) {
      return;
    }

    const result = haystack.some((item) => {
      return item.id === needleId;
    });

    return result;
  };

  const filterAppendicesFactsOptions = appendicesFacts.filter(
    (appendixFact) => {
      const isDisabled = checkIsAlreadySelected(
        disabledOptions,
        appendixFact.id
      );
      return !isDisabled;
    }
  );

  if (!filterAppendicesFactsOptions.length) {
    return null;
  }

  const appendicesFactsOptions = filterAppendicesFactsOptions.map(
    (appendixFact) => {
      return {
        label: appendixFact.name,
        value: appendixFact.id,
      };
    }
  );

  return (
    <Dropdown<string>
      disabled={isAppendicesFactsLoading || disabled}
      options={appendicesFactsOptions}
      value={value.id}
      placeholder={t("DROPDOWN_PLACEHOLDER")}
      onChange={handleOnChange}
    />
  );
}
