import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Add16Regular } from "@fluentui/react-icons";

import { Button } from "components/button";
import { Input } from "components/input";
import { useTranslation } from "hooks/use-translate";
import { isValidOrganizationNumberOrSSN } from "libs/is-valid-organization-number-or-ssn";
import { useGetCapegoCustomerQuery } from "services/ludvigApi";
import "components/input/Input.scss";

type CapegoIdFormFieldProps = {
  value: string;
  orgNumber?: string;
  onChangeCapegoId: (capegoId: string) => void;
};

export function CapegoIdFormField({
  value,
  onChangeCapegoId,
  orgNumber = "",
}: CapegoIdFormFieldProps) {
  const { t } = useTranslation();
  const [isAutoPopulated, setIsAutoPopulated] = useState(false);

  const { data: capegoData, isLoading: isLoadingCapegoData } =
    useGetCapegoCustomerQuery(orgNumber, {
      skip: !isValidOrganizationNumberOrSSN(orgNumber),
    });

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeCapegoId(e.target.value);
  };

  const onSelectCustomer = (item: any) => {
    onChangeCapegoId(item.systemMetadata.id.toString());
  };

  useEffect(() => {
    // Cleanup component
    return () => {
      setIsAutoPopulated(false);
    };
  }, []);

  useEffect(() => {
    //
    // Autopopulate field when capegoData is loaded
    //

    if (!capegoData) {
      return;
    }

    const findCapegoCustomers = capegoData.filter(
      (item) => !!item.systemMetadata
    );

    let foundCustomer;
    if (findCapegoCustomers && findCapegoCustomers.length === 1) {
      foundCustomer = { ...findCapegoCustomers[0] };
    }

    // If we only find one customer for this org number we might just as well populate the capegoId field
    if (foundCustomer && foundCustomer.systemMetadata && !isAutoPopulated) {
      onChangeCapegoId(foundCustomer.systemMetadata.id.toString());
      setIsAutoPopulated(true);
    }
  }, [capegoData, isAutoPopulated, value, onChangeCapegoId]);

  return (
    <div>
      <label>{t("CAPEGOFORTNOX_CONNECTION_CAPEGO_ID")} *</label>

      <Input
        isLoading={isLoadingCapegoData}
        value={value}
        placeholder={t("CAPEGOFORTNOX_CONNECTION_CAPEGO_ID")}
        onChange={handleChangeText}
      />

      {capegoData && capegoData.length > 1 && !value ? (
        <Row className="g-1">
          <Col xs={12}>{t("CAPEGO_FOUND_MULTIPLE_WITH_SAME_ORGNO")}</Col>
          {capegoData.map((item) =>
            item.systemMetadata ? (
              <Col xs="auto">
                <Button key={item.id} onClick={() => onSelectCustomer(item)}>
                  <Add16Regular className="mr-sm" />
                  {item.systemMetadata.companyName}
                </Button>
              </Col>
            ) : null
          )}
        </Row>
      ) : null}
    </div>
  );
}
