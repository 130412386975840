import { ReactNode, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import copy from "copy-to-clipboard";
import { Copy16Regular, ErrorCircle20Regular } from "@fluentui/react-icons";

import { Accordion } from "components/accordion";
import { TooltipInfoIcon } from "components/icon";
import Modal from "components/modal";
import { ModalHeader } from "components/modal/ModalHeader";
import { ModalFooter } from "components/modal/ModalFooter";
import Section from "components/section/Section";
import { OverlaySpinner } from "components/spinner";
import { useTranslation } from "hooks/use-translate";
import {
  canUploadPhysicalDeal,
  isDealEditable,
} from "libs/customerdeal-helpers";
import { isPlaceholderEmail } from "libs/generate-placeholder-email";
import { useServiceMatrix } from "libs/service-matrix";
import { Appendix } from "models/offer/CustomerDeal";
import { SigningMethod } from "models/offer/DealContact";
import { ServiceCategory } from "models/offer/ServiceLine";
import { LoadingStatus, RootState } from "state";
import { appendError, appendToastMessage } from "state/notifications";
import { SearchBy, searchForCustomer } from "state/offer/companyThunks";
import {
  fetchDeal,
  patchOffer,
  sendOfferForSigning,
  updateServicesAndPostDealData,
} from "state/offer/offersThunks";
import { AppDispatch } from "state/use-app-redux";
import ContractTexts from "views/offer/wizard/Summary/components/ContractTexts";
import FinalPrice from "views/offer/wizard/Summary/components/FinalPrice/FinalPrice";
import ServiceInfoTable from "views/offer/wizard/Summary/components/ServiceInfoTable";
import InvalidSigningMethodModal from "views/offer/wizard/Summary/components/modals/InvalidSigningMethodModal";
import MissingProjectManagerModal from "views/offer/wizard/Summary/components/modals/MissingProjectManagerModal";
import MissingSmallBusinessYear from "views/offer/wizard/Summary/components/modals/MissingSmallBusinessYear";
import UnapprovedServicesModal from "views/offer/wizard/Summary/components/modals/UnapprovedServicesModal";
import { WizardSection } from "views/offer/components/wizardSection";
import { OfferRouteHelper } from "views/offer/wizard/offerRoutes";

import "./summary.scss";

type FinalPriceSectionProps = {
  title: string;
  loadingStatus: LoadingStatus;
};

function FinalPriceSection({ title }: FinalPriceSectionProps) {
  return (
    <Accordion title={title}>
      <FinalPrice />
    </Accordion>
  );
}

type ContractTextsSectionProps = {
  title: string;
  contractTexts: Appendix[];
};

function ContractTextsSection({
  title,
  contractTexts,
}: ContractTextsSectionProps) {
  return (
    <Accordion title={title}>
      <ContractTexts contractTexts={contractTexts} />
    </Accordion>
  );
}

function Summary() {
  const dispatch: AppDispatch = useDispatch();
  const { t: translate } = useTranslation();
  const { offerId, orgId } = useParams<{ orgId: string; offerId: string }>();
  const navigate = useNavigate();
  const {
    GetCategoriesWithMissingPM,
    GetCategoriesWithMissingYear,
    IsSignerValid,
    GetSigner,
  } = useServiceMatrix();

  const {
    currentOffer: { data: currentOffer, status: loadingStatus },
  } = useSelector((state: RootState) => state.offers);

  const { currentUser: user } = useSelector((state: RootState) => state.users);

  const [renderExternalContent, setRenderExternalContent] =
    useState<ReactNode>("");

  const [showModal, setShowModal] = useState<{
    header: ReactNode;
    content: ReactNode;
    footer?: ReactNode;
    warning?: boolean;
  }>({ header: "", content: "" });

  const closeModal = () => {
    setShowModal({ header: "", content: "" });
  };

  useEffect(() => {
    if (orgId && offerId && !currentOffer?.id) {
      dispatch(
        searchForCustomer({
          searchData: {
            customerId: orgId,
            searchByParam: SearchBy.OrgNumber,
          },
          checkCustomerBlocked: false,
        })
      );
      dispatch(fetchDeal({ dealId: offerId, orgId }));
    }
  }, [orgId, offerId, dispatch, currentOffer?.id]);

  const serviceLines = (currentOffer?.service_areas ?? []).flatMap(
    (sa) => sa.service_lines
  );

  const allServiceCategories = serviceLines
    .flatMap((sl) => sl.service_groups)
    .flatMap((sg) => sg.service_categories);

  if (!allServiceCategories) {
    throw new Error("No service categories found");
  }

  const copyToClipBoard = async () => {
    if (!currentOffer?.customer?.org_number || !currentOffer?.id) {
      dispatch(
        appendError("OFFER_LINK_COPY_FAILED", {
          message: "Offer id or customer number missing",
        } as Error)
      );
      return;
    }

    const link = `${
      window.location.origin +
      OfferRouteHelper.getExistingSummary(
        currentOffer.customer.org_number,
        currentOffer.id
      )
    }`;
    try {
      await navigator.clipboard.writeText(link);
    } catch (err) {
      if (!copy(link)) {
        dispatch(appendError("OFFER_LINK_COPY_FAILED", err as Error));
      }
    } finally {
      dispatch(appendToastMessage("OFFER_LINK_COPIED", "success"));
    }
  };

  function renderUnapprovedModal(onApprove: () => void) {
    setShowModal({
      header: <ModalHeader headerTitleText="WARNING" />,
      footer: (
        <ModalFooter
          onSave={() => {
            onApprove();
            closeModal();
          }}
          onCancel={closeModal}
          labelSubmit="YES"
          labelCancel="NO"
        />
      ),
      content: (
        <div className="d-flex flex-column justify-content-center">
          <div className="p-xs d-flex ">
            {translate("UNAPPROVE_MODAL_MESSAGE")}
          </div>
        </div>
      ),

      warning: true,
    });
  }

  const toggleApproveServiceCategory = (serviceCategory: ServiceCategory) => {
    if (!currentOffer?.service_areas) {
      return;
    }

    const updatedAreas = ServiceCategory.updateServiceCategoryInServiceArea(
      currentOffer.service_areas,
      { ...serviceCategory, approved_by_email: user.email }
    );

    if (serviceCategory.approved_by_email) {
      renderUnapprovedModal(() => {
        dispatch(
          updateServicesAndPostDealData({
            service_areas: updatedAreas,
          })
        );
      });
      return;
    }

    dispatch(
      updateServicesAndPostDealData({
        service_areas: updatedAreas,
      })
    );
  };

  const showFailedSendForSigningModal = () => {
    setShowModal({
      header: <ModalHeader headerTitleText="WARNING" />,
      content: (
        <div className="d-flex px-lg pb-xs text-color-red align-items-center">
          <ErrorCircle20Regular className="mr-sm" />
          <span className="p-xxs ">{translate("FAILS_TO_SEND_OFFER")}</span>
        </div>
      ),
      warning: true,
    });
  };

  const handleSendDigitalDeal = async () => {
    if (currentOffer?.id) {
      closeModal();

      const response = await dispatch(
        sendOfferForSigning({ dealId: currentOffer.id })
      );

      if (response.meta.requestStatus === "fulfilled") {
        navigate(OfferRouteHelper.getCompletion());
      }

      if (response.meta.requestStatus === "rejected") {
        showFailedSendForSigningModal();
      }
    }
  };

  const showSendDigitalDealConfirmationModal = () => {
    setShowModal({
      header: <ModalHeader headerTitleText="CONFIRMATION_MODAL_TITLE" />,
      footer: (
        <ModalFooter
          labelCancel="NO_CANCEL"
          onCancel={closeModal}
          labelSubmit="YES_SEND_OFFER"
          onSave={handleSendDigitalDeal}
        />
      ),
      content: (
        <div className="d-flex flex-column p-xxs">
          <div className="d-flex flex-column p-xxs">
            <div className="fw-bold d-flex justify-content-center">
              {translate("CONFIRMATION_MODAL_TEXT_1")}
            </div>
            <div className="d-flex justify-content-center mt-md">
              {translate("CONFIRMATION_MODAL_TEXT_2")}
            </div>
          </div>
        </div>
      ),
    });
  };

  const handleSendPhysicalDeal = async () => {
    if (currentOffer?.id) {
      closeModal();

      if (currentOffer && currentOffer.state === "contract") {
        navigate(OfferRouteHelper.getCompletion());
        return;
      }

      const response = await dispatch(patchOffer({ state: "contract" }));

      if (response.meta.requestStatus === "fulfilled") {
        navigate(OfferRouteHelper.getCompletion());
      }
    }
  };

  const showSendPhysicalDealConfirmationModal = () => {
    setShowModal({
      header: (
        <ModalHeader headerTitleText="CONFIRMATION_PHYSICAL_MODAL_TITLE" />
      ),
      footer: (
        <ModalFooter
          labelCancel="NO_CANCEL"
          onCancel={closeModal}
          labelSubmit="YES_PROCEED"
          onSave={handleSendPhysicalDeal}
        />
      ),
      content: (
        <div className="d-flex flex-column p-xxs">
          <div className="d-flex flex-column p-xxs">
            <div className="fw-bold d-flex justify-content-center">
              {translate("CONFIRMATION_PHYSICAL_MODAL_TEXT_1")}
            </div>
            <div className="d-flex justify-content-center mt-md">
              {translate("CONFIRMATION_PHYSICAL_MODAL_TEXT_2")}
            </div>
          </div>
        </div>
      ),
    });
  };

  const sendDealForSigning = async () => {
    const isDealMissingContactData = !(currentOffer?.contacts || []).length;

    if (!IsSignerValid() || isDealMissingContactData) {
      setRenderExternalContent(
        <InvalidSigningMethodModal
          onCancel={() => setRenderExternalContent("")}
        />
      );
      return false;
    }

    const signerContact = GetSigner();
    let isSigningMethodPhysical = false;
    if (
      signerContact?.is_signer &&
      signerContact.signing_method === SigningMethod.PHYSICAL
    ) {
      // If we have selected a physical signing method we send the deal for signing in the next step
      isSigningMethodPhysical = true;
    }

    // check if all selected services are approved by manager
    if (!currentOffer?.id) {
      setRenderExternalContent(
        <UnapprovedServicesModal
          onCancel={() => setRenderExternalContent("")}
        />
      );

      return false;
    }

    // check if there is a service category that is missing project manager
    const servicesMissingProjectManager = GetCategoriesWithMissingPM();
    if (servicesMissingProjectManager.length) {
      setRenderExternalContent(
        <MissingProjectManagerModal
          missingPMforServices={servicesMissingProjectManager.map(
            (s) => s.name
          )}
          onCancel={() => setRenderExternalContent("")}
        />
      );
      return false;
    }

    const servicesMissingYear = GetCategoriesWithMissingYear();
    if (servicesMissingYear.length) {
      setRenderExternalContent(
        <MissingSmallBusinessYear
          onCancel={() => setRenderExternalContent("")}
        />
      );

      return false;
    }

    // check if the signing method has valid data for the signing method
    if (!isSigningMethodPhysical) {
      const contactHasInvalidEmail =
        isPlaceholderEmail(signerContact?.contact.email ?? "") ||
        signerContact?.contact.email === "";

      if (contactHasInvalidEmail) {
        setRenderExternalContent(
          <InvalidSigningMethodModal
            onCancel={() => setRenderExternalContent("")}
          />
        );
        return false;
      }
    }

    if (isSigningMethodPhysical) {
      showSendPhysicalDealConfirmationModal();
    } else {
      showSendDigitalDealConfirmationModal();
    }

    return false;
  };

  const isLoading = () => currentOffer?.id;

  return (
    <WizardSection
      loadingStatus={isLoading() ? "idle" : "pending"}
      className="mr-0 ml-0"
      isBackHidden={!isDealEditable(currentOffer)}
      isNextHidden={
        !isDealEditable(currentOffer) && !canUploadPhysicalDeal(currentOffer)
      }
      onBeforeNext={async () => sendDealForSigning()}
      content={
        <div className="w-100 d-flex justify-content-end">
          <TooltipInfoIcon
            tooltipIcon={
              <Copy16Regular
                className="cursor-pointer text-color-blue"
                onClick={copyToClipBoard}
              />
            }
            tooltip={{ content: "COPY_LINK_TO_OFFER" }}
          />
        </div>
      }
    >
      {renderExternalContent && <>{renderExternalContent} </>}
      <Modal
        header={showModal.header}
        footer={showModal.footer}
        isOpen={showModal.content !== ""}
        onDismiss={() => closeModal()}
        isWarning={showModal.warning}
        size="medium"
      >
        {showModal.content}
      </Modal>
      <Row>
        <Col xs={12}>
          <Section>
            <ServiceInfoTable
              serviceLines={serviceLines}
              onToggleApproveServiceCategory={toggleApproveServiceCategory}
              isLoading={loadingStatus === "pending"}
            />
          </Section>
        </Col>

        <Col xs={12}>
          <FinalPriceSection
            title={translate("PRICE_ADJUSTMENT")}
            loadingStatus={loadingStatus}
          />
        </Col>

        <Col xs={12}>
          <ContractTextsSection
            contractTexts={currentOffer?.contract_texts ?? []}
            title={translate("OFFER_ASSIGNMENT_AGREEMENT")}
          />
        </Col>
      </Row>

      {currentOffer?.state === "offer" && loadingStatus === "pending" ? (
        <OverlaySpinner />
      ) : null}
    </WizardSection>
  );
}

export default Summary;
