import { useNavigate } from "react-router-dom";

import { useTranslation } from "hooks";
import { AppRouteHelper } from "routes";
import { CompletionSuccessComponent } from "shared/CompletionScreen";
import WizardSectionBusinessOpportunity from "../WizardSectionBusinessOpportinity";

export default function BusinessOpportunityCompletion() {
  const { t: translate } = useTranslation();

  const navigate = useNavigate();

  return (
    <WizardSectionBusinessOpportunity
      title={translate("COMPLETION_TITLE")}
      subtitles={[translate("BO_CREATION_FLOW_COMPLETE")]}
      backLabel="CREATE.NEW.DEAL.BACK_TO_FIRST_PAGE"
      customBackButtonRoute={AppRouteHelper.getBusinessOpportunity()}
    >
      <CompletionSuccessComponent
        successMessage={translate("BO_CREATION_COMPLETE_MESSAGE")}
        infoMessage={
          <div>
            {translate("BO_CREATION_COMPLETE_INFO_PT1")}{" "}
            <span
              className="blue-link"
              onClick={() => navigate(AppRouteHelper.getDeals())}
            >
              {translate("BO_CREATION_COMPLETE_INFO_PT2")}
            </span>
          </div>
        }
      />
    </WizardSectionBusinessOpportunity>
  );
}
