import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";

import { RootState } from "state";
import { addKyc, approveRisk } from "state/offer/kycSlice";
import { getWeightPoints, KYC } from "models/offer/kyc";
import { IKycQuestion, kycQuestions } from "views/offer/static/kyc-questions";
import { TextArea } from "components/textarea";
import Switch from "components/switch";
import Checkbox from "components/checkbox";
import { WizardSection } from "views/offer/components/wizardSection";
import { addKycToCurrentOffer } from "state/offer/kycThunks";
import { useTranslation } from "hooks/use-translate";

import "./kyc.scss";

type KYCFormData = {
  company_operations_text: string;
  accept_risk_level: boolean;
};

export function KYCSection() {
  const {
    data: kyc,
    existingKycData: kycCachedData,
    error: kycError,
    status,
  } = useSelector((state: RootState) => state.kyc);

  const dispatch = useDispatch();

  const { t: translate } = useTranslation();

  const { register } = useForm<KYCFormData>({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const weightPoints = getWeightPoints(kyc);

  const acceptRiskLevel = register("accept_risk_level", {
    onChange: (e) => dispatch(approveRisk(e.target.checked)),
  });

  const kycComment = register("company_operations_text", {
    value: kyc?.company_operations_text,
  });

  const hasAnswers = Object.keys(kycCachedData ?? {}).length !== 0;

  const kycHasErrors = Object.values(kycError ?? {}).length > 0;

  const kycRiskLevelColor =
    !hasAnswers || kycHasErrors
      ? "blue"
      : weightPoints === 0
      ? "green"
      : weightPoints < 3
      ? "yellow"
      : "red";

  async function setQuestionValues(question: IKycQuestion, checked?: boolean) {
    const value = checked ?? false;
    const newValue: Partial<KYC> = { [question.key]: value };
    dispatch(addKyc(newValue));
  }

  function getQuestionValue(question: IKycQuestion) {
    const kycHasData = kyc && Object.keys(kyc ?? {}).length > 0;
    if (!kycHasData) {
      return false;
    }

    const { [question.key]: answer } = kyc;
    if (answer === undefined) {
      return false;
    }
    return answer;
  }

  function isQuestionDisabled(
    hasAnswers: boolean,
    kycCachedData: Partial<KYC>,
    question: IKycQuestion
  ): boolean | undefined {
    if (kycHasErrors) {
      return true;
    }
    return (
      (hasAnswers && kycCachedData[question.key]) ||
      (question.key === "residence_is_high_risk_country" &&
        kycCachedData[question.key] !== undefined)
    );
  }

  const isNextHidden = weightPoints > 0 && !kyc.accept_risk_level;

  const onChangeAcceptRiskLevelHandler = async (
    e: React.FormEvent<HTMLElement | HTMLInputElement>
  ) => await acceptRiskLevel.onChange(e);

  return (
    <WizardSection
      serviceName="KYC"
      loadingStatus={status}
      onNext={() => dispatch(addKycToCurrentOffer())}
      isNextHidden={status !== "idle" || isNextHidden}
    >
      <Row>
        <Col md={5}>
          {kycQuestions.map((question: IKycQuestion) => (
            <Switch
              key={`kyc-question-${question.key}`}
              onToggleMethod={(e, { checked }) =>
                setQuestionValues(question, checked)
              }
              checked={getQuestionValue(question)}
              tooltipText={<span>{question.tooltip}</span>}
              label={question.question}
              disabled={isQuestionDisabled(hasAnswers, kycCachedData, question)}
            />
          ))}
          <Row className="pt-md">
            <Col>
              <label>{translate("KYC_COMMENT_LABEL")}</label>
              <TextArea
                formRegister={kycComment}
                onChange={({ target }) => {
                  dispatch(addKyc({ company_operations_text: target.value }));
                }}
                value={kyc?.company_operations_text ?? ""}
              />
            </Col>
          </Row>
        </Col>
        <Col md={1} className="d-flex justify-content-center">
          <div className="vr" />
        </Col>
        <Col md={5}>
          <Row>
            <h5>{translate("RISK_ASSESSMENT")}</h5>
            <p>{translate("RISK_FILLIN")}</p>
            {hasAnswers && kycRiskLevelColor === "green" && (
              <div
                id="pog-section-kyc-risk-analysis-green"
                className="card green"
              >
                <p>
                  Utifrån den information vi inhämtat i denna del av KYC:n
                  bedöms risken på kundens verksamhet vara låg och du som
                  rådgivare kan gå vidare i offert/avtalsarbetet. Tänk på att du
                  oavsett denna riskbedömning alltid ska vara uppmärksam på
                  signaler som tyder på penningtvätt eller finansiering av
                  terrorism. För mer information om de olika risknivåerna klicka
                  här:{" "}
                  <a
                    href="http://intranet.ludvig.se/organisation/finans/compliance/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    länk
                  </a>
                </p>
              </div>
            )}
            {hasAnswers && kycRiskLevelColor === "yellow" && (
              <div className="card yellow">
                <div
                  className="p-xs m-auto ms-xs"
                  style={{ background: "white" }}
                >
                  <Checkbox
                    {...acceptRiskLevel}
                    onChange={(e) => e && onChangeAcceptRiskLevelHandler(e)}
                    checked={kyc?.accept_risk_level ?? false}
                    label="(Rutan behöver klickas i för du ska kunna gå vidare)"
                  />
                </div>
                <p>
                  Utifrån den information vi inhämtat i denna del av KYC:n
                  bedöms risken på kundens verksamhet vara medelhög. Jag som
                  rådgivare och skapare av denna offert intygar att jag tagit
                  del och förstått varför kundens verksamhet ingår i riskgrupp
                  gul (medelrisk) och har vidtagit de åtgärder som krävs på
                  kunder med medelrisk . Tänk på att du alltid ska vara
                  uppmärksam på signaler som tyder på penningtvätt eller
                  finansiering av terrorism. Mer information om hur man hanterar
                  kunder i riskgrupp gul hittar du här:{" "}
                  <a
                    href="http://intranet.ludvig.se/organisation/finans/compliance/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    länk
                  </a>
                </p>
              </div>
            )}
            {hasAnswers && kycRiskLevelColor === "red" && (
              <div className="card red">
                <p>
                  Utifrån den information vi inhämtat i denna del av KYC:n
                  bedöms risken på kundens verksamhet vara hög. Det innebär att
                  du som rådgivare inte kan skapa en offert på kunden, utan
                  ombeds istället att kontakta din KC i ärendet. För mer
                  information kring hantering av kunder med hög risk hittar du
                  här:{" "}
                  <a
                    href="http://intranet.ludvig.se/organisation/finans/compliance/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    länk
                  </a>
                  .
                </p>
                <div
                  className="p-xs m-auto ms-xs"
                  style={{ background: "white" }}
                >
                  <Checkbox
                    {...acceptRiskLevel}
                    onChange={(e) => e && onChangeAcceptRiskLevelHandler(e)}
                    checked={kyc?.accept_risk_level ?? false}
                    label="(Rutan behöver klickas i för du ska kunna gå vidare)"
                  />
                </div>
                <p>
                  KC har varit i kontakt med Compliance och Compliance har gett
                  sitt medgivande till att skapa offerten. Jag som rådgivare och
                  skapare av denna offert är införstådd med att detta är en
                  högriskkund och har vidtagit de åtgärder som Compliance anser
                  vara nödvändiga för att ha denna högriskkund.
                </p>
              </div>
            )}
          </Row>
        </Col>
      </Row>
    </WizardSection>
  );
}

export default KYCSection;
