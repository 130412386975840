import { Fragment } from "react";
import {
  Button,
  Menu,
  MenuDivider,
  MenuGroup,
  MenuGroupHeader,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import { renderIcon } from "libs/render-icon";
import { MoreHorizontal20Regular } from "@fluentui/react-icons";

import TooltipV9, { TooltipV9Props } from "components/tooltip/TooltipV9";

import "./ContextualMenuV9.scss";

export type MenuGroups = {
  title: string;
  className?: string;
  groupItems: ListItem[];
};

type Props = {
  menuItems?: ListItem[];
  menuGroups?: MenuGroups[];
  buttonText?: string;
  buttonStyle?: string;
  buttonIcon?: () => JSX.Element;
};

export type ListItem = {
  hasDivider?: boolean;
  text?: string;
  icon?: () => JSX.Element;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  content?: JSX.Element;
  persistOnClick?: boolean;
  tooltip?: Omit<TooltipV9Props, "children">;
  hidden?: boolean;
};

function ContextualMenuV9({
  menuItems,
  menuGroups,
  buttonText = "",
  buttonStyle = "ghost-button min-width-fit-content justify-content-start",
  buttonIcon = () =>
    renderIcon(
      <MoreHorizontal20Regular className="fw-bold fem-2 cursor-pointer" />
    ),
}: Props) {
  const menuItem = (item: ListItem) => (
    <MenuItem
      onClick={item.onClick}
      disabled={item.disabled}
      icon={item.icon && item.icon()}
      persistOnClick={item.persistOnClick}
      hidden={item.hidden}
    >
      <div className="d-flex align-items-center">
        {item.content}
        {item.text && <div className={item.className}>{item.text}</div>}
      </div>
    </MenuItem>
  );

  const renderMenuItem = (item: ListItem) => (
    <Fragment key={item.text}>
      {item.tooltip ? (
        <TooltipV9 {...item.tooltip}>{menuItem(item)}</TooltipV9>
      ) : (
        menuItem(item)
      )}
      {item.hasDivider && <MenuDivider key={`divider-${item.text}`} />}
    </Fragment>
  );

  return (
    <Menu>
      <MenuTrigger disableButtonEnhancement>
        <Button className={buttonStyle}>
          {buttonIcon()}
          <span>{buttonText}</span>
        </Button>
      </MenuTrigger>

      <MenuPopover>
        <MenuList className="menu-list">
          {menuItems && menuItems.map((item) => renderMenuItem(item))}
          {menuGroups &&
            menuGroups.map((group, index) => (
              <Fragment key={`section-${group.title}`}>
                <MenuGroup>
                  <MenuGroupHeader className={group.className}>
                    {group.title}
                  </MenuGroupHeader>
                  {group.groupItems.map((item) => renderMenuItem(item))}
                </MenuGroup>
                {index !== menuGroups.length - 1 && <MenuDivider />}
              </Fragment>
            ))}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
}

export default ContextualMenuV9;
