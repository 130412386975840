import React, {createContext, useCallback, useContext, useState,} from "react";
import {Providers} from "@microsoft/mgt-element";
import {useSelector} from "react-redux";
import {User} from "../models/user";
import {LoadingStatus, RootState} from "../state";
import {AccessToken} from "./access-token";

type AuthContext = {
  user?: User;
  userHasSystemRole: (role: string) => boolean;
  getToken: () => Promise<string>;
  loadingStatus: LoadingStatus;
};

const authContext = createContext<AuthContext>({
  user: undefined,
  userHasSystemRole: () => false,
  getToken: () => { return new Promise<string>((resolve) => resolve(""));},
  loadingStatus: "idle",
});

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth(): AuthContext {
  const [token] = useState<AccessToken>();
  const {currentUser, loadingStatus} = useSelector(
      (state: RootState) => state?.users
  );

  const getUser = useCallback(() => {
    if ("id" in currentUser) {
      return currentUser;
    }

    return undefined;
  }, [currentUser]);

  const userHasSystemRole = (role: string) => {
    return currentUser?.systemRoles?.includes(role) ?? false;
  }

  const getToken = async () => {
    if (!token) {
      throw new Error("Token not set.");
    }

    return Providers.globalProvider.getAccessToken();
  };

  return {
    user: getUser(),
    getToken,
    userHasSystemRole,
    loadingStatus
  }
}

export function AuthorizationProvider({children}: { children: React.ReactNode }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}