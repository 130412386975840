import { ChevronDown24Regular } from "@fluentui/react-icons";

import { TranslationKey } from "i18n";
import { Translate } from "i18n/translator";
import {
  CustomerActivitiesServices,
  Recurrence,
  ServiceInstance,
  ServiceTypes,
} from "models/activities/activity";

export const FILTER_ACTIVITY_USERS_IDS = "localFilterUserIds";
export const FILTER_NEXT_ACTIVITY_USERS = "localFilterNextActivityUsers";
export const FILTER_ACTIVITY_SERVICE_TYPES = "localFilterServiceTypes";
export const FILTER_ACTIVITY_DEFINITIONS = "localFilterActivityDefinitions";
export const FILTER_ACTIVITY_FREQUENCIES = "localFilterActivityFrequencies";
export const FILTER_ACTIVITY_STATUSES = "localFilterActivityStatuses";
export const FILTER_SELECTED_DATE = "localFilterSelectedDate";
export const FILTER_OVERDUE_ENABLED = "localFilterOverdueEnabled";
export const FILTER_ACTIVITY_DEADLINE = "localFilterActivityDeadline";
export const SORT_HANDED_DOCS = "localSortHandedDocs";
export const SELECTED_TAB_VIEW = "localSelectedTabView";
export const FILTER_INCOMPLETE_ENABLED = "localFilterIncompleteEnabled";
export const TOGGLE_EXPAND_BUTTON = "localToggleExpandButton";
export const ACTIVITY_STATUS_PASSED_DEADLINE_KEY = "PASSED_DEADLINE";

export const RecurrenceTypes = Object.values(Recurrence);
export const ServiceTypesList = Object.values(ServiceTypes);

export enum TabViewValue {
  ALL = "all",
  ACTIVE = "active",
  COLLAPSED = "collapsed",
}

export function generateDeadlineOptions(
  translate: Translate<TranslationKey>,
  days: number
) {
  const deadlineOptions = [];
  for (let i = 1; i <= days; i++) {
    deadlineOptions.push({
      value: i.toString(),
      text: `${i} ${translate(
        i > 1 ? "WORKING_DAYS_FROM_NOW" : "WORKING_DAY_FROM_NOW"
      )}`,
    });
  }
  return deadlineOptions;
}

export const getServiceBoxMonth = (serviceBoxId: string) => {
  const monthString = serviceBoxId.split("-").pop() ?? "";
  return Number(monthString.substring(1));
};

export const getMonthName = (month: number): TranslationKey => {
  switch (month) {
    case 2:
      return "FEBRUARY";
    case 3:
      return "MARCH";
    case 4:
      return "APRIL";
    case 5:
      return "MAY";
    case 6:
      return "JUNE";
    case 7:
      return "JULY";
    case 8:
      return "AUGUST";
    case 9:
      return "SEPTEMBER";
    case 10:
      return "OCTOBER";
    case 11:
      return "NOVEMBER";
    case 12:
      return "DECEMBER";
    default:
      return "JANUARY";
  }
};

export interface MonthOrder {
  month: number;
  year: number;
}

export const groupByYearAndMonth = (
  serviceInstances: ServiceInstance[]
): {
  groupedActivities: { [year: number]: { [month: number]: ServiceInstance[] } };
  yearsOrder: { year: number; monthsOrder: MonthOrder[] }[];
} => {
  const groupedActivities: {
    [year: number]: { [month: number]: ServiceInstance[] };
  } = {};
  const yearsOrder: { year: number; monthsOrder: MonthOrder[] }[] = [];

  serviceInstances.forEach((instance) => {
    const { month, year } = instance;

    if (!groupedActivities[year]) {
      groupedActivities[year] = {};
      yearsOrder.push({ year, monthsOrder: [] });
    }

    if (!groupedActivities[year][month]) {
      groupedActivities[year][month] = [];
      const yearEntry = yearsOrder.find((entry) => entry.year === year);
      if (yearEntry) {
        yearEntry.monthsOrder.push({ month, year });
      }
    }

    groupedActivities[year][month].push(instance);
  });

  return { groupedActivities, yearsOrder };
};

export const unfinishedActivitiesLength = (
  unfinishedActivityServices: ServiceInstance[]
) => {
  const { groupedActivities, yearsOrder } = groupByYearAndMonth(
    unfinishedActivityServices
  );

  const remainingActivities: ServiceInstance[] = yearsOrder.reduce<
    ServiceInstance[]
  >((acc, yearInstance) => {
    const { year, monthsOrder } = yearInstance;
    const months = monthsOrder.slice(2);

    const activitiesForYear = months.reduce<ServiceInstance[]>(
      (monthAcc, { month }) => {
        return monthAcc.concat(groupedActivities[year][month]);
      },
      []
    );

    return acc.concat(activitiesForYear);
  }, []);

  return remainingActivities.length > 0 ? (
    `+ ${remainingActivities.length}`
  ) : (
    <ChevronDown24Regular className="color-redLight" />
  );
};

export const getCustomerServices = (
  customerNumber: string,
  services: CustomerActivitiesServices[]
) => {
  return (
    services.find((service) => service.customer_id === customerNumber)
      ?.services ?? []
  );
};

const isServiceInMonth = (date: Date, { year, month }: ServiceInstance) =>
  year === date.getFullYear() && month === date.getMonth() + 1;

export const getServicesInTimelineWindow = (
  date: Date,
  servicesInTimeWindow: ServiceInstance[]
) => servicesInTimeWindow.filter((service) => isServiceInMonth(date, service));

export const isServiceInTimelineWindow = (
  timelineWindow: Date[],
  service: ServiceInstance
) => timelineWindow.some((yearMonth) => isServiceInMonth(yearMonth, service));

export const isServiceInFutureMonth = (
  timelineWindow: Date[],
  service: ServiceInstance
) => {
  const lastTimelineMonth =
    timelineWindow[timelineWindow.length - 1].getMonth() + 1;
  const lastTimelineYear =
    timelineWindow[timelineWindow.length - 1].getFullYear();

  if (service.year > lastTimelineYear) {
    return true;
  }
  if (service.year === lastTimelineYear) {
    return service.month >= lastTimelineMonth;
  }
  return false;
};
