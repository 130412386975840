import { startOfMonth, subMonths, addMonths } from "date-fns";
import { plainToClass } from "class-transformer";
import produce from "immer";

import { generateMonths } from "libs/date/generate-month";
import {
  Activity,
  ActivityDefinition,
  CustomerActivitiesServices,
  ActivitiesTeam,
  FormAnswer,
  FormQuestion,
  ServiceInstance,
  ServiceTypeDefinition,
  ServiceTypes,
} from "models/activities/activity";
import { DeliveryFact } from "models/deliveryPlan";
import * as ActionTypes from ".";
import { ActivitiesAction } from ".";

export type CustomersActivitiesState = {
  data: CustomerActivitiesServices[];
  isLoading: boolean;
  hasFailed: boolean;
  hasLoaded: boolean;

  unfinishedActivities: {
    data: CustomerActivitiesServices[];
    isLoading: boolean;
    hasFailed: boolean;
    hasLoaded: boolean;
  };

  serviceBoxActivities: {
    data: ServiceInstance[];
    customerId: string;
    isLoading: boolean;
    hasFailed: boolean;
    hasLoaded: boolean;
  };

  timelineWindow: Date[];

  timelineWindow2: Date[];

  activitiesTeams: {
    data: ActivitiesTeam[];
    isLoading: boolean;
    hasFailed: boolean;
    hasLoaded: boolean;
  };

  currentServiceActivitiesForMonth: CustomerActivitiesServices[];

  serviceTypes: ServiceTypeDefinition[];

  definitions: {
    dataMap: { [key: string]: ActivityDefinition };
    data: ActivityDefinition[];
    isLoading: boolean;
  };

  optionalActivities: {
    data: Activity[];
    isLoading: boolean;
  };

  availableDeliveryServices: {
    data: DeliveryFact[];
    isLoading: boolean;
  };

  formQuestions: FormQuestion[];
  formAnswers: FormAnswer[];
};

const CurrentMonthStart = startOfMonth(new Date());
const CurrentMonthMinusTwo = subMonths(CurrentMonthStart, 2);
const CurrentMonthPlusOne = addMonths(CurrentMonthStart, 1);
const Months = generateMonths(CurrentMonthMinusTwo, CurrentMonthPlusOne);

const Months2 = generateMonths(CurrentMonthMinusTwo, CurrentMonthStart);

const initialActivitiesState: CustomersActivitiesState = {
  data: [],
  isLoading: false,
  hasFailed: false,
  hasLoaded: false,

  unfinishedActivities: {
    data: [],
    isLoading: false,
    hasFailed: false,
    hasLoaded: false,
  },

  serviceBoxActivities: {
    data: [],
    customerId: "",
    isLoading: false,
    hasFailed: false,
    hasLoaded: false,
  },

  timelineWindow: Months,
  timelineWindow2: Months2,

  activitiesTeams: {
    data: [],
    isLoading: false,
    hasFailed: false,
    hasLoaded: false,
  },

  currentServiceActivitiesForMonth: [],

  serviceTypes: [],

  definitions: {
    dataMap: {},
    data: [],
    isLoading: false,
  },

  optionalActivities: {
    data: [],
    isLoading: false,
  },

  availableDeliveryServices: {
    data: [],
    isLoading: false,
  },

  formQuestions: [],
  formAnswers: [],
};

export function activitiesReducer(
  state = initialActivitiesState,
  action: ActivitiesAction
) {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_SERVICE: {
      const data = action.payload;

      return {
        ...state,
        currentServiceActivitiesForMonth: data,
      };
    }
    case ActionTypes.SET_AVAILABLE_SERVICES: {
      const { payload } = action;
      return {
        ...state,
        availableDeliveryServices: {
          ...state.availableDeliveryServices,
          data: payload,
        },
      };
    }
    case ActionTypes.SET_AVAILABLE_SERVICES_LOADING: {
      return {
        ...state,
        availableDeliveryServices: {
          ...state.availableDeliveryServices,
          isLoading: action.payload,
        },
      };
    }
    // TODO: Replace with version 2
    case ActionTypes.SET_ACTIVITY_INSTANCE_LOADING: {
      const { data } = state;
      const customerActivities = data.find(
        (d) => d.org_number === action.payload.customerOrgNumber
      );

      if (!customerActivities) {
        throw new Error("Could not match customer");
      }

      const activity = customerActivities.services
        .flatMap((s) => s.activities)
        .find((a) => a.original_id === action.payload.activityId);

      if (!activity) {
        throw new Error("Could not match activity");
      }

      activity.isLoading = action.payload.isLoading;
      return { ...state, data };
    }
    case ActionTypes.SET_ACTIVITY_INSTANCE_LOADING_V2: {
      const { data } = state.serviceBoxActivities;

      if (!data) {
        throw new Error("Could not match customer");
      }

      const activity = data
        .flatMap((s) => s.activities)
        .find((a) => a.original_id === action.payload.activityId);

      if (!activity) {
        throw new Error("Could not match activity");
      }

      activity.isLoading = action.payload.isLoading;
      return {
        ...state,
        serviceBoxActivities: {
          data,
        },
      };
    }
    // TODO: Replace with version 2
    case ActionTypes.APPEND_ACTIVITY_INSTANCE_MESSAGE: {
      const { data } = state;

      const customerActivities = data.find(
        (d) => d.org_number === action.payload.customerOrgNumber
      );

      if (!customerActivities) {
        throw new Error("Could not match customer");
      }

      const activity = customerActivities.services
        .flatMap((s) => s.activities)
        .find((a) => a.original_id === action.payload.activityId);

      if (!activity) {
        throw new Error("Could not match activity");
      }

      activity.status = {
        status: action.payload.status,
        created_at: new Date(),
      };

      if (!activity.teams_conversation_weburl) {
        activity.teams_conversation_weburl =
          action.payload.teamsConversationUrl;
      }

      return { ...state, data };
    }
    case ActionTypes.APPEND_ACTIVITY_INSTANCE_MESSAGE_V2: {
      const { data } = state.serviceBoxActivities;

      if (!data) {
        throw new Error("Could not match customer");
      }

      const activity = data
        .flatMap((s) => s.activities)
        .find((a) => a.original_id === action.payload.activityId);

      if (!activity) {
        throw new Error("Could not match activity");
      }

      activity.status = {
        status: action.payload.status,
        created_at: new Date(),
      };

      if (!activity.teams_conversation_weburl) {
        activity.teams_conversation_weburl =
          action.payload.teamsConversationUrl;
      }

      return {
        ...state,
        serviceBoxActivities: {
          data,
        },
      };
    }
    // TODO: Replace with version 2
    case ActionTypes.UPDATE_ACTIVITY_DEADLINE: {
      const { data } = state;

      const customerActivities = data.find(
        (d) => d.org_number === action.payload.customerOrgNumber
      );

      if (!customerActivities) {
        throw new Error("Could not match customer");
      }

      const activity = customerActivities.services
        .flatMap((s) => s.activities)
        .find((a) => a.original_id === action.payload.activityId);

      if (!activity) {
        throw new Error("Could not match activity");
      }

      activity.deadline = action.payload.deadlineDate;

      return { ...state, data };
    }
    case ActionTypes.UPDATE_ACTIVITY_DEADLINE_V2: {
      const { data } = state.serviceBoxActivities;

      if (!data) {
        throw new Error("Could not match customer");
      }

      const activity = data
        .flatMap((s) => s.activities)
        .find((a) => a.original_id === action.payload.activityId);

      if (!activity) {
        throw new Error("Could not match activity");
      }

      activity.deadline = action.payload.deadlineDate;

      return {
        ...state,
        serviceBoxActivities: {
          data,
        },
      };
    }
    case ActionTypes.UPDATE_OPTIONAL_ACTIVITIES: {
      const optionalActivities = state.optionalActivities.data;

      // Set the status of the optional activities that have been added
      action.payload.added.forEach((a) => {
        const optional = optionalActivities.find(
          (o) => o.activity_type === a.activity_type
        );
        if (!optional) {
          return;
        }
        optional.available_to_add = false;
      });

      // Set the status of the optional activities that have been removed
      action.payload.removed.forEach((a) => {
        const optional = optionalActivities.find(
          (o) => o.activity_type === a.activity_type
        );
        if (!optional) {
          return;
        }
        optional.available_to_add = true;
      });

      return {
        ...state,
        optionalActivities: { data: optionalActivities, isLoading: false },
      };
    }
    case ActionTypes.SET_LOADING_OPTIONAL_ACTIVITIES: {
      return {
        ...state,
        optionalActivities: {
          ...state.optionalActivities,
          isLoading: action.payload,
        },
      };
    }
    case ActionTypes.SET_OPTIONAL_ACTIVITIES: {
      return {
        ...state,
        optionalActivities: { data: action.payload, isLoading: false },
      };
    }
    case ActionTypes.SET_TIMELINE_WINDOW: {
      const data = action.payload || Months;
      localStorage.setItem("actionsTimelineWindow", JSON.stringify(data));

      return {
        ...state,
        timelineWindow: data,
      };
    }
    case ActionTypes.SET_TIMELINE_WINDOW2: {
      const data = action.payload || Months2;
      localStorage.setItem("actionsTimelineWindow2", JSON.stringify(data));

      return {
        ...state,
        timelineWindow2: data,
      };
    }
    case ActionTypes.LOADING_SERVICES_ACTIVITIES_FAILED: {
      return { ...state, isLoading: false, hasLoaded: true, hasFailed: true };
    }
    case ActionTypes.SET_LOADING_SERVICE_ACTIVITIES: {
      return { ...state, isLoading: action.payload };
    }
    // TODO: Replace with version 2
    case ActionTypes.UPDATE_ACTIVITY: {
      const newState = produce(state, (draft) => {
        const activityInstance = draft.data
          .flatMap((d) => d.services)
          .flatMap((s) => s.activities)
          .find((a) => a.original_id === action.payload.original_id);

        if (activityInstance) {
          activityInstance.completed_at = activityInstance.completed_at
            ? undefined
            : action.payload.completed_at;
          activityInstance.marked_deleted = action.payload.marked_deleted;
          activityInstance.completed_by_user = action.payload.completed_by_user;
        }
      });

      return newState;
    }
    case ActionTypes.UPDATE_ACTIVITY_V2: {
      const newState = produce(state, (draft) => {
        const activityInstance = draft.serviceBoxActivities.data
          .flatMap((s) => s.activities)
          .find((a) => a.original_id === action.payload.original_id);

        if (activityInstance) {
          activityInstance.completed_at = activityInstance.completed_at
            ? undefined
            : action.payload.completed_at;
          activityInstance.marked_deleted = action.payload.marked_deleted;
          activityInstance.completed_by_user = action.payload.completed_by_user;
        }
      });

      return newState;
    }
    // TODO: To be removed after version V2 is live
    case ActionTypes.UPDATE_CUSTOMER_DEFAULT_ASSIGNED_USER: {
      const newState = produce(state, (draft) => {
        const customerActivities =
          draft.data.find(
            (d) => d.org_number === action.payload.customerOrgNumber
          ) ||
          draft.unfinishedActivities.data.find(
            (d) => d.org_number === action.payload.customerOrgNumber
          );

        if (!customerActivities) {
          throw new Error("Could not match customer");
        }

        const userField =
          action.payload.packageName === ServiceTypes.YearEnd
            ? "year_end_client_user"
            : "client_user";

        customerActivities.assigned_users[userField] = action.payload.userId;
      });

      return newState;
    }
    // TODO: Replace with version 2
    case ActionTypes.UPDATE_ACTIVITY_TYPE_ASSIGNED_USER: {
      const { data } = state;
      let activityInstances = data
        .filter(
          (serviceGroup) =>
            serviceGroup.customer_id === action.payload.customerNumber
        )
        .flatMap((serviceGroup) => serviceGroup.services)
        .flatMap((service) => service.activities);

      const { affectedActivities } = action.payload;
      if (affectedActivities) {
        activityInstances = activityInstances.filter((activity) =>
          affectedActivities.includes(activity.original_id)
        );
      } else {
        activityInstances = activityInstances.filter(
          (activity) => activity.original_id === action.payload.originalId
        );
      }

      activityInstances.forEach((activity) => {
        activity.assigned_user = action.payload.userId;
      });

      return { ...state, data };
    }
    case ActionTypes.UPDATE_ACTIVITY_TYPE_ASSIGNED_USER_V2: {
      const { data } = state.serviceBoxActivities;
      let activityInstances = data.flatMap((service) => service.activities);

      const { affectedActivities } = action.payload;
      if (affectedActivities) {
        activityInstances = activityInstances.filter((activity) =>
          affectedActivities.includes(activity.original_id)
        );
      } else {
        activityInstances = activityInstances.filter(
          (activity) => activity.original_id === action.payload.originalId
        );
      }

      activityInstances.forEach((activity) => {
        activity.assigned_user = action.payload.userId;
      });

      return {
        ...state,
        serviceBoxActivities: {
          data,
        },
      };
    }
    // TODO: Replace with version 2
    case ActionTypes.UPDATE_SERVICE_TYPE_DEFAULT_ASSIGNED_USER: {
      const newState = produce(state, (draft) => {
        const customerActivities = draft.data.filter(
          (c) => c.org_number !== action.payload.customerOrgNumber
        );
        const activityInstances = customerActivities
          .flatMap((customer) => customer.services)
          .filter(
            (service) => service.cb_service_type === action.payload.serviceType
          )
          .flatMap((activityInstance) => activityInstance.activities);

        activityInstances.forEach((a) => {
          a.assigned_user = action.payload.userId;
        });
      });

      return newState;
    }
    case ActionTypes.UPDATE_SERVICE_TYPE_DEFAULT_ASSIGNED_USER_V2: {
      const newState = produce(state, (draft) => {
        const activityInstances = draft.serviceBoxActivities.data
          .filter(
            (service) => service.cb_service_type === action.payload.serviceType
          )
          .flatMap((activityInstance) => activityInstance.activities);

        activityInstances.forEach((a) => {
          a.assigned_user = action.payload.userId;
        });
      });

      return newState;
    }
    // TODO: Remove not used anywhere
    case ActionTypes.SET_ACTIVITY_COMPLETED: {
      const { data } = state;
      const activityInstance = data
        .flatMap((d) => d.services)
        .flatMap((s) => s.activities)
        .find((a) => a.original_id === action.payload.original_id);

      if (activityInstance) {
        activityInstance.completed_at = action.payload.completed_at;
      }

      return { ...state, data };
    }
    // TODO: Remove not used anywhere
    case ActionTypes.SET_ACTIVITY_UNCOMPLETED: {
      const { data } = state;
      const activityInstance = data
        .flatMap((d) => d.services)
        .flatMap((s) => s.activities)
        .find((a) => a.original_id === action.payload.original_id);

      if (activityInstance) {
        activityInstance.completed_at = undefined;
      }

      return { ...state, data };
    }

    case ActionTypes.SET_SERVICES_ACTIVITIES: {
      const customerServices = action.payload;

      const filteredCustomers = state.data.filter(
        (d) => !customerServices.some((m) => m.org_number === d.org_number)
      );

      const mergedCastServicesCustomers = customerServices.map((s) => {
        return plainToClass(CustomerActivitiesServices, s);
      });

      return {
        ...state,
        data: [...filteredCustomers, ...mergedCastServicesCustomers],
        isLoading: false,
        hasFailed: false,
        hasLoaded: true,
      };
    }
    case ActionTypes.APPEND_SERVICES_ACTIVITIES: {
      // Get all unique org_numbers from the payload
      const orgNumbers = Array.from(
        new Set(
          action.payload.map((serviceActivity) => serviceActivity.org_number)
        )
      );

      // Filter out the old activities
      const customerActivities = state.data.filter(
        (activity) => !orgNumbers.includes(activity.org_number)
      );

      // Add the customers new activities
      customerActivities.push(...action.payload);

      return {
        ...state,
        data: customerActivities,
        isLoading: false,
        hasFailed: false,
      };
    }
    case ActionTypes.SET_ACTIVITIES_DEFINITIONS: {
      const dataMap = action.payload.reduce(
        (acc, val) => ({ ...acc, [val.title]: val }),
        {}
      );

      return {
        ...state,
        definitions: {
          data: action.payload,
          dataMap,
          isLoading: false,
        },
      };
    }
    case ActionTypes.SET_ACTIVITIES_DEFINITIONS_LOADING: {
      return {
        ...state,
        definitions: { ...state.definitions, isLoading: action.payload },
      };
    }
    case ActionTypes.LOADING_ACTIVITIES_DEFINITIONS_FAILED: {
      return {
        ...state,
        isLoading: false,
        hasLoaded: true,
        hasFailed: true,
      };
    }
    case ActionTypes.SET_FORM_QUESTIONS: {
      const tempArray: FormQuestion[] = [];
      // removing the default "false" answer for KYC Form5 to force the user to explicitly set the answer
      action.payload.formQuestions.forEach((question) => {
        const tempQuestion: FormQuestion = {
          ...question,
          answer: action.payload.removeDefaultAnswer
            ? undefined
            : question.answer,
        };
        tempArray.push(tempQuestion);
      });

      tempArray.sort((a, b) => a.sort_order - b.sort_order);

      return {
        ...state,
        formQuestions: tempArray,
      };
    }
    case ActionTypes.SET_FORM_QUESTION_ANSWER: {
      const tempArray: FormQuestion[] = [];
      state.formQuestions.forEach((question) => {
        const tempQuestion: FormQuestion = { ...question };
        if (question.question_id === action.payload.questionID) {
          tempQuestion.answer = action.payload.answer;
        }
        tempArray.push(tempQuestion);
      });

      tempArray.sort((a, b) => a.sort_order - b.sort_order);

      return {
        ...state,
        formQuestions: tempArray,
      };
    }

    case ActionTypes.SET_SERVICE_TYPES_LIST: {
      return {
        ...state,
        serviceTypes: action.payload.serviceTypes,
      };
    }

    case ActionTypes.SET_FORM_ANSWERS: {
      return {
        ...state,
        formAnswers: action.payload.formAnswers,
      };
    }
    case ActionTypes.RESET_FORM_ANSWERS: {
      return {
        ...state,
        formAnswers: [],
      };
    }
    case ActionTypes.SET_LOADING_UNFINISHED_ACTIVITIES: {
      return {
        ...state,
        unfinishedActivities: {
          ...state.unfinishedActivities,
          isLoading: action.payload,
        },
      };
    }
    case ActionTypes.LOADING_UNFINISHED_ACTIVITIES_FAILED: {
      return {
        ...state,
        unfinishedActivities: {
          data: [],
          isLoading: false,
          hasLoaded: true,
          hasFailed: true,
        },
      };
    }
    case ActionTypes.APPEND_UNFINISHED_ACTIVITIES: {
      const orgNumbers = Array.from(
        new Set(
          action.payload.map((serviceActivity) => serviceActivity.org_number)
        )
      );

      const customerUnfinishedActivities =
        state.unfinishedActivities.data.filter(
          (activity) => !orgNumbers.includes(activity.org_number)
        );

      customerUnfinishedActivities.push(...action.payload);

      return {
        ...state,
        unfinishedActivities: {
          data: customerUnfinishedActivities,
          isLoading: false,
          hasLoaded: true,
          hasFailed: false,
        },
      };
    }
    case ActionTypes.SET_SERVICE_BOX_ACTIVITIES: {
      return {
        ...state,
        serviceBoxActivities: {
          data: action.payload.serviceBoxActivities,
          customerId: action.payload.customerId,
        },
      };
    }
    case ActionTypes.SET_LOADING_SERVICE_BOX_ACTIVITIES: {
      return {
        ...state,
        serviceBoxActivities: {
          ...state.serviceBoxActivities,
          isLoading: action.payload,
        },
      };
    }
    case ActionTypes.LOADING_SERVICE_BOX_ACTIVITIES_FAILED: {
      return {
        ...state,
        serviceBoxActivities: {
          data: [],
          customerId: "",
          isLoading: false,
          hasLoaded: true,
          hasFailed: true,
        },
      };
    }
    case ActionTypes.UPDATE_SERVICE_ACTIVITIES: {
      const updateList = (
        customerActivitiesServices: CustomerActivitiesServices[]
      ) =>
        customerActivitiesServices.map((customerActivitiesService) =>
          customerActivitiesService.customer_id === action.payload.customerId
            ? {
                ...customerActivitiesService,
                services: customerActivitiesService.services.map(
                  (customerService) =>
                    customerService.service_box_id ===
                    action.payload.service.service_box_id
                      ? action.payload.service
                      : customerService
                ),
              }
            : customerActivitiesService
        );

      const removeCompletedServices = (
        customerActivitiesServices: CustomerActivitiesServices[]
      ): CustomerActivitiesServices[] =>
        customerActivitiesServices.reduce<CustomerActivitiesServices[]>(
          (acc, customerActivitiesService) => {
            if (
              customerActivitiesService.customer_id ===
              action.payload.customerId
            ) {
              const filteredServices =
                customerActivitiesService.services.filter(
                  (customerService) => !customerService.isCompleted()
                );

              if (filteredServices.length > 0) {
                const customerServices = {
                  ...customerActivitiesService,
                  services: filteredServices,
                };
                acc.push(customerServices as CustomerActivitiesServices);
              }
            } else {
              acc.push(customerActivitiesService);
            }

            return acc;
          },
          []
        );

      const updatedData = updateList(state.data);
      const updatedUnfinishedActivities = removeCompletedServices(
        plainToClass(
          CustomerActivitiesServices,
          updateList(state.unfinishedActivities.data)
        )
      );

      return {
        ...state,
        data: plainToClass(CustomerActivitiesServices, updatedData),
        unfinishedActivities: {
          ...state.unfinishedActivities,
          data: plainToClass(
            CustomerActivitiesServices,
            updatedUnfinishedActivities
          ),
        },
      };
    }
    case ActionTypes.SET_LOADING_ACTIVITIES_TEAM: {
      return {
        ...state,
        activitiesTeams: {
          ...state.activitiesTeams,
          isLoading: action.payload,
        },
      };
    }
    case ActionTypes.LOADING_ACTIVITIES_TEAM_FAILED: {
      return {
        ...state,
        activitiesTeams: {
          data: [],
          isLoading: false,
          hasLoaded: true,
          hasFailed: true,
        },
      };
    }
    case ActionTypes.APPEND_ACTIVITIES_TEAM: {
      const orgNumbers = Array.from(
        new Set(
          action.payload.map((serviceActivity) => serviceActivity.org_number)
        )
      );

      const customerTeam = state.activitiesTeams.data.filter(
        (activity) => !orgNumbers.includes(activity.org_number)
      );

      customerTeam.push(...action.payload);

      return {
        ...state,
        activitiesTeams: {
          data: customerTeam,
          isLoading: false,
          hasFailed: false,
        },
      };
    }
    default: {
      return state;
    }
  }
}
