import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Warning20Filled } from "@fluentui/react-icons";

import { Accordion } from "components/accordion";
import TooltipV9 from "components/tooltip/TooltipV9";
import { useTranslation } from "hooks/use-translate";
import { LoadingStatusEnum } from "models/enums/LoadingStatus.enum";
import { RootState } from "state";
import WizardSectionBusinessOpportunity from "../WizardSectionBusinessOpportinity";
import CustomerContacts from "./CustomerContacts";
import PotentialDeals from "./PotentialDeals";

export default function BusinessOpportunityCustomerDetails() {
  const { t: translate } = useTranslation();

  const { data: companyInfoData } = useSelector(
    (state: RootState) => state.offers.companyInfo
  );

  const {
    dealsByOrgNumber: {
      isLoading: isPotentialDealsLoading,
      data: potentialDeals,
    },
    customerContacts: { data: contacts, isLoading: isCustomerContactsLoading },
  } = useSelector((state: RootState) => state.sales);

  if (!companyInfoData) {
    return <div>{translate("NO_COMPANY")}</div>;
  }

  return (
    <WizardSectionBusinessOpportunity
      loadingStatus={
        isPotentialDealsLoading ? LoadingStatusEnum.PENDING : undefined
      }
      subtitles={[translate("BO_CUSTOMER_DETAILS_SUBHEADER")]}
      nextLabel="CREATE.NEW.DEAL.GO_TO_CONTACTS"
      backLabel="MAKE_ANOTHER_SEARCH"
      isNextDisabled={isCustomerContactsLoading || isPotentialDealsLoading}
    >
      <Row className="pt-xl">
        <h2 className="text-align-center">{companyInfoData.name}</h2>
      </Row>
      <Row className="customer-details m-auto text-align-center pt-lg">
        <Col className="m-auto">
          {translate("ORG_NUM")}:{" "}
          <span className="fw-semibold">{companyInfoData.org_number}</span>
        </Col>
        <Col className="m-auto">
          {translate("CUSTOMER_NUM")}:{" "}
          <span className="fw-semibold">
            {companyInfoData?.customer_number || (
              <TooltipV9 content="MISSING_CUSTOMER_NUMBER">
                <span style={{ color: "var(--red)" }}>
                  <Warning20Filled className="mb-xs" />
                </span>
              </TooltipV9>
            )}
          </span>
        </Col>
        <Col className="m-auto">
          {translate("ADDRESS")}:{" "}
          <span className="fw-semibold">{companyInfoData?.address}</span>
        </Col>
        <Col className="m-auto">
          {translate("ZIP")}:{" "}
          <span className="fw-semibold">{companyInfoData?.zip}</span>
        </Col>
        <Col className="m-auto">
          {translate("CITY")}:{" "}
          <span className="fw-semibold">{companyInfoData.city}</span>
        </Col>
      </Row>
      <Row className="pt-xl">
        <div className="d-flex align-items-center pb-lg">
          <div className="w-100">
            <Row className="pt-lg">
              <Col md={12} className="m-auto">
                <Accordion
                  title={
                    <>
                      {translate("BUSINESS_OPPORTUNITIES")}{" "}
                      <span className="disabled">
                        ({potentialDeals?.length || 0})
                      </span>
                    </>
                  }
                  isInitiallyOpen
                  loadingStatus={isPotentialDealsLoading ? "pending" : ""} // we only care if its loading in order to display a spinner
                >
                  <PotentialDeals />
                </Accordion>
                <Accordion
                  title={
                    <>
                      {translate("CONTACTS")}{" "}
                      <span className="disabled">
                        ({contacts?.length || 0})
                      </span>
                    </>
                  }
                  loadingStatus={isCustomerContactsLoading ? "pending" : ""}
                >
                  <CustomerContacts contacts={contacts} showNumberOfItems={4} />
                </Accordion>
              </Col>
            </Row>
          </div>
        </div>
      </Row>
    </WizardSectionBusinessOpportunity>
  );
}
