import { Customer } from "models/customer";
import { uuidv4 } from "libs/uuid";
import SkeletonServiceRow from "./SkeletonServiceRow";
import ServiceTableCustomerRow from "./ServiceTableCustomerRow";

type Props = {
  filteredMonths: Date[];
  customers: Customer[];
  selectedCustomerNumbers: string[];
  setSelectedCustomerNumbers: (customers: string[]) => void;
  isTimelineLoading: boolean;
  isFullLoading: boolean;
  isCollapsedView: boolean;
  isExpanded: boolean;
  openPanel: (customer: Customer) => void;
  showCompleted?: boolean;
  showCheckbox?: boolean;
};

export default function ServiceTableContent({
  filteredMonths,
  customers,
  selectedCustomerNumbers,
  setSelectedCustomerNumbers,
  isTimelineLoading,
  isFullLoading,
  isCollapsedView = false,
  isExpanded,
  openPanel,
  showCompleted = false,
  showCheckbox = false,
}: Props) {
  const isFilteredByMonth = filteredMonths.length === 1;

  const handleCheckbox = (customerNumber: string) => {
    if (selectedCustomerNumbers.includes(customerNumber)) {
      const updatedCustomers = selectedCustomerNumbers.filter(
        (cn) => cn !== customerNumber
      );
      setSelectedCustomerNumbers(updatedCustomers);
    } else {
      setSelectedCustomerNumbers([...selectedCustomerNumbers, customerNumber]);
    }
  };

  return (
    <div className="pb-md px-md">
      {isFullLoading &&
        [...Array(10)].map(() => (
          <SkeletonServiceRow
            key={uuidv4()}
            isFull={isFullLoading}
            isExpanded={isExpanded}
            openPanel={openPanel}
            isCollapsedView={isCollapsedView}
            isFilteredByMonth={isFilteredByMonth}
          />
        ))}
      {!isFullLoading &&
        customers.map((customer, index) =>
          isTimelineLoading ? (
            <SkeletonServiceRow
              key={customer.customer_number}
              customer={customer}
              openPanel={openPanel}
              isCollapsedView={isCollapsedView}
              isExpanded={isExpanded}
              isFilteredByMonth={isFilteredByMonth}
            />
          ) : (
            <ServiceTableCustomerRow
              key={customer.customer_number}
              customer={customer}
              isCollapsedView={isCollapsedView}
              isExpanded={isExpanded}
              openPanel={openPanel}
              filteredMonths={filteredMonths}
              showCompleted={showCompleted}
              checkbox={
                showCheckbox
                  ? selectedCustomerNumbers.includes(customer.customer_number)
                  : undefined
              }
              handleCheckbox={() => handleCheckbox(customer.customer_number)}
              showDivider={index !== customers.length - 1}
            />
          )
        )}
    </div>
  );
}
