import { useTranslation } from "../../hooks/use-translate";
import {
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutBody,
} from "../../components/layout/Layout";

export function AccessDeniedPage() {
  const { t } = useTranslation();

  return (
    <>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <h1>{t("ACCESS_DENIED")}</h1>
        </LayoutHeaderLeft>
      </LayoutHeader>

      <LayoutBody>{t("ACCESS_DENIED_INFO")}</LayoutBody>
    </>
  );
}
