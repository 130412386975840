import { Col, Row } from "react-bootstrap";

import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { useTranslation } from "hooks/use-translate";

type CustomerTypeRemoveModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onDiscard: () => void;
  onSave: () => void;
};

export default function CustomerTypeRemoveModal({
  isOpen,
  onCancel,
  onDiscard,
  onSave,
}: CustomerTypeRemoveModalProps) {
  const { t: translate } = useTranslation();

  return (
    <Modal
      size="medium"
      isOpen={isOpen}
      onDismiss={onDiscard}
      header={<ModalHeader headerTitleText="CUSTOMER_TYPE_REMOVE_HEADER" />}
      footer={
        <ModalFooter
          onCancel={onCancel}
          onSave={onSave}
          labelSubmit="CONFIRM_CUSTOMER_TYPE_REMOVE"
        />
      }
    >
      <Row className="minHeight-100 align-items-center pb-lg">
        <Col md={12}>{translate("REMOVE_CUSTOMER_TYPE_QUESTION")} </Col>
        <Col md={12}>{translate("REMOVE_CUSTOMER_TYPE_INFO")}</Col>
      </Row>
    </Modal>
  );
}
