/* eslint-disable camelcase */
import { useState } from "react";
import { MessageBar, Radio, RadioGroup } from "@fluentui/react-components";
import { addHours, addYears, startOfMonth, startOfYear } from "date-fns";

import Modal from "components/modal";
import { ModalHeader } from "components/modal/ModalHeader";
import { ModalFooter } from "components/modal/ModalFooter";
import { Button } from "components/button";
import { DatePickerV9 } from "components/date/DatePickerV9";
import { OverlaySpinner } from "components/spinner";
import { useTranslation } from "hooks/use-translate";
import { TranslationKey } from "i18n";
import { dateLanguageFormatter } from "libs/date/date-format";
import { subtractMonthsFromTodaysDate } from "libs/date/date-subtract-months";
import { CustomerPackage, DeliveryRecurrence } from "models/deliveryPlan";
import { GENERAL_ACCOUNTING_DELIVERY } from "state/customers/actions";
import { useAppSelector } from "state/use-app-redux";
import { DeadlineCalendar } from "../../../activities/serviceActivities/components/DeadlineCalendar";
import { DeliveryChangeProps } from ".";

const ONE_YEAR_AHEAD = addYears(new Date(), 1);

const DeliveryPackageOptionsWithRecurrences = [
  { name: "AccPackMstd", recurrence: "MONTHLY" },
  { name: "AccPackMspecial", recurrence: "MONTHLY" },
  { name: "AccPackQstd", recurrence: "QUARTERLY" },
  { name: "AccPackQspecial", recurrence: "QUARTERLY" },
  { name: "AccPackYstd", recurrence: "YEARLY" },
  { name: "AccPackYeu", recurrence: "YEARLY" },
] as const;

type GeneralAccountingDeliveryPackageOptions =
  typeof DeliveryPackageOptionsWithRecurrences[number]["name"];

const VATPeriodOptions = [
  "YEARLY",
  "MONTHLY",
  "QUARTERLY",
  "VAT_FREE",
] as const;

type VATPeriod = typeof VATPeriodOptions[number];

type GeneralAccountingOptions = {
  startDate: Date;
  deliveryDate: Date;
  recurrence: DeliveryRecurrence;
  vatPeriod: VATPeriod;
  deliveryPackage: GeneralAccountingDeliveryPackageOptions;
};

function getCustomerPackagesFromOptions(
  options: GeneralAccountingOptions
): CustomerPackage[] {
  const packages: CustomerPackage[] = [];

  packages.push({
    package_key_str: options.deliveryPackage,
  });

  const vatPackage = [
    { key: "YEARLY", value: "VatY" },
    { key: "MONTHLY", value: "VatM" },
    { key: "QUARTERLY", value: "VatQ" },
  ].find((kv) => kv.key === options.vatPeriod);

  if (vatPackage) {
    packages.push({
      package_key_str: vatPackage.value,
    });
  }

  return packages;
}

export function GeneralAccountingPackageConfiguration({
  customer,
  onChange,
  onRemoveAll,
  existingDeliveries,
  preSelectedStartDate,
  loadingDeliveries,
}: DeliveryChangeProps) {
  const { t: translate, language } = useTranslation();
  const formatter = dateLanguageFormatter(language, "yyyy-MM-dd");
  const [options, setOptions] = useState<GeneralAccountingOptions>({
    startDate: addHours(startOfYear(preSelectedStartDate || new Date()), 12),
    deliveryDate: addHours(startOfYear(preSelectedStartDate || new Date()), 12),
    vatPeriod: "" as VATPeriod,
    deliveryPackage: "AccPackMstd",
    recurrence: "MONTHLY",
  });
  const [showConfirmRemove, setShowConfirmRemove] = useState(false);
  const [endDate, setEndDate] = useState(new Date());
  const customerState = useAppSelector((state) => state.customers.data).find(
    (c) => c.customer.customer_number === customer.customer_number
  );

  if (!customerState) {
    return <>Missing customer</>;
  }

  const earliest_onboarding_date = customer.deliveryPlan.find(
    (cd) =>
      cd.delivery_name === GENERAL_ACCOUNTING_DELIVERY && cd.ends_at != null
  )?.ends_at;

  const isLoading = customerState && customerState.isLoading;

  return (
    <>
      {loadingDeliveries && (
        <OverlaySpinner label={translate("REMOVING_DELIVERIES")} />
      )}
      <div className="d-flex mt-2 mb-4 fg-4">
        <div>
          <h4>{translate("DELIVERY_PACKAGE")}</h4>
          <RadioGroup
            onChange={(_, option) => {
              const selected = DeliveryPackageOptionsWithRecurrences.find(
                (p) => p.name === option?.value
              );

              if (!selected) {
                throw new Error("Could not match recurrence");
              }

              setOptions({
                ...options,
                deliveryPackage:
                  option?.value as GeneralAccountingDeliveryPackageOptions,
                recurrence: selected.recurrence,
              });
            }}
            value={options.deliveryPackage}
          >
            {DeliveryPackageOptionsWithRecurrences.map((option) => (
              <Radio
                value={option.name}
                label={translate(option.name as TranslationKey)}
              />
            ))}
          </RadioGroup>
        </div>

        <div>
          <h4>{translate("VAT_PERIOD")}</h4>
          <RadioGroup
            onChange={(_, option) =>
              setOptions({
                ...options,
                vatPeriod: option?.value as VATPeriod,
              })
            }
            value={options.vatPeriod}
          >
            {VATPeriodOptions.map((option) => (
              <Radio
                value={option}
                label={translate(option as TranslationKey)}
              />
            ))}
          </RadioGroup>
        </div>

        <div>
          <h4>{translate("SET_START_DATE")}</h4>
          <DatePickerV9
            placeholder={translate("SET_START_DATE")}
            selectedDate={options.startDate}
            onSelectDate={(date) =>
              setOptions({
                ...options,
                ...(date && { startDate: addHours(startOfMonth(date), 12) }),
              })
            }
            borderless
            dateFormatter={(date) => formatter(date, "MMMM yyyy")}
            calendarProps={{
              isDayPickerVisible: false,
              showGoToToday: false,
              highlightSelectedMonth: true,
            }}
          />
          {options.recurrence === "YEARLY" && (
            <section>
              <p />
              <h4>{translate("SET_DELIVERY_DATE")}</h4>
              <DatePickerV9
                placeholder={translate("SET_DELIVERY_DATE")}
                selectedDate={options.deliveryDate}
                onSelectDate={(date) =>
                  setOptions({
                    ...options,
                    ...(date && {
                      deliveryDate: addHours(startOfMonth(date), 12),
                    }),
                  })
                }
                dateFormatter={(date) => formatter(date, "MMMM yyyy")}
                calendarProps={{
                  isDayPickerVisible: false,
                  showGoToToday: false,
                  highlightSelectedMonth: true,
                }}
                borderless
              />
            </section>
          )}
        </div>
      </div>

      {earliest_onboarding_date &&
        earliest_onboarding_date > options.startDate && (
          <MessageBar intent="warning" className="mb-2">
            Leveransen för löpande redovisning har ett start datum som ligger
            innan {new Date(earliest_onboarding_date).toString()}. Ändra
            vänligen start datum på nya leveransen.
          </MessageBar>
        )}

      <Button
        disabled={
          isLoading ||
          !options.deliveryPackage ||
          !options.vatPeriod ||
          !options.startDate ||
          (earliest_onboarding_date &&
            earliest_onboarding_date > options.startDate)
        }
        onClick={() => {
          const packages = getCustomerPackagesFromOptions(options);
          onChange(
            packages,
            options.recurrence,
            options.deliveryDate,
            options.startDate
          );
        }}
      >
        {existingDeliveries.length
          ? translate("CHANGE")
          : translate("COMPLETE")}
      </Button>

      {existingDeliveries.length > 0 && onRemoveAll && (
        <Button
          className="ms-2"
          variant="danger"
          onClick={() => {
            setShowConfirmRemove(true);
          }}
        >
          {translate("REMOVE")}
        </Button>
      )}

      <Modal
        isOpen={showConfirmRemove}
        header={<ModalHeader headerTitleText="REMOVE_PACKAGE" />}
        footer={
          <ModalFooter
            labelSubmit="CONFIRM"
            onCancel={() => setShowConfirmRemove(false)}
            onSave={() => {
              setShowConfirmRemove(false);
              if (onRemoveAll) {
                onRemoveAll(endDate);
              }
            }}
            saveButtonVariant="danger"
          />
        }
        onDismiss={() => setShowConfirmRemove(false)}
      >
        <label>{translate("SELECT_END_DATE")}</label>
        <DeadlineCalendar
          minDate={subtractMonthsFromTodaysDate(3)}
          language={language}
          translate={translate}
          maxDate={ONE_YEAR_AHEAD}
          value={endDate}
          onSelectedDate={(date) => setEndDate(date)}
        />

        <p>{translate("CONFIRM_DELETE_PACKAGE_DESCRIPTION")}</p>
      </Modal>
    </>
  );
}
