import { Spinner } from "@fluentui/react-components";
import { Info16Regular } from "@fluentui/react-icons";

import { useTranslation } from "hooks/use-translate";
import { isTranslationKey, TranslationKey } from "i18n";

type TitleTextProps = {
  headerTitleText?: string | TranslationKey;
  isLoading?: boolean;
  headerTitleContent?: never;
};

type TitleContentProps = {
  headerTitleText?: never;
  isLoading?: never;
  headerTitleContent?: JSX.Element;
};

type SubheaderTextProps = {
  subheaderText?: string | TranslationKey;
  subheaderIcon?: JSX.Element;
  subheaderContent?: never;
};

type SubheaderContentProps = {
  subheaderText?: never;
  subheaderIcon?: never;
  subheaderContent?: JSX.Element;
};

type SharedProps = {
  className?: string;
};

type ModalHeaderProps = (TitleTextProps | TitleContentProps) &
  (SubheaderTextProps | SubheaderContentProps) &
  SharedProps;

export function ModalHeader({
  headerTitleText,
  headerTitleContent,
  subheaderText,
  subheaderContent,
  subheaderIcon,
  isLoading,
  className = "",
}: ModalHeaderProps) {
  const { t: translate } = useTranslation();
  const renderHeaderTitle = () => {
    if (headerTitleContent) {
      return <div>{headerTitleContent}</div>;
    }
    return (
      <div className="modal-title pr-md d-flex align-items-center position-relative">
        <h3 className="mb-0 pl-sm">
          {headerTitleText && isTranslationKey(headerTitleText)
            ? translate(headerTitleText)
            : headerTitleText}
        </h3>
        {isLoading && <Spinner size="extra-small" className="ms-2" />}
      </div>
    );
  };

  const renderSubheader = () => {
    if (subheaderContent) {
      return <div className="pt-sm fpx-14 fw-400">{subheaderContent}</div>;
    }
    if (subheaderText) {
      return (
        <div className="pt-sm fpx-14 fw-400">
          {subheaderIcon ?? <Info16Regular className="mr-sm my-auto" />}
          {subheaderText && isTranslationKey(subheaderText)
            ? translate(subheaderText)
            : subheaderText}
        </div>
      );
    }
  };

  return (
    <div className={`modal-header ${className}`}>
      {renderHeaderTitle()}
      {renderSubheader()}
    </div>
  );
}
