import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { ArrowRight20Regular } from "@fluentui/react-icons";

import {
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutWrapper,
} from "components/layout/Layout";
import { Picker } from "components/picker";
import { useTranslation } from "hooks/use-translate";
import { AppRouteHelper } from "routes";
import {
  Content,
  ContentBody,
  ContentFooter,
} from "components/content/Content";
import { ContentHeader } from "components/content/ContentHeader";
import TooltipV9 from "components/tooltip/TooltipV9";
import "../wizard.scss";

const business = "business";

export enum CreateNewEnum {
  BUSINESS_OPPORTUNITY = "Opportunity",
  CUSTOMER = "Customer",
  CONTRACT = "Contract",
}

export default function CreateNewWizard() {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState<string>("");
  const isNotSelected = toggle.length === 0;

  const buttonNextStep = {
    [AppRouteHelper.getOffers()]: translate("CREATE_NEW_CONTRACT"),
    [AppRouteHelper.getCreateCustomer()]: translate("CREATE_NEW_CUSTOMER"),
    [AppRouteHelper.getBusinessOpportunity()]: translate(
      "CREATE_NEW_BUSINESS_OPPORTUNITY"
    ),
  };

  function getTitleSubText() {
    if (toggle) {
      if (toggle === business) {
        return translate("SELECTED_CREATE_NEW_OPTION", [
          translate("BUSINESS_OPPORTUNITY"),
        ]);
      }
      if (toggle === AppRouteHelper.getOffers()) {
        return translate("SELECTED_CREATE_NEW_OPTION", [translate("CONTRACT")]);
      }
      if (toggle === AppRouteHelper.getCreateCustomer()) {
        return translate("SELECTED_CREATE_NEW_OPTION", [translate("CUSTOMER")]);
      }
      if (toggle === AppRouteHelper.getBusinessOpportunity()) {
        return translate("SELECTED_CREATE_NEW_OPTION", [
          translate("BUSINESS_OPPORTUNITY"),
        ]);
      }
    }
    return translate("CREATE_NEW_DESCRIPTION");
  }

  return (
    <>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <h1>{translate("CREATE_NEW")}</h1>
        </LayoutHeaderLeft>
      </LayoutHeader>
      <LayoutWrapper>
        <Content>
          <ContentHeader
            title={translate("CREATE_NEW_HEADER")}
            subtitles={[getTitleSubText()]}
          />
          <ContentBody>
            <Row className="minHeight-100">
              <Col xl={2} lg={1} className="pt-lg m-auto d-none d-xl-block">
                <div className="horizontal-divider" />
              </Col>
              <Col xl={8} lg={10} className="m-auto">
                <div className="d-flex flex-wrap justify-content-center fg-4">
                  <Picker
                    name={translate("BUSINESS_OPPORTUNITY")}
                    key={CreateNewEnum.BUSINESS_OPPORTUNITY}
                    description={translate("BUSINESS_OPPORTUNITY_DESCRIPTION")}
                    className="mx-sm"
                    checked={toggle === AppRouteHelper.getBusinessOpportunity()}
                    onToggle={() =>
                      toggle === AppRouteHelper.getBusinessOpportunity()
                        ? setToggle("")
                        : setToggle(AppRouteHelper.getBusinessOpportunity())
                    }
                    toggles={[]}
                  />
                  <Picker
                    name={translate("CONTRACT")}
                    key={CreateNewEnum.CONTRACT}
                    description={translate("CONTRACT_DESCRIPTION")}
                    className="mx-sm"
                    checked={toggle === AppRouteHelper.getOffers()}
                    onToggle={() =>
                      toggle === AppRouteHelper.getOffers()
                        ? setToggle("")
                        : setToggle(AppRouteHelper.getOffers())
                    }
                    toggles={[]}
                  />
                  <Picker
                    name={translate("CUSTOMER")}
                    key={CreateNewEnum.CUSTOMER}
                    description={translate("CUSTOMER_DESCRIPTION")}
                    className="mx-sm"
                    checked={toggle === AppRouteHelper.getCreateCustomer()}
                    onToggle={() =>
                      toggle === AppRouteHelper.getCreateCustomer()
                        ? setToggle("")
                        : setToggle(AppRouteHelper.getCreateCustomer())
                    }
                    toggles={[]}
                  />
                </div>
              </Col>
              <Col xl={2} lg={1} className="pt-lg m-auto d-none d-xl-block">
                <div className="horizontal-divider" />
              </Col>
            </Row>
          </ContentBody>
          <ContentFooter>
            <Row className="stepper-buttons justify-content-end">
              <Col md="auto">
                <TooltipV9
                  content="FORWARD_BUTTON_TOOLTIP"
                  hasJSXContent
                  enabled={!!isNotSelected}
                >
                  <Button
                    disabled={isNotSelected}
                    onClick={() => {
                      navigate(toggle);
                    }}
                  >
                    <span className="d-flex flex-row align-items-center">
                      <span className="px-sm">
                        {isNotSelected
                          ? translate("CREATE_NEW")
                          : buttonNextStep[toggle]}
                      </span>
                      <ArrowRight20Regular className="ml-xs" />
                    </span>
                  </Button>
                </TooltipV9>
              </Col>
            </Row>
          </ContentFooter>
        </Content>
      </LayoutWrapper>
    </>
  );
}
