import { useEffect, useState } from "react";
import { FieldError, useFormContext } from "react-hook-form";

import "./DealFieldsStyles.scss";
import { DropdownV9 } from "components/dropdown/DropdownV9";
import { Deal } from "models/deals/deal";
import { DealDropdownProps } from "./models/field-types";
import { useUpdateDealProperty } from "./useUpdateDealProperty";

function DealDropdown({ configuration, disabled = false }: DealDropdownProps) {
  const { isLoading } = useUpdateDealProperty();

  const isDisabled = isLoading || disabled;

  const {
    formState: { errors },
    register,
    setValue,
    watch,
    trigger,
  } = useFormContext<Partial<Deal>>();

  const error = errors[configuration.property] as FieldError;
  const value = watch(configuration.property) as string;

  const [selectedId, setSelectedId] = useState(value);

  const dropdownOptions = !configuration.options
    ? []
    : configuration.options.map((option) => {
        const dropdownOption = {
          value: option.value,
          text: option.label,
        };
        return dropdownOption;
      });

  useEffect(() => {
    setSelectedId(value);
  }, [value]);

  return (
    <DropdownV9
      disabled={isDisabled}
      formRegister={register(configuration.property)}
      placeholder={configuration.placeholder}
      dropdownClassName={`deal-dropdown ${
        isDisabled ? "deal-dropdown-disabled" : ""
      }`}
      onOptionSelect={(_, data) => {
        setValue(configuration.property, data.selectedOptions[0], {
          shouldValidate: true,
          shouldDirty: true,
        });
      }}
      onBlur={(e) => {
        e.preventDefault();
        trigger();
      }}
      errorMessage={error?.message}
      defaultSelectedOptions={[selectedId]}
      selectedOptions={[selectedId]}
      options={dropdownOptions}
    />
  );
}

export default DealDropdown;
