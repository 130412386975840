import { useState } from "react";
import {
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { authMethod } from "auth";
import { useTranslation } from "hooks";
import { CustomersAPI } from "api/customers";
import { isValidOrganizationNumberOrSSN } from "libs/is-valid-organization-number-or-ssn";
import { getDigitsFromString, normalizeOrgNrAndSSN } from "libs/number-format";
import { removeWhiteSpace } from "libs/remove-whitespace-from-strings";
import { isValidSwedishZipcode } from "libs/is-valid-zipcode";
import {
  MAX_CHAR_ADDRESS,
  MAX_CHAR_CITY,
  MAX_CHAR_COMPANY_NAME,
  MAX_CHAR_FULL_NAME,
} from "constants/maxCharConsts";
import { DealCustomer } from "models/offer/Customer";
import { Customer } from "models/customer";
import { RHFInput } from "components/input/RHFInput";

type CompanyRegistrationInputs = {
  organizationNumber: string;
  companyName: string;
  legalForm: string;
  postalTown: string;
  postalCode: string;
  address: string;
};

type Props = {
  register: UseFormRegister<CompanyRegistrationInputs>;
  watch: UseFormWatch<CompanyRegistrationInputs>;
  setValue: UseFormSetValue<CompanyRegistrationInputs>;
  getValues: UseFormGetValues<CompanyRegistrationInputs>;
  errors: FieldErrors<CompanyRegistrationInputs>;
  trigger: UseFormTrigger<CompanyRegistrationInputs>;
  maconomyCustomer?: Customer;
  setMaconomyCustomer: (maconomyCustomer?: Customer) => void;
  isNewCustomer?: boolean;
  offerCustomer?: DealCustomer;
};

export default function CompanyForm({
  register,
  watch,
  setValue,
  getValues,
  errors,
  trigger,
  maconomyCustomer,
  setMaconomyCustomer,
  isNewCustomer,
  offerCustomer,
}: Props) {
  const { t: translate } = useTranslation();
  const watchLegalForm = watch("legalForm");
  const [orgNumber, setOrgNumber] = useState("");
  const [isLoadingCustomer, setIsLoadingCustomer] = useState(false);

  const organizationNumberValidation = register("organizationNumber", {
    required:
      watchLegalForm === "pp"
        ? translate("SSN_REQUIRED")
        : translate("ORGANIZATION_NUMBER_REQUIRED"),
    validate: {
      isValid: async (number) => {
        if (!number) {
          return false;
        }
        if (!isValidOrganizationNumberOrSSN(number, true)) {
          return translate("INVALID_ORGNR_OR_SSN_MESSAGE");
        }
        if (isNewCustomer) {
          const normalizedNumber = normalizeOrgNrAndSSN(number);
          const customer = await isExistingCustomer(normalizedNumber);
          if (customer) {
            return translate("CUSTOMER_EXIST_MACONOMY", [
              customer.customer_number,
            ]);
          }
        }
      },
    },
  });

  const companyNameValidation = register("companyName", {
    required:
      watchLegalForm === "pp"
        ? translate("CUSTOMER_NAME_REQUIRED")
        : translate("COMPANY_NAME_REQUIRED"),
  });

  const isExistingCustomer = async (newOrgNumber: string) => {
    if (newOrgNumber) {
      if (newOrgNumber === orgNumber) {
        return maconomyCustomer;
      }
      try {
        setIsLoadingCustomer(true);
        setOrgNumber(newOrgNumber);
        const token = await authMethod.getStoredAccessToken();
        const customer = await CustomersAPI.fetchCustomerById(
          token,
          newOrgNumber
        );
        setMaconomyCustomer(customer);
        return customer;
      } catch (e) {
        setMaconomyCustomer(undefined);
        return false;
      } finally {
        setIsLoadingCustomer(false);
      }
    }
  };

  const addressValidation = register("address", {
    required: translate("ADDRESS_REQUIRED"),
  });

  const postalTownValidation = register("postalTown", {
    required: translate("POSTAL_TOWN_REQUIRED"),
  });

  const postalCodeValidation = register("postalCode", {
    required: translate("POSTAL_CODE_REQUIRED"),
    validate: {
      isValid: (postalCode) =>
        isValidSwedishZipcode(postalCode) || translate("POSTAL_CODE_INVALID"),
    },
  });

  return (
    <>
      <Row className="mb-md">
        <Col md={4}>
          <label
            className="fw-semibold"
            htmlFor={organizationNumberValidation.name}
          >
            {watchLegalForm === "pp" ? translate("SSN") : translate("ORG_NUM")}{" "}
            *
          </label>
          <RHFInput
            register={organizationNumberValidation}
            placeholder={translate("ORGNR_OR_SSN_PLACEHOLDER")}
            trigger={trigger}
            onChange={(e) => {
              setValue(
                "organizationNumber",
                getDigitsFromString(e.target.value),
                {
                  shouldValidate: true,
                }
              );
            }}
            errorMessage={errors.organizationNumber?.message}
            disabled={!!offerCustomer?.org_number}
          />
        </Col>
        <Col md={4}>
          <label className="fw-semibold" htmlFor={companyNameValidation.name}>
            {watchLegalForm === "pp"
              ? translate("CUSTOMER_NAME")
              : translate("COMPANY_NAME")}{" "}
            *
          </label>
          <RHFInput
            placeholder={translate(
              watchLegalForm === "pp" ? "CUSTOMER_NAME" : "COMPANY_NAME"
            )}
            maxChar={
              watchLegalForm !== "pp"
                ? MAX_CHAR_COMPANY_NAME
                : MAX_CHAR_FULL_NAME
            }
            value={getValues("companyName")}
            onChange={(e) =>
              setValue("companyName", e.target.value, {
                shouldValidate: true,
              })
            }
            register={companyNameValidation}
            trigger={trigger}
            errorMessage={errors.companyName?.message}
            disabled={isLoadingCustomer}
          />
        </Col>
        <Col md={4}>
          <label className="fw-semibold" htmlFor={postalTownValidation.name}>
            {translate("CITY")} *
          </label>
          <RHFInput
            pattern=".{2,}"
            placeholder="Postort"
            maxChar={MAX_CHAR_CITY}
            value={getValues("postalTown")}
            onChange={(e) =>
              setValue("postalTown", e.target.value, {
                shouldValidate: true,
              })
            }
            register={postalTownValidation}
            trigger={trigger}
            errorMessage={errors.postalTown?.message}
            disabled={isLoadingCustomer}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <label className="fw-semibold" htmlFor={postalCodeValidation.name}>
            {translate("ZIP")} *
          </label>
          <RHFInput
            placeholder="XXXXX"
            onChange={(e) =>
              setValue("postalCode", removeWhiteSpace(e.target.value), {
                shouldValidate: true,
              })
            }
            register={postalCodeValidation}
            trigger={trigger}
            errorMessage={errors.postalCode?.message}
            disabled={isLoadingCustomer}
          />
        </Col>
        <Col md={4}>
          <label className="fw-semibold" htmlFor={addressValidation.name}>
            {translate("ADDRESS")} *
          </label>
          <RHFInput
            placeholder="Gatuvägen 11"
            register={addressValidation}
            maxChar={MAX_CHAR_ADDRESS}
            value={getValues("address")}
            onChange={(e) => {
              setValue("address", e.target.value, {
                shouldValidate: true,
              });
            }}
            trigger={trigger}
            errorMessage={errors.address?.message}
            disabled={isLoadingCustomer}
          />
        </Col>
      </Row>
    </>
  );
}
