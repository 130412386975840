import { plainToClass } from "class-transformer";
import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Dismiss16Regular } from "@fluentui/react-icons";

import { Dropdown } from "components/dropdown";
import { RHFInput } from "components/input/RHFInput";
import Switch from "components/switch";
import { useTranslation } from "hooks/use-translate";
import {
  generatePlaceholderEmail,
  isPlaceholderEmail,
} from "libs/generate-placeholder-email";
import { isValidEmail } from "libs/is-valid-email";
import { isValidPhone } from "libs/is-valid-phone";
import { isValidSwedishSSN } from "libs/is-valid-ssn";
import { getDigitsFromString } from "libs/number-format";
import { CreateCustomer, CustomerTypes } from "models/customer";
import { SigningMethod } from "models/offer/DealContact";
import { PersonPicker } from "components/people";
import VerticalStepper from "components/verticalStepper";
import { getNameFromEmail } from "libs/name-from-email";
import { removeWhiteSpace } from "libs/remove-whitespace-from-strings";
import {
  MAX_CHAR_FIRST_NAME,
  MAX_CHAR_LAST_NAME,
} from "constants/maxCharConsts";
import { LoadingStatusEnum } from "models/enums/LoadingStatus.enum";
import { CompanyTypeOptions } from "models/offer/Company";
import { DealCustomer } from "models/offer/Customer";
import { RootState } from "state";
import { createNewCustomer } from "state/customers/actions";
import { createOrUpdateCustomer } from "state/offer/companyThunks";
import {
  createUpdateContact,
  fetchDealsByOrgNumber,
  searchContact,
} from "state/sales/actions";
import { AppDispatch } from "state/use-app-redux";
import { CreateCustomerContext } from "../CreateCustomerContext";
import WizardSectionCustomer from "../components/WizardSectionCustomer";

export default function CustomerContactDetailsPage() {
  const { t: translate } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const { isLoadingNewCustomer } = useSelector(
    (state: RootState) => state.customers
  );

  const {
    currentDeal,
    dealsByOrgNumber: { isLoading: isPotentialDealsLoading },
  } = useSelector((state: RootState) => state.sales);

  const [isReseted, setReset] = useState(false);
  const [isLoadingContact, setIsLoadingContact] = useState(false);
  const [isEmailFilled, setIsEmailFilled] = useState(false);
  const [isExistingContact, setIsExistingContact] = useState(false);

  const { newCustomer, updateNewCustomer } = useContext(CreateCustomerContext);

  const isLoading = isLoadingNewCustomer || isLoadingContact;

  const {
    register,
    formState: { errors, isValid },
    setValue,
    control,
    trigger,
    getValues,
    reset,
  } = useForm<CreateCustomer>({
    mode: "onTouched",
  });

  useEffect(() => {
    // fetch deals only if there isn't a current deal already marked for update
    if (!currentDeal) {
      dispatch(fetchDealsByOrgNumber(newCustomer.CompanyRegistrationNumber));
    }
  }, [dispatch, newCustomer.CompanyRegistrationNumber, currentDeal]);

  useEffect(() => {
    if (!isReseted) {
      reset(newCustomer, { keepErrors: true });
    }
    setReset(true);
  }, [reset, newCustomer, isReseted]);

  const socialSecurityNumberOptions = register("SignerSsn", {
    validate: {
      isValid: (value) => {
        if (!value) {
          return true;
        }
        return isValidSwedishSSN(value) || translate("INVALID_FULLSSN_MESSAGE");
      },
    },
  });

  const firstNameOptions = register("FirstName", {
    required: translate("FIRSTNAME_REQUIRED"),
  });

  const lastNameOptions = register("LastName", {
    required: translate("LASTNAME_REQUIRED"),
  });

  const phoneOptions = register("Telephone", {
    validate: {
      isValid: (value) => {
        // Phone number is optional. Only validate if we have a number.
        if (!value) {
          return true;
        }

        return isValidPhone(value) || translate("PHONE_VALIDATION_MESSAGE");
      },
    },
  });

  const mobilePhoneOptions = register("Mobile", {
    validate: {
      isValid: (value) => {
        // Mobile phone number is optional. Only validate if we have a number.
        if (!value) {
          return true;
        }

        return isValidPhone(value) || translate("PHONE_VALIDATION_MESSAGE");
      },
    },
  });

  const emailOption = register("ElectronicMailaddress", {
    required: translate("ENTER_EMAIL_EXAMPLE"),
    validate: {
      isValid: (value) => {
        if (!value) {
          return true;
        }

        return isValidEmail(value) || translate("VALID_EMAIL");
      },
    },
  });

  async function postNewCustomer() {
    const formData = getValues();
    const newCustomerData = updateNewCustomer({
      ...formData,
      SalespersonEmail: newCustomer.SalespersonEmail,
      SalespersonFirstname: newCustomer.SalespersonFirstname,
      SalespersonLastname: newCustomer.SalespersonLastname,
    });

    setIsLoadingContact(true);
    const returnValue = await dispatch(createNewCustomer(newCustomerData));
    if (returnValue && !isExistingContact) {
      await dispatch(
        createUpdateContact({
          email: newCustomerData.ElectronicMailaddress,
          firstName: newCustomerData.FirstName,
          lastName: newCustomerData.LastName,
          phone: newCustomerData.Telephone,
          mobile: newCustomerData.Mobile,
        })
      );
    }
    // create the customer in Hubspot too
    await dispatch(
      createOrUpdateCustomer(
        plainToClass(DealCustomer, {
          address: newCustomerData.PostalAddress,
          postal_code: removeWhiteSpace(newCustomerData.ZipCode),
          postal_town: newCustomerData.PostalDistrict,
          org_number: newCustomerData.CompanyRegistrationNumber,
          customer_name: newCustomerData.CompanyName,
          legal_form: newCustomerData.CompanyForm,
        })
      )
    );

    setIsLoadingContact(false);
    return true;
  }

  const fetchContact = async (email: string) => {
    if (!isValidEmail(email)) {
      return;
    }
    setIsEmailFilled(true);
    setIsLoadingContact(true);
    try {
      const contacts = await dispatch(searchContact(email));
      const contactsFound = contacts && contacts.length !== 0;
      if (contactsFound) {
        const contact = contacts[0];
        setValue("SignerSsn", contact.social_security_number);
        setValue("FirstName", contact.firstName);
        setValue("LastName", contact.lastName);
        setValue("Telephone", contact.phone);
        setValue("Mobile", contact.mobile);
        setValue("ElectronicMailaddress", contact.email);

        setIsExistingContact(true);
      } else {
        setIsExistingContact(false);
      }
    } finally {
      setIsLoadingContact(false);
    }
  };

  const resetCustomerContactForm = () => {
    setValue("ElectronicMailaddress", "", {
      shouldValidate: true,
    });
    reset({
      SignerSsn: "",
      FirstName: "",
      LastName: "",
      Telephone: "",
      id: "",
    });
    updateNewCustomer({
      SalespersonEmail: "",
      SalespersonFirstname: "",
      SalespersonLastname: "",
    });
    setIsEmailFilled(false);
  };

  const handleMissingEmailToggle = (isChecked: boolean) => {
    const email = isChecked ? generatePlaceholderEmail() : "";

    setIsEmailFilled(isChecked || false);
    if (isChecked) {
      reset({
        SignerSsn: "",
        FirstName: "",
        LastName: "",
        Telephone: "",
        id: "",
      });
      setIsExistingContact(false);
    }
    setValue("ElectronicMailaddress", email, {
      shouldValidate: true,
    });
  };

  const renderEmailForm = () => (
    <Row className="mb-md pr-xl">
      <Col md={4}>
        <label className="fw-semibold">{translate("EMAIL")} *</label>
        {isEmailFilled ? (
          <div className="w-100 border border-dark d-flex justify-content-between align-items-center pl-sm py-xs ">
            <div className="text-grayed stringLength">
              {getValues("ElectronicMailaddress")}
            </div>
            <div onClick={resetCustomerContactForm}>
              <Dismiss16Regular className="text-gray mr-xs" />
            </div>
          </div>
        ) : (
          <RHFInput
            type="email"
            register={emailOption}
            disabled={isLoading}
            required
            placeholder="email@example.com"
            trigger={trigger}
            errorMessage={errors.ElectronicMailaddress?.message}
            onBlur={() => {
              fetchContact(getValues("ElectronicMailaddress"));
            }}
            onChange={({ target }) => {
              const { value } = target;
              setValue("ElectronicMailaddress", value, {
                shouldValidate: true,
              });
            }}
          />
        )}
      </Col>

      <Col xs={12}>
        <Switch
          checked={isPlaceholderEmail(getValues("ElectronicMailaddress") ?? "")}
          disabled={isLoading}
          label={translate("CONTACT_MISSING_EMAIL")}
          onToggleMethod={(e, { checked }) => handleMissingEmailToggle(checked)}
        />
      </Col>
    </Row>
  );

  const renderCompanyForm = () => (
    <Row className="mb-md">
      <Row className="pt-md">
        <Col md={4}>
          <label className="fw-semibold">{translate("SSN")}</label>
          <RHFInput
            placeholder={translate("SSN_PLACEHOLDER")}
            disabled={isLoading}
            trigger={trigger}
            errorMessage={errors.SignerSsn?.message}
            register={socialSecurityNumberOptions}
            onChange={({ target }) => {
              const { value } = target;
              setValue("SignerSsn", getDigitsFromString(value), {
                shouldValidate: true,
              });
            }}
          />
        </Col>

        <Col lg={4}>
          <label className="fw-semibold">{translate("FIRST_NAME")} *</label>
          <RHFInput
            disabled={isLoading}
            placeholder={translate("FIRST_NAME")}
            errorMessage={errors.FirstName?.message}
            register={firstNameOptions}
            maxChar={MAX_CHAR_FIRST_NAME}
            value={getValues("FirstName")}
            onChange={({ target }) => {
              const { value } = target;
              setValue("FirstName", value, {
                shouldValidate: true,
              });
            }}
          />
        </Col>

        <Col lg={4}>
          <label className="fw-semibold">{translate("LAST_NAME")} *</label>
          <RHFInput
            placeholder={translate("LAST_NAME")}
            disabled={isLoading}
            trigger={trigger}
            errorMessage={errors.LastName?.message}
            register={lastNameOptions}
            maxChar={MAX_CHAR_LAST_NAME}
            value={getValues("LastName")}
            onChange={({ target }) => {
              const { value } = target;
              setValue("LastName", value, {
                shouldValidate: true,
              });
            }}
          />
        </Col>
      </Row>
      <Row className="pt-lg">
        <Col lg={4}>
          <label className="fw-semibold">{translate("PHONE_NUM")}</label>
          <RHFInput
            type="text"
            disabled={isLoading}
            placeholder={translate("PHONE_NUM")}
            trigger={trigger}
            errorMessage={errors.Telephone?.message}
            register={phoneOptions}
            onChange={({ target }) => {
              const { value } = target;
              setValue("Telephone", value, {
                shouldValidate: true,
              });
            }}
          />
        </Col>
        <Col lg={4}>
          <label className="fw-semibold">{translate("MOBILE_PHONE")}</label>
          <RHFInput
            type="text"
            disabled={isLoading}
            placeholder={translate("MOBILE_PHONE")}
            trigger={trigger}
            errorMessage={errors.Mobile?.message}
            register={mobilePhoneOptions}
            onChange={({ target }) => {
              const { value } = target;
              setValue("Mobile", value, {
                shouldValidate: true,
              });
            }}
          />
        </Col>
        <Col lg={4}>
          <Row>
            <Col xs={12}>
              <label className="fw-semibold">{translate("SIGNING")} *</label>
              <Controller
                name="SigningMethod"
                control={control}
                rules={{
                  required: translate("SIGNING_METHOD_REQUIRED"),
                  validate: (value) => {
                    if (
                      isPlaceholderEmail(getValues("ElectronicMailaddress"))
                    ) {
                      return value !== SigningMethod.BANKID
                        ? true
                        : translate("MISSING_VALID_EMAIL_FOR_BANKID");
                    }
                    return true;
                  },
                }}
                render={({ field }) => (
                  <Dropdown
                    disabled={isLoading}
                    options={[
                      {
                        value: "Körkort",
                        label: translate("SIGN_METHOD_DRIVING_LICENCE"),
                      },
                      {
                        value: "Idhandling",
                        label: translate("SIGN_METHOD_ID"),
                      },
                      { value: "Känd", label: translate("SIGN_METHOD_KNOWN") },
                      {
                        value: "Id-kontroll krävs ej",
                        label: translate("SIGN_METHOD_NOT_REQUIRED"),
                      },
                      {
                        value: "Elektronisk legitimation",
                        label: translate("SIGN_METHOD_ELECTRONIC"),
                      },
                    ]}
                    value={field.value}
                    placeholder={translate("PLACEHOLDER_SELECT_SIGNING_METHOD")}
                    onChange={(signingMethod) => {
                      field.onChange(signingMethod);
                    }}
                    formRegister={field}
                    onBlur={() => {
                      trigger("SigningMethod");
                      field.onBlur();
                    }}
                    trigger={trigger}
                    errorMessage={errors.SigningMethod?.message}
                  />
                )}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="pt-lg">
        <Col xs={4}>
          <label className="no-padding fw-semibold">
            {translate("CUSTOMER_MANAGER")} *
          </label>
          <PersonPicker
            className="mt-xs"
            selectionMode="single"
            emptyPlaceholder={translate("SELECT_EMPLOYEE")}
            placeholder={translate("SEARCH_PERSON")}
            disabled={isLoading}
            required
            hasError={!newCustomer.SalespersonEmail}
            errorMessage={translate("CUSTOMER_MANAGER_REQUIRED")}
            selectedUsers={
              newCustomer.SalespersonEmail
                ? [{ id: newCustomer.SalespersonEmail, name: undefined }]
                : []
            }
            onPersonChange={(_, __, person) => {
              if (person) {
                const personEmail = person?.userPrincipalName || person?.id;

                const personFirstName =
                  person?.givenName ??
                  getNameFromEmail(personEmail).split(" ")[0];

                const personLastName =
                  person?.surname ??
                  getNameFromEmail(personEmail).split(" ")[1];

                updateNewCustomer({
                  SalespersonEmail: personEmail,
                  SalespersonFirstname: personFirstName,
                  SalespersonLastname: personLastName,
                });
              }
            }}
          />
        </Col>
      </Row>
    </Row>
  );

  const customerSteps = [
    {
      key: "1",
      label: translate("EMAIL_VERIFICATION"),
      circleLabel: "A",
      component: renderEmailForm(),
      state: {
        highlighted: !getValues("ElectronicMailaddress"),
        active: !getValues("ElectronicMailaddress"),
        disabled: isLoadingContact,
      },
    },
    {
      key: "2",
      label: translate("CONTACT_PERSON_DETAILS"),
      circleLabel: "B",
      component: isEmailFilled ? (
        renderCompanyForm()
      ) : (
        <span className="text-gray fs-italic p-sm">
          {translate("EMAIL_VERIFY_INFO")}
        </span>
      ),
      state: {
        highlighted: !!getValues("ElectronicMailaddress"),
        active: isEmailFilled,
        disabled: !isEmailFilled,
      },
    },
  ];

  const isNextDisabled =
    !isValid ||
    !newCustomer.SalespersonEmail ||
    isLoading ||
    isPotentialDealsLoading;

  return (
    <WizardSectionCustomer
      loadingStatus={
        isLoadingNewCustomer
          ? LoadingStatusEnum.PENDING
          : LoadingStatusEnum.IDLE
      }
      serviceName={translate("OFFER_CUSTOMERINFO")}
      titleBreadCrumb={
        newCustomer.Type === CustomerTypes.PRIVATE_PERSON
          ? translate("CUSTOMER_DETAILS")
          : translate("CONTACT_INFORMATION")
      }
      nextLabel="ADD_BUSINESS_DETAILS"
      isNextDisabled={isNextDisabled}
      onBeforeNext={() => postNewCustomer()}
      onPrevious={resetCustomerContactForm}
      backLabel="GO_BACKTO_CUSTOMER_DETAILS"
      subtitles={[
        translate("CUSTOMER_TYPE_DETAILS", [
          newCustomer.CompanyForm !== ""
            ? CompanyTypeOptions[newCustomer.CompanyForm].label
            : "",
        ]),
        translate("CONTACT_DETAILS_SUBTEXT"),
      ]}
    >
      <Col md={12} className="mt-sm">
        <VerticalStepper steps={customerSteps} />
      </Col>
    </WizardSectionCustomer>
  );
}
