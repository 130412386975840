import { ArrowRight20Regular, Payment20Regular } from "@fluentui/react-icons";
import { addDays } from "date-fns";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Label, Spinner } from "@fluentui/react-components";

import { DropdownV9 } from "components/dropdown/DropdownV9";
import Modal, { ModalStepsConfig } from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { SpinnerSize } from "components/spinner";
import { TextArea } from "components/textarea";
import { useTranslation } from "hooks";
import { dateLanguageFormatter } from "libs/date/date-format";
import {
  fetchCustomerDeferredPayments,
  updateCustomerDeferredPayments,
} from "state/customers/actions";
import { AppDispatch } from "state/use-app-redux";


type PostponePaymentForInvoiceModalProps = {
  customerNumber: string;
  onDismiss: () => void;
  isOpen?: boolean;
};

export function PostponePaymentForInvoiceModal({
  customerNumber,
  onDismiss,
  isOpen = true,
}: PostponePaymentForInvoiceModalProps) {
  const { t, language } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();
  const dateFormat = dateLanguageFormatter(language, "yyyy-MM-dd");

  const [customerDeferredPaymentsData, setCustomerDeferredPaymentsData] =
    useState<any>([]);

  const [selectedInvoiceData, setSelectedInvoiceData] =
    useState<any>(undefined);
  const [stepsConfig, setStepsConfig] = useState<ModalStepsConfig>({
    activeStep: 1,
    stepsCount: 2,
  });
  const [newComment, setNewComment] = useState<string>("");
  const [postponedDate, setPostponedDate] = useState<string>("");
  const [interestReminder, setInterestReminder] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const init = async () => {
      if (customerNumber) {
        setLoading(true);
        dispatch(fetchCustomerDeferredPayments(customerNumber))
          .then((res) => {
            setCustomerDeferredPaymentsData(res);
            setLoading(false);
          })
          .catch((err) => console.log(err));
      }
    };
    init();
  }, [dispatch, customerNumber]);

  const customerInvoiceList = customerDeferredPaymentsData?.map(
    (invoicedata: { transaction_number: string; reminder_level: number }) => {
      return {
        value: invoicedata.transaction_number,
        text: invoicedata.transaction_number,
        disabled: invoicedata.reminder_level !== 0,
      };
    }
  );

  const isNextDisabled = !(
    (stepsConfig.activeStep === 1 && selectedInvoiceData) ||
    (stepsConfig.activeStep === 2 && newComment !== "" && postponedDate !== "")
  );
  const postponedForList = [{ value: 14, text: `14 ${t("DAYS_LC")}` }];

  const handleOnSave = async () => {
    if (stepsConfig.activeStep === 1) {
      setStepsConfig({ ...stepsConfig, activeStep: 2 });
    } else {
      // handle save with API
      selectedInvoiceData.new_remark = newComment;
      selectedInvoiceData.new_due_date = postponedDate;
      selectedInvoiceData.interest_reminder_block_types.map((blockType: any) =>
        blockType.value === interestReminder
          ? (blockType.selected = true)
          : (blockType.selected = false)
      );

      dispatch(
        updateCustomerDeferredPayments(customerNumber, selectedInvoiceData)
      );
      onDismiss();
    }
  };

  const handleCloseModal = () => {
    if (stepsConfig.activeStep === 2) {
      setStepsConfig({ ...stepsConfig, activeStep: 1 });
    } else {
      return onDismiss();
    }
  };

  const calculateNewPostponeDate = (numberOfDays: string | number) => {
    const orgDateObject = new Date(selectedInvoiceData.original_due_date);
    const newDate = addDays(orgDateObject, numberOfDays as number);

    setPostponedDate(dateFormat(newDate));
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      size={stepsConfig.activeStep === 1 ? "medium" : "small-to-mid"}
      stepsConfig={stepsConfig}
      header={
        <ModalHeader
          isLoading={false}
          subheaderText={
            stepsConfig.activeStep === 1
              ? "CUSTOMERS.POSTPONE_INVOICE.STEP_1.SUBHEADER"
              : "CUSTOMERS.POSTPONE_INVOICE.STEP_2.SUBHEADER"
          }
          headerTitleText="CUSTOMERS.POSTPONE_INVOICE.TITLE"
        />
      }
      footer={
        <ModalFooter
          isDisabled={isNextDisabled}
          onCancel={() => handleCloseModal()}
          onSave={() => handleOnSave()}
          sendButtonIcon={
            stepsConfig.activeStep === 1 ? (
              <ArrowRight20Regular />
            ) : (
              <Payment20Regular />
            )
          }
          labelSubmit={
            stepsConfig.activeStep === 1
              ? "CUSTOMERS.POSTPONE_INVOICE.BUTTON.PROCEED_TO_POSTPONE.TITLE"
              : "CUSTOMERS.POSTPONE_INVOICE.BUTTON.POSTPONE_PAYMENT.TITLE"
          }
          labelCancel={
            stepsConfig.activeStep === 1
              ? "CANCEL"
              : "CUSTOMERS.POSTPONE_INVOICE.BUTTON.GO_BACK_TO_INVOICE.TITLE"
          }
        />
      }
    >
      {stepsConfig.activeStep === 1 ? (
        <>
          {loading && (
            <Spinner
              className="px-sm py-md"
              label={t("CUSTOMERS.POSTPONE_INVOICE.LOADING_INVOICES")}
              size={SpinnerSize.ExtraSmall}
            />
          )}
          <Row className="gy-3 py-md ">
            <Col md={3} className="pt-lg px-md">
              <div className="horizontal-divider" />
            </Col>
            <Col md={6}>
              <Label className="fw-600">
                {t("CUSTOMERS.POSTPONE_INVOICE.INVOICE_NUMBER.LABEL")} *
              </Label>
              <DropdownV9
                placeholder={t("DROPDOWN_PLACEHOLDER")}
                onOptionSelect={(_, { optionValue }) => {
                  const selectedInvoiceObj = customerDeferredPaymentsData.find(
                    (invoiceData: {
                      transaction_number: string | number | undefined;
                    }) => invoiceData.transaction_number === optionValue
                  );
                  setSelectedInvoiceData(selectedInvoiceObj);
                }}
                selectedOptions={[
                  selectedInvoiceData?.transaction_number as string,
                ]}
                options={customerInvoiceList}
              />
            </Col>
            <Col md={3} className="pt-lg px-md">
              <div className="horizontal-divider" />
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row className="gy-3 p-md ">
            <Col md={12}>
              <div>
                <Label className="fw-400 text-color-gray-100">
                  {t("CUSTOMERS.POSTPONE_INVOICE.INVOICE_NUMBER.LABEL")}:{" "}
                  {selectedInvoiceData?.transaction_number}
                </Label>
              </div>
            </Col>
          </Row>
          <Row className="horizontal-divider" />
          <div className="vertical-scroll">
            <Row className="gy-3 p-md ">
              <Col md={12}>
                <Label className="fw-600">
                  {t("CUSTOMERS.POSTPONE_INVOICE.ORIGINAL_DEADLINE.LABEL")}{" "}
                </Label>
                <div>
                  <span>{selectedInvoiceData?.original_due_date}</span>
                </div>
              </Col>
            </Row>
            <Row className="horizontal-divider" />
            <Row className="gy-3 p-md ">
              <Col md={12}>
                <Label className="fw-600">
                  {t("CUSTOMERS.POSTPONE_INVOICE.POSTPONED_UNTIL.LABEL")}{" "}
                </Label>
                <div>
                  <span>{postponedDate}</span>
                </div>
              </Col>
            </Row>

            <Row className="horizontal-divider" />
            <Row className="gy-3 p-md">
              <Col md={12}>
                <Label className="fw-600">
                  {t("CUSTOMERS.POSTPONE_INVOICE.PAST_COMMENTS.LABEL")}
                </Label>
                <div>
                  {selectedInvoiceData?.remark.map((comment: string) => (
                    <Row className="gy-md pb-md">
                      <Col md={12}>
                        <Label className="text-color-gray-900 fw-400 pb-xxs">
                          {comment}
                        </Label>
                      </Col>
                    </Row>
                  )) ?? "/"}
                </div>
              </Col>
            </Row>
          </div>
          <Row className="horizontal-divider" />
          <Row className="gy-3 p-md ">
            <Col md={12}>
              <label className="fw-600">
                {t("CUSTOMERS.POSTPONE_INVOICE.POSTPONE_FOR.LABEL")} *
              </label>
              <DropdownV9
                placeholder={t("DROPDOWN_PLACEHOLDER")}
                onOptionSelect={(_, { optionValue }) => {
                  if (optionValue) {
                    calculateNewPostponeDate(parseInt(optionValue, 10));
                  }
                }}
                options={postponedForList}
              />
              <div className="text-gray fpx-14 pt-xxs">
                {t("CUSTOMERS.POSTPONE_INVOICE.POSTPONE_FOR.NOTE")}
              </div>
            </Col>
          </Row>
          <Row className="horizontal-divider" />
          <Row className="gy-3 p-md ">
            <Col md={12}>
              <label className="fw-600">
                {t("CUSTOMERS.POSTPONE_INVOICE.INTEREST_REMINDER.LABEL")} *
              </label>
              <DropdownV9
                placeholder={t("DROPDOWN_PLACEHOLDER")}
                onOptionSelect={(_, { optionValue }) => {
                  if (optionValue) {
                    setInterestReminder(optionValue);
                  }
                }}
                options={selectedInvoiceData?.interest_reminder_block_types.map(
                  (blockType: any) => {
                    return {
                      value: blockType.value,
                      text:
                        blockType.title === ""
                          ? t(
                              "CUSTOMERS.POSTPONE_INVOICE.INTEREST_REMINDER.OPTION.EMPTY_TITLE"
                            )
                          : blockType.title,
                    };
                  }
                )}
                selectedOptions={[
                  interestReminder ??
                    (selectedInvoiceData?.interest_reminder_block_types.find(
                      (blockType: any) => blockType.selected
                    ).value as string) ??
                    "",
                ]}
              />
            </Col>
          </Row>
          <Row className="horizontal-divider" />
          <Row className="gy-3 p-md">
            <Col md={12}>
              <label className="fw-600">{t("COMMENT")} *</label>
              <TextArea
                className="mb-2"
                placeholder={t(
                  "CUSTOMERS.POSTPONE_INVOICE.COMMENT.PLACEHOLDER"
                )}
                required
                onChange={(str) => {
                  setNewComment(str.target.value);
                }}
              />
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
}
