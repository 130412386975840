import { plainToClass } from "class-transformer";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { Picker } from "components/picker";
import { useTranslation } from "hooks/use-translate";
import { capitalize } from "libs/capitalize";
import { DealCustomer } from "models/offer/Customer";
import { RootState } from "state";
import { updateOfferCustomer } from "state/offer/offersSlice";
import { AppDispatch } from "state/use-app-redux";
import { WizardSection } from "../../components/wizardSection";

export enum CustomerTypeEnum {
  PERSON = "Person",
  COMPANY = "Company",
  CUSTOMER = "Customer",
}

function CustomerTypes() {
  const { t: translate } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const offers = useSelector((state: RootState) => state.offers);
  const { currentUser } = useSelector((state: RootState) => state.users);

  const currentOffer = offers.currentOffer.data;
  const offerCustomer = currentOffer?.customer;

  const [selectedCustomerType, setSelectedCustomerType] = useState("");

  const dealCustomerUpdatedByUser = !offerCustomer?.created_by_username
    ? {
        created_by_username: currentUser.email,
        changed_by_username: currentUser.email,
      }
    : {
        changed_by_username: currentUser.email,
      };

  const dispatchUpdateOfferCustomer = (customer: Partial<DealCustomer>) => {
    dispatch(
      updateOfferCustomer(
        plainToClass(DealCustomer, {
          ...offerCustomer,
          ...dealCustomerUpdatedByUser,
          ...customer,
        })
      )
    );
  };

  const handleSelectService = (type: CustomerTypeEnum) => {
    setSelectedCustomerType(type);
    dispatchUpdateOfferCustomer({
      ...offerCustomer,
      legal_form: type === CustomerTypeEnum.PERSON ? "pp" : "ab",
    });
  };

  const renderSubtitle = () => {
    return selectedCustomerType
      ? translate("CUSTOMER_TYPE_DESCRIPTION", [
          capitalize(selectedCustomerType),
        ])
      : translate("CUSTOMER_TYPE_DESCRIPTION_DEFAULT");
  };

  const getCustomerTypeDesc = (type: CustomerTypeEnum): string | undefined => {
    return type === CustomerTypeEnum.COMPANY
      ? translate("CREATE_COMPANY_DESCRIPTION")
      : translate("CREATE_PRIVATE_PERSON_DESCRIPTION");
  };

  return (
    <WizardSection
      loadingStatus={offers.currentOffer.status}
      isNextHidden={!selectedCustomerType}
      hideNavigation={!offerCustomer?.legal_form}
      subtitles={[renderSubtitle()]}
      nextButtonText={translate("ADD_CUSTOMER_INFO")}
    >
      <Row className="minHeight-100">
        <Col xl={3} lg={2} className="pt-lg m-auto">
          <div className="horizontal-divider" />
        </Col>
        <Col xl={6} lg={8} className="m-auto">
          <div className="d-flex flex-wrap justify-content-center fg-4">
            <Picker
              name={translate("CUSTOMER_TYPE_PERSON")}
              checked={selectedCustomerType === CustomerTypeEnum.PERSON}
              key={CustomerTypeEnum.PERSON}
              description={getCustomerTypeDesc(CustomerTypeEnum.PERSON)}
              className="mx-sm"
              onToggle={() => handleSelectService(CustomerTypeEnum.PERSON)}
              toggles={[]}
            />
            <Picker
              name={translate("CUSTOMER_TYPE_COMPANY")}
              checked={selectedCustomerType === CustomerTypeEnum.COMPANY}
              key={CustomerTypeEnum.COMPANY}
              description={getCustomerTypeDesc(CustomerTypeEnum.COMPANY)}
              className="mx-sm"
              onToggle={() => {
                handleSelectService(CustomerTypeEnum.COMPANY);
              }}
              toggles={[]}
            />
          </div>
        </Col>
        <Col xl={3} lg={2} className="pt-lg m-auto">
          <div className="horizontal-divider" />
        </Col>
      </Row>
    </WizardSection>
  );
}

export default CustomerTypes;
