import {
  Toast,
  ToastFooter,
  ToastIntent,
  ToastTitle,
  ToastTrigger,
} from "@fluentui/react-components";
import {
  CheckmarkCircle20Regular,
  Dismiss20Regular,
  ErrorCircle20Regular,
  Info20Regular,
  Warning20Regular,
} from "@fluentui/react-icons";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "hooks/use-translate";
import { TranslationKey } from "i18n";
import { NotificationEntry } from "state/notifications";
import "./ToasterNotification.scss";

type Props = {
  message: NotificationEntry;
  onDismiss: (dismissToast: NotificationEntry) => void;
  notificationActionLink?: string;
  notificationActionText?: string;
};

export function NotificationToast({
  message,
  onDismiss,
  notificationActionLink,
  notificationActionText = "",
}: Props) {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const { notificationType: intent, translationKey, translationArgs } = message;

  const renderIcon = (type: ToastIntent) => {
    switch (true) {
      case type === "success":
        return <CheckmarkCircle20Regular className="notification-success" />;
      case type === "error":
        return <Warning20Regular className="notification-error" />;
      case type === "warning":
        return <ErrorCircle20Regular className="notification-warning" />;
      default:
        return <Info20Regular className="notification-info" />;
    }
  };

  const renderErrorObject = useCallback(
    (toast: NotificationEntry) => {
      if (toast.error?.name && toast.error?.args) {
        const alt1 = translate(
          toast.error.name as TranslationKey,
          toast.error.args
        );
        const alt2 = `${toast.error.name}${
          toast.error.args.length > 0 ? `: ${toast.error.args.join(", ")}` : ""
        }`;

        return <span>({alt1 || alt2})</span>;
      }

      return "";
    },
    [translate]
  );

  return (
    <Toast className={`${intent}-color`}>
      <ToastTitle
        media={renderIcon(intent)}
        action={
          <ToastTrigger>
            <Dismiss20Regular onClick={() => onDismiss(message)} />
          </ToastTrigger>
        }
      >
        <div className="d-flex align-items-center">
          <span className="me-2">
            {translate(translationKey, translationArgs)}
          </span>
          {renderErrorObject(message)}
        </div>
      </ToastTitle>
      {notificationActionLink && notificationActionText && (
        <ToastFooter>
          <span
            onClick={() => navigate(notificationActionLink)}
            className="blue-link"
          >
            {notificationActionText}
          </span>
        </ToastFooter>
      )}
    </Toast>
  );
}
