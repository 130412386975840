import {
  ArrowUpRight16Regular,
  CheckmarkCircle20Regular,
} from "@fluentui/react-icons";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "hooks/use-translate";
import { RootState } from "state";
import { SettingsCategory } from "models/customerSettings/settingsCategory";
import { Button } from "components/button";
import { AppRouteHelper } from "routes";
import { isUserTeamOwner } from "libs/customer-settings-helpers";

type CustomerPageEnabledSettingsProps = {
  customerId: string;
};
export default function CustomerPageEnabledSettings({
  customerId,
}: CustomerPageEnabledSettingsProps) {
  const { t: translate, language } = useTranslation();
  const navigate = useNavigate();

  const URLProps = new URLSearchParams(window.location.search);
  const isConveyorBeltV2 = URLProps.get("conveyorBeltV2");

  const { currentUser } = useSelector((state: RootState) => state.users);
  const customers = useSelector((state: RootState) => state.customers.data);
  const customer = customers.find(
    (e) => e.customer.customer_number === customerId
  )?.customer;

  const { data: customerSettingsData } = useSelector(
    (state: RootState) => state.settings.customerSettings
  );

  const currentServiceLine = useSelector(
    (state: RootState) => state.settings.serviceLines.currentServiceLine
  );

  const dataToShowFrom = currentServiceLine
    ? customerSettingsData.filter(
        (settings) => settings.serviceline === currentServiceLine.serviceline
      )
    : [...customerSettingsData].sort((a, b) => a.id - b.id);

  // filter out categories with same id
  const settingsCategories = dataToShowFrom
    .flatMap((serviceLine) => serviceLine.settings_categories)
    .filter(
      (value, index, self) => self.findIndex((m) => m.id === value.id) === index
    )
    .sort((a, b) => a.sorting_number - b.sorting_number);

  const categoriesWithEnabledSettings = settingsCategories.filter((category) =>
    category.settings.some((setting) => setting.status)
  );

  const getEnabledCategoySettings = (category: SettingsCategory) => {
    return category.settings.filter((setting) => setting.status);
  };

  return (
    <>
      <div className="p-lg cs-content">
        {categoriesWithEnabledSettings.length > 0 ? (
          <>
            <Row className="pb-md">
              <Col md={4}>
                <span className="fw-semibold">
                  {translate("SETTING_CATEGORY")}
                </span>
              </Col>
              <Col md={8}>
                <span className="fw-semibold">
                  {translate("ENABLED_SETTINGS")}
                </span>
              </Col>
            </Row>

            <Row>
              {categoriesWithEnabledSettings.map((category) => (
                <Row key={category.id} className="py-sm align-items-center">
                  <Col md={4} className="">
                    {language === "SV" ? category.title : category.title_en}
                  </Col>
                  <Col md={8} className="d-flex flex-wrap">
                    {getEnabledCategoySettings(category).map(
                      (setting, index) => (
                        <div className="d-flex py-xxs" key={setting.id}>
                          <CheckmarkCircle20Regular color="#00B294" />
                          <span className="px-sm width-max">
                            {language === "SV"
                              ? setting.title
                              : setting.title_en}
                          </span>
                          {index + 1 <
                            getEnabledCategoySettings(category).length && (
                            <div className="vertical-divider mx-md" />
                          )}
                        </div>
                      )
                    )}
                  </Col>
                  <div className="horizontal-divider mt-sm" />
                </Row>
              ))}
            </Row>
          </>
        ) : (
          <div>{translate("NO_ENABLED_SETTINGS")}</div>
        )}
      </div>
      {customer && isUserTeamOwner(customer, currentUser) && (
        <Col md={12}>
          <Row className="mt-md">
            <div className="d-flex align-items-end mb-xs px-0">
              <Button
                variant="outline-outline"
                className="justify-content-start primary-colored-text"
                onClick={() =>
                  navigate(
                    isConveyorBeltV2
                      ? AppRouteHelper.getCustomerSettings(customerId, true)
                      : AppRouteHelper.getCustomerSettings(customerId)
                  )
                }
              >
                <ArrowUpRight16Regular />
                <span className="d-inline mx-sm fw-bold primary">
                  {translate("GO_TO_SETTINGS_PAGE")}
                </span>
              </Button>
            </div>
          </Row>
        </Col>
      )}
    </>
  );
}
