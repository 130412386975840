import {
  Customer,
  CustomersLudvigServiceStatus,
  SieFile,
} from "../../models/customer";
import {
  CustomerDelivery,
  CustomerTaxObjects,
  DeletedDelivery,
} from "../../models/deliveryPlan";
import { PersonDetails } from "../../models/profile";
import { CustomerState } from "./reducer";

export const LOAD_CUSTOMERS = "customers/LOAD_CUSTOMERS";
export const LOADING_CUSTOMERS = "customers/LOADING_CUSTOMERS";
export const LOADING_NEW_CUSTOMER = "customers/LOADING_NEW_CUSTOMER";
export const LOADING_CUSTOMERS_FAILED = "customers/LOADING_CUSTOMERS_FAILED";
export const SET_ASSIGNMENT_MANAGERS = "customers/SET_ASSIGNMENT_MANAGERS";
export const UPDATE_CUSTOMER = "customers/UPDATE_CUSTOMER";
export const APPEND_CUSTOMER = "customers/APPEND_CUSTOMER";
export const SET_CUSTOMER_LOADING = "customers/SET_CUSTOMER_LOADING";
export const SET_CUSTOMER_SIE_FILES = "customers/SET_CUSTOMER_SIE_FILES";
export const SET_CUSTOMER_SIE_FILE_PRIORITY =
  "customers/SET_CUSTOMER_SIE_FILE_PRIORITY";
export const SET_CUSTOMER_SIE_FILE_PRIORITIZE_LOADING =
  "customers/SET_CUSTOMER_SIE_FILE_PRIORITIZE_LOADING";
export const UPDATE_DELIVERY = "customers/UPDATE_DELIVERY";
export const CREATE_DELIVERY = "customers/CREATE_DELIVERY";
export const DELETE_DELIVERY = "customers/DELETE_DELIVERY";
export const DELETE_DELIVERIES_BATCH = "customers/DELETE_DELIVERIES_BATCG";
export const SET_CUSTOMER_UI_STATE = "customers/SET_CUSTOMER_UI_STATE";
export const SET_CUSTOMER_TAX_OBJECTS_LOADING =
  "customers/SET_CUSTOMER_TAX_OBJECTS_LOADING";
export const GET_CUSTOMER_TAX_OBJECTS = "customers/GET_CUSTOMER_TAX_OBJECTS";
export const POST_LOAD_CUSTOMERS = "customers/POST_LOAD_CUSTOMERS";
export const SET_CUSTOMERS_LUDVIG_SERVICES =
  "customers/SET_CUSTOMERS_LUDVIG_SERVICES";
export const UPDATE_CUSTOMERS_LUDVIG_SERVICES =
  "customers/UPDATE_CUSTOMERS_LUDVIG_SERVICES";

export type UpdateCustomersLudvigServices = {
  type: typeof UPDATE_CUSTOMERS_LUDVIG_SERVICES;
  payload: CustomersLudvigServiceStatus[];
};

export type SetCustomersLudvigServices = {
  type: typeof SET_CUSTOMERS_LUDVIG_SERVICES;
  payload: CustomersLudvigServiceStatus[];
};

export type PostLoadCustomers = {
  type: typeof POST_LOAD_CUSTOMERS;
  payload: CustomerState[];
};

export type SetCustomerSieFilePrioritizeLoading = {
  type: typeof SET_CUSTOMER_SIE_FILE_PRIORITIZE_LOADING;
  payload: {
    customer: Customer;
    sieFile: SieFile;
    loading: boolean;
  };
};

export type SetCustomerSieFilePriority = {
  type: typeof SET_CUSTOMER_SIE_FILE_PRIORITY;
  payload: {
    customer: Customer;
    sieFile: SieFile;
  };
};

export type SetCustomerSieFiles = {
  type: typeof SET_CUSTOMER_SIE_FILES;
  payload: {
    customer: Customer;
    sieFiles: SieFile[];
  };
};

export type LoadCustomersAction = {
  type: typeof LOAD_CUSTOMERS;
  payload: CustomerState[];
};

export type LoadingCustomersAction = {
  type: typeof LOADING_CUSTOMERS;
  payload: boolean;
};

export type SetCustomer = {
  type: typeof UPDATE_CUSTOMER;
  payload: {
    update: Partial<Customer>;
    customer_number: string;
  };
};

export type SetAssignmentManagers = {
  type: typeof SET_ASSIGNMENT_MANAGERS;
  payload: {
    customer: Customer;
    managers: PersonDetails[];
  };
};

export type LoadingCustomersFailedAction = {
  type: typeof LOADING_CUSTOMERS_FAILED;
};

export type LoadingNewCustomerAction = {
  type: typeof LOADING_NEW_CUSTOMER;
  payload: boolean;
};

export type SetCustomerLoading = {
  type: typeof SET_CUSTOMER_LOADING;
  payload: {
    customer: Customer;
    isLoading: boolean;
  };
};

export type SetCustomerState = {
  type: typeof SET_CUSTOMER_UI_STATE;
  payload: {
    customer: Customer;
    state: Partial<CustomerState>;
  };
};

export type UpdateDelivery = {
  type: typeof UPDATE_DELIVERY;
  payload: {
    customer: Customer;
    update: Partial<CustomerDelivery>;
    deliveryId: string;
  };
};

export type CreateDelivery = {
  type: typeof CREATE_DELIVERY;
  payload: {
    customer: Customer;
    delivery: CustomerDelivery;
  };
};

export type DeleteDelivery = {
  type: typeof DELETE_DELIVERY;
  payload: {
    customer: Customer;
    deliveryId: string;
  };
};

export type DeleteDeliveriesBatch = {
  type: typeof DELETE_DELIVERIES_BATCH;
  payload: {
    customer: Customer;
    batchResponse: DeletedDelivery[];
  };
};

export type AppendCustomer = {
  type: typeof APPEND_CUSTOMER;
  payload: Customer;
};

export type SetCustomerTaxObjectsLoading = {
  type: typeof SET_CUSTOMER_TAX_OBJECTS_LOADING;
  payload: boolean;
};
export type GetCustomerTaxObjects = {
  type: typeof GET_CUSTOMER_TAX_OBJECTS;
  payload: {
    taxObjects: CustomerTaxObjects;
    customerNumber: string;
  };
};

export type CustomersAction =
  | LoadCustomersAction
  | LoadingCustomersAction
  | LoadingNewCustomerAction
  | LoadingCustomersFailedAction
  | SetCustomer
  | SetAssignmentManagers
  | SetCustomerLoading
  | AppendCustomer
  | SetCustomerSieFiles
  | SetCustomerSieFilePriority
  | SetCustomerSieFilePrioritizeLoading
  | UpdateDelivery
  | CreateDelivery
  | DeleteDelivery
  | DeleteDeliveriesBatch
  | SetCustomerState
  | SetCustomerTaxObjectsLoading
  | GetCustomerTaxObjects
  | PostLoadCustomers
  | SetCustomersLudvigServices
  | UpdateCustomersLudvigServices;
