import { Spinner } from "@fluentui/react-components";
import { ReactNode } from "react";
import { Col, Row } from "react-bootstrap";

import { Button, Variant } from "components/button";
import { SpinnerSize } from "components/spinner";
import TooltipV9, { TooltipV9Props } from "components/tooltip/TooltipV9";
import { useTranslation } from "hooks/use-translate";
import { isTranslationKey, TranslationKey } from "i18n";

type ModalFooterProps = {
  onCancel?: () => void;
  onSave: (e: React.MouseEvent) => void;
  labelCancel?: string | TranslationKey;
  labelSubmit: string | TranslationKey; // Not optional to encourage specific submit labels for better UX of what the user is submitting
  isDisabled?: boolean;
  isCancelDisabled?: boolean;
  sendButtonIcon?: ReactNode;
  cancelButtonIcon?: ReactNode;
  hideSubmit?: boolean;
  saveButtonVariant?: Variant;
  saveButtonTooltip?: Omit<TooltipV9Props, "children">;
  cancelButtonTooltip?: Omit<TooltipV9Props, "children">;
  isLoading?: boolean;
};

export function ModalFooter({
  onCancel,
  onSave,
  labelCancel,
  labelSubmit,
  isDisabled = false,
  isCancelDisabled = false,
  sendButtonIcon,
  cancelButtonIcon,
  hideSubmit = false,
  saveButtonVariant = "primary",
  saveButtonTooltip,
  cancelButtonTooltip,
  isLoading = false,
}: ModalFooterProps) {
  const { t: translate } = useTranslation();

  const labelSubmitText =
    labelSubmit && isTranslationKey(labelSubmit)
      ? translate(labelSubmit)
      : labelSubmit;

  const labelCancelText =
    labelCancel && isTranslationKey(labelCancel)
      ? translate(labelCancel)
      : labelCancel;

  if (onSave && !onCancel) {
    return (
      <Row className="justify-content-end">
        <Col xs="auto">
          <Button
            disabled={isDisabled || isLoading}
            variant="primary"
            className="p-sm full-width d-flex"
            onClick={(e) => onSave(e)}
          >
            {labelSubmitText}
            {isLoading && (
              <Spinner
                className="ml-sm"
                appearance="inverted"
                size={SpinnerSize.ExtraSmall}
              />
            )}
          </Button>
        </Col>
      </Row>
    );
  }

  const renderButtonWithIcon = (icon: ReactNode, label: string) => (
    <span className="d-flex align-items-center mr-xs button-icon">
      <span className="mr-xs button-icon">{icon}</span>
      {label}
    </span>
  );

  const buttonContent = sendButtonIcon
    ? renderButtonWithIcon(sendButtonIcon, labelSubmitText)
    : labelSubmitText;

  const submitButton = (
    <Button
      disabled={isDisabled || isLoading}
      variant={saveButtonVariant}
      className="py-sm full-width d-flex align-items-center"
      onClick={onSave}
    >
      {buttonContent}
      {isLoading && (
        <Spinner
          className="ml-sm"
          appearance="inverted"
          size={SpinnerSize.ExtraSmall}
        />
      )}
    </Button>
  );

  const renderSubmitButton = () =>
    saveButtonTooltip ? (
      <TooltipV9 {...saveButtonTooltip}>{submitButton}</TooltipV9>
    ) : (
      submitButton
    );

  const cancelButton = (
    <Button
      variant="outline-primary"
      className="py-sm full-width"
      onClick={onCancel}
      disabled={isLoading || isCancelDisabled}
    >
      {cancelButtonIcon ? (
        renderButtonWithIcon(
          cancelButtonIcon,
          labelCancelText ?? translate("CANCEL")
        )
      ) : (
        <span>{labelCancelText ?? translate("CANCEL")}</span>
      )}
    </Button>
  );

  const renderCancelButton = () =>
    cancelButtonTooltip ? (
      <TooltipV9 {...cancelButtonTooltip}>{cancelButton}</TooltipV9>
    ) : (
      cancelButton
    );

  return (
    <Row className="justify-content-between">
      <Col xs="auto">{renderCancelButton()}</Col>
      {!hideSubmit && <Col xs="auto">{renderSubmitButton()}</Col>}
    </Row>
  );
}
