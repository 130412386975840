import { useFormContext } from "react-hook-form";

import { useTranslation } from "hooks/use-translate";
import { DateFormat } from "libs/date/date-format";
import { DatePickerV9 } from "components/date/DatePickerV9";
import { Deal } from "models/deals/deal";
import { DealFieldProps } from "./models/field-types";
import { useUpdateDealProperty } from "./useUpdateDealProperty";

function DealDatePicker({ configuration }: DealFieldProps) {
  const { t: translate, dateFormatter } = useTranslation();
  const { isLoading } = useUpdateDealProperty();

  const today = new Date();

  const { setValue, getValues } = useFormContext<Partial<Deal>>();

  const value = getValues(configuration.property) as Date;

  return (
    <DatePickerV9
      disabled={isLoading}
      placeholder={
        (value && value.toLocaleDateString()) || translate("SELECT_MONTH")
      }
      calendarProps={{
        minDate: today,
      }}
      dateFormatter={(date) => dateFormatter(date, DateFormat.YearDayMonth)}
      selectedDate={value}
      onSelectDate={(newDate) => {
        setValue(configuration.property, newDate, {
          shouldDirty: true,
          shouldValidate: true,
        });
      }}
      fullWidth
    />
  );
}

export default DealDatePicker;
