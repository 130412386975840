import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ChevronUp16Regular } from "@fluentui/react-icons";

import { AppRouteHelper } from "routes";
import { ServiceInstance } from "models/activities/activity";
import { Customer } from "models/customer";
import { Button } from "components/button";
import { ServiceBox } from "./ServiceBox";
import { ServiceBoxCompleted } from "./ServiceBoxCompleted";

type ServiceBoxListProps = {
  servicesList: ServiceInstance[];
  maxNumberServices?: number;
  customer: Customer;
  expanded: boolean;
  completed: boolean;
};

export default function ServiceBoxList({
  servicesList,
  maxNumberServices,
  customer,
  expanded,
  completed,
}: ServiceBoxListProps) {
  const navigate = useNavigate();

  const [sortedServicesListByRecurrence, setSortedServicesListByRecurrence] =
    useState<ServiceInstance[]>([]);
  const [serviceListTrimmed, setServiceListTrimmed] = useState<
    ServiceInstance[]
  >([]);
  const [showServices, setShowServices] = useState(serviceListTrimmed);
  const [showingAllServices, setShowingAllServices] = useState(false);

  useEffect(() => {
    function priorityNumberOfRecurrence(recurrence: string) {
      switch (recurrence) {
        case "WEEKLY":
          return 1;
        case "MONTHLY":
          return 2;
        case "QUARTERLY":
          return 3;
        case "YEARLY":
          return 4;
        default:
          return 0;
      }
    }

    const sortedServicesListByRecurrenceObj = servicesList
      .sort((a, b) => (a.week > b.week ? 1 : -1))
      .sort(
        (a, b) =>
          priorityNumberOfRecurrence(a.recurrence) -
          priorityNumberOfRecurrence(b.recurrence)
      );
    setSortedServicesListByRecurrence(sortedServicesListByRecurrenceObj);

    const serviceListTrimmedObj = maxNumberServices
      ? servicesList.slice(0, maxNumberServices)
      : sortedServicesListByRecurrence;
    setServiceListTrimmed(serviceListTrimmedObj);
    setShowServices(serviceListTrimmedObj);
    setShowingAllServices(false);
  }, [servicesList, maxNumberServices, sortedServicesListByRecurrence]);

  const maxDisplayServicesExceeded =
    maxNumberServices &&
    sortedServicesListByRecurrence.length > maxNumberServices;

  const colWidth = maxDisplayServicesExceeded ? 11 : 12;

  const openService = (service: ServiceInstance) => {
    navigate({
      pathname: AppRouteHelper.getAssignmentsServices(
        customer.customer_number,
        service.service_box_id
      ),
    });
  };

  return (
    <Row>
      {!expanded && completed && (
        <ServiceBoxCompleted servicesList={servicesList} />
      )}
      {(expanded || !completed) && (
        <>
          <Col md={colWidth}>
            {showServices.map((s) => {
              return (
                <ServiceBox
                  key={s.service_box_id}
                  service={s}
                  onClick={() => {
                    openService(s);
                  }}
                />
              );
            })}
          </Col>
          {maxDisplayServicesExceeded && maxNumberServices && (
            <Col
              md={1}
              className={`d-flex ${
                showingAllServices ? "align-items-end" : "align-items-center"
              } justify-content-center px-0 mx-0`}
            >
              <Button
                variant="outline-outline"
                className="mx-0 px-0 text-primary"
                size="md"
                onClick={(event) => {
                  event.stopPropagation();
                  if (showingAllServices) {
                    setShowServices(serviceListTrimmed);
                  } else {
                    setShowServices(servicesList);
                  }

                  setShowingAllServices(!showingAllServices);
                }}
              >
                <span>
                  {showingAllServices ? (
                    <ChevronUp16Regular />
                  ) : (
                    `+ ${servicesList.length - maxNumberServices}`
                  )}
                </span>
              </Button>
            </Col>
          )}
        </>
      )}
    </Row>
  );
}
