import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import { RHFInput } from "components/input/RHFInput";
import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { TextArea } from "components/textarea";
import { useTranslation } from "hooks/use-translate";
import { Deal } from "models/deals/deal";
import { DealReturnProps } from "models/deals/dealReturnProps";
import { returnDealToManager } from "state/sales/actions";

type StageDealReturnModalProps = {
  setOpenModal: (arg: boolean) => void;
  openModal: boolean;
  deal: Deal;
};
export default function DealReturnModal({
  setOpenModal,
  openModal,
  deal,
}: StageDealReturnModalProps) {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [returnDealProps, setReturnDealProps] = useState<DealReturnProps>();

  const { register } = useForm();

  const confirmReturnToManager = () => {
    const returnProps = {
      return_reason: returnDealProps?.return_reason,
      suggested_agent: returnDealProps?.suggested_agent,
    } as DealReturnProps;
    dispatch(returnDealToManager(deal, returnProps));
  };

  const disabledModal = () => {
    return !(
      returnDealProps &&
      returnDealProps.return_reason &&
      returnDealProps.suggested_agent
    );
  };

  const onChangeReturnReason = (value: string) => {
    const returnReasonChange = {
      ...returnDealProps,
      return_reason: value,
    } as DealReturnProps;
    setReturnDealProps(returnReasonChange);
  };

  const onChangeSuggestedAgent = (value: string) => {
    const suggestAgentChange = {
      ...returnDealProps,
      suggested_agent: value,
    } as DealReturnProps;
    setReturnDealProps(suggestAgentChange);
  };

  return (
    <Modal
      size="medium"
      onDismiss={() => setOpenModal(false)}
      isOpen={openModal}
      footer={
        <ModalFooter
          labelSubmit="RETURN"
          onSave={() => {
            confirmReturnToManager();
            setOpenModal(false);
          }}
          isDisabled={disabledModal()}
          onCancel={() => setOpenModal(false)}
        />
      }
      header={<ModalHeader headerTitleText="RETURN_DEAL" />}
    >
      <Row className="fw-bold mb-2 fs-6">
        <Col md={12}>{translate("RETURN_REASON")} *</Col>
      </Row>
      <Row className="mb-4 fs-6">
        <Col md={10}>
          <TextArea
            formRegister={register("return_reason")}
            rows={5}
            placeholder={translate("RETURN_REASON")}
            className="mb-2"
            value={returnDealProps?.return_reason ?? ""}
            onChange={(str) => onChangeReturnReason(str.target.value)}
            autoFocus
          />
        </Col>
      </Row>

      <Row className="fw-bold mb-2 fs-6">
        <Col md={12}>{translate("SUGGESTED_AGENT")} *</Col>
      </Row>
      <Row className="mb-4">
        <Col md={8}>
          <RHFInput
            register={register("suggested_agent")}
            placeholder={translate("SUGGESTED_AGENT")}
            onChange={(str) => onChangeSuggestedAgent(str.target.value)}
            value={returnDealProps?.suggested_agent ?? ""}
            onFocus={(e) => e.stopPropagation}
            autoFocus
          />
        </Col>
      </Row>
    </Modal>
  );
}
