import { Spinner } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { LayoutFooter } from "components/layout/Layout";
import Paginator from "components/Pagination";
import { defaultPaginatorOptions } from "components/Pagination/consts";
import { SpinnerSize } from "components/spinner";
import { useTranslation } from "hooks/use-translate";
import { numberFormatOrZero } from "libs/number-format";
import { CustomerDetailsParams } from "routes";
import { RootState } from "state";
import { fetchBalanceInvoicePaymentsData } from "state/activities/actions";
import { AppDispatch } from "state/use-app-redux";
import BalancesTable from "./balancesTable";
import CustomerPageLayout from "..";

export default function CustomerPageBalancesPage() {
  const { t: translate } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const [customerData, setCustomerData] = useState<any>();
  const [deptSum, setCustomerDeptSum] = useState(0);
  const [creditSum, setCustomerCreditSum] = useState(0);
  const { customerId } = useParams<CustomerDetailsParams>();

  const customers = useSelector((state: RootState) => state.customers.data);
  const customer = customers.find(
    (e) => e.customer.customer_number === customerId
  )?.customer;

  const [activePage, setActivePage] = useState<number>(1);
  const [itemsShownPerPage, setItemsShownPerPage] = useState<number>(15);
  const activePageObjects = customerData?.slice(
    (activePage - 1) * itemsShownPerPage,
    activePage * itemsShownPerPage
  );

  useEffect(() => {
    const init = async () => {
      if (customer) {
        dispatch(fetchBalanceInvoicePaymentsData(customer))
          .then((res) => setCustomerData(res))
          .catch((err) => console.log(err));
      }
    };

    init();
  }, [customer, customerId, dispatch]);

  useEffect(() => {
    if (customerData) {
      const deptSummary = customerData.reduce((a: number, b: any) => {
        return (a += b.debitbase);
      }, 0);

      const creditSummary = customerData.reduce((a: number, b: any) => {
        return (a += b.creditbase);
      }, 0);
      setCustomerDeptSum(deptSummary);
      setCustomerCreditSum(creditSummary);
    }
  }, [customerData]);

  return (
    <>
      <CustomerPageLayout>
        <div className="new-page-content">
          <div className="d-flex customer-settings-table-sticky contentHeader">
            <div className="header-title d-flex align-items-center">
              <h3 className="pl-sm">
                {translate("CUSTOMER_PAGE_BALANCES_INVOICES_PAYMENTS")}
              </h3>
              <span className="px-sm  text-muted font-size-em-5">
                {customerData && `(${customerData.length})`}
              </span>
            </div>
            <div className="ml-auto">
              <Paginator
                itemsLength={customerData?.length || 0}
                defaultItemsShownPerPage={itemsShownPerPage}
                showItemsLabel={translate("SHOW")}
                options={defaultPaginatorOptions}
                onSelectPage={setActivePage}
                onChangeShownItems={(v) => setItemsShownPerPage(v)}
              />
            </div>
          </div>

          {customerData === undefined ? (
            <Spinner className="mt-sm" size={SpinnerSize.ExtraSmall} />
          ) : (
            <BalancesTable activePageObjects={activePageObjects} />
          )}
        </div>
      </CustomerPageLayout>
      <LayoutFooter>
        <div className="footer pl-lg d-flex align-items-center">
          <div className="fw-700">Summary:</div>

          <div className="vertical-divider ml-md p-sm" />

          <div className="info-label">
            {translate("DEPT")}: <span>{numberFormatOrZero(deptSum)}</span>
          </div>

          <div className="vertical-divider ml-md p-sm" />

          <div className="info-label">
            {translate("CREDIT")}: <span>{numberFormatOrZero(creditSum)}</span>
          </div>
        </div>
      </LayoutFooter>
    </>
  );
}
