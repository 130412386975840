import { useNavigate } from "react-router-dom";
import { ArrowUpRight16Regular } from "@fluentui/react-icons";
import { useTranslation } from "hooks/use-translate";
import { Customer } from "models/customer";
import { AppRouteHelper } from "routes";
import { Button } from "components/button";
import { numberFormatOrZero } from "libs/number-format";

type PguMainTableProps = {
  activePageObjects: Customer[];
  customerNumber?: string;
  isNotDetailedPage?: boolean;
};

export default function PguMainTable({
  activePageObjects,
  customerNumber,
  isNotDetailedPage = false,
}: PguMainTableProps) {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const URLProps = new URLSearchParams(window.location.search);
  const isConveyorBeltV2 = URLProps.get("conveyorBeltV2");

  return (
    <>
      <div className="p-lg horizontal-scroll cs-content">
        <table>
          <thead>
            <tr className="full-width px-sm align-items-center my-sm fw-bold no-border">
              <th>
                <span>{translate("ASSIGNMENT")}</span>
              </th>
              <th>
                <span>{translate("FINANCIAL_STATEMENTS")}</span>
              </th>
              <th>
                <span className="number-columns-alignment-right">
                  {translate("REGISTERED_PRICE")}
                </span>
              </th>
              <th>
                <span className="number-columns-alignment-right">
                  {translate("OPEN_RESALE_PRICE")}
                </span>
              </th>
              <th>
                <span className="number-columns-alignment-right">
                  {translate("UP_DOWN_ADJUSTMENTS")}
                </span>
              </th>
              <th>
                <span className="number-columns-alignment-right">
                  {translate("INVOICED_VALUE")}
                </span>
              </th>
              <th>
                <span className="number-columns-alignment-right">
                  {translate("INVOICE")}
                </span>
              </th>
              <th className="px-md">
                <span>{translate("PROJECT_STATEMENT")}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {activePageObjects &&
              activePageObjects.map((data: any, index) => {
                return (
                  <tr
                    className="full-width px-sm py-sm align-items-center"
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >
                    <td>
                      <span
                        className="cursor-pointer text-color-blue"
                        onClick={() =>
                          navigate({
                            pathname: isConveyorBeltV2
                              ? AppRouteHelper.getAssignmentTable(
                                  customerNumber,
                                  data.jobnumber,
                                  true
                                )
                              : AppRouteHelper.getAssignmentTable(
                                  customerNumber,
                                  data.jobnumber
                                ),
                          })
                        }
                      >
                        {data.jobnumber}
                      </span>
                    </td>
                    <td>
                      <span
                        className="cursor-pointer text-color-blue"
                        onClick={() => {
                          navigate({
                            pathname: isConveyorBeltV2
                              ? AppRouteHelper.getFfinancialStatementsTable(
                                  customerNumber,
                                  data.jobnumber,
                                  data.purposename,
                                  true
                                )
                              : AppRouteHelper.getFfinancialStatementsTable(
                                  customerNumber,
                                  data.jobnumber,
                                  data.purposename
                                ),
                          });
                        }}
                      >
                        {data.purposename}
                      </span>
                    </td>
                    <td>
                      <span className="number-columns-alignment-right">
                        {numberFormatOrZero(data.billingPriceReg)}
                      </span>
                    </td>
                    <td>
                      <span className="number-columns-alignment-right">
                        {numberFormatOrZero(data.billingPriceOpen)}
                      </span>
                    </td>
                    <td>
                      <span className="number-columns-alignment-right">
                        {numberFormatOrZero(data.billingPriceUpDown)}
                      </span>
                    </td>
                    <td>
                      <span
                        className="cursor-pointer text-color-blue number-columns-alignment-right"
                        onClick={() => {
                          navigate({
                            pathname: isConveyorBeltV2
                              ? AppRouteHelper.getPartialyInvoicedTable(
                                  customerNumber,
                                  data.jobnumber,
                                  "1",
                                  true
                                )
                              : AppRouteHelper.getPartialyInvoicedTable(
                                  customerNumber,
                                  data.jobnumber,
                                  "1"
                                ),
                          });
                        }}
                      >
                        {numberFormatOrZero(data.partialInvoice)}
                      </span>
                    </td>
                    <td>
                      <span className="number-columns-alignment-right">
                        {numberFormatOrZero(data.billingPriceInvoiced)}
                      </span>
                    </td>
                    <td className="d-flex justify-content-evenly">
                      <span
                        className="cursor-pointer text-color-blue"
                        onClick={() => {
                          navigate({
                            pathname: isConveyorBeltV2
                              ? AppRouteHelper.getPGUDetailsTable(
                                  customerNumber,
                                  data.jobnumber,
                                  "1",
                                  true
                                )
                              : AppRouteHelper.getPGUDetailsTable(
                                  customerNumber,
                                  data.jobnumber,
                                  "1"
                                ),
                          });
                        }}
                      >
                        {translate("DETAILED")}
                      </span>
                      <div className="vertical-divider" />
                      <span
                        className="cursor-pointer text-color-blue"
                        onClick={() => {
                          navigate({
                            pathname: isConveyorBeltV2
                              ? AppRouteHelper.getDetailsPerTask(
                                  customerNumber,
                                  data.jobnumber,
                                  "True",
                                  true
                                )
                              : AppRouteHelper.getDetailsPerTask(
                                  customerNumber,
                                  data.jobnumber,
                                  "True"
                                ),
                          });
                        }}
                      >
                        {translate("PER_TASK")}
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {isNotDetailedPage && (
        <div className="d-flex align-items-end my-md px-0">
          <Button
            variant="outline-outline"
            className="justify-content-start primary-colored-text"
            onClick={() =>
              navigate({
                pathname: isConveyorBeltV2
                  ? AppRouteHelper.getCustomerPguPage(customerNumber, true)
                  : AppRouteHelper.getCustomerPguPage(customerNumber),
              })
            }
          >
            <ArrowUpRight16Regular />
            <span className="d-inline mx-sm fw-bold primary">
              {translate("GO_TO_PGU_LINK")}
            </span>
          </Button>
        </div>
      )}
    </>
  );
}
