import { ReactNode } from "react";
import { Field, makeStyles, SearchBox } from "@fluentui/react-components";
import type { SearchBoxProps } from "@fluentui/react-components";

type SearchPropsV9 = {
  label?: string;
  errorMessage?: ReactNode;
} & SearchBoxProps;

const useStyles = makeStyles({
  searchBox: {
    maxWidth: "100%",
  },
});

export function SearchInput({ label, ...rest }: SearchPropsV9) {
  const styles = useStyles();

  return (
    <div>
      <Field label={label}>
        <SearchBox
          {...rest}
          className={`${styles.searchBox} ${rest.className ?? ""}`}
        />
        {rest.errorMessage && rest.errorMessage}
      </Field>
    </div>
  );
}
