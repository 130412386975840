import {
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  TableColumnDefinition,
  createTableColumn,
} from "@fluentui/react-components";

import Switch from "components/switch";
import TooltipV9 from "components/tooltip/TooltipV9";
import { useTranslation } from "hooks";
import { Deal, PotentialDealItem } from "models/deals/deal";
import "./potentialDealsGrid.scss";

interface PotentialDealsGridProps {
  potentialDeals: Deal[];
  items: PotentialDealItem[];
  isExpandedView?: boolean;
  selectedDealId?: string;
  shouldToggleDeal?: boolean;
  togglePotentialDeal?: (item: PotentialDealItem, checked: boolean) => void;
}

export function PotentialDealsGrid({
  potentialDeals,
  items,
  isExpandedView = false,
  selectedDealId,
  shouldToggleDeal = false,
  togglePotentialDeal,
}: PotentialDealsGridProps) {
  const { t: translate } = useTranslation();

  const formatHeaderCell = (text: string) => {
    return <span className="fw-semibold w-100">{text}</span>;
  };

  const columns: TableColumnDefinition<PotentialDealItem>[] = [
    createTableColumn<PotentialDealItem>({
      columnId: "businessArea",
      compare: (a, b) => a.businessArea.localeCompare(b.businessArea),
      renderHeaderCell: () => formatHeaderCell(translate("DEAL_BUSINESS_AREA")),
      renderCell: (item) => `${item.businessArea}`,
    }),
    createTableColumn<PotentialDealItem>({
      columnId: "dateCreated",
      compare: (a, b) =>
        a.dateCreatedAsMilliseconds - b.dateCreatedAsMilliseconds,
      renderHeaderCell: () => formatHeaderCell(translate("DATE_CREATED")),
      renderCell: (item) => item.dateCreatedFormatted,
    }),
    createTableColumn<PotentialDealItem>({
      columnId: "sourceUnit",
      compare: (a, b) => a.sourceValue - b.sourceValue,
      renderHeaderCell: () => formatHeaderCell(translate("DEAL_SOURCE")),
      renderCell: (item) => item.sourceLabel,
    }),
    createTableColumn<PotentialDealItem>({
      columnId: "services",
      compare: (a, b) => a.services.length - b.services.length,
      renderHeaderCell: () => formatHeaderCell(translate("SERVICES")),
      renderCell: (item) => {
        const serviceArray = item.services.slice(1).map((service) => service);
        return (
          <>
            {item.services?.[0]}
            {item.services.length > 1 && (
              <span className="ml-sm">
                <TooltipV9 notTranslatable content={serviceArray.join(", ")}>
                  <span className="cursor-pointer text-color-blue">
                    +{item.services.length - 1}
                  </span>
                </TooltipV9>
              </span>
            )}
          </>
        );
      },
    }),
    createTableColumn<PotentialDealItem>({
      columnId: "businessValue",
      compare: (a, b) => {
        const amountA = a.businessValueAmount ?? 0;
        const amountB = b.businessValueAmount ?? 0;
        return amountA - amountB;
      },
      renderHeaderCell: () => formatHeaderCell(translate("BUSINESS_VALUE")),
      renderCell: (item) =>
        item.businessValueAmount === null ? (
          <span className="body-italic-light">{translate("NO_VALUE")}</span>
        ) : (
          item.businessValueAmountFormatted
        ),
    }),
    createTableColumn<PotentialDealItem>({
      columnId: "contactPerson",
      compare: (a, b) => a.contactPersonName.localeCompare(b.contactPersonName),
      renderHeaderCell: () => formatHeaderCell(translate("CONTACT_PERSON")),
      renderCell: (item) => (
        <div className="stringLength">{item.contactPersonName}</div>
      ),
    }),
    createTableColumn<PotentialDealItem>({
      columnId: "stage",
      compare: (a, b) => a.stage.localeCompare(b.stage),
      renderHeaderCell: () => formatHeaderCell(translate("STAGE")),
      renderCell: (item) => item.stage,
    }),
    createTableColumn<PotentialDealItem>({
      columnId: "responsiblePerson",
      compare: (a, b) =>
        a.responsiblePersonEmail.localeCompare(b.responsiblePersonEmail),
      renderHeaderCell: () => formatHeaderCell(translate("RESPONSIBLE_PERSON")),
      renderCell: (item) => {
        return (
          <div className="stringLength">{item.responsiblePersonEmail}</div>
        );
      },
    }),
  ];

  if (shouldToggleDeal) {
    const toggleColumn = createTableColumn<PotentialDealItem>({
      columnId: "dealSelection",
      renderCell: (item) => {
        return (
          <Switch
            checked={item.dealId === selectedDealId}
            onToggleMethod={(_, { checked }) =>
              togglePotentialDeal?.(item, checked)
            }
          />
        );
      },
    });
    columns.unshift(toggleColumn);
  }

  return (
    <div className={`w-100 ${shouldToggleDeal ? "toggle-data-grid" : ""}`}>
      {potentialDeals.length > 0 ? (
        <DataGrid
          items={isExpandedView ? items : [items[0]]}
          columns={columns}
          sortable
          className="sticky-button-list"
        >
          <DataGridHeader>
            <DataGridRow>
              {({ renderHeaderCell }) => (
                <DataGridHeaderCell className="dg-th-cell">
                  {renderHeaderCell()}
                </DataGridHeaderCell>
              )}
            </DataGridRow>
          </DataGridHeader>
          <DataGridBody<PotentialDealItem>>
            {({ item, rowId }) => (
              <DataGridRow<PotentialDealItem> key={rowId}>
                {({ renderCell }) => (
                  <DataGridCell className="dg-td-cell">
                    {renderCell(item)}
                  </DataGridCell>
                )}
              </DataGridRow>
            )}
          </DataGridBody>
        </DataGrid>
      ) : (
        <div>{translate("NO_BUSINESS_OPPORTUNITIES")}</div>
      )}
    </div>
  );
}
