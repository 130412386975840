import { Spinner } from "@fluentui/react-components";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import BreadcrumbV9, {
  BreadcrumbDefinitionItem,
} from "components/breadcrumbV2";
import { ContentBodyV2, ContentV2 } from "components/contentV2/ContentV2";
import { LayoutHeader, LayoutHeaderLeft } from "components/layout/Layout";
import { LayoutBodyV2 } from "components/layoutV2/LayoutV2";
import { useTranslation } from "hooks/use-translate";
import { LoadingStatusEnum } from "models/enums/LoadingStatus.enum";

import { SpinnerSize } from "components/spinner";
import { AppRouteHelper, CustomerDetailsParams } from "routes";
import { RootState } from "state";
import { resetCustomerSettingsData } from "state/customerSettings/customerSettingsSlice";
import {
  fetchCustomerSettingsCallerWrapper,
  fetchServiceLinesCallerWrapper,
} from "state/customerSettings/customerSettingsThunk";
import CustomerPageServiceLine from "../customerPage/customerPageServiceLine";
import CustomerSettingsCategoriesCard from "./components/customerSettingsCategoriesCard";
import "./index.scss";

export default function CustomerSettingsPage() {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const URLProps = new URLSearchParams(window.location.search);
  const isConveyorBeltV2 = URLProps.get("conveyorBeltV2");

  const { customerId } = useParams<CustomerDetailsParams>();
  const customers = useSelector((state: RootState) => state.customers.data);
  const customer = customers.find(
    (e) => e.customer.customer_number === customerId
  )?.customer;

  const { currentUser } = useSelector((state: RootState) => state.users);

  const currentServiceLine = useSelector(
    (state: RootState) => state.settings.serviceLines.currentServiceLine
  );

  const { data: customerSettingsData, status } = useSelector(
    (state: RootState) => state.settings.customerSettings
  );

  const dataToShowFrom = currentServiceLine
    ? customerSettingsData.filter(
        (settings) => settings.serviceline === currentServiceLine.serviceline
      )
    : [...customerSettingsData].sort((a, b) => a.id - b.id);

  // filter out categories with same id
  const settingsCategories = dataToShowFrom
    .flatMap((serviceLine) => serviceLine.settings_categories)
    .filter(
      (value, index, self) => self.findIndex((m) => m.id === value.id) === index
    )
    .sort((a, b) => a.sorting_number - b.sorting_number);

  useEffect(() => {
    const init = async () => {
      await dispatch(fetchServiceLinesCallerWrapper());

      if (customerId && customerSettingsData.length === 0) {
        dispatch(
          fetchCustomerSettingsCallerWrapper({
            customerId,
            userGraphId: currentUser.graphId,
          })
        );
      }
    };
    init();
  }, [currentUser.graphId, customerId, customerSettingsData.length, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetCustomerSettingsData());
    };
  }, [dispatch]);

  const breadcrumbs: BreadcrumbDefinitionItem[] = [
    {
      text: translate("MY_ASSIGNMENTS"),
      onClick: () =>
        navigate(
          isConveyorBeltV2
            ? AppRouteHelper.getAssignments("", true)
            : AppRouteHelper.getAssignments()
        ),
    },
    {
      text: (customer && customer.name) || "",
      onClick: () =>
        navigate(
          isConveyorBeltV2
            ? AppRouteHelper.getAssignments(customerId, true)
            : AppRouteHelper.getAssignments(customerId)
        ),
    },
    {
      text: translate("SETTINGS"),
    },
  ];

  const getAllToggledSettings = () => {
    return settingsCategories
      .flatMap((category) => category.settings)
      .filter((setting) => setting.status).length;
  };

  const getAllAvailableSettings = () => {
    return settingsCategories.flatMap((category) => category.settings).length;
  };

  return (
    <>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <div className="d-flex align-items-center justify-content-between">
            <div className="pr-md">
              <BreadcrumbV9 items={breadcrumbs} />
            </div>

            <div className="vertical-divider pr-sm" />
            <div className="d-flex align-items-center justify-content-between">
              {" "}
              <label className="pr-md pl-sm">{translate("VIEW")}:</label>
              <CustomerPageServiceLine />
            </div>
          </div>
        </LayoutHeaderLeft>
      </LayoutHeader>
      <LayoutBodyV2>
        <ContentV2>
          <ContentBodyV2>
            {customerId ? (
              <>
                <div className="header-title px-sm d-flex">
                  <h3 className="pl-sm stringLength">
                    {translate("SETTINGS")}
                  </h3>
                  {status !== LoadingStatusEnum.PENDING &&
                    settingsCategories.length > 0 && (
                      <span className="accordion-subtitle align-self-center ml-sm">
                        {getAllToggledSettings()}/
                        <span className="text-muted">
                          {getAllAvailableSettings()}
                        </span>
                      </span>
                    )}
                </div>
                {status === LoadingStatusEnum.PENDING ||
                settingsCategories.length === 0 ? (
                  <div>
                    <Spinner size={SpinnerSize.ExtraSmall} />
                  </div>
                ) : (
                  <div className="settings-wrapper px-sm py-md">
                    {settingsCategories.length > 0 &&
                      settingsCategories.map((category) => (
                        <div key={`card-${category.id}`}>
                          <CustomerSettingsCategoriesCard
                            settingsCategory={category}
                          />
                        </div>
                      ))}
                  </div>
                )}
              </>
            ) : (
              <div>{translate("NO_CUSTOMER_ID")}</div>
            )}
          </ContentBodyV2>
        </ContentV2>
      </LayoutBodyV2>
    </>
  );
}
