import { LocalizationHelper } from "@microsoft/mgt-react";
import { CacheService } from "@microsoft/mgt-element";
import { TranslationKey } from "./i18n";
import { Translate } from "./i18n/translator";

// TODO: Add cache for personQuery='email'
// https://docs.microsoft.com/en-us/graph/toolkit/customize-components/cache

const OneDay = 24 * 60 * 60 * 1000;
const FiveMinutes = 5 * 60 * 1000;

CacheService.config.isEnabled = true;
CacheService.config.defaultInvalidationPeriod = OneDay;
CacheService.config.users.isEnabled = true;
CacheService.config.users.invalidationPeriod = OneDay;
CacheService.config.photos.isEnabled = true;
CacheService.config.photos.invalidationPeriod = OneDay;
CacheService.config.people.isEnabled = true;
CacheService.config.people.invalidationPeriod = OneDay;
CacheService.config.presence.isEnabled = true;
CacheService.config.presence.invalidationPeriod = FiveMinutes;

export function setTranslation(translation: Translate<TranslationKey>) {
  LocalizationHelper.strings = {
    noResultsFound: translation("NO_SEARCH_RESULTS"),
    _components: {
      "people-picker": {
        inputPlaceholderText: translation(
          "MGT_PEOPLE_PICKER_INPUT_PLACEHOLDER_TEXT"
        ),
        noResultsFound: translation("MGT_PEOPLE_PICKER_NO_RESULTS_FOUND"),
        loadingMessage: translation("MGT_PEOPLE_PICKER_LOADING"),
        maxSelectionsPlaceHolder: "",
      },
    },
  } as any;
}
