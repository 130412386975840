import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ArrowTrendingLines20Regular,
  Comment20Regular,
} from "@fluentui/react-icons";

import BreadcrumbV9, {
  BreadcrumbDefinitionItem,
} from "components/breadcrumbV2";
import {
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
} from "components/layout/Layout";
import Modal from "components/modal";
import { useTranslation } from "hooks/use-translate";
import { CompanyTypeOptions, ValidLegalFormCodes } from "models/offer/Company";
import { CustomerDeal } from "models/offer/CustomerDeal";
import { AppRouteHelper } from "routes";
import { VolumeAnalysis } from "views/offer/components/wizardHeader/VolumeAnalysis/VolumeAnalysis";
import "./wizardHeader.scss";

interface WizardHeaderProps {
  currentOffer?: CustomerDeal;
  comments?: string[];
}

export default function WizardHeader({ currentOffer }: WizardHeaderProps) {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const customer = currentOffer?.customer;
  const isNewCustomer = currentOffer?.new_customer;
  const [modalVisable, setModalVisable] = useState(false);

  function getCompanyTypeLabel(wkname: string | undefined) {
    if (!wkname) {
      return "";
    }
    return CompanyTypeOptions[wkname as ValidLegalFormCodes].label;
  }
  const breadcrumbs: BreadcrumbDefinitionItem[] = [
    {
      text: translate("CREATE_NEW"),
      onClick: () => navigate(AppRouteHelper.getCreateNew()),
    },
    {
      text: translate("CONTRACT"),
      onClick: () => {
        if (isNewCustomer || customer?.org_number) {
          navigate(AppRouteHelper.getOffers());
        }
      },
    },
  ];

  if (isNewCustomer) {
    breadcrumbs.push({
      text: translate("NEW_CUSTOMER"),
    });
  } else if (customer?.org_number) {
    breadcrumbs.push({
      text: translate("EXISTING_CUSTOMER"),
    });
  }

  if (!currentOffer?.customer) {
    return (
      <LayoutHeader>
        <BreadcrumbV9 items={breadcrumbs} />
      </LayoutHeader>
    );
  }

  const showModal = () => {
    setModalVisable(!modalVisable);
  };

  return (
    <LayoutHeader>
      <LayoutHeaderLeft>
        <div className="wizard-header d-flex align-items-center">
          <BreadcrumbV9 items={breadcrumbs} />
          <div className="vertical-divider" />
          <div className="info-label">
            {translate("ORG_NUM")}: <span>{customer?.org_number}</span>
          </div>
          <div className="vertical-divider" />
          {currentOffer.id && (
            <>
              <div className="info-label">
                {translate("OFFER_ID")}: <span>{currentOffer.id}</span>
              </div>

              <div className="vertical-divider" />
            </>
          )}

          <div className="info-label">
            {translate("COMP_TYPE")}:{" "}
            <span>{getCompanyTypeLabel(customer?.legal_form)}</span>
          </div>
        </div>
      </LayoutHeaderLeft>

      <LayoutHeaderRight>
        <ArrowTrendingLines20Regular
          onClick={showModal}
          className="financial-icon text-color-blue"
        />
        <Comment20Regular className="comment-icon ml-sm text-color-blue" />
      </LayoutHeaderRight>

      {modalVisable && (
        <Modal
          isOpen={modalVisable}
          onDismiss={() => {
            setModalVisable(false);
          }}
          size="large"
        >
          {customer && modalVisable ? (
            <VolumeAnalysis organizationNumber={customer.org_number || ""} />
          ) : null}
        </Modal>
      )}
    </LayoutHeader>
  );
}
