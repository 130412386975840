/* eslint-disable camelcase */
import { Fragment, MouseEvent, useContext, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableSelectionCell,
  useTableSelection,
  TableRowId,
  createTableColumn,
  Tooltip,
  TableColumnSizingOptions,
  useTableColumnSizing_unstable,
  useTableSort,
  TableColumnId,
} from "@fluentui/react-components";
import { ErrorCircle24Regular, Info20Regular } from "@fluentui/react-icons";
import { PersonViewType } from "@microsoft/mgt-react";

import { useTranslation } from "hooks/use-translate";
import {
  useTableExpandingRows,
  useTableFeaturesExtended,
  useTableLockingRows,
} from "components/table";
import { CustomerManagement } from "models/customerManagement";
import { formatMonth } from "libs/date/date-format";
import {
  TableColumnDefinitionExtended,
  createTableColumnExtended,
} from "components/table/createTableColumnExtended";
import TooltipV9 from "components/tooltip/TooltipV9";
import { Persona } from "components/people";
import { RootState } from "state";
import {
  DynamicDealPropertiesObjectName,
  DynamicDealPropertiesPropertyName,
} from "shared/dealEnums";
import { ManageCustomersContext } from "./ManageCustomersContext";
import ChosenClientProgramCell from "./ChosenClientProgramCell";

const COLUMNS = {
  CUSTOMER_NAME: "customer_name",
  CUSTOMER_NUMBER: "customer_number",
  BUSINESS_UNIT_NAME: "business_unit_name",
  YE: "YE",
  MARKET_AREA_NAME: "market_area_name",
  PROJECTS: "projects",
  RECOMMENDED_CLIENT_PROGRAM: "recommended_client_program",
  CHOSEN_CLIENT_PROGRAM: "chosen_client_program",
  SALESPERSON_NAME: "sales",
  MANAGERS: "managers",
};

const useStyles = makeStyles({
  row: {
    "&:hover": {
      cursor: "ns-resize",
    },
  },
  lockedRow: {
    "&:hover": {
      cursor: "not-allowed",
      backgroundColor: "unset",
    },
    "&:active": {
      backgroundColor: "unset",
    },
    opacity: 0.4,
  },
  childRow: {
    borderBottomWidth: 0,
    borderTopWidth: 0,
    borderBottomColor: "transparent",
    backgroundColor: "var(--gray-300)",
    "&:active": {
      backgroundColor: "var(--colorSubtleBackgroundHover)",
    },
  },
  parentRow: {
    borderBottomWidth: 0,
    backgroundColor: "var(--gray-300)",
    "&:hover": {
      cursor: "ns-resize",
    },
  },
});

export default function CustomersTable() {
  const { t: translate, language } = useTranslation();

  const styles = useStyles();

  const {
    dynamicDealProperties: { data: dynamicDealProperties },
  } = useSelector((state: RootState) => state.sales);

  const recommendedProgramProperties =
    dynamicDealProperties[
      `${DynamicDealPropertiesObjectName.COMPANIES}-${DynamicDealPropertiesPropertyName.RECOMMENDED_PROGRAM}`
    ];
  const chosenProgramProperties =
    dynamicDealProperties[
      `${DynamicDealPropertiesObjectName.COMPANIES}-${DynamicDealPropertiesPropertyName.CHOSEN_PROGRAM}`
    ];

  const {
    customers,
    customerFilter,
    selectedRows,
    setSelectedRows,
    lockedRows,
  } = useContext(ManageCustomersContext);

  const renderNoLongerEmployeeWarning = () => (
    <TooltipV9
      content="MANAGE_CUSTOMERS.ERROR_TOOLTIP_PERSON_NO_LONGER_EMPLOYEE"
      className="fit-content-min"
      childrenClassName="no-longer-employee-warning"
    >
      <ErrorCircle24Regular
        className="text-color-yellow cursor-pointer transform-x-5 mt-xxs"
        onClick={(e) => e.stopPropagation()}
      />
    </TooltipV9>
  );

  const columnsDef: TableColumnDefinitionExtended<CustomerManagement>[] = [
    createTableColumnExtended<CustomerManagement>({
      columnId: COLUMNS.CUSTOMER_NAME,
      compare: (a, b) => a.customer_name.localeCompare(b.customer_name),
      renderHeaderCell: () => translate("MANAGE_CUSTOMERS.COLUMN_NAME"),
      renderCell: (item) => (
        <TableCellLayout truncate>{item.customer_name}</TableCellLayout>
      ),
    }),
    createTableColumn<CustomerManagement>({
      columnId: COLUMNS.CUSTOMER_NUMBER,
      compare: (a, b) => a.customer_number.localeCompare(b.customer_number),
      renderHeaderCell: () => translate("MANAGE_CUSTOMERS.COLUMN_NUMBER"),
      renderCell: (item) => item.customer_number,
    }),
    createTableColumn<CustomerManagement>({
      columnId: COLUMNS.BUSINESS_UNIT_NAME,
      compare: (a, b) =>
        a.business_unit_name.localeCompare(b.business_unit_name),
      renderHeaderCell: () =>
        translate("MANAGE_CUSTOMERS.COLUMN_BUSINESS_UNIT"),
      renderCell: (item) => (
        <TableCellLayout truncate>{item.business_unit_name}</TableCellLayout>
      ),
    }),
    createTableColumn<CustomerManagement>({
      columnId: COLUMNS.YE,
      compare: (a, b) => {
        const aYE = a.YE ? parseFloat(a.YE) : Number.MAX_SAFE_INTEGER;
        const bYE = b.YE ? parseFloat(b.YE) : Number.MAX_SAFE_INTEGER;
        return aYE - bYE;
      },
      renderHeaderCell: () => translate("MANAGE_CUSTOMERS.COLUMN_YEAR_END"),
      renderCell: (item) => item.YE && formatMonth(language, item.YE),
    }),
    createTableColumn<CustomerManagement>({
      columnId: COLUMNS.MARKET_AREA_NAME,
      compare: (a, b) => a.market_area_name.localeCompare(b.market_area_name),
      renderHeaderCell: () => translate("MANAGE_CUSTOMERS.COLUMN_MARKET_AREA"),
      renderCell: (item) => (
        <TableCellLayout truncate>{item.market_area_name}</TableCellLayout>
      ),
    }),
    createTableColumnExtended<CustomerManagement>({
      columnId: COLUMNS.PROJECTS,
      compare: (a, b) => a.projects.length - b.projects.length,
      renderHeaderCell: () => translate("MANAGE_CUSTOMERS.COLUMN_PROJECTS"),
      renderCell: (item) => item.projects.length,
      classNameTd: "text-center",
    }),
    createTableColumn<CustomerManagement>({
      columnId: COLUMNS.RECOMMENDED_CLIENT_PROGRAM,
      compare: (a, b) => {
        const aProgram = recommendedProgramProperties.find(
          (prop) => prop.value === a.recommended_client_program
        );
        const bProgram = recommendedProgramProperties.find(
          (prop) => prop.value === b.recommended_client_program
        );
        const aDisplayOrder = aProgram
          ? aProgram.displayOrder
          : Number.MAX_SAFE_INTEGER;
        const bDisplayOrder = bProgram
          ? bProgram.displayOrder
          : Number.MAX_SAFE_INTEGER;
        return aDisplayOrder - bDisplayOrder;
      },
      renderHeaderCell: () =>
        translate("MANAGE_CUSTOMERS.COLUMN_RECOMMENDED_CLIENT_PROGRAM"),
      renderCell: (item) => {
        const program = recommendedProgramProperties.find(
          (prop) => prop.value === item.recommended_client_program
        );
        return (
          <TableCellLayout truncate>
            {program?.label || item.recommended_client_program || (
              <span className="text-gray body-italic">
                {translate("MANAGE_CUSTOMERS.CLIENT_PROGRAM.EMPTY")}
              </span>
            )}
          </TableCellLayout>
        );
      },
    }),
    createTableColumn<CustomerManagement>({
      columnId: COLUMNS.CHOSEN_CLIENT_PROGRAM,
      compare: (a, b) => {
        const aProgram = chosenProgramProperties.find(
          (prop) => prop.value === a.chosen_client_program
        );
        const bProgram = chosenProgramProperties.find(
          (prop) => prop.value === b.chosen_client_program
        );
        const aDisplayOrder = aProgram
          ? aProgram.displayOrder
          : Number.MAX_SAFE_INTEGER;
        const bDisplayOrder = bProgram
          ? bProgram.displayOrder
          : Number.MAX_SAFE_INTEGER;
        return aDisplayOrder - bDisplayOrder;
      },
      renderHeaderCell: () =>
        translate("MANAGE_CUSTOMERS.COLUMN_CLIENT_PROGRAM"),
      renderCell: (item) => (
        <ChosenClientProgramCell
          rowData={item}
          chosenProgramProperties={chosenProgramProperties}
        />
      ),
    }),
    createTableColumnExtended<CustomerManagement>({
      columnId: COLUMNS.SALESPERSON_NAME,
      compare: (a, b) => a.salesperson_name.localeCompare(b.salesperson_name),
      renderHeaderCell: () => translate("MANAGE_CUSTOMERS.COLUMN_SALESPERSONS"),
      renderCell: (item) => (
        <div className="d-flex">
          <Persona
            className="persona-default-size"
            view={PersonViewType.oneline}
            userId={item.salesperson_graph_id || ""}
            fallbackDetails={
              item.salesperson_graph_id
                ? undefined
                : { displayName: item.salesperson_name }
            }
          />

          {!item.salesperson_graph_id && renderNoLongerEmployeeWarning()}
        </div>
      ),
    }),
    createTableColumnExtended<CustomerManagement>({
      columnId: COLUMNS.MANAGERS,
      compare: (a, b) => {
        const aDistinctProjectManagers = new Set(
          a.projects.map((project) => project.projectmanager_name)
        );
        const bDistinctProjectManagers = new Set(
          b.projects.map((project) => project.projectmanager_name)
        );
        return aDistinctProjectManagers.size - bDistinctProjectManagers.size;
      },
      renderHeaderCell: () =>
        translate("MANAGE_CUSTOMERS.COLUMN_PROJECT_MANAGERS"),
      renderCell: (item) => {
        const distinctProjectManagers = new Set(
          item.projects.map((project) => project.projectmanager_name)
        );
        return distinctProjectManagers.size;
      },
      classNameTh: "managers-cell-th",
      classNameTd: "managers-cell-td",
    }),
  ];

  const [columns] =
    useState<TableColumnDefinitionExtended<CustomerManagement>[]>(columnsDef);
  const [columnSizingOptions] = useState<TableColumnSizingOptions>({
    [COLUMNS.CUSTOMER_NAME]: {
      minWidth: 190,
      idealWidth: 270,
    },
    [COLUMNS.CUSTOMER_NUMBER]: {
      minWidth: 80,
      idealWidth: 100,
    },
    [COLUMNS.BUSINESS_UNIT_NAME]: {
      minWidth: 110,
      idealWidth: 130,
    },
    [COLUMNS.YE]: {
      minWidth: 100,
      idealWidth: 120,
    },
    [COLUMNS.MARKET_AREA_NAME]: {
      minWidth: 120,
    },
    [COLUMNS.PROJECTS]: {
      minWidth: 80,
      idealWidth: 100,
    },
    [COLUMNS.RECOMMENDED_CLIENT_PROGRAM]: {
      minWidth: 130,
    },
    [COLUMNS.CHOSEN_CLIENT_PROGRAM]: {
      minWidth: 140,
    },
    [COLUMNS.SALESPERSON_NAME]: {
      minWidth: 220,
    },
    [COLUMNS.MANAGERS]: {
      minWidth: 150,
    },
  });

  const renderChildRows = (
    rowId: TableRowId,
    item: CustomerManagement,
    expanded: boolean,
    locked: boolean
  ) => {
    if (!expanded || !item.projects.length || locked) {
      return null;
    }

    return (
      <>
        <TableRow key={`${rowId}-child-header`} className={styles.childRow}>
          <TableCell colSpan={7} />
          <TableCell className="header-cell">
            {translate("MANAGE_CUSTOMERS.COLUMN_PROJECT_TYPE")}
          </TableCell>
          <TableCell />
          <TableCell className="header-cell">
            {translate("MANAGE_CUSTOMERS.COLUMN_PROJECT_MANAGER")}
          </TableCell>
          <TableCell />
        </TableRow>
        {item.projects.map((project) => (
          <TableRow
            key={`${rowId}-${project.project_number}-child`}
            className={styles.childRow}
          >
            <TableCell colSpan={7} />
            <TableCell colSpan={2}>
              {project.project_type.toUpperCase()}
              <Tooltip
                relationship="description"
                withArrow
                content={{ children: project.project_number }}
              >
                <Info20Regular className="cursor-pointer ml-sm v-align-sub" />
              </Tooltip>
            </TableCell>
            <TableCell className="d-flex">
              <Persona
                className="persona-default-size"
                view={PersonViewType.oneline}
                userId={project.projectmanager_graph_id || ""}
                fallbackDetails={
                  project.projectmanager_graph_id
                    ? undefined
                    : { displayName: project.projectmanager_name }
                }
              />

              {!project.projectmanager_graph_id &&
                renderNoLongerEmployeeWarning()}
            </TableCell>
            <TableCell>
              <Button appearance="transparent" />
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  const {
    getRows,
    selection: {
      allRowsSelected,
      toggleAllRows: selectAllRows,
      toggleRow: selectRow,
      isRowSelected,
    },
    columnSizing_unstable,
    tableRef,
    expandingRows: { isRowExpanded, toggleRow: selectExpandingRow },
    lockingRows: { isRowLocked },
    sort: { getSortDirection, toggleColumnSort, sort },
  } = useTableFeaturesExtended(
    {
      columns,
      items: customers,
    },
    [
      useTableSelection({
        selectionMode: "multiselect",
        selectedItems: selectedRows,
        onSelectionChange: (_, { selectedItems }) =>
          setSelectedRows(selectedItems),
      }),
      useTableColumnSizing_unstable({ columnSizingOptions }),
      useTableSort({
        defaultSortState: {
          sortColumn: "customer_name",
          sortDirection: "ascending",
        },
      }),
    ],
    [
      useTableExpandingRows({ expandingMode: "single" }),
      useTableLockingRows({ lockedItems: lockedRows }),
    ]
  );

  const allRowsToggled = () => {
    if (!lockedRows.size) {
      return allRowsSelected;
    }

    const unlockedRows = getRows().filter((row) => !lockedRows.has(row.rowId));

    if (unlockedRows.every((row) => isRowSelected(row.rowId))) {
      return true;
    }
    if (unlockedRows.some((row) => isRowSelected(row.rowId))) {
      return "mixed";
    }
    return false;
  };

  const toggleAllRows = (e: React.SyntheticEvent<Element, Event>) => {
    if (!lockedRows.size) {
      selectAllRows(e);
      return;
    }

    const { checked } = e.target as HTMLInputElement;
    if (!checked) {
      setSelectedRows(new Set());
      return;
    }

    const unlockedRows = getRows().filter((row) => !lockedRows.has(row.rowId));
    const unlockedRowsIds = new Set(unlockedRows.map((row) => row.rowId));
    setSelectedRows(unlockedRowsIds);
  };

  const headerSortProps = (columnId: TableColumnId) => ({
    onClick: (e: MouseEvent) => {
      toggleColumnSort(e, columnId);
    },
    sortDirection: getSortDirection(columnId),
  });

  let rows = sort(
    getRows((row) => {
      const selected = isRowSelected(row.rowId);
      const expanded = isRowExpanded(row.rowId);
      const locked = isRowLocked(row.rowId);

      return {
        ...row,
        selected,
        expanded,
        locked,
        onClick: (e: MouseEvent) => selectExpandingRow(e, row.rowId),
      };
    })
  );

  if (customerFilter) {
    rows = rows.filter((row) =>
      row.item.customer_name
        .toLowerCase()
        .includes(customerFilter.toLowerCase())
    );
  }

  return (
    <Table
      className="customer-management-list"
      sortable
      ref={tableRef}
      {...columnSizing_unstable.getTableProps()}
    >
      <TableHeader>
        <TableRow className="top-sticky-header">
          <TableSelectionCell
            checked={allRowsToggled()}
            onClick={toggleAllRows}
          />
          {columnsDef.map((column) => (
            <TableHeaderCell
              key={column.columnId}
              {...headerSortProps(column.columnId)}
              className={`header-cell${
                column.classNameTh ? ` ${column.classNameTh}` : ""
              }`}
              {...columnSizing_unstable.getTableHeaderCellProps(
                column.columnId
              )}
            >
              {column.renderHeaderCell()}
            </TableHeaderCell>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {rows.map(({ item, rowId, selected, expanded, locked, onClick }) => (
          <Fragment key={rowId}>
            <TableRow
              key={`${rowId}-parent`}
              id={rowId as string}
              onClick={locked ? undefined : onClick}
              className={
                (expanded && styles.parentRow) ||
                (locked && styles.lockedRow) ||
                styles.row
              }
            >
              {locked ? (
                <TableCell />
              ) : (
                <TableSelectionCell
                  checked={selected}
                  onClick={(e) => {
                    e.stopPropagation();
                    selectRow(e, rowId);
                  }}
                  className="row-selection-cell locked"
                />
              )}
              {columnsDef.map((column) => (
                <TableCell
                  key={`${rowId}-parent-${column.columnId}`}
                  className={column.classNameTd}
                  {...columnSizing_unstable.getTableCellProps(column.columnId)}
                >
                  {column.renderCell(item)}
                </TableCell>
              ))}
            </TableRow>
            {renderChildRows(rowId, item, expanded, locked)}
          </Fragment>
        ))}
      </TableBody>
    </Table>
  );
}
