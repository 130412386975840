import { Spinner } from "@fluentui/react-components";
import { ArrowDownload20Regular } from "@fluentui/react-icons";
import { useState } from "react";

import { OffersAPI } from "api/offers";
import { authMethod } from "auth";
import { Button } from "components/button";
import { SpinnerSize } from "components/spinner";
import { useTranslation } from "hooks/use-translate";

interface DownloadPdfButtonProps {
  dealId?: string;
  type?: "button" | "link";
  pdf_type?: "contract" | "additional";
  filename?: string;
  onPdfDownloaded?: () => void;
}

export default function DownloadPdfButton({
  dealId,
  type = "button",
  pdf_type = "contract",
  filename,
  onPdfDownloaded,
}: DownloadPdfButtonProps) {
  const { t: translate } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const handleOnClick = async () => {
    if (!dealId || isLoading) {
      return;
    }

    try {
      setIsLoading(true);

      const token = await authMethod.getStoredAccessToken();
      let pdfUrl = "";
      if (pdf_type === "contract") {
        pdfUrl = await OffersAPI.fetchDealPdfUrl(token, dealId);
      } else {
        pdfUrl = await OffersAPI.fetchDealAttachment(token, dealId);
      }

      if (pdfUrl) {
        onPdfDownloaded?.();
        window.open(pdfUrl, "_blank");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const renderButtonContent = () => {
    if (isLoading) {
      return (
        <span className="d-flex">
          <Spinner size={SpinnerSize.ExtraSmall} className="me-2" />{" "}
          {pdf_type === "contract"
            ? translate("DOWNLOAD_PDF")
            : filename || translate("DOWNLOAD_ADDITIONAL_PDF")}
        </span>
      );
    }

    return pdf_type === "contract"
      ? translate("DOWNLOAD_PDF")
      : filename || translate("DOWNLOAD_ADDITIONAL_PDF");
  };

  if (type === "link") {
    return (
      <span
        onClick={handleOnClick}
        className="text-color-blue cursor-pointer d-flex align-items-center"
      >
        <ArrowDownload20Regular />{" "}
        <span className="ms-2">{renderButtonContent()}</span>
      </span>
    );
  }

  return <Button onClick={handleOnClick}>{renderButtonContent()}</Button>;
}
