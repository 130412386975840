/* eslint-disable no-await-in-loop */
import { plainToClass } from "class-transformer";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Dropdown } from "components/dropdown";
import { OverlaySpinner } from "components/spinner";
import VerticalStepper from "components/verticalStepper";
import { useTranslation } from "hooks/use-translate";
import { isPlaceholderEmail } from "libs/generate-placeholder-email";
import { removeWhiteSpace } from "libs/remove-whitespace-from-strings";
import { Deal } from "models/deals/deal";
import { ValidLegalFormCodes } from "models/offer/Company";
import { DealCustomer } from "models/offer/Customer";
import { DealContact, SigningMethod } from "models/offer/DealContact";
import DealContactDetails from "models/offer/DealContactDetails";
import { Customer } from "models/customer";
import { useGetCompanyTypesQuery } from "services/ludvigApi";
import { RootState } from "state";
import { appendToastMessage } from "state/notifications";
import {
  createOrUpdateCompanyContact,
  createOrUpdateCustomer,
} from "state/offer/companyThunks";
import { updateOfferContact } from "state/offer/offersSlice";
import { resetOffer, setupNewOfferForCustomer } from "state/offer/offersThunks";
import { searchContact } from "state/sales/actions";
import { AppDispatch } from "state/use-app-redux";
import { useRoutingForOffer } from "views/offer/components/wizardSection/useRoutingForOffer";
import { WizardSection } from "../../components/wizardSection";
import CustomerTypeChangeModal from "../../components/customerTypeChangeModal";
import CustomerSource from "../CustomerSource";
import { OfferRouteHelper } from "../offerRoutes";
import CompanyForm from "./CompanyForm";

type CompanyRegistrationInputs = {
  organizationNumber: string;
  companyName: string;
  legalForm: string;
  postalTown: string;
  postalCode: string;
  address: string;
};

function CustomerDetails() {
  const { t: translate } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const offers = useSelector((state: RootState) => state.offers);
  const { currentUser } = useSelector((state: RootState) => state.users);
  const currentOffer = offers.currentOffer.data;
  const offerCustomer = currentOffer?.customer;
  const isNewCustomer = currentOffer?.new_customer;
  const { data: companyTypeList } = useGetCompanyTypesQuery();
  const location = useLocation();
  const routing = useRoutingForOffer(location.pathname, currentOffer);
  const navigate = useNavigate();
  const { currentDeal: hubspotDeal } = useSelector(
    (state: RootState) => state.sales
  );
  const [loadingMessage, setLoadingMessage] = useState("");
  const [maconomyCustomer, setMaconomyCustomer] = useState<
    Customer | undefined
  >();
  const [customerSourceIsOpen, setCustomerSourceIsOpen] = useState(true);
  const [isChangeCustomerTypeDialogOpen, setChangeCustomerTypeDialogOpen] =
    useState(false);
  const [newCustomerType, setNewCustomerType] = useState("");

  const getCompanyTypeFromHubspotDeal = () => {
    if (hubspotDeal && !hubspotDeal.company) {
      return "pp";
    }
    return "ab";
  };

  const getHubspotCompanyName = () => {
    if (hubspotDeal) {
      return hubspotDeal.company
        ? hubspotDeal.company
        : `${hubspotDeal.contact.firstname} ${hubspotDeal.contact.lastname}`;
    }
    return "";
  };

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
    reset,
  } = useForm<CompanyRegistrationInputs>({
    mode: "all",
    reValidateMode: "onBlur",
    // DV-2291 if there is a hubspot deal and it is the register flow
    // get the data from the hubspot deal
    defaultValues: {
      address:
        isNewCustomer && hubspotDeal
          ? hubspotDeal?.postal_address
          : offerCustomer?.address ?? "",
      companyName:
        isNewCustomer && hubspotDeal
          ? getHubspotCompanyName()
          : offerCustomer?.customer_name ?? "",
      legalForm:
        hubspotDeal && isNewCustomer
          ? getCompanyTypeFromHubspotDeal()
          : offerCustomer?.legal_form ?? "",
      postalCode:
        isNewCustomer && hubspotDeal
          ? hubspotDeal?.postal_address_co
          : offerCustomer?.postal_code ?? "",
      organizationNumber:
        isNewCustomer && hubspotDeal
          ? hubspotDeal?.organisationsnummer
          : offerCustomer?.org_number ?? "",
      postalTown:
        isNewCustomer && hubspotDeal
          ? hubspotDeal?.postal_city
          : offerCustomer?.postal_town ?? "",
    },
  });
  const isFormValid =
    isValid && Object.keys(errors).length === 0 && !maconomyCustomer;
  const watchLegalForm = watch("legalForm");

  if (!currentOffer) {
    return <>Missing offer</>;
  }

  if (!offerCustomer) {
    return <>Missing offer customer</>;
  }
  if (!companyTypeList) {
    return <>Missing company types</>;
  }

  // DV-2291 customer might not be created when we try to create the contact for the offer
  // proposed solution was to try 3 times before giving up
  const createCompanyContactWithRetry = async (
    contact: DealContactDetails,
    deal: Deal
  ) => {
    const maxRetries = 3;
    let retryIteration = 1;

    while (retryIteration <= maxRetries) {
      try {
        // eslint-disable-next-line no-promise-executor-return
        await new Promise((resolve) => setTimeout(resolve, 2000));
        const pogContact = await dispatch(
          createOrUpdateCompanyContact({
            contactData: contact,
            orgNum: deal.organisationsnummer,
            disableErrorNotification: true,
          })
        ).unwrap();
        setLoadingMessage("");
        return pogContact;
      } catch (error) {
        console.warn(
          `API request failed. Retry ${retryIteration + 1}/${maxRetries + 1}`
        );

        if (retryIteration === maxRetries) {
          setLoadingMessage("");
          dispatch(
            appendToastMessage("CREATE_POG_CONTACT_ERROR_MESSAGE", "error")
          );
          throw new Error(`Max retries exceeded. Giving up. Error: ${error}`);
        }

        retryIteration++;
        setLoadingMessage(
          translate("CREATE_POG_CONTACT_RETRY_LOADING_MESSAGE")
        );
      }
    }
  };

  // DV-2291 create a contact from the hubspot deal before navigating to the contacts step
  const createOrUpdateHSContactInPogOffer = async () => {
    // search the contact by deal's contact email
    if (hubspotDeal) {
      setLoadingMessage(translate("CREATE_POG_CONTACT_LOADING_MESSAGE"));
      const contacts = await dispatch(searchContact(hubspotDeal.contact.email));
      const contactFound = contacts?.[0];

      const contactData = {
        is_signer: false,
        signing_method: isPlaceholderEmail(hubspotDeal.contact.email)
          ? SigningMethod.PHYSICAL
          : SigningMethod.BANKID,
        contact: {
          first_name: hubspotDeal.contact.firstname,
          last_name: hubspotDeal.contact.lastname,
          social_security_number: contactFound?.social_security_number ?? "",
          mobile_phone: hubspotDeal.contact.mobilephone,
          phone: hubspotDeal.contact.phone,
          email: hubspotDeal.contact.email,
          changed_by_username: currentUser.email,
          created_by_username: currentUser.email,
          id: "",
        },
      };

      const pogContact = await createCompanyContactWithRetry(
        contactData.contact,
        hubspotDeal
      );

      if (pogContact) {
        const refreshedDealContact = new DealContact({
          ...contactData,
          is_signer: !!pogContact.social_security_number,
          contact: pogContact,
        });

        // refresh the contact with new newest data
        dispatch(updateOfferContact(refreshedDealContact));
      }
    }
  };

  const handleOnBeforeNext = async () => {
    await handleSubmit(async (data) => {
      const tempDealCustomerUpdatedByUser = !offerCustomer.created_by_username
        ? {
            created_by_username: currentUser.email,
            changed_by_username: currentUser.email,
          }
        : {
            changed_by_username: currentUser.email,
          };

      await dispatch(
        createOrUpdateCustomer(
          plainToClass(DealCustomer, {
            ...currentOffer.customer,
            address: data.address,
            postal_code: data.postalCode,
            postal_town: removeWhiteSpace(data.postalTown),
            org_number: data.organizationNumber,
            customer_name: data.companyName,
            legal_form: data.legalForm,
            ...tempDealCustomerUpdatedByUser,
          })
        )
      );

      // if the flow starts from deals-> start creating Pog offer,
      // after registering the company create the contact
      if (hubspotDeal && currentOffer.new_customer) {
        await createOrUpdateHSContactInPogOffer();
      }
    })();

    return true;
  };

  const handleOnBeforePrevious = async () => {
    await dispatch(setupNewOfferForCustomer(offerCustomer));

    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
  };

  const renderCustomerTypeForm = () => {
    const companyTypeInput = register("legalForm", {
      required: "COMPANY_TYPE_REQUIRED",
      validate: (value) =>
        companyTypeList
          .map((cti) => cti.WKName)
          .includes(value as ValidLegalFormCodes) || "INVALID",
    });

    const companyTypeOptions = [
      ...companyTypeList.map((item) => ({
        value: item.WKName,
        label: item.NameSE,
      })),
    ];

    return (
      <Row className="mb-md">
        <Col md={4}>
          <label className="fw-semibold">{translate("CUSTOMER_TYPE")}</label>
          <Dropdown
            formRegister={companyTypeInput}
            options={companyTypeOptions}
            placeholder={translate("SELECT_COMPANY_FORM")}
            onChange={(e) => {
              const legalForm = e;
              if (watchLegalForm) {
                setNewCustomerType(legalForm);
                setChangeCustomerTypeDialogOpen(!!isNewCustomer);
                if (!isNewCustomer) {
                  setValue("legalForm", legalForm as ValidLegalFormCodes, {
                    shouldValidate: true,
                  });
                }
              } else {
                setValue("legalForm", legalForm as ValidLegalFormCodes, {
                  shouldValidate: true,
                });
              }
            }}
            value={watchLegalForm}
            disabled={
              !isNewCustomer &&
              !!offerCustomer?.legal_form &&
              offerCustomer.legal_form !== "öv"
            }
          />
        </Col>
      </Row>
    );
  };

  const handleCustomerTypeChangeConfirm = () => {
    reset();
    setValue("legalForm", newCustomerType as ValidLegalFormCodes, {
      shouldValidate: true,
    });
    setNewCustomerType("");
    setChangeCustomerTypeDialogOpen(false);
  };

  const handleCustomerTypeChangeCancel = () => {
    setNewCustomerType("");
    setChangeCustomerTypeDialogOpen(false);
  };

  const customerSteps = [
    {
      key: "1",
      label: translate("CUSTOMER_TYPE"),
      circleLabel: "A",
      component: renderCustomerTypeForm(),
      state: {
        active: true,
        disabled: false,
        highlighted: !watchLegalForm,
      },
    },
    {
      key: "2",
      label: translate("CUSTOMER_DETAILS"),
      circleLabel: "B",
      component: watchLegalForm ? (
        <CompanyForm
          register={register}
          trigger={trigger}
          watch={watch}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          maconomyCustomer={maconomyCustomer}
          setMaconomyCustomer={(customer) => setMaconomyCustomer(customer)}
          isNewCustomer={isNewCustomer}
          offerCustomer={offerCustomer}
        />
      ) : (
        <span className="text-gray fs-italic p-sm">
          {translate("CUSTOMER_DETAILS_WARNING")}
        </span>
      ),
      state: {
        active: !!watchLegalForm,
        disabled: !watchLegalForm,
        highlighted: !!watchLegalForm,
      },
    },
  ];

  return (
    <WizardSection
      loadingStatus={offers.currentOffer.status}
      isNextHidden={!isFormValid}
      onBeforeNext={handleOnBeforeNext}
      subtitles={[translate("REGISTER_COMPANY_INFO")]}
      onBeforePrevious={handleOnBeforePrevious}
    >
      {loadingMessage && <OverlaySpinner label={loadingMessage} />}
      <Col md={12} className="mt-lg">
        <VerticalStepper steps={customerSteps} />
      </Col>
      {isNewCustomer && !hubspotDeal?.deal_source && (
        <CustomerSource
          isOpen={customerSourceIsOpen}
          customer={offerCustomer}
          handleCancel={async () => {
            setCustomerSourceIsOpen(false);
            await dispatch(resetOffer());
            navigate(OfferRouteHelper.getCustomerSearch());
          }}
          handleConfirm={() => setCustomerSourceIsOpen(false)}
          disableClickOutside
          hideCloseButton
        />
      )}
      {isChangeCustomerTypeDialogOpen && (
        <CustomerTypeChangeModal
          isOpen={isChangeCustomerTypeDialogOpen}
          onDiscard={handleCustomerTypeChangeCancel}
          onCancel={handleCustomerTypeChangeCancel}
          onSave={handleCustomerTypeChangeConfirm}
        />
      )}
    </WizardSection>
  );
}

export default CustomerDetails;
