import { useCallback, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@fluentui/react-components";

import "./ManageCustomers.scss";
import { useTranslation } from "hooks/use-translate";
import { CompletionSuccessComponent } from "shared/CompletionScreen";
import {
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutWrapper,
} from "components/layout/Layout";
import BreadcrumbV9 from "components/breadcrumbV2";
import { Content, ContentBody } from "components/content/Content";
import { ContentHeader } from "components/content/ContentHeader";
import { usePostCustomersForManagementMutation } from "services/customerManagementApi";
import {
  CustomerManagement,
  CustomerManagementProject,
  CustomerManagementRoles,
  CustomerManagementWithIndex,
} from "models/customerManagement";
import { appendError, appendToastMessage } from "state/notifications";
import { AppDispatch } from "state/use-app-redux";
import { ManageCustomersContext } from "./ManageCustomersContext";
import { ManageCustomersRouteHelper } from "./manageCustomersRoutes";
import { SpinnerSize } from "components/spinner";

export default function UpdateInProgress() {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const {
    customers,
    setCustomers,
    assignee,
    assigneeFullName,
    newAssigneeId,
    affectedCustomersCounter,
    affectedProjectsCounter,
    affectedRoles,
    setSelectedRows,
    selectedCustomers,
  } = useContext(ManageCustomersContext);

  if (!assignee || !assignee.userPrincipalName) {
    throw new Error("Missing assignee!");
  }
  if (!newAssigneeId) {
    throw new Error("Missing new assignee!");
  }

  const [postManagement] = usePostCustomersForManagementMutation();

  const assigneeIsRole = useCallback(
    (roleGraphId?: string | null) => {
      if (!roleGraphId) {
        return false;
      }
      return roleGraphId === assignee.id;
    },
    [assignee.id]
  );

  const updatePersonInArray = useCallback(
    (customersToUpdate: CustomerManagement[]) => {
      if (
        !customersToUpdate.length ||
        affectedRoles === CustomerManagementRoles.None
      ) {
        return;
      }

      const isMarkedForUpdateSalesPerson = [
        CustomerManagementRoles.SalesPerson,
        CustomerManagementRoles.Both,
      ].includes(affectedRoles);

      const isMarkedForUpdateProjectManager = [
        CustomerManagementRoles.ProjectManager,
        CustomerManagementRoles.Both,
      ].includes(affectedRoles);

      customersToUpdate.forEach((customer) => {
        const shouldUpdateSalesPerson =
          isMarkedForUpdateSalesPerson &&
          assigneeIsRole(customer.salesperson_graph_id);

        if (shouldUpdateSalesPerson) {
          customer.previous_salesperson_graph_id =
            customer.salesperson_graph_id!;
          customer.salesperson_graph_id = newAssigneeId;

          // We need to remove 'projectmanager_graph_id' because
          // we don't want to update the project manager here;
          // we only want to update the sales person of the project
          if (!isMarkedForUpdateProjectManager) {
            customer.projects.forEach((project) => {
              delete project.projectmanager_graph_id;
            });
          }
        }

        if (isMarkedForUpdateProjectManager) {
          const projects: CustomerManagementProject[] = [];

          customer.projects.forEach((project) => {
            if (assigneeIsRole(project.projectmanager_graph_id)) {
              project.projectmanager_graph_id = newAssigneeId;
              projects.push(project);
            } else if (shouldUpdateSalesPerson) {
              delete project.projectmanager_graph_id;
              projects.push(project);
            } // else: leave out the project
          });

          customer.projects = projects;
        }
      });
    },
    [affectedRoles, newAssigneeId, assigneeIsRole]
  );

  const dispatchError = useCallback(
    (error: any) => {
      dispatch(
        appendError(
          "MANAGE_CUSTOMERS.FAILED_TO_UPDATE_CUSTOMERS_FOR_MANAGEMENT",
          error as Error
        )
      );
    },
    [dispatch]
  );

  const submitManagementChanges = useCallback(() => {
    const data = structuredClone(selectedCustomers).map(
      ({ customer }: CustomerManagementWithIndex) => customer
    );
    updatePersonInArray(data);

    postManagement({
      assigneeEmail: assignee.userPrincipalName as string,
      body: { data },
    })
      .then((response) => {
        if (
          "error" in response ||
          (response.data && "error" in response.data)
        ) {
          const error =
            "error" in response ? response.error : (response.data as any).error;

          dispatchError(error);

          return;
        }

        const affectedCustomerNumbers = response.data.data.map(
          (customer) => customer.customer_number
        );

        // The logic on the BE dictates that if a customer is already locked
        // (which means he's being currently processed), he'll be skipped from
        // being returned in the response.
        const lockedCustomers = data.filter(
          (customer: CustomerManagement) =>
            !affectedCustomerNumbers.find(
              (customerNumber) => customerNumber === customer.customer_number
            )
        );

        if (lockedCustomers.length) {
          dispatch(
            appendToastMessage(
              "MANAGE_CUSTOMERS.TOAST_MESSAGE.SKIPPING_CUSTOMERS",
              "warning"
            )
          );
        }

        // We need to update the status of both the affected customers and the
        // skipped ones.
        const updatedCustomers = customers.map((customer) => {
          let customerToUpdate = affectedCustomerNumbers.find(
            (customerNumber) => customerNumber === customer.customer_number
          );
          if (!customerToUpdate) {
            customerToUpdate = lockedCustomers.find(
              (missingCustomer: CustomerManagement) =>
                missingCustomer.customer_number === customer.customer_number
            )?.customer_number;
          }
          if (customerToUpdate) {
            return { ...customer, status: true };
          }
          return customer;
        });
        setCustomers(updatedCustomers);
      })
      .catch((error) => dispatchError(error))
      .finally(() => {
        setSelectedRows(new Set());
        navigate(ManageCustomersRouteHelper.getListOfCustomers());
      });
  }, [
    assignee.userPrincipalName,
    customers,
    dispatch,
    dispatchError,
    navigate,
    postManagement,
    selectedCustomers,
    setCustomers,
    setSelectedRows,
    updatePersonInArray,
  ]);

  useEffect(() => {
    submitManagementChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <BreadcrumbV9
            items={[
              {
                text: translate("MANAGE_CUSTOMERS.BREADCRUMBS_FIRST_PART"),
                onClick: () =>
                  navigate(ManageCustomersRouteHelper.getLandingPage()),
              },
              { text: assigneeFullName },
            ]}
          />
        </LayoutHeaderLeft>
      </LayoutHeader>

      <LayoutWrapper>
        <Content>
          <ContentHeader
            title={translate(
              "MANAGE_CUSTOMERS.UPDATE_IN_PROGRESS.CONTENT_HEADER_TITLE"
            )}
          />
          <ContentBody>
            <CompletionSuccessComponent
              successMessage={translate(
                "MANAGE_CUSTOMERS.UPDATE_IN_PROGRESS.SUCCESS_MESSAGE",
                [
                  affectedCustomersCounter.toString(),
                  affectedProjectsCounter.toString(),
                ]
              )}
              infoMessage={
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: translate(
                      "MANAGE_CUSTOMERS.UPDATE_IN_PROGRESS.INFO_MESSAGE",
                      [assigneeFullName]
                    ),
                  }}
                />
              }
              icon={<Spinner size={SpinnerSize.Huge} className="zoom-2" />}
            />
          </ContentBody>
        </Content>
      </LayoutWrapper>
    </>
  );
}
