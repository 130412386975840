import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { DateRangeType } from "@fluentui/react-calendar-compat";

import { Button } from "@fluentui/react-components";
import {
  ArrowClockwise16Regular,
  ArrowClockwise20Regular,
  Add16Regular,
  Dismiss16Filled,
} from "@fluentui/react-icons";

import { useTranslation } from "hooks/use-translate";
import useWindowDimensions from "hooks/use-window-dimensions";
import {
  Recurrence,
  ActivityStatus,
  ActivityDefinition,
} from "models/activities/activity";
import { PersonDetails } from "models/profile";
import { UserSnapshot } from "models/user";
import { DatePickerV9 } from "components/date/DatePickerV9";
import { DropdownV9, OptionItem } from "components/dropdown/DropdownV9";
import TooltipV9 from "components/tooltip/TooltipV9";
import { uuidv4 } from "libs/uuid";
import { PersonPicker } from "components/people";
import {
  ACTIVITY_STATUS_PASSED_DEADLINE_KEY,
  FILTER_ACTIVITY_DEFINITIONS,
} from "./AssignmentManagementV2/helpers";
import "./FilterSortBox.scss";


type FilterSortTableProps = {
  selectedDate: Date | undefined;
  setSelectedDate: (params: Date | undefined) => void;

  selectedActivityFilterFrequencies: Recurrence[];
  setActivityFilterFrequencies: (params: Recurrence[]) => void;

  selectedActivityFilterServiceTypes: string[];
  setActivityFilterServiceTypes: (params: string[]) => void;

  selectedActivityFilterDeadline: string | null;
  setActivityFilterDeadline: (params: string | null) => void;

  selectedActivityFilterDefinitions: ActivityDefinition[];
  setActivityFilterDefinitions: (params: ActivityDefinition[]) => void;

  selectedActivityFilterStatuses: ActivityStatus[];
  setActivityFilterStatuses: (params: ActivityStatus[]) => void;

  selectedActivityFilterUserIds: UserSnapshot[];
  setActivityFilterUserIds: (params: UserSnapshot[]) => void;

  selectedNextActivityFilterUsers: UserSnapshot[];
  setNextActivityFilterUsers: (params: UserSnapshot[]) => void;

  overdueFilterEnabled: boolean;
  setEnabledOverdueFilter: (params: boolean) => void;

  resetTimeWindowComplete: () => void;
  setTimeWindowAt: (params: Date | null | undefined) => void;

  handedDocs: boolean;
  setHandedDocs: (params: boolean) => void;

  disableSort: boolean;

  deadlineOptions: OptionItem[];
  frequencyOptions: OptionItem[];
  serviceTypesOptions: OptionItem[];
  statusOptions: OptionItem[];
  activityTypeOptions: OptionItem[];
};

export function FilterSortBox({
  selectedDate,
  setSelectedDate,
  selectedActivityFilterFrequencies,
  setActivityFilterFrequencies,
  selectedActivityFilterServiceTypes,
  setActivityFilterServiceTypes,
  selectedActivityFilterDeadline,
  setActivityFilterDeadline,
  selectedActivityFilterDefinitions,
  setActivityFilterDefinitions,
  selectedActivityFilterStatuses,
  setActivityFilterStatuses,
  selectedActivityFilterUserIds,
  setActivityFilterUserIds,

  selectedNextActivityFilterUsers,
  setNextActivityFilterUsers,

  overdueFilterEnabled,
  setEnabledOverdueFilter,
  resetTimeWindowComplete,
  ...props
}: FilterSortTableProps) {
  const i18n = useTranslation();
  const { t: translate, dateFormatter } = i18n;

  const resetTimeWindow = () => {
    setSelectedDate(undefined);
  };

  const resetFilters = () => {
    setActivityFilterDefinitions([]);
    setActivityFilterUserIds([]);
    setNextActivityFilterUsers([]);
    setActivityFilterFrequencies([]);
    setActivityFilterServiceTypes([]);
    setActivityFilterDeadline(null);
    setActivityFilterStatuses([]);
    resetTimeWindow();
    resetTimeWindowComplete();
    setEnabledOverdueFilter(false);
  };

  const handlePersonChange = (
    isSingleSelection: boolean,
    selectedUsers: UserSnapshot[],
    setSelectedUsers: (params: UserSnapshot[]) => void,
    selected?: PersonDetails
  ) => {
    if (!selected) {
      return;
    }

    const person: UserSnapshot = {
      id: selected.id,
      name: selected.displayName,
    };

    if (isSingleSelection) {
      setSelectedUsers([person]);
    } else {
      const includes = selectedUsers.find((s) => selected.id === s.id);
      if (!includes) {
        setSelectedUsers([...selectedUsers, person]);
      }
    }
  };

  const handleActivityStatusChange = (isSelected: boolean, value?: string) => {
    if (!value) {
      return;
    }

    if (value === ACTIVITY_STATUS_PASSED_DEADLINE_KEY) {
      setEnabledOverdueFilter(isSelected);
    } else {
      setActivityFilterStatuses(
        isSelected
          ? [...selectedActivityFilterStatuses, value as ActivityStatus]
          : selectedActivityFilterStatuses.filter((key) => key !== value)
      );
    }
  };

  return (
    <div className="w-100">
      <Row>
        <Col xs={11}>
          <Row>
            <Col className="px-md py-lg" md={3}>
              <Row className="mb-md">
                <Col className="d-flex align-items-center fw-600">
                  <label>{translate("SERVICE_FILTERS")}:</label>
                </Col>
              </Row>
              <Row className="mb-xs">
                <Col className="d-flex align-items-center">
                  <label className="fw-semibold">
                    {translate("FILTER_ON_FREQUENCY")}
                  </label>
                  <ArrowClockwise16Regular
                    className="ml-sm text-color-blue"
                    onClick={() => setActivityFilterFrequencies([])}
                  />
                </Col>
              </Row>
              <Row className="mb-sm">
                <Col className="d-flex align-items-center w-100" lg={12}>
                  <DropdownV9
                    placeholder={translate("FILTER_ON_FREQUENCY_PLACEHOLDER")}
                    multiselect
                    onOptionSelect={(_, data, isSelected) => {
                      if (data.optionValue) {
                        setActivityFilterFrequencies(
                          isSelected
                            ? [
                                ...selectedActivityFilterFrequencies,
                                data.optionValue as Recurrence,
                              ]
                            : selectedActivityFilterFrequencies.filter(
                                (key) => key !== data.optionValue
                              )
                        );
                      }
                    }}
                    selectedOptions={
                      selectedActivityFilterFrequencies as string[]
                    }
                    options={props.frequencyOptions}
                  />
                </Col>
              </Row>
              {/* service type */}
              <Row className="mb-xs">
                <Col className="d-flex align-items-center">
                  <label className="fw-semibold">
                    {translate("SERVICE_TYPE")}
                  </label>
                  <ArrowClockwise16Regular
                    className="ml-sm text-color-blue"
                    onClick={() => setActivityFilterServiceTypes([])}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex align-items-center w-100" lg={12}>
                  <DropdownV9
                    placeholder={translate(
                      "FILTER_ON_SERVICE_TYPES_PLACEHOLDER"
                    )}
                    multiselect
                    onOptionSelect={(_, { optionValue }, isSelected) => {
                      if (optionValue) {
                        setActivityFilterServiceTypes(
                          isSelected
                            ? [
                                ...selectedActivityFilterServiceTypes,
                                optionValue as string,
                              ]
                            : selectedActivityFilterServiceTypes.filter(
                                (key) => key !== optionValue
                              )
                        );
                      }
                    }}
                    selectedOptions={selectedActivityFilterServiceTypes}
                    options={props.serviceTypesOptions}
                  />
                </Col>
              </Row>
            </Col>

            <Col className="px-md py-lg verticalDivider" md={3}>
              <Row className="mb-md">
                <Col className="d-flex align-items-center fw-600">
                  <label>{translate("DATE_FILTERS")}:</label>
                </Col>
              </Row>
              <Row className="mb-xs">
                <Col className="d-flex align-items-center">
                  <label className="fw-semibold">
                    {translate("FILTER_ON_PERIOD")}
                  </label>
                  <ArrowClockwise16Regular
                    className="ml-sm text-color-blue"
                    onClick={() => resetTimeWindowComplete()}
                  />
                </Col>
              </Row>
              <Row className="mb-xs">
                <Col className="d-flex align-items-center w-100">
                  <DatePickerV9
                    fullWidth
                    dateFormatter={dateFormatter}
                    onSelectDate={(date) => props.setTimeWindowAt(date)}
                    showGoToToday={false}
                    selectedDate={selectedDate ?? null}
                    placeholder={translate("SELECT_MONTH")}
                    calendarProps={{
                      dateRangeType: DateRangeType.Month,
                      isDayPickerVisible: false,
                    }}
                  />
                </Col>
              </Row>
              <Row className="mb-xs">
                <Col className="d-flex align-items-center">
                  <label className="fw-semibold">
                    {translate("FILTER_ON_DEADLINE")}
                  </label>
                  <ArrowClockwise16Regular
                    className="ml-sm text-color-blue"
                    onClick={() => setActivityFilterDeadline(null)}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex align-items-center w-100" lg={12}>
                  <DropdownV9
                    placeholder={translate("FILTER_ON_DEADLINE_PLACEHOLDER")}
                    onOptionSelect={(_, { optionValue }) => {
                      if (optionValue) {
                        setActivityFilterDeadline(optionValue);
                      }
                    }}
                    selectedOptions={[selectedActivityFilterDeadline ?? ""]}
                    options={props.deadlineOptions}
                  />
                </Col>
              </Row>
            </Col>
            <Col className="px-md py-lg verticalDivider" md={3}>
              <Row className="mb-md">
                <Col className="d-flex align-items-center fw-600">
                  <label>{translate("ACTIVITY_FILTERS")}:</label>
                </Col>
              </Row>
              <Row className="mb-xs">
                <Col className="d-flex align-items-center">
                  <label className="fw-semibold">
                    {translate("FILTER_ON_ACTIVITY")}
                  </label>
                  <ArrowClockwise16Regular
                    className="ml-sm text-color-blue"
                    onClick={() => setActivityFilterDefinitions([])}
                  />
                </Col>
              </Row>
              <Row className="mb-sm">
                <Col className="d-flex align-items-center w-100" lg={12}>
                  <DropdownV9
                    multiselect
                    isSearchable
                    placeholder={translate("FILTER_ON_ACTIVITY_PLACEHOLDER")}
                    //  searchFieldPlaceholder={translate("SEARCH_PLACEHOLDER")}
                    selectedOptions={selectedActivityFilterDefinitions.map(
                      (activityData) => activityData.title
                    )}
                    onOptionSelect={(_, { optionValue }, isSelected) => {
                      if (optionValue) {
                        const item = props.activityTypeOptions.find(
                          (option) => option.value === optionValue
                        );
                        if (item) {
                          setActivityFilterDefinitions(
                            isSelected
                              ? [
                                  ...selectedActivityFilterDefinitions,
                                  item.data as ActivityDefinition,
                                ]
                              : selectedActivityFilterDefinitions.filter(
                                  (data) =>
                                    data.activity_name !==
                                    item.data.activity_name
                                )
                          );
                        }
                      }
                    }}
                    options={props.activityTypeOptions}
                  />
                </Col>
              </Row>

              <Row className="mb-xs">
                <Col className="d-flex align-items-center">
                  <label className="fw-semibold">
                    {translate("FILTER_ON_STATUS")}
                  </label>
                  <ArrowClockwise16Regular
                    className="ml-sm text-color-blue"
                    onClick={() => {
                      return (
                        setActivityFilterStatuses([]),
                        setEnabledOverdueFilter(false)
                      );
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex align-items-center w-100" lg={12}>
                  <DropdownV9
                    placeholder={translate(
                      "FILTER_ON_ACTIVITY_STATUS_PLACEHOLDER"
                    )}
                    multiselect
                    selectedOptions={
                      overdueFilterEnabled
                        ? [
                            ...selectedActivityFilterStatuses,
                            ACTIVITY_STATUS_PASSED_DEADLINE_KEY as ActivityStatus,
                          ]
                        : selectedActivityFilterStatuses
                    }
                    onOptionSelect={(_, { optionValue }, isSelected) =>
                      handleActivityStatusChange(isSelected, optionValue)
                    }
                    options={props.statusOptions}
                  />
                </Col>
              </Row>
            </Col>

            <Col className="px-md py-lg verticalDivider" md={3}>
              <Row className="mb-md">
                <Col className="d-flex align-items-center fw-600">
                  <label>{translate("USER_FILTERS")}:</label>
                </Col>
              </Row>
              <Row className="mb-sm">
                <Col className="d-flex align-items-center mb-sm" lg={12}>
                  <PersonPicker
                    label={translate("FILTER_ON_USERS")}
                    selectionMode="multi"
                    placeholder={translate("SEARCH_PERSON")}
                    selectedUsers={selectedActivityFilterUserIds}
                    removeSelectedUser={(params) =>
                      setActivityFilterUserIds(params)
                    }
                    onPersonChange={(_, isSingleSelection, selected) => {
                      handlePersonChange(
                        isSingleSelection,
                        selectedActivityFilterUserIds,
                        setActivityFilterUserIds,
                        selected
                      );
                    }}
                    onReset={() => setActivityFilterUserIds([])}
                  />
                </Col>
                <Col className="d-flex align-items-center" lg={12}>
                  <PersonPicker
                    label={translate("FILTER_ON_NEXT_USERS_ASSIGNED")}
                    selectionMode="multi"
                    placeholder={translate("SEARCH_PERSON")}
                    selectedUsers={selectedNextActivityFilterUsers}
                    removeSelectedUser={(params) =>
                      setNextActivityFilterUsers(params)
                    }
                    onPersonChange={(_, isSingleSelection, selected) => {
                      handlePersonChange(
                        isSingleSelection,
                        selectedNextActivityFilterUsers,
                        setNextActivityFilterUsers,
                        selected
                      );
                    }}
                    onReset={() => setNextActivityFilterUsers([])}
                  />
                </Col>
              </Row>
              <Row className="mb-xs">
                <Col className="d-flex align-items-center">
                  <label className="fw-semibold" />
                </Col>
              </Row>
              <Row>
                <Col className="d-flex align-items-center" lg={12}>
                  <div />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col
          xs={1}
          sm={1}
          className="px-md py-lg my-xl verticalDivider d-flex align-items-center"
        >
          <Button
            appearance="transparent"
            className="text-color-blue"
            onClick={() => resetFilters()}
          >
            <ArrowClockwise20Regular className="mr-xs" />
            {translate("NO_FILTERED_CUSTOMERS_RESET")}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export function FilterSortList({ ...props }: FilterSortTableProps) {
  const i18n = useTranslation();
  const { dateFormatter, t: translate } = i18n;

  const listCount =
    props.selectedActivityFilterFrequencies.length +
    props.selectedActivityFilterServiceTypes.length +
    props.selectedActivityFilterDefinitions.length +
    props.selectedActivityFilterStatuses.length +
    props.selectedActivityFilterUserIds.length +
    props.selectedNextActivityFilterUsers.length +
    (props.handedDocs ? 1 : 0) +
    (props.selectedActivityFilterDeadline ? 1 : 0) +
    (props.selectedDate ? 1 : 0);

  const allFiltersList: {
    id: string;
    labelText: string;
    onClick: () => void;
  }[] = [];

  props.selectedActivityFilterFrequencies.map((key) =>
    allFiltersList.push({
      id: key,
      labelText:
        props.frequencyOptions.filter((item) => key === item.value)[0].text ??
        "",
      onClick: () =>
        props.setActivityFilterFrequencies(
          props.selectedActivityFilterFrequencies.filter((item) => key !== item)
        ),
    })
  );
  props.selectedActivityFilterServiceTypes.map((key) =>
    allFiltersList.push({
      id: key,
      labelText:
        props.serviceTypesOptions.filter((item) => key === item.value)[0]
          .text ?? "",
      onClick: () =>
        props.setActivityFilterServiceTypes(
          props.selectedActivityFilterServiceTypes.filter(
            (item) => key !== item
          )
        ),
    })
  );

  props.selectedActivityFilterStatuses.forEach((key) => {
    return allFiltersList.push({
      id: key,
      labelText:
        props.statusOptions.filter((item) => key === item.value)[0].text ?? "",
      onClick: () =>
        props.setActivityFilterStatuses(
          props.selectedActivityFilterStatuses.filter((item) => key !== item)
        ),
    });
  });
  if (props.overdueFilterEnabled) {
    allFiltersList.push({
      id: ACTIVITY_STATUS_PASSED_DEADLINE_KEY,
      labelText: translate("PASSED_DEADLINE"),
      onClick: () => props.setEnabledOverdueFilter(false),
    });
  }

  props.selectedActivityFilterDefinitions.forEach((item) => {
    let labelText = "";

    if (props.activityTypeOptions) {
      const activity = props.activityTypeOptions.find(
        (activityObj) => item.title === activityObj.value
      );

      if (activity) {
        labelText = activity.text ?? "";
      } else {
        window.localStorage.removeItem(FILTER_ACTIVITY_DEFINITIONS);
        return;
      }
    }

    return allFiltersList.push({
      id: item.title,
      labelText,
      onClick: () =>
        props.setActivityFilterDefinitions(
          props.selectedActivityFilterDefinitions.filter(
            (obj) => obj.activity_name !== item.activity_name
          )
        ),
    });
  });
  props.selectedActivityFilterUserIds.map((selectedUsers) =>
    allFiltersList.push({
      id: selectedUsers.id,
      labelText: selectedUsers.name || "",
      onClick: () =>
        props.setActivityFilterUserIds(
          props.selectedActivityFilterUserIds.filter(
            (item) => selectedUsers.id !== item.id
          )
        ),
    })
  );
  props.selectedNextActivityFilterUsers.map((nextSelectedUsers) =>
    allFiltersList.push({
      id: nextSelectedUsers.id,
      labelText: nextSelectedUsers.name || "",
      onClick: () =>
        props.setNextActivityFilterUsers(
          props.selectedNextActivityFilterUsers.filter(
            (item) => nextSelectedUsers.id !== item.id
          )
        ),
    })
  );

  if (props.selectedActivityFilterDeadline) {
    allFiltersList.push({
      id: props.selectedActivityFilterDeadline,
      labelText:
        props.deadlineOptions.filter(
          (item) => props.selectedActivityFilterDeadline === item.value
        )[0].text ?? "",
      onClick: () => props.setActivityFilterDeadline(null),
    });
  }

  if (props.selectedDate) {
    allFiltersList.push({
      id: dateFormatter(props.selectedDate),
      labelText: dateFormatter(props.selectedDate),
      onClick: () => props.resetTimeWindowComplete(),
    });
  }

  if (!props.disableSort && props.handedDocs) {
    allFiltersList.push({
      id: props.handedDocs ? "Sorted by handed docs" : "Not sorted",
      labelText: "By date of handed docs",
      onClick: () => props.setHandedDocs(!props.handedDocs),
    });
  }

  let listCountAdjustable = 0;
  const additionalCount = allFiltersList.length - 3;
  const [dropdownShown, setDropdownShown] = useState(true);
  const { windowWidth } = useWindowDimensions();
  const isSmallScreen = windowWidth < 1800;
  const dropdownAdditionalFiltersList = isSmallScreen
    ? allFiltersList
    : allFiltersList.slice(3);

  if (!props.selectedDate || !props.selectedActivityFilterServiceTypes.length) {
    if (props.handedDocs) {
      props.setHandedDocs(!props.handedDocs);
    }
  }

  return (
    <>
      {!isSmallScreen &&
        allFiltersList.map(({ id, labelText, onClick }) => {
          listCountAdjustable += 1;

          return (
            listCountAdjustable <= 3 && (
              <FiltersListItem
                key={id}
                labelText={labelText}
                onClick={onClick}
                labelClassName="filter-item-label filter-item-label-short-view pills-left"
              />
            )
          );
        })}

      {(isSmallScreen ? allFiltersList.length > 0 : additionalCount > 0) && (
        <>
          <label
            key={listCount}
            className="ml-sm fg-xs px-sm filter-item-label pills-right"
            onClick={() => setDropdownShown(!dropdownShown)}
          >
            {isSmallScreen ? (
              <span className="pr-xs fw-600 text-color-purple fpx-12">
                {translate("ACTIVE_FILTERS")} : {allFiltersList.length}
              </span>
            ) : (
              <>
                <Add16Regular className="px-xxs mb-xxs" />
                <span className="pr-xs fw-600 text-color-purple fpx-12">
                  {additionalCount}
                </span>
              </>
            )}
          </label>

          <div hidden={dropdownShown} className="mt-md filters-list-dropdown">
            {dropdownAdditionalFiltersList.map(({ id, labelText, onClick }) => {
              return (
                <div className="pb-xs" key={`${uuidv4()}-${id}`}>
                  <FiltersListItem
                    keyId={`${uuidv4()}-${id}`}
                    labelText={labelText}
                    onClick={onClick}
                    style={{ width: "100%" }}
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
}

type FilterSortItemProps = Pick<React.HTMLProps<HTMLDivElement>, "style"> & {
  keyId?: string | number;
  labelText: string;
  labelClassName?: string;
  onClick: () => void;
};

export function FiltersListItem({
  keyId,
  labelText,
  labelClassName,
  onClick,
  ...props
}: FilterSortItemProps) {
  return (
    <label
      key={keyId}
      style={props.style}
      className={`ml-sm  fg-xs px-sm cursor-pointer ${labelClassName}`}
    >
      <TooltipV9 content={labelText} notTranslatable>
        <div className="d-flex justify-content-between w-100">
          <span className="pr-xs fw-600 text-color-purple fpx-12 stringLength filter-item-label-max-width">
            {labelText}
          </span>
          <Dismiss16Filled className="pr-xs mt-xxs" onClick={onClick} />
        </div>
      </TooltipV9>
    </label>
  );
}
