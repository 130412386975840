import { useState } from "react";
import { useSelector } from "react-redux";
import { DrawerBody, OverlayDrawer } from "@fluentui/react-components";

import { RootState } from "state";
import { useTranslation } from "hooks";
import { Offer } from "models/offer/SavedOffers";
import { CustomerDeal } from "models/offer/CustomerDeal";
import DrawerHeader from "components/drawer/drawerHeader/DrawerHeader";
import OffersTable from "./components/OffersTable";

interface IOfferProps {
  isInPanelDisplay?: boolean;
  filteredAndSortedContracts: Offer[];
  filteredDeals: CustomerDeal[];
  count: number;
  noDealsFound: string;
}

export default function Offers({
  isInPanelDisplay = false,
  filteredAndSortedContracts,
  filteredDeals,
  count,
  noDealsFound,
}: IOfferProps) {
  const { t: translate } = useTranslation();
  const { currentUser } = useSelector((state: RootState) => state.users);
  const { data: currentOffer } = useSelector(
    (state: RootState) => state.offers.currentOffer
  );
  const customer = currentOffer?.customer;
  const [showPanel, setShowPanel] = useState(isInPanelDisplay);

  return (
    <div>
      {count > 0 ? (
        <>
          <div className="stack-horizontal">
            <OffersTable
              currentUser={currentUser}
              filteredDeals={filteredDeals}
              filteredAndSortedContracts={filteredAndSortedContracts}
              customer={customer}
            />
            {count > 1 && (
              <div
                className="ml-md mb-xl cursor-pointer pog-link d-flex align-items-center"
                onClick={() => {
                  setShowPanel(!showPanel);
                }}
              >
                <span className="blue-link-underline text-wrap-none fw-semibold counter-number pl-md">
                  {translate("SHOW_ALL")}
                </span>
              </div>
            )}
          </div>
          {count > 1 && (
            <OverlayDrawer open={showPanel} position="end" size="large">
              <DrawerHeader
                header="OFFER_OFFERS_LABEL"
                onDismiss={() => {
                  setShowPanel(false);
                }}
              />
              <DrawerBody>
                <OffersTable
                  currentUser={currentUser}
                  filteredDeals={filteredDeals}
                  filteredAndSortedContracts={filteredAndSortedContracts}
                  customer={customer}
                  isExpandedView
                />
              </DrawerBody>
            </OverlayDrawer>
          )}
        </>
      ) : (
        <span className="text-color-gray-700">{noDealsFound}</span>
      )}
    </div>
  );
}
