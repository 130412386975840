/* eslint-disable react/jsx-no-useless-fragment */
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { Skeleton, SkeletonItem } from "@fluentui/react-components";

import { RootState } from "state";
import { ServiceInstance } from "models/activities/activity";
import { Customer } from "models/customer";
import { TimespanFilter } from "views/activities/components/filters/service-filters";
import ServicesPerMonthColumnV2 from "./ServicesPerMonthColumnV2";
import ServicesPerMonthCollapsedColumnV2 from "./ServicesPerMonthCollapsedColumnV2";
import {
  getServicesInTimelineWindow,
  isServiceInFutureMonth,
  isServiceInTimelineWindow,
} from "./helpers";
import { useServicesFilter } from "./AssignmentManagementContext";

type Props = {
  filteredMonths: Date[];
  customer: Customer;
  isExpanded: boolean;
  showCompleted?: boolean;
  showCollapsedView?: boolean;
  isCollapsedRow: boolean;
};

export default function ServiceTableRowMonthServiceBox({
  customer,
  isExpanded,
  showCompleted = false,
  showCollapsedView = false,
  isCollapsedRow,
}: Props) {
  const activities = useSelector((state: RootState) => state.activities);
  const {
    timelineWindow2,
    unfinishedActivities: {
      data: unfinishedActivities,
      isLoading: isUnfinishedActivitiesLoading,
    },
  } = activities;

  const { servicesFilter } = useServicesFilter();

  const unfinishedActivityServices = servicesFilter(
    customer.customer_number,
    unfinishedActivities
  );

  const customerServices = servicesFilter(customer.customer_number);

  const timelineFilter = new TimespanFilter(timelineWindow2);

  const servicesInTimeWindow = customerServices.filter((s) =>
    s.activities.every((a) => timelineFilter.check(a, s))
  );

  // Filter out unfinished services that overlap with the timeline window
  // or are in the future (from the timeline window)
  const filteredUnfinishedActivityServices = unfinishedActivityServices.filter(
    (service) =>
      !isServiceInTimelineWindow(timelineWindow2, service) &&
      !isServiceInFutureMonth(timelineWindow2, service)
  );

  const filterServicesByCompletion = (
    services: ServiceInstance[],
    isCompleted: boolean
  ) => {
    return services.filter((service) => service.isCompleted() === isCompleted);
  };

  const allCompletedServices = useMemo(() => {
    return timelineWindow2
      .map((yearMonth) => {
        const completedWindowServices = filterServicesByCompletion(
          getServicesInTimelineWindow(yearMonth, servicesInTimeWindow),
          true
        );
        return completedWindowServices;
      })
      .flat();
  }, [timelineWindow2, servicesInTimeWindow]);

  const completedServicesClass = () => {
    if (!isExpanded) {
      return "no-completed-services-list-height2";
    }

    switch (allCompletedServices.length) {
      case 0:
        return "no-completed-services-list-height2";
      case 1:
        return "one-completed-service-list-height2";

      default:
        return "min-completed-services-list-height2";
    }
  };

  const renderUnfinishedActivitiesIndicator = (index: number) => {
    if (index > 0) {
      return;
    }

    if (isUnfinishedActivitiesLoading) {
      return (
        <Skeleton className="unfinished-activities-loader p-0">
          <SkeletonItem className="h-100 unfinished-activities-skeleton" />
        </Skeleton>
      );
    }

    if (filteredUnfinishedActivityServices.length > 0) {
      return <div className="unfinished-activities-indicator" />;
    }
  };

  return (
    <>
      {timelineWindow2?.map((yearMonth, index) => {
        const windowServices = getServicesInTimelineWindow(
          yearMonth,
          servicesInTimeWindow
        );
        const completedWindowServices = filterServicesByCompletion(
          windowServices,
          true
        );
        const incompleteWindowServices = filterServicesByCompletion(
          windowServices,
          false
        );
        const allWindowServicesEmpty = !windowServices.length;

        const overdueWindowServices = windowServices.filter((service) =>
          service.isOverdue()
        );

        const unfinishedActivityServicesValue =
          index === 0 ? filteredUnfinishedActivityServices : [];
        return (
          <Col
            md={4}
            key={`${customer.customer_number}-customerRowServices-${yearMonth}`}
            className="p-0"
          >
            <div className="ml-sm d-flex flex-direction-column h-100">
              {(() => {
                if (showCollapsedView && isCollapsedRow) {
                  return (
                    <Row className="wrapper w-100 flex-1">
                      {renderUnfinishedActivitiesIndicator(index)}
                      <ServicesPerMonthCollapsedColumnV2
                        yearMonth={yearMonth}
                        completedServices={0}
                        allServices={
                          windowServices.length - completedWindowServices.length
                        }
                        allEmpty={
                          !(
                            windowServices.length -
                            completedWindowServices.length
                          )
                        }
                        hasOverdueServices={overdueWindowServices.length > 0}
                        showCompleted={showCompleted}
                      />
                    </Row>
                  );
                }

                if (allWindowServicesEmpty) {
                  return (
                    <Row
                      className={`wrapper w-100 flex-1 ${
                        showCompleted
                          ? "min-services-list-height2"
                          : "min-services-list-height-alt2"
                      } ${isExpanded ? "" : "shrunk"}`}
                    >
                      {renderUnfinishedActivitiesIndicator(index)}
                      <ServicesPerMonthColumnV2
                        services={[]}
                        customer={customer}
                        yearMonth={yearMonth}
                        isCompleted
                        allEmpty
                        isExpanded={isExpanded}
                        unfinishedActivityServices={
                          unfinishedActivityServicesValue
                        }
                        showCompleted={showCompleted}
                      />
                    </Row>
                  );
                }
                return (
                  <>
                    {showCompleted && (
                      <Row
                        className={`wrapper w-100 ${completedServicesClass()} lh-lg ${
                          isExpanded &&
                          !(allWindowServicesEmpty && showCompleted)
                            ? ""
                            : "shrunk"
                        }`}
                      >
                        <ServicesPerMonthColumnV2
                          services={completedWindowServices}
                          customer={customer}
                          yearMonth={yearMonth}
                          isCompleted
                          isExpanded={isExpanded}
                          unfinishedActivityServices={
                            unfinishedActivityServicesValue
                          }
                          showCompleted={showCompleted}
                        />
                      </Row>
                    )}
                    <Row
                      className={`wrapper w-100 flex-1 ${
                        showCompleted
                          ? "min-incomplete-services-list-height2"
                          : "min-incomplete-services-list-height-alt2"
                      } ${
                        !isExpanded ||
                        (showCompleted && !!allCompletedServices.length)
                          ? "shrunk"
                          : ""
                      }`}
                    >
                      {renderUnfinishedActivitiesIndicator(index)}
                      <ServicesPerMonthColumnV2
                        services={incompleteWindowServices}
                        customer={customer}
                        yearMonth={yearMonth}
                        isCompleted={false}
                        showDivider={showCompleted}
                        allEmpty={allWindowServicesEmpty}
                        isExpanded={isExpanded}
                        showCompleted={showCompleted}
                        unfinishedActivityServices={
                          unfinishedActivityServicesValue
                        }
                      />
                    </Row>
                  </>
                );
              })()}
            </div>
          </Col>
        );
      })}
    </>
  );
}
