/* eslint-disable camelcase */
import { useState } from "react";
import { MessageBar, Radio, RadioGroup } from "@fluentui/react-components";
import { addHours, addYears, startOfMonth, startOfYear } from "date-fns";

import Modal from "components/modal";
import { ModalHeader } from "components/modal/ModalHeader";
import { ModalFooter } from "components/modal/ModalFooter";
import { Button } from "components/button";
import Checkbox from "components/checkbox";
import { DatePickerV9 } from "components/date/DatePickerV9";
import { useTranslation } from "hooks/use-translate";
import { TranslationKey } from "i18n";
import { dateLanguageFormatter } from "libs/date/date-format";
import { CustomerPackage } from "models/deliveryPlan";
import { YEAR_END_DELIVERY } from "state/customers/actions";
import { useAppSelector } from "state/use-app-redux";
import { subtractMonthsFromTodaysDate } from "libs/date/date-subtract-months";
import { OverlaySpinner } from "components/spinner";
import { DeadlineCalendar } from "views/activities/serviceActivities/components/DeadlineCalendar";
import { DeliveryChangeProps } from ".";

const ONE_YEAR_AHEAD = addYears(new Date(), 1);

export type YearEndGeneralAccounting =
  | "INTERNAL_ACCOUNTING"
  | "EXTERNAL_ACCOUNTING"
  | "VAT_HANDLED_BY_LUDVIG"
  | "NONE";

export const YearEndDeliveryPackageOptions = [
  "YE_AB_K2",
  "YE_AB_K3",
  "YE_EF_SIMPLIFIED_YEAR_END",
  "YE_EF",
  "YE_HB_KB_K3_LEGAL_ENTITY",
  "YE_HB_KB_K3_PERSON",
  "YE_HB_KB_K2_LEGAL_ENTITY",
  "YE_HB_KB_K2_PERSON",
  "YE_FOUNDATION",
  "YE_ECONOMIC_ASSOCIATION",
  "YE_NONPROFIT_ORGANIZATION_SIMPLIFIED_YEAR_END",
  "YE_NONPROFIT_ORGANIZATION",
] as const;

export type YearEndDeliveryPackage =
  typeof YearEndDeliveryPackageOptions[number];

export type YearEndOptions = {
  startRecurrenceDate: Date;
  deliveryDate: Date;
  deliveryPackage: YearEndDeliveryPackage;
  auditor: boolean;
  accounting: YearEndGeneralAccounting;
  externalAccountingVatHandledByLudvig?: boolean;
};

function getCustomerPackagesFromOptions(
  options: YearEndOptions
): CustomerPackage[] {
  const packages: CustomerPackage[] = [];

  if (options.deliveryPackage) {
    packages.push({
      package_key_str: options.deliveryPackage,
    });
  }

  if (options.accounting && options.accounting === "INTERNAL_ACCOUNTING") {
    packages.push({
      package_key_str: "INTERNAL_ACC",
    });
  }

  if (options.externalAccountingVatHandledByLudvig) {
    packages.push({
      package_key_str: "EXTERNAL_ACC",
    });
  }

  if (options.auditor) {
    packages.push({
      package_key_str: "AUDITOR",
    });
  }

  return packages;
}

export function YearEndPackageConfiguration({
  customer,
  onChange,
  onRemoveAll,
  existingDeliveries,
  preSelectedStartDate,
  loadingDeliveries,
}: DeliveryChangeProps) {
  const { t: translate, language } = useTranslation();
  const formatter = dateLanguageFormatter(language, "yyyy-MM-dd");
  const [options, setOptions] = useState<YearEndOptions>({
    deliveryPackage: "" as YearEndDeliveryPackage,
    startRecurrenceDate: addHours(
      preSelectedStartDate || startOfYear(new Date()),
      12
    ),
    deliveryDate: addHours(preSelectedStartDate || startOfYear(new Date()), 12),
    auditor: false,
    accounting: "" as YearEndGeneralAccounting,
    externalAccountingVatHandledByLudvig: false,
  });

  const [showConfirmRemove, setShowConfirmRemove] = useState(false);
  const [endDate, setEndDate] = useState(new Date());
  const customerState = useAppSelector((state) => state.customers.data).find(
    (c) => c.customer.customer_number === customer.customer_number
  );

  const availableStartPeriods = [
    new Date().getFullYear() - 1,
    new Date().getFullYear(),
    new Date().getFullYear() + 1,
  ];

  const isLoading = customerState && customerState.isLoading;

  const earliest_onboarding_date = customer.deliveryPlan.find(
    (cd) => cd.delivery_name === YEAR_END_DELIVERY && cd.ends_at != null
  )?.ends_at;

  return (
    <>
      {loadingDeliveries && (
        <OverlaySpinner label={translate("REMOVING_DELIVERIES")} />
      )}
      <div className="d-flex mt-2 mb-4">
        <div className="me-4">
          <h4>{translate("DELIVERY_PACKAGE")}</h4>
          <RadioGroup
            onChange={(_, option) =>
              setOptions({
                ...options,
                deliveryPackage: option?.value as YearEndDeliveryPackage,
              })
            }
            value={options.deliveryPackage}
          >
            {YearEndDeliveryPackageOptions.map((option) => (
              <Radio
                value={option}
                label={translate(option as TranslationKey)}
              />
            ))}
          </RadioGroup>
        </div>

        <div className="d-flex flex-column fg-4">
          <div>
            <h4>{translate("ACCOUNTING")}</h4>
            <RadioGroup
              onChange={(_, option) =>
                setOptions({
                  ...options,
                  accounting: option?.value as YearEndGeneralAccounting,
                })
              }
              value={options.accounting}
            >
              {["INTERNAL_ACCOUNTING", "EXTERNAL_ACCOUNTING"].map((option) => (
                <Radio
                  value={option}
                  label={translate(option as TranslationKey)}
                />
              ))}
            </RadioGroup>
          </div>
          <Checkbox
            label={translate("VAT_HANDLED_BY_LUDVIG")}
            onChange={(_, { checked }) =>
              setOptions({
                ...options,
                externalAccountingVatHandledByLudvig: !!checked,
              })
            }
            disabled={options.accounting !== "EXTERNAL_ACCOUNTING"}
          />
          <Checkbox
            label={translate("AUDITOR")}
            checked={options.auditor}
            onChange={() =>
              setOptions({
                ...options,
                auditor: !options.auditor,
              })
            }
          />

          {!preSelectedStartDate && (
            <div>
              <h4>{translate("START_PERIOD")}</h4>
              <RadioGroup
                onChange={(_, option) =>
                  setOptions({
                    ...options,
                    startRecurrenceDate: new Date(
                      `${option?.value}-01-01 12:00`
                    ),
                  })
                }
                value={options.startRecurrenceDate?.getFullYear().toString()}
              >
                {availableStartPeriods.map((option) => (
                  <Radio value={option.toString()} label={option.toString()} />
                ))}
              </RadioGroup>
            </div>
          )}

          <div>
            <h4>{translate("SET_DELIVERY_DATE")}</h4>
            <DatePickerV9
              placeholder={translate("SET_DELIVERY_DATE")}
              selectedDate={options.deliveryDate}
              onSelectDate={(date) =>
                setOptions({
                  ...options,
                  ...(date && {
                    deliveryDate: addHours(startOfMonth(date), 12),
                  }),
                })
              }
              dateFormatter={(date) => formatter(date, "MMMM yyyy")}
              calendarProps={{
                isDayPickerVisible: false,
                showGoToToday: false,
                highlightSelectedMonth: true,
              }}
              borderless
            />
          </div>
        </div>
      </div>

      {earliest_onboarding_date &&
        earliest_onboarding_date > options.startRecurrenceDate && (
          <MessageBar intent="warning" className="mb-2">
            Leveransen för bokslut har ett start datum som ligger innan{" "}
            {new Date(earliest_onboarding_date).toString()}. Ändra vänligen
            start datum på nya leveransen.
          </MessageBar>
        )}

      <Button
        disabled={
          !options.deliveryPackage ||
          !options.accounting ||
          isLoading ||
          (earliest_onboarding_date &&
            earliest_onboarding_date > options.startRecurrenceDate)
        }
        onClick={() => {
          const packages = getCustomerPackagesFromOptions(options);
          onChange(
            packages,
            "YEARLY",
            options.deliveryDate,
            options.startRecurrenceDate
          );
        }}
      >
        {existingDeliveries.length
          ? translate("CHANGE")
          : translate("COMPLETE")}
      </Button>

      {existingDeliveries.length > 0 && onRemoveAll && (
        <Button
          className="ms-2"
          variant="danger"
          onClick={() => {
            setShowConfirmRemove(true);
          }}
        >
          {translate("REMOVE")}
        </Button>
      )}
      <Modal
        isOpen={showConfirmRemove}
        header={<ModalHeader headerTitleText="REMOVE_PACKAGE" />}
        onDismiss={() => setShowConfirmRemove(false)}
        footer={
          <ModalFooter
            labelSubmit="CONFIRM"
            onCancel={() => setShowConfirmRemove(false)}
            onSave={() => {
              setShowConfirmRemove(false);
              if (onRemoveAll) {
                onRemoveAll(endDate);
              }
            }}
            saveButtonVariant="danger"
          />
        }
      >
        <label>{translate("SELECT_END_DATE")}</label>
        <DeadlineCalendar
          minDate={subtractMonthsFromTodaysDate(3)}
          language={language}
          translate={translate}
          maxDate={ONE_YEAR_AHEAD}
          value={endDate}
          onSelectedDate={(date) => setEndDate(date)}
        />

        <p>{translate("CONFIRM_DELETE_PACKAGE_DESCRIPTION")}</p>
      </Modal>
    </>
  );
}
