import { Col, Row } from "react-bootstrap";
import { Skeleton, SkeletonItem } from "@fluentui/react-components";
import type { SkeletonProps } from "@fluentui/react-components";

import { Customer } from "models/customer";
import CustomerDetailsV2 from "./CustomerDetailsV2";

type Props = {
  openPanel: (customer: Customer) => void;
  customer?: Customer;
  isFull?: boolean;
  isExpanded?: boolean;
  isCollapsedView?: boolean;
  isFilteredByMonth?: boolean;
} & Partial<SkeletonProps>;

export default function SkeletonServiceRow({
  openPanel,
  customer,
  isFull = false,
  isExpanded = false,
  isCollapsedView = false,
  isFilteredByMonth = false,
  ...props
}: Props) {
  return (
    <Skeleton {...props}>
      <Row
        xs={12}
        className={`w-100 py-xs wrapper ${
          !isCollapsedView ? "min-height-80" : "min-height-45"
        }`}
      >
        <div className="d-flex p-0 w-customer-details">
          {!customer || isFull ? (
            <div className="h-100 w-100">
              <SkeletonItem className="h-100" />
            </div>
          ) : (
            <div className="box-background details h-100 w-100">
              <CustomerDetailsV2
                customer={customer}
                isExpanded={isExpanded}
                toggleInfo={() => openPanel(customer)}
                isCollapsedView={isCollapsedView}
              />
            </div>
          )}
        </div>
        <div className="d-flex p-0 w-timeline">
          <Col xs={4} className="p-0">
            <div className="ml-sm h-100">
              <SkeletonItem className="h-100" />
            </div>
          </Col>
          {!isFilteredByMonth && (
            <>
              <Col xs={4} className="p-0">
                <div className="ml-sm h-100">
                  <SkeletonItem className="h-100" />
                </div>
              </Col>
              <Col xs={4} className="p-0">
                <div className="ml-sm h-100">
                  <SkeletonItem className="h-100" />
                </div>
              </Col>
            </>
          )}
        </div>
      </Row>
    </Skeleton>
  );
}
