import { Spinner } from "@fluentui/react-components";
import { Info20Regular } from "@fluentui/react-icons";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import Modal from "components/modal";
import { ModalHeader } from "components/modal/ModalHeader";
import { Persona } from "components/people";
import { SpinnerSize } from "components/spinner";
import TooltipV9 from "components/tooltip/TooltipV9";
import { useTranslation } from "hooks/use-translate";
import { TranslationKey } from "i18n";
import { isUserRestricted } from "libs/customer-settings-helpers";
import { ActivityInstance } from "models/activities/activity";
import { Customer, ProjectType } from "models/customer";
import { User } from "models/user";
import { RootState } from "state";
import { useAppDispatch } from "state/use-app-redux";
import { fetchUserByIdOrEmail } from "state/users/userThunks";
import CustomerDetailsAssignedUserForPackage, {
  Assignee,
} from "./CustomerDetailsAssignedUserForPackage";

type ProjectManager = {
  pmEmail: string;
  projectType: any;
};

type PMData = {
  email: string;
  name: string;
  projectType: string[];
};

type TeamModalProps = {
  customer: Customer;
  isOpen: boolean;
  onDismiss: (open: boolean) => void;
  assignees: {
    assigned_user: ActivityInstance;
    cb_service_type: string;
    isDefault: boolean;
  }[];
  projectManagers: ProjectManager[];
  customerAssignedServices: string[];
  onPackagePersonPickerChange: (packageName: string, userEmail: string) => any;
};

export default function TeamModal({
  customer,
  isOpen = true,
  onDismiss,
  assignees,
  projectManagers,
  customerAssignedServices,
  onPackagePersonPickerChange,
}: TeamModalProps) {
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const { currentUser } = useSelector((state: RootState) => state.users);
  const [pmData, setPMData] = useState<(PMData | undefined)[]>();
  const [LoadingNewAssigneeForPackage, setLoadingNewAssigneeForPackage] =
    useState<string>("");
  const [assigneeData, setAssigneeData] = useState<Assignee[]>();

  const userPM = useCallback(
    async (userEmail: string, projectType: string[]) => {
      const projectManager: User | undefined = await dispatch(
        fetchUserByIdOrEmail(userEmail)
      ).unwrap();

      if (!projectManager) {
        return;
      }

      return {
        email: projectManager.email,
        name: `${projectManager.firstName} ${projectManager.lastName}`,
        projectType,
      };
    },
    [dispatch]
  );

  const userAssignee = useCallback(
    async (userId: string) => {
      const assignee: User | undefined = await dispatch(
        fetchUserByIdOrEmail(userId)
      ).unwrap();

      if (!assignee) {
        return;
      }

      return {
        email: assignee.email,
        name: `${assignee.firstName} ${assignee.lastName}`,
      };
    },
    [dispatch]
  );

  const resolveAllAssignees = useCallback(
    async (allAssignees: any) => {
      const data = [];

      for (const assignee of allAssignees) {
        const res = await userAssignee(assignee.assigned_user.assigned_user);

        data.push({
          ...res,
          service_type: assignee.cb_service_type,
          isDefault: assignee.isDefault,
        });
      }

      if (!data) {
        return [];
      }

      return data;
    },
    [userAssignee]
  );

  const resolveAllPMs = useCallback(
    async (projectManagers) => {
      const data = await Promise.all(
        projectManagers.map(async (pm: ProjectManager) => {
          try {
            return await userPM(pm.pmEmail, pm.projectType);
          } catch (error) {
            console.error("Error resolving project manager:", error);
            return undefined;
          }
        })
      );

      const filteredData = data.filter((pm) => pm !== undefined);

      return filteredData.length > 0 ? filteredData : [];
    },
    [userPM]
  );

  useEffect(() => {
    (async () => {
      const projectManager = await resolveAllPMs(projectManagers);
      setPMData(projectManager);
      const assignee = await resolveAllAssignees(assignees);

      setAssigneeData(assignee);
    })();
  }, [assignees, projectManagers, resolveAllAssignees, resolveAllPMs]);

  function filterAssigneesByServiceType(
    serviceType: string,
    allAssignees: {
      email?: string | undefined;
      name?: string | undefined;
      service_type: string;
      isDefault: boolean;
    }[]
  ) {
    return allAssignees.filter(
      (assignee) =>
        assignee.service_type.toLowerCase() === serviceType.toLowerCase()
    );
  }

  function removeDuplicateAssigneesByEmail(
    allAssignees: {
      email?: string | undefined;
      name?: string | undefined;
      service_type: string;
    }[]
  ) {
    return allAssignees.filter(
      (v, i, a) =>
        a.findIndex(
          (v2) => JSON.stringify(v2.email) === JSON.stringify(v.email)
        ) === i
    );
  }

  const assigneesByServiceType = Array.from(customerAssignedServices).map(
    (serviceType) => {
      const assignee = filterAssigneesByServiceType(
        serviceType,
        assigneeData || []
      );

      const assigneesFiltered = removeDuplicateAssigneesByEmail(assignee);
      return {
        serviceType,
        assignees: assigneesFiltered,
      };
    }
  );

  useEffect(() => {
    setLoadingNewAssigneeForPackage("");
  }, [assigneeData]);

  const tableDesign = useCallback(
    (subjectHeader: string, packageName: string, content: any) => {
      const onPersonChange = async (
        currPackage: string,
        personEmail: string
      ) => {
        try {
          setLoadingNewAssigneeForPackage(packageName);

          const currenAssignee = await onPackagePersonPickerChange(
            currPackage,
            personEmail
          );

          if (!currenAssignee) {
            return;
          }
        } catch (e) {
          setLoadingNewAssigneeForPackage("");
        }
      };

      return (
        <>
          {subjectHeader && (
            <>
              <Row className="p-sm align-items-center background-gray-400">
                <Col className="d-flex justify-content-between align-items-center">
                  <span>
                    <b>{subjectHeader}</b>
                  </span>
                  {content.length === 0 &&
                    !isUserRestricted(customer, currentUser) && (
                      <span className="w-35 ">
                        <CustomerDetailsAssignedUserForPackage
                          packageName={packageName}
                          defaultAssignee={undefined}
                          onPackagePersonPickerChange={onPersonChange}
                        />
                      </span>
                    )}
                </Col>
              </Row>
              <Row className="horizontal-divider" />
            </>
          )}

          {packageName ? (
            <>
              <Row className="p-sm align-items-center background-gray-300">
                <Col className="d-flex justify-content-between align-items-center">
                  <span>
                    <b>{translate(packageName as TranslationKey)}</b>
                  </span>
                  {!isUserRestricted(customer, currentUser) && (
                    <span className="w-35 ">
                      <CustomerDetailsAssignedUserForPackage
                        packageName={packageName}
                        defaultAssignee={content.find(
                          (assignee: Assignee) => assignee.isDefault
                        )}
                        onPackagePersonPickerChange={onPersonChange}
                        isLoading={
                          LoadingNewAssigneeForPackage !== "" &&
                          LoadingNewAssigneeForPackage === packageName
                        }
                      />
                    </span>
                  )}
                </Col>
              </Row>

              {content.length > 0 && (
                <Row className="d-flex mt-sm fw-600 align-items-center">
                  <Col md={4}>
                    <span>{translate("NAME")}</span>
                  </Col>
                  <Col md={5}>
                    <span>{translate("EMAIL_SHORT")}</span>
                  </Col>
                  <Row className=" horizontal-divider" />
                </Row>
              )}
            </>
          ) : (
            <Row className="full-width my-sm fw-600">
              <Col md={4}>
                <span>{translate("NAME")}</span>
              </Col>
              <Col md={5}>
                <span>{translate("EMAIL_SHORT")}</span>
              </Col>
              <Col md={3}>
                {subjectHeader === translate("PROJECT_MANAGERS") && (
                  <span>{translate("PROJECT_TYPES")}</span>
                )}
              </Col>
              <Row className="horizontal-divider" />
            </Row>
          )}

          {LoadingNewAssigneeForPackage !== "" &&
          LoadingNewAssigneeForPackage === packageName ? (
            <Col md={12}>
              <Spinner className="py-md" size={SpinnerSize.ExtraSmall} />
            </Col>
          ) : (
            <Col className="align-items-center my-sm">
              {content.length > 0 &&
                subjectHeader === translate("PROJECT_MANAGERS") &&
                content.map(
                  (projManager: {
                    email: string;
                    name: string;
                    projectType: ProjectType;
                  }) => {
                    if (!projManager) {
                      return [];
                    }
                    return (
                      <Row
                        key={`project-manager-${projManager.email}`}
                        className="d-flex align-items-center py-sm"
                      >
                        <Col md={4} className="d-flex align-items-center">
                          <Persona userId={projManager.email} />
                          <span className="pl-sm fw-bold">
                            {projManager.name}
                          </span>
                        </Col>
                        <Col md={5}>
                          <span>{projManager.email}</span>
                        </Col>
                        <Col md={3}>
                          <span>{projManager.projectType}</span>
                        </Col>
                        <Row className="horizontal-divider mt-sm" />
                      </Row>
                    );
                  }
                )}
              {content.length > 0 ? (
                subjectHeader !== translate("PROJECT_MANAGERS") &&
                content.map(
                  (assignee: {
                    email: string;
                    name: string;
                    isDefault: boolean;
                  }) => {
                    if (!assignee) {
                      return [];
                    }

                    return (
                      <Row
                        key={`assignee-${assignee.email}`}
                        className="d-flex align-items-center py-sm"
                      >
                        <Col md={4} className="d-flex align-items-center">
                          <Persona userId={assignee.email} />
                          <span className="pl-sm fw-bold">{assignee.name}</span>
                        </Col>
                        <Col md={4} className="d-flex align-items-center">
                          <span>{assignee.email}</span>
                        </Col>
                        <Col
                          md={4}
                          className="d-flex align-items-center font-size-px-12"
                        />
                        <Row className="horizontal-divider mt-sm" />
                      </Row>
                    );
                  }
                )
              ) : (
                <Row>
                  <Col md={8}>
                    <span className="text-style">
                      {translate("NO_ASSIGNEES")}
                    </span>
                  </Col>
                </Row>
              )}
            </Col>
          )}
        </>
      );
    },
    [
      currentUser,
      customer,
      LoadingNewAssigneeForPackage,
      onPackagePersonPickerChange,
      translate,
    ]
  );

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={() => onDismiss(false)}
      size="medium"
      header={
        <ModalHeader
          headerTitleContent={
            <div className="d-flex align-items-center header-title">
              <h3 className="pl-sm stringLength">{translate("TEAM")}</h3>
              <TooltipV9
                content="CUSTOMER_USERS_MISSING"
                wrapperClassName="ml-md"
              >
                <Info20Regular className="v-align-super" />
              </TooltipV9>
            </div>
          }
        />
      }
    >
      <Row className="scrollable-content m-sm">
        <Col>
          {(() => {
            if (!pmData) {
              return (
                <Spinner
                  className="py-md"
                  label={translate("LOADING_PROJECT_MANAGERS")}
                  size={SpinnerSize.ExtraSmall}
                />
              );
            }

            if (pmData.length === 0) {
              return (
                <>
                  <span className="text-style">
                    {translate("NO_USER_FOUND")}
                  </span>
                  <br />
                </>
              );
            }

            return tableDesign(translate("PROJECT_MANAGERS"), "", pmData);
          })()}

          {!assigneeData && (
            <Spinner
              className="py-md"
              label={translate("LOADING_ASSIGNEES")}
              size={SpinnerSize.ExtraSmall}
            />
          )}
          {assigneeData &&
            (assigneesByServiceType.length > 0 ? (
              <>
                <Row className="p-sm align-items-center background-gray-400">
                  <Col>
                    <span>
                      <b>{translate("ASSIGNEES")}</b>
                    </span>
                  </Col>
                </Row>
                <Row className="horizontal-divider" />
                {assigneesByServiceType.map(({ serviceType, assignees }) =>
                  tableDesign("", serviceType, assignees)
                )}
              </>
            ) : (
              assigneeData.length === 0 && (
                <span className="text-style">{translate("NO_ASSIGNEES")}</span>
              )
            ))}
        </Col>
      </Row>
    </Modal>
  );
}
