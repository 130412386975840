import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startOfMonth } from "date-fns";
import { ArrowSort20Regular, Filter20Regular } from "@fluentui/react-icons";

import { useStickyState, useStickyStateForDate, useTranslation } from "hooks";
import {
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
  LayoutSubHeader,
  LayoutWrapper,
} from "components/layout/Layout";
import "./AssignmentManagementV2.scss";
import {
  ActivityDefinition,
  ActivityStatus,
  Recurrence,
} from "models/activities/activity";
import {
  fetchServiceTypes,
  setTimelineWindow2,
  setTimelineWindowFromLocalStorage2,
  setActivitiesTimelineWindow2,
  getActivitiesTeam,
  fetchUnfinishedActivities,
} from "state/activities/actions";
import { AppDispatch } from "state/use-app-redux";
import { RootState } from "state";
import { renderIcon } from "libs/render-icon";
import ContextualMenuV9 from "components/contextualMenu/ContextualMenuV9";
import { DropdownV9 } from "components/dropdown/DropdownV9";
import { fetchCustomersV2 } from "state/customers/actions";
import { UserSnapshot } from "models/user";
import { Customer } from "models/customer";
import { useCustomerServiceFilter } from "views/activities/components/filters/service-filters";
import { useCustomerDocumentsCompleted } from "views/activities/components/sorting/use-completed-documents";
import Checkbox from "components/checkbox";
import { SearchInput } from "components/input/search";
import { FilterSortBoxV2, FilterSortListV2 } from "./FilterSortBoxV2";
import ServicesTable from "./ServicesTable";
import * as ServiceHelpers from "./helpers";
import { CustomerDetailsPanel } from "../CustomerDetailsPanel";
import { AssignmentManagementContextProvider } from "./AssignmentManagementContext";

interface TabViewOptions {
  value: string;
  text: string;
}

export default function AssignmentManagementV2() {
  const { t: translate, language } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const { data: customers, isLoading: isCustomersLoading } = useSelector(
    (state: RootState) => state.customers
  );
  const {
    data: activitiesData,
    isLoading: isActivitiesLoading,
    hasLoaded: hasLoadedActivities,
    timelineWindow2,
    activitiesTeams: {
      data: activitiesTeams,
      isLoading: isActivitiesTeamsLoading,
    },
    unfinishedActivities: {
      data: unfinishedActivities,
      isLoading: isUnfinishedActivitiesLoading,
    },
    definitions: { data: definitions, dataMap: definitionsMap },
  } = useSelector((state: RootState) => state.activities);

  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);
  const [customerSearchTerm, setCustomerSearchTerm] = useState("");
  const [disableSort, setDisableSort] = useState(true);
  const [isSearchDataLoading, setIsSearchDataLoading] = useState(false);
  const [isBackendSearchDataDisplayed, setIsBackendSearchDataDisplayed] =
    useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<
    Customer | undefined
  >(undefined);
  const [showCustomerDetailsPanel, setShowCustomerDetailsPanel] =
    useState(false);

  const [selectedTabView, setSelectedTabView] = useStickyState<string>(
    ServiceHelpers.SELECTED_TAB_VIEW,
    ServiceHelpers.TabViewValue.ALL
  );
  const [selectedActivityFilterFrequencies, setActivityFilterFrequencies] =
    useStickyState<Recurrence[]>(
      ServiceHelpers.FILTER_ACTIVITY_FREQUENCIES,
      []
    );
  const [selectedDate, setSelectedDate] = useStickyStateForDate(
    ServiceHelpers.FILTER_SELECTED_DATE
  );
  const [selectedActivityFilterServiceTypes, setActivityFilterServiceTypes] =
    useStickyState<string[]>(ServiceHelpers.FILTER_ACTIVITY_SERVICE_TYPES, []);
  const [selectedActivityFilterDeadline, setActivityFilterDeadline] =
    useStickyState<string | null>(
      ServiceHelpers.FILTER_ACTIVITY_DEADLINE,
      null
    );
  const [selectedActivityFilterDefinitions, setActivityFilterDefinitions] =
    useStickyState<ActivityDefinition[]>(
      ServiceHelpers.FILTER_ACTIVITY_DEFINITIONS,
      []
    );
  const [selectedActivityFilterStatuses, setActivityFilterStatuses] =
    useStickyState<ActivityStatus[]>(
      ServiceHelpers.FILTER_ACTIVITY_STATUSES,
      []
    );
  const [selectedActivityFilterUserIds, setActivityFilterUserIds] =
    useStickyState<UserSnapshot[]>(
      ServiceHelpers.FILTER_ACTIVITY_USERS_IDS,
      []
    );
  const [selectedNextActivityFilterUsers, setNextActivityFilterUsers] =
    useStickyState<UserSnapshot[]>(
      ServiceHelpers.FILTER_NEXT_ACTIVITY_USERS,
      []
    );
  const [overdueFilterEnabled, setEnabledOverdueFilter] =
    useStickyState<boolean>(ServiceHelpers.FILTER_OVERDUE_ENABLED, false);
  const [handedDocs, setHandedDocs] = useStickyState(
    ServiceHelpers.SORT_HANDED_DOCS,
    false
  );
  const [incompleteFilterEnabled, setIncompleteFilterEnabled] =
    useStickyState<boolean>(ServiceHelpers.FILTER_INCOMPLETE_ENABLED, false);

  const searchInputOnSearch = (newValue?: string) => {
    setCustomerSearchTerm(newValue?.trim() || "");
  };

  const resetTimeWindowComplete = () => {
    if (selectedDate) {
      setSelectedDate(undefined);
      dispatch(setActivitiesTimelineWindow2({}));
    }
  };

  const setTimeWindowAt = (date: Date | null | undefined) => {
    const newDate = date ? startOfMonth(date) : undefined;
    setSelectedDate(newDate);

    if (newDate != null) {
      dispatch(setActivitiesTimelineWindow2({ months: [newDate] }));
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const noActivitiesTeam =
      customers.length > 0 &&
      activitiesTeams.length === 0 &&
      !isActivitiesTeamsLoading;

    if (noActivitiesTeam) {
      dispatch(
        getActivitiesTeam(
          customers.map(
            (customerState) => customerState.customer.customer_number
          )
        )
      );
    }
  }, [customers]);

  useEffect(() => {
    // TODO: reset store if other version of CB was loaded first
    // should be removed after going live with CB2
    if (
      !localStorage.getItem("conveyorBeltVersion") ||
      localStorage.getItem("conveyorBeltVersion") === "1"
    ) {
      localStorage.setItem("conveyorBeltVersion", "2");
      dispatch(setActivitiesTimelineWindow2({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const noUnfinishedActivitiesForCustomers =
      customers.length > 0 &&
      unfinishedActivities.length === 0 &&
      !isUnfinishedActivitiesLoading;

    if (noUnfinishedActivitiesForCustomers) {
      dispatch(
        fetchUnfinishedActivities(
          customers.map(
            (customerState) => customerState.customer.customer_number
          )
        )
      );
    }
  }, [customers]);

  useEffect(() => {
    if (selectedDate != null) {
      dispatch(setTimelineWindow2([selectedDate]));
      dispatch(setActivitiesTimelineWindow2({ months: [selectedDate] }));
    } else if (activitiesData.length === 0 && !hasLoadedActivities) {
      dispatch(setTimelineWindowFromLocalStorage2());
    }
    dispatch(fetchServiceTypes());
  }, [selectedDate, dispatch]);

  // deadline
  const deadlineOptions = ServiceHelpers.generateDeadlineOptions(translate, 5);

  // frequency
  const frequencyOptions = ServiceHelpers.RecurrenceTypes.filter(
    (recurrence) => recurrence !== Recurrence.Unknown
  ).map((recurrence) => ({
    value: recurrence,
    text: translate(recurrence),
  }));

  const serviceTypesOptions = ServiceHelpers.ServiceTypesList.map(
    (service) => ({
      value: service,
      text: translate(service),
    })
  );

  // statuses
  const statusOptions = [
    {
      value: ServiceHelpers.ACTIVITY_STATUS_PASSED_DEADLINE_KEY,
      text: translate("PASSED_DEADLINE"),
    },
    {
      value: ActivityStatus.ManagerSupport,
      text: translate(ActivityStatus.ManagerSupport),
    },
    {
      value: ActivityStatus.SupportSentToClient,
      text: translate(ActivityStatus.SupportSentToClient),
    },
    {
      value: ActivityStatus.SupportNeeded,
      text: translate(ActivityStatus.SupportNeeded),
    },
    {
      value: ActivityStatus.SupportFinished,
      text: translate(ActivityStatus.SupportFinished),
    },
  ];

  // activity definition
  const activityTypeOptions = definitions
    .filter(
      (definition, i, arr) =>
        arr.findIndex((d) => d.activity_name === definition.activity_name) === i
    )
    .map((definition) => ({
      text: definition.getTranslation(language),
      value: definition.title,
      data: definition,
    }))
    .sort((a, b) => (a.text < b.text ? -1 : 1));

  const searchCustomers = useCallback(() => {
    setIsSearchDataLoading(true);
    setIsBackendSearchDataDisplayed(true);

    dispatch(fetchCustomersV2(customerSearchTerm)).then(() => {
      setIsSearchDataLoading(false);
    });
  }, [customerSearchTerm, dispatch]);

  const { servicesFilter } = useCustomerServiceFilter({
    filterOverdue: overdueFilterEnabled,
    filterActive: incompleteFilterEnabled,
    filterStatuses: selectedActivityFilterStatuses,
    filterRecurrence: selectedActivityFilterFrequencies,
    filterDefinitions: selectedActivityFilterDefinitions,
    filterUserIds: selectedActivityFilterUserIds,
    filterServiceTypes: selectedActivityFilterServiceTypes,
    filterByMonth: selectedDate,
    filterByDeadline: selectedActivityFilterDeadline,
    filterNextUserIds: selectedNextActivityFilterUsers,
    definitionsMap,
  });

  useEffect(() => {
    if (isBackendSearchDataDisplayed && customerSearchTerm === "") {
      searchCustomers();
      setIsBackendSearchDataDisplayed(false);
    }
  }, [
    customerSearchTerm,
    isBackendSearchDataDisplayed,
    searchCustomers,
    setIsBackendSearchDataDisplayed,
  ]);

  const filterCustomer = (customer: Customer) => {
    if (!customerSearchTerm) {
      return true;
    }

    return (
      customer.salesperson_email
        .toLowerCase()
        .includes(customerSearchTerm.toLowerCase()) ||
      customer.customer_number.includes(customerSearchTerm) ||
      customer.name.toLowerCase().includes(customerSearchTerm.toLowerCase()) ||
      customer
        .getManagers()
        .some((manager) =>
          manager.email?.toLowerCase().includes(customerSearchTerm)
        )
    );
  };

  useEffect(() => {
    if (
      Boolean(selectedDate) &&
      selectedActivityFilterServiceTypes.length > 0
    ) {
      setDisableSort(false);
    } else {
      setDisableSort(true);
    }
  }, [selectedDate, selectedActivityFilterServiceTypes]);

  const allCustomers = customers.map(
    (customersState) => customersState.customer
  );
  const filteredCustomers = allCustomers
    .filter(filterCustomer)
    .sort((a, b) => (a.name < b.name ? -1 : 1));

  const allFilteredCustomersServices = filteredCustomers.flatMap((fc) => {
    return {
      customer_number: fc.customer_number,
      servicesFilter: servicesFilter(fc.customer_number),
    };
  });

  const sortedCustomerActivities = useCustomerDocumentsCompleted(
    timelineWindow2,
    allFilteredCustomersServices
  );

  const filteredCustomerData = useRef<Customer[]>(filteredCustomers);

  const mergeSortedAndFilteredCustomers = useCallback(() => {
    for (let i = 0; i < sortedCustomerActivities.length; i++) {
      const foundCustomer = filteredCustomers.find(
        (f) => f.customer_number === sortedCustomerActivities[i].customer_id
      );

      const index = filteredCustomers.findIndex(
        (f) => f.customer_number === sortedCustomerActivities[i].customer_id
      );

      filteredCustomers.splice(index, 1);

      if (foundCustomer) {
        filteredCustomers.unshift(foundCustomer);
      }
    }

    return filteredCustomers;
  }, [sortedCustomerActivities, filteredCustomers]);

  if (handedDocs) {
    filteredCustomerData.current = mergeSortedAndFilteredCustomers();
  }

  const openCustomerPanel = (customer: Customer) => {
    if (showCustomerDetailsPanel) {
      if (customer.customer_number === selectedCustomer?.customer_number) {
        setShowCustomerDetailsPanel(false);
        setSelectedCustomer(undefined);
      } else {
        setSelectedCustomer(customer);
      }
    } else {
      setShowCustomerDetailsPanel(true);
      setSelectedCustomer(customer);
    }
  };

  const filtersPropsList = {
    selectedActivityFilterFrequencies,
    setActivityFilterFrequencies,
    selectedDate,
    setSelectedDate,
    selectedActivityFilterServiceTypes,
    setActivityFilterServiceTypes,
    selectedActivityFilterDeadline,
    setActivityFilterDeadline,
    selectedActivityFilterDefinitions,
    setActivityFilterDefinitions,
    selectedActivityFilterStatuses,
    setActivityFilterStatuses,
    selectedActivityFilterUserIds,
    setActivityFilterUserIds,

    selectedNextActivityFilterUsers,
    setNextActivityFilterUsers,

    overdueFilterEnabled,
    setEnabledOverdueFilter,
    resetTimeWindowComplete,
    setTimeWindowAt,

    handedDocs,
    setHandedDocs,
    disableSort,
    setDisableSort,

    deadlineOptions,
    frequencyOptions,
    serviceTypesOptions,
    statusOptions,
    activityTypeOptions,
  };

  const renderOption: JSX.Element = (
    <Checkbox
      className="mr-sm align-items-center"
      checked={handedDocs}
      onChange={() => setHandedDocs(!handedDocs)}
      noMargin={false}
    />
  );

  const getDefaultValueForView = (tabView: string) => {
    switch (tabView) {
      case ServiceHelpers.TabViewValue.ACTIVE:
        return translate("ACTIVE_VIEW");
      case ServiceHelpers.TabViewValue.COLLAPSED:
        return translate("COLLAPSED_VIEW");
      default:
        return translate("STANDARD_VIEW");
    }
  };

  const tabViewOptions: TabViewOptions[] = [
    {
      value: ServiceHelpers.TabViewValue.ALL,
      text: translate("STANDARD_VIEW"),
    },
    {
      value: ServiceHelpers.TabViewValue.ACTIVE,
      text: translate("ACTIVE_VIEW"),
    },
    {
      value: ServiceHelpers.TabViewValue.COLLAPSED,
      text: translate("COLLAPSED_VIEW"),
    },
  ];

  return (
    <AssignmentManagementContextProvider servicesFilter={servicesFilter}>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <div className="d-flex  align-items-center justify-content-between">
            <div className="pr-md">
              <h1>{translate("MY_ASSIGNMENTS")}</h1>
            </div>
            <div className="vertical-divider pr-sm" />
            <label className="pr-sm">{translate("VIEW")}:</label>
            <DropdownV9
              className="fit-content"
              dropdownClassName="dropdown-view"
              appearance="underline"
              selectedOptions={[selectedTabView]}
              defaultValue={getDefaultValueForView(selectedTabView)}
              onOptionSelect={(_, item) => {
                if (item.optionValue) {
                  setIncompleteFilterEnabled(
                    item.optionValue === ServiceHelpers.TabViewValue.ACTIVE ||
                      item.optionValue === ServiceHelpers.TabViewValue.COLLAPSED
                  );
                  setSelectedTabView(item.optionValue);
                }
              }}
              options={tabViewOptions}
            />
          </div>
        </LayoutHeaderLeft>
        <LayoutHeaderRight>
          <div className="d-flex flex-wrap fg-lg align-items-center">
            <div className=" d-flex flex-row justify-content-center align-items-center">
              <FilterSortListV2 {...filtersPropsList} />
            </div>
            <button
              type="button"
              className={`filter-button px-xs d-flex align-items-center ${
                showAdditionalFilters ? "active-state-bg" : ""
              }`}
              onClick={() => setShowAdditionalFilters(!showAdditionalFilters)}
            >
              <Filter20Regular className="mr-xs" />
              <span className="fw-600 px-xs">{translate("FILTER")}</span>
            </button>
            <ContextualMenuV9
              buttonText={translate("SORT")}
              buttonStyle="sort-button ghost-button min-width-fit-content"
              buttonIcon={() =>
                renderIcon(<ArrowSort20Regular className="pb-xxs mr-sm" />)
              }
              menuItems={[
                {
                  disabled: disableSort,
                  content: !disableSort ? renderOption : undefined,
                  persistOnClick: true,
                  text: translate("SORT_BY_DATE"),
                  tooltip: disableSort
                    ? { content: "SORT_UNAVAILABLE" }
                    : undefined,
                },
              ]}
            />
            <div className="vw-15">
              <SearchInput
                placeholder={translate("SEARCH_PLACEHOLDER")}
                onChange={(_, newValue) => searchInputOnSearch(newValue.value)}
                onKeyDown={(ev) => {
                  if (ev.key === "Enter") {
                    ev.preventDefault();
                    searchCustomers();
                  }
                }}
                data-testid="search-input"
                disabled={isSearchDataLoading}
              />
            </div>
          </div>
        </LayoutHeaderRight>
      </LayoutHeader>
      {showAdditionalFilters && (
        <LayoutSubHeader>
          <FilterSortBoxV2 {...filtersPropsList} />
        </LayoutSubHeader>
      )}
      <LayoutWrapper>
        {!isSearchDataLoading &&
        !isCustomersLoading &&
        allCustomers.length === 0 ? (
          <p>{translate("NO_SEARCH_CUSTOMER_RESULTS")}</p>
        ) : (
          <ServicesTable
            customers={
              filteredCustomerData.current.length > 0 && handedDocs
                ? filteredCustomerData.current
                : filteredCustomers
            }
            selectedActivityFilterUsers={selectedActivityFilterUserIds}
            isTimelineLoading={isActivitiesLoading}
            isFullLoading={isCustomersLoading || isSearchDataLoading}
            isCollapsedView={
              selectedTabView === ServiceHelpers.TabViewValue.COLLAPSED
            }
            incompleteFilterEnabled={incompleteFilterEnabled}
            openCustomerPanel={openCustomerPanel}
          />
        )}
      </LayoutWrapper>
      {selectedCustomer && (
        <CustomerDetailsPanel
          showCustomerDetailsPanel={showCustomerDetailsPanel}
          selectedCustomer={selectedCustomer}
          onDismiss={() => setShowCustomerDetailsPanel(false)}
        />
      )}
    </AssignmentManagementContextProvider>
  );
}
