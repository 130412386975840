import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { CircleFilled } from "@fluentui/react-icons";

import BreadcrumbV9, {
  BreadcrumbDefinitionItem,
} from "components/breadcrumbV2";
import { Button } from "components/button";

import {
  LayoutBody,
  LayoutHeader,
  LayoutHeaderLeft,
} from "components/layout/Layout";
import { TextArea } from "components/textarea";
import TooltipV9 from "components/tooltip/TooltipV9";
import { useTranslation } from "hooks/use-translate";
import { TranslationKey } from "i18n";
import { AppRouteHelper, CustomerDetailsParams, ServicesParams } from "routes";
import { RootState } from "state";
import { createLudvigServiceFeedback } from "state/customers/actions";
import { DropdownV9 } from "components/dropdown/DropdownV9";

export function LudvigServiceFeedbackForm() {
  const { t: translate, ts } = useTranslation();
  const dispatch = useDispatch();

  const { customerId } = useParams<CustomerDetailsParams>();
  const { serviceId } = useParams<ServicesParams>();
  const navigate = useNavigate();

  const URLProps = new URLSearchParams(window.location.search);
  const isConveyorBeltV2 = URLProps.get("conveyorBeltV2");

  const customers = useSelector((state: RootState) => state.customers.data);
  const customer = customers.find(
    (e) => e.customer.customer_number === customerId
  )?.customer;

  const [selectedFeedbackOption, setFeedbackOption] = useState("");
  const [selectedFreeTextFeedback, setFreeTextFeedback] = useState("");
  const [selectedColorRating, setColorRating] = useState("");

  if (!customer) {
    return <>Missing customer</>;
  }

  if (!serviceId) {
    return <>Missing service ID</>;
  }

  const breadcrumbs: BreadcrumbDefinitionItem[] = [
    {
      text: translate("MY_ASSIGNMENTS"),
      onClick: () =>
        navigate(
          isConveyorBeltV2
            ? AppRouteHelper.getAssignments("", true)
            : AppRouteHelper.getAssignments()
        ),
    },
    {
      text: (customer && customer.name) || "",
      onClick: () =>
        navigate(
          isConveyorBeltV2
            ? AppRouteHelper.getAssignments("customerId", true)
            : AppRouteHelper.getAssignments(customerId)
        ),
    },
    {
      text: "Ludvig Service Feedback",
    },
    {
      text: translate(serviceId as TranslationKey),
    },
  ];

  return (
    <>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <div className="pr-md">
            <BreadcrumbV9 items={breadcrumbs} />
          </div>
        </LayoutHeaderLeft>
      </LayoutHeader>
      <LayoutBody>
        <div>
          <div className="header-title px-sm d-flex">
            <h3 className="pl-sm stringLength">
              {translate("CUSTOMER_FEEDBACK_PAGE.HEADER", [ts(serviceId)])}
            </h3>
          </div>

          <div className="vw-30 pt-xl px-sm">
            <label>{translate("RATING")}</label>
            <div style={{ fontSize: "1.5em" }}>
              {[
                { color: "green", tooltip: "Om du är nöjd med leveransen" },
                {
                  color: "yellow",
                  tooltip: "Om du tycker att något kan förbättras",
                },
                { color: "red", tooltip: "Om du är missnöjd med leveransen" },
              ].map((option) => {
                const c =
                  selectedColorRating && selectedColorRating !== option.color
                    ? `#eee`
                    : `var(--${option.color}`;

                return (
                  <TooltipV9
                    key={option.color}
                    content={option.tooltip}
                    notTranslatable
                  >
                    <CircleFilled
                      className="cursor-pointer me-2"
                      onClick={() => setColorRating(option.color)}
                      style={{
                        color: c,
                        transition: ".2s color",
                      }}
                    />
                  </TooltipV9>
                );
              })}
            </div>

            <div className="mt-2">
              <label>{translate("SELECT_FEEDBACK")}</label>
              <DropdownV9
                placeholder={translate("FEEDBACK")}
                onOptionSelect={(_, {optionValue}) => {
                  if(optionValue){
                    setFeedbackOption(optionValue)
                  }
                }}
                options={[
                  {
                    text: translate("DELAYED_DELIVERY"),
                    value: "DELAYED_DELIVERY",
                  },
                  {
                    text: translate("QUALITY"),
                    value: "QUALITY",
                  },
                  {
                    text: translate("OTHER"),
                    value: "OTHER",
                  },
                ]}
              />
            </div>

            <div className="mt-2">
              <label>{translate("FEEDBACK_FREE_TEXT")}</label>
              <TextArea onChange={(e) => setFreeTextFeedback(e.target.value)} />
            </div>

            <div className="mt-2">
              <Button
                disabled={
                  !selectedColorRating ||
                  (selectedColorRating !== "green" && !selectedFeedbackOption)
                }
                onClick={() => {
                  dispatch(
                    createLudvigServiceFeedback(customer, {
                      ServiceType: serviceId,
                      FeedbackOption: selectedFeedbackOption,
                      FeedbackFreeText: selectedFreeTextFeedback,
                      Color: selectedColorRating.toUpperCase(),
                    })
                  );
                  navigate("/assignments");
                }}
              >
                {translate("SUBMIT_FEEDBACK")}
              </Button>
            </div>
          </div>
        </div>
      </LayoutBody>
    </>
  );
}
