import { createApi } from "@reduxjs/toolkit/query/react";

import {
  PostFortnoxAdminAddAdminsBody,
  PostFortnoxAdminAddAdminsResponse,
} from "models/fortnoxAdminAddAdmins";
import {
  CompanyInformation,
  CompanyTypeFactsTableItem,
  CompanyTypeListItem,
} from "models/offer/Company";
import { SearchData } from "state/offer/companyThunks";
import {
  CapegoFortnoxConnection,
  CapegoFortnoxConnectionResponse,
} from "models/CapegoFortnoxConnection";
import { normalizeOrgNrAndSSN } from "libs/number-format";
import { CapegoCustomerData } from "models/CapegoCustomerData";
import { CapegoFortnoxStatus } from "models/CapegoFortnoxStatus";
import { getBaseQueryConfig } from "./utils";

const baseQuery = getBaseQueryConfig();

export const ludvigApiService = createApi({
  reducerPath: "ludvigApi",
  baseQuery,
  tagTypes: ["offers"],
  endpoints: (build) => ({
    getCompanyTypes: build.query<CompanyTypeListItem[], void>({
      query: () => "/list/customer-types",
      keepUnusedDataFor: 5000,
      transformResponse: (
        customerTypeListsResponse: CompanyTypeFactsTableItem[]
      ) =>
        customerTypeListsResponse.reduce(
          (transformedItems: CompanyTypeListItem[], item) => {
            const pickedItems = {
              Title: item.Title,
              CapegoID: item.CapegoID,
              NameEN: item.NameEN,
              NameSE: item.NameSE,
              ShortNameSE: item.ShortNameSE,
              WKName: item.WKName,
              RetrieverName: item.RetrieverName,
            };
            return [...transformedItems, pickedItems];
          },
          []
        ),
    }),
    getCompanyInfoBy: build.query<CompanyInformation, SearchData>({
      query: ({ customerId, searchByParam }) => ({
        url: `/companies/${customerId}?search_by_param=${searchByParam}`,
        responseHandler: "json",
      }),
      keepUnusedDataFor: 5000,
    }),
    getOnedriveShortcut: build.query({
      query: () => ({
        url: `/onedriveshortcut`,
        responseHandler: "json",
      }),
    }),
    postFortnoxAdminAddAdmins: build.mutation<
      PostFortnoxAdminAddAdminsResponse,
      PostFortnoxAdminAddAdminsBody
    >({
      query: (body) => ({
        url: "/fortnox/admin/addadmins",
        method: "POST",
        body,
      }),
    }),
    postCapegoAddFortnox: build.mutation<
      CapegoFortnoxConnectionResponse,
      CapegoFortnoxConnection
    >({
      query: (body) => {
        const tempBody = { ...body, orgno: normalizeOrgNrAndSSN(body.orgno) };

        return {
          url: "/capego/add_fortnox",
          method: "POST",
          body: tempBody,
        };
      },
    }),
    deleteCapegoRemoveFortnox: build.mutation<unknown, CapegoFortnoxConnection>(
      {
        query: (body) => ({
          url: `/capego/remove_fortnox/${body.orgno}/${body.capegoId}?username=${body.username}`,
          method: "DELETE",
        }),
      }
    ),
    getCapegoFortnoxStatus: build.query<CapegoFortnoxStatus[], string>({
      query: (orgNumber) => ({
        url: `/capego/fortnox_status/${orgNumber}`,
      }),
      keepUnusedDataFor: 15,
    }),
    getCapegoCustomer: build.query<CapegoCustomerData[], string | string[]>({
      query: (orgNumbers) => {
        let tempOrgNumbers = orgNumbers;
        if (typeof orgNumbers !== "string") {
          tempOrgNumbers = orgNumbers.join(",");
        }

        return {
          url: "/capego/customers",
          params: {
            "org-numbers": tempOrgNumbers,
          },
        };
      },
    }),
  }),
});

export const {
  useGetCompanyTypesQuery,
  useLazyGetCompanyInfoByQuery,
  useLazyGetOnedriveShortcutQuery,
  usePostFortnoxAdminAddAdminsMutation,
  usePostCapegoAddFortnoxMutation,
  useDeleteCapegoRemoveFortnoxMutation,
  useGetCapegoFortnoxStatusQuery,
  useGetCapegoCustomerQuery,
} = ludvigApiService;
