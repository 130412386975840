/* eslint-disable camelcase */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  DrawerBody,
  OverlayDrawer,
  Spinner,
  Tag,
} from "@fluentui/react-components";
import { Checkmark16Regular, Info16Filled } from "@fluentui/react-icons";

import { Button } from "components/button";
import Checkbox from "components/checkbox";
import { DropdownV9 } from "components/dropdown/DropdownV9";
import { Input } from "components/input";
import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { Persona } from "components/people";
import { SpinnerSize } from "components/spinner";
import TooltipV9 from "components/tooltip/TooltipV9";
import StatusIndicatorIcon from "components/icon/StatusIndicatorIcon";
import DrawerHeader from "components/drawer/drawerHeader/DrawerHeader";
import { useTranslation } from "hooks/use-translate";
import { isUserTeamOwner } from "libs/customer-settings-helpers";
import { Recurrence } from "models/activities/activity";
import { Customer, CustomerSettings } from "models/customer";
import { AppRouteHelper } from "routes";
import { RootState } from "state";
import {
  fetchCustomerLudvigServices,
  updateCustomerSettings,
} from "state/customers/actions";
import { useAppSelector } from "state/use-app-redux";

const ASSIGNMENT_PROJECT_ALLOWED_SUFFIXES = ["ei", "aa", "ek", "eay", "eby"];

const CustomerDetailsPanelModalState = {
  ENABLE_ARA_MODAL: "ENABLE_ARA_MODAL",
  DISABLE_ARA_MODAL: "DISABLE_ARA_MODAL",
  ENABLE_YEAREND_START_MODAL: "ENABLE_YEAREND_START_MODAL",
  DISABLE_YEAREND_START_MODAL: "DISABLE_YEAREND_START_MODAL",
  ENABLE_YEAREND_FINISH_MODAL: "ENABLE_YEAREND_FINISH_MODAL",
  DISABLE_YEAREND_FINISH_MODAL: "DISABLE_YEAREND_FINISH_MODAL",
  DELETE_MODAL: "DELETE_MODAL",
};

type DetailsPanelProps = {
  onDismiss: () => void;
  showCustomerDetailsPanel: boolean;
  selectedCustomer: Customer;
  customerDetails?: Customer;
};

export function CustomerDetailsPanel({
  showCustomerDetailsPanel,
  onDismiss,
  selectedCustomer,
}: DetailsPanelProps) {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const URLProps = new URLSearchParams(window.location.search);
  const isConveyorBeltV2 = URLProps.get("conveyorBeltV2");

  const CustomerHasAcceptedTerms = (c: Customer) => c.hasAcceptedTerms();

  const [modalState, setModalState] = useState<string>("");

  const openModal = (modal: string) => {
    setModalState(modal);
  };

  const closeModal = () => {
    setModalState("");
  };

  const { currentUser } = useSelector((state: RootState) => state.users);
  const customerState = useAppSelector((state) => state.customers.data).find(
    (data) => data.customer.customer_number === selectedCustomer.customer_number
  );
  const customerLudvigServicesState = useSelector(
    (state: RootState) => state.customers.customer_ludvig_services
  ).filter((data) => data.customer_number === selectedCustomer.customer_number);

  const currentCustomer = customerState?.customer;

  const customerLudvigServicesYEStart =
    currentCustomer?.customerHasUnfinishedLudvigServiceFeedback(
      customerLudvigServicesState || [],
      "YEAR_END_START"
    );

  const customerLudvigServicesYEFinish =
    currentCustomer?.customerHasUnfinishedLudvigServiceFeedback(
      customerLudvigServicesState || [],
      "YEAR_END_FINISH"
    );

  const customerManagersEmailsRaw =
    currentCustomer &&
    currentCustomer.projects.map((p) => p.projectManagerEmail);

  const customerManagersEmailsUnique =
    customerManagersEmailsRaw &&
    customerManagersEmailsRaw.filter((v, i, a) => a.indexOf(v) === i);

  const handleUpdateCustomerSettings = (
    settings: Partial<CustomerSettings>
  ) => {
    if (currentCustomer) {
      dispatch(updateCustomerSettings(currentCustomer, settings));
    }
  };

  useEffect(() => {
    if (customerLudvigServicesState.length === 0) {
      dispatch(fetchCustomerLudvigServices(selectedCustomer));
    }
  }, [selectedCustomer, customerLudvigServicesState, dispatch]);

  if (!currentCustomer) {
    return null;
  }

  function disableAssignmentYearEndCheckboxes() {
    if (!customerState || !currentCustomer) {
      return;
    }

    return (
      customerState.isLoading ||
      !currentCustomer.settings?.ludvig_service_ek_project ||
      !currentCustomer.year_end_package ||
      currentCustomer.year_end_package.toUpperCase() === Recurrence.Unknown ||
      !isUserTeamOwner(currentCustomer, currentUser)
    );
  }

  return (
    <OverlayDrawer
      open={showCustomerDetailsPanel}
      modalType="non-modal"
      position="end"
      size="medium"
    >
      <DrawerHeader
        header={currentCustomer.name}
        notTranslatable
        onDismiss={onDismiss}
      />
      <DrawerBody>
        <div>
          <h6 className="pt-md">
            {translate("CUSTOMER_DETAILS.CUSTOMER_INFO")}
          </h6>
          <div>
            <Tag className="m-xs">
              <div className="d-flex align-items-center">
                <span className="pr-xs">
                  {translate("ORGANIZATION_NUMBER")}:
                </span>
                <span className="fw-bold">
                  {currentCustomer.getCompanyRegistrationNumber()}
                </span>
              </div>
            </Tag>
            <Tag className="m-xs">
              <div className="d-flex align-items-center">
                <span className="pr-xs">{translate("CUSTOMER_NUMBER")}:</span>
                <span className="fw-bold">
                  <a
                    href={AppRouteHelper.getCustomerMaconomyUrl(
                      currentCustomer.customer_number
                    )}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {currentCustomer.customer_number}
                  </a>
                </span>
              </div>
            </Tag>
            <Tag className="m-xs">
              <div className="d-flex align-items-center">
                <span className="pr-xs">
                  {translate("CUSTOMER_DETAILS.APPROVED_TERMS")}:
                </span>
                {CustomerHasAcceptedTerms(currentCustomer) ? (
                  <div>
                    <span className="fw-bold pr-xs">
                      {translate("CUSTOMER_DETAILS.GENERAL_TERMS.ACCEPTED")}
                    </span>
                    <Checkmark16Regular />
                  </div>
                ) : (
                  <TooltipV9 content="GENERAL_TERMS_NOT_ACCEPTED">
                    <div>
                      <span className="fw-bold pr-xs">
                        {translate(
                          "CUSTOMER_DETAILS.GENERAL_TERMS.NOT_ACCEPTED"
                        )}
                      </span>
                      <StatusIndicatorIcon status="offline" />
                    </div>
                  </TooltipV9>
                )}
              </div>
            </Tag>
          </div>
          <div className="horizontal-divider mt-sm" />
        </div>

        {currentCustomer.apps && (
          <>
            <h6 className="pt-xl">
              {translate("CUSTOMER_DETAILS.SYSTEM_STATUSES")}
            </h6>
            <table className="cell-p-2">
              <tbody>
                <tr className="no-border">
                  <td>
                    <b>Fortnox</b>
                  </td>
                  <td>
                    <StatusIndicatorIcon
                      status={currentCustomer.apps.fortnox.status}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Ludvig Skicka Teams</b>
                  </td>
                  <td>
                    <StatusIndicatorIcon
                      status={currentCustomer.apps.teams?.status ?? "loading"}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>MittKontor</b>
                  </td>
                  <td>
                    <StatusIndicatorIcon
                      status={currentCustomer.apps.mittkontor.status}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Datalagret</b>
                  </td>
                  <td>
                    <StatusIndicatorIcon
                      status={currentCustomer.apps.datalagret.status}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}

        <h6 className="pt-xl">{translate("ECONOMY_ASSIGNMENTS")}</h6>
        <table>
          <tbody>
            <tr className="no-border">
              <td>
                <b>{translate("SELECT_ASSIGNMENT_NUMBER")}</b>
              </td>
              <td>
                <DropdownV9
                  placeholder={translate("ASSIGNMENT")}
                  selectedOptions={[
                    currentCustomer.settings?.ludvig_service_ek_project ?? "",
                  ]}
                  disabled={!isUserTeamOwner(currentCustomer, currentUser)}
                  onOptionSelect={(_, { optionValue }) => {
                    if (optionValue) {
                      handleUpdateCustomerSettings({
                        ludvig_service_ek_project: optionValue,
                      });
                    }
                  }}
                  options={currentCustomer.projects
                    .filter((project) => {
                      return ASSIGNMENT_PROJECT_ALLOWED_SUFFIXES.some(
                        (suffix) =>
                          project.projectNumber.toLowerCase().endsWith(suffix)
                      );
                    })
                    .map((project) => ({
                      value: project.projectNumber,
                      text: project.projectNumber,
                    }))}
                />
              </td>
            </tr>
            <tr>
              <td>
                <b
                  className={`${
                    !currentCustomer.settings?.ludvig_service_ek_project ||
                    !isUserTeamOwner(currentCustomer, currentUser)
                      ? " text-muted"
                      : ""
                  }`}
                >
                  <TooltipV9 content="LUDVIG_SERVICE_ARA_DESCRIPTION">
                    <Info16Filled className="mr-sm" />
                  </TooltipV9>
                  {translate("ARA")}
                </b>
              </td>
              <td>
                <Checkbox
                  disabled={disableAssignmentYearEndCheckboxes()}
                  onChange={(_, { checked }) =>
                    checked
                      ? openModal(
                          CustomerDetailsPanelModalState.ENABLE_ARA_MODAL
                        )
                      : openModal(
                          CustomerDetailsPanelModalState.DISABLE_ARA_MODAL
                        )
                  }
                  checked={
                    currentCustomer.settings?.ludvig_service_ara || false
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                <b
                  className={`${
                    disableAssignmentYearEndCheckboxes() ? " text-muted" : ""
                  }`}
                >
                  <TooltipV9 content="LUDVIG_SERVICE_YEAR_END_START_DESCRIPTION">
                    <Info16Filled className="mr-sm" />
                  </TooltipV9>
                  {translate("YEAR_END_START")}
                </b>
              </td>

              <td>
                <div className="d-flex">
                  <Checkbox
                    disabled={disableAssignmentYearEndCheckboxes()}
                    onChange={(_, checked) =>
                      checked
                        ? openModal(
                            CustomerDetailsPanelModalState.ENABLE_YEAREND_START_MODAL
                          )
                        : openModal(
                            CustomerDetailsPanelModalState.DISABLE_YEAREND_START_MODAL
                          )
                    }
                    checked={
                      currentCustomer.settings?.ludvig_service_year_end_start ||
                      false
                    }
                  />

                  {customerLudvigServicesYEStart &&
                    isUserTeamOwner(currentCustomer, currentUser) && (
                      <Button
                        onClick={() =>
                          navigate({
                            pathname:
                              AppRouteHelper.getCustomerLudvigServiceFeedback(
                                currentCustomer.customer_number,
                                "YEAR_END_START",
                                !!isConveyorBeltV2
                              ),
                          })
                        }
                        className="ms-2"
                        size="sm"
                      >
                        {translate("GIVE_FEEDBACK")}
                      </Button>
                    )}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <b
                  className={`${
                    disableAssignmentYearEndCheckboxes() ? " text-muted" : ""
                  }`}
                >
                  <TooltipV9 content="LUDVIG_SERVICE_YEAR_END_FINISH_DESCRIPTION">
                    <Info16Filled className="mr-sm" />
                  </TooltipV9>
                  {translate("YEAR_END_FINISH")}
                </b>
              </td>

              <td>
                <div className="d-flex">
                  <Checkbox
                    disabled={disableAssignmentYearEndCheckboxes()}
                    onChange={(_, checked) =>
                      checked
                        ? openModal(
                            CustomerDetailsPanelModalState.ENABLE_YEAREND_FINISH_MODAL
                          )
                        : openModal(
                            CustomerDetailsPanelModalState.DISABLE_YEAREND_FINISH_MODAL
                          )
                    }
                    checked={
                      currentCustomer.settings
                        ?.ludvig_service_year_end_finish || false
                    }
                  />
                  {customerLudvigServicesYEFinish &&
                    isUserTeamOwner(currentCustomer, currentUser) && (
                      <Button
                        onClick={() =>
                          navigate({
                            pathname:
                              AppRouteHelper.getCustomerLudvigServiceFeedback(
                                currentCustomer.customer_number,
                                "YEAR_END_FINISH",
                                !!isConveyorBeltV2
                              ),
                          })
                        }
                        className="ms-2"
                        size="sm"
                      >
                        {translate("GIVE_FEEDBACK")}
                      </Button>
                    )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <h6 className="pt-xl">{translate("ACTIVE_ASSIGNMENT")}</h6>
        <table className="padding-sm">
          <tbody>
            <tr className="no-border">
              <td>
                <b>{translate("ASSIGNMENT_MANAGER")}</b>
              </td>
              <td>
                <div className="d-flex flex-wrap fg-2">
                  {customerManagersEmailsUnique &&
                    customerManagersEmailsUnique.map((email) => (
                      <Persona key={email} userId={email} />
                    ))}
                </div>
              </td>
            </tr>
            <tr className="no-border">
              <td>
                <b>{translate("CUSTOMER_DETAILS.SALES_MANAGER")}</b>
              </td>
              <td>
                <div className="d-flex flex-wrap fg-2">
                  {currentCustomer.salesperson_email && (
                    <Persona
                      key={currentCustomer.salesperson_email}
                      userId={currentCustomer.salesperson_email}
                    />
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <b>{translate("FREQUENCY")}</b>
              </td>
              <td>{translate(currentCustomer.getRecurrence() || "UNKNOWN")}</td>
            </tr>
            <tr>
              <td>
                <b>{translate("ASSIGNMENT_VALUE")}</b>
              </td>
              <td>
                {currentCustomer.getAssignmentValue() ? (
                  <Input
                    type="number"
                    placeholder={translate("ASSIGNMENT_VALUE")}
                    disabled
                    defaultValue={currentCustomer.getAssignmentValue()}
                  />
                ) : (
                  translate("UNKNOWN")
                )}
              </td>
            </tr>
          </tbody>
        </table>

        {customerState.isLoading && (
          <Spinner size={SpinnerSize.ExtraSmall} className="mt-4" />
        )}

        <Modal
          onDismiss={() => closeModal()}
          isOpen={
            modalState === CustomerDetailsPanelModalState.DISABLE_ARA_MODAL
          }
          footer={
            <ModalFooter
              labelSubmit="DISABLE_ARA"
              labelCancel="CANCEL"
              onSave={() => {
                handleUpdateCustomerSettings({
                  ludvig_service_ara: false,
                });
                closeModal();
              }}
              onCancel={() => closeModal()}
              saveButtonVariant="danger"
            />
          }
          header={<ModalHeader headerTitleText="DISABLE_ARA" />}
        >
          <p>{translate("CONFIRM_DISABLE_ARA")}</p>
        </Modal>
        <Modal
          onDismiss={() => closeModal()}
          isOpen={
            modalState === CustomerDetailsPanelModalState.ENABLE_ARA_MODAL
          }
          footer={
            <ModalFooter
              labelSubmit="ENABLE_ARA"
              labelCancel="CANCEL"
              onSave={() => {
                handleUpdateCustomerSettings({
                  ludvig_service_ara: true,
                });
                closeModal();
              }}
              onCancel={() => closeModal()}
            />
          }
          header={<ModalHeader headerTitleText="ENABLE_ARA" />}
        >
          <p>{translate("CONFIRM_ENABLE_ARA")}</p>
        </Modal>
        <Modal
          onDismiss={() => closeModal()}
          isOpen={
            modalState ===
            CustomerDetailsPanelModalState.ENABLE_YEAREND_START_MODAL
          }
          footer={
            <ModalFooter
              labelSubmit="ENABLE_YEAR_END_START"
              labelCancel="CANCEL"
              onSave={() => {
                handleUpdateCustomerSettings({
                  ludvig_service_year_end_start: true,
                });
                closeModal();
              }}
              onCancel={() => closeModal()}
            />
          }
          header={<ModalHeader headerTitleText="ENABLE_YEAR_END_START" />}
        >
          <p>{translate("CONFIRM_ENABLE_YEAR_END_START")}</p>
        </Modal>

        <Modal
          onDismiss={() => closeModal()}
          isOpen={
            modalState ===
            CustomerDetailsPanelModalState.DISABLE_YEAREND_START_MODAL
          }
          footer={
            <ModalFooter
              labelSubmit="DISABLE_YEAR_END_START"
              labelCancel="CANCEL"
              onSave={() => {
                handleUpdateCustomerSettings({
                  ludvig_service_year_end_start: false,
                });
                closeModal();
              }}
              onCancel={() => closeModal()}
              saveButtonVariant="danger"
            />
          }
          header={<ModalHeader headerTitleText="DISABLE_YEAR_END_START" />}
        >
          <p>{translate("CONFIRM_DISABLE_YEAR_END_START")}</p>
        </Modal>

        <Modal
          onDismiss={() => closeModal()}
          isOpen={
            modalState ===
            CustomerDetailsPanelModalState.ENABLE_YEAREND_FINISH_MODAL
          }
          footer={
            <ModalFooter
              labelSubmit="ENABLE_YEAR_END_FINISH"
              labelCancel="CANCEL"
              onSave={() => {
                handleUpdateCustomerSettings({
                  ludvig_service_year_end_finish: true,
                });
                closeModal();
              }}
              onCancel={() => closeModal()}
            />
          }
          header={<ModalHeader headerTitleText="ENABLE_YEAR_END_FINISH" />}
        >
          <p>{translate("CONFIRM_ENABLE_YEAR_END_FINISH")}</p>
        </Modal>

        <Modal
          onDismiss={() => closeModal()}
          isOpen={
            modalState ===
            CustomerDetailsPanelModalState.DISABLE_YEAREND_FINISH_MODAL
          }
          footer={
            <ModalFooter
              labelSubmit="DISABLE_YEAR_END_START"
              labelCancel="CANCEL"
              onSave={() => {
                handleUpdateCustomerSettings({
                  ludvig_service_year_end_finish: false,
                });
                closeModal();
              }}
              onCancel={() => closeModal()}
              saveButtonVariant="danger"
            />
          }
          header={<ModalHeader headerTitleText="DISABLE_YEAR_END_FINISH" />}
        >
          <p>{translate("CONFIRM_DISABLE_YEAR_END_FINISH")}</p>
        </Modal>
      </DrawerBody>
    </OverlayDrawer>
  );
}
