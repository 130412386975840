import { useMemo } from "react";
import {
  CustomerActivitiesServicesAssignedUsers,
  ServiceInstance,
  ServiceTypes,
} from "../../../../models/activities/activity";

const useAssignees = (
  serviceTypes: string[],
  customerActivities: ServiceInstance[],
  defaultAssignees: CustomerActivitiesServicesAssignedUsers | undefined
) => {
  const assigneesForCustomers = useMemo(() => {
    const findAllServicesByTitle = serviceTypes.flatMap((service) => {
      return customerActivities?.filter(
        (customerService) => customerService.cb_service_type === service
      );
    });

    const assignedUsers = findAllServicesByTitle.flatMap((service) => {
      return service.activities
        .filter((activity) => activity.assigned_user)
        .map((user) => {
          const defaultClientUser =
            service.cb_service_type === ServiceTypes.YearEnd
              ? defaultAssignees?.year_end_client_user
              : defaultAssignees?.client_user;

          return {
            assigned_user: user,
            cb_service_type: service.cb_service_type,
            isDefault: defaultAssignees
              ? defaultClientUser === user.assigned_user
              : false,
          };
        });
    });

    return assignedUsers;
  }, [serviceTypes, customerActivities, defaultAssignees]);

  return assigneesForCustomers;
};

export { useAssignees };
