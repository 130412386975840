import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Add24Regular,
  AppFolder24Regular,
  Apps24Regular,
  DataHistogram24Regular,
  ChevronLeft24Regular,
  ChevronRight24Regular,
  Home24Regular,
  PeopleSettings24Regular,
  PersonTag24Regular,
  SlideTransition24Regular,
} from "@fluentui/react-icons";

import AppConfig from "app-config";
import MittLudvigMonogram from "assets/MittLudvig-logo-monogram.svg";
import { useAuth } from "auth/use-auth";
import { Button } from "components/button";
import TooltipV9 from "components/tooltip/TooltipV9";
import { useTranslation } from "hooks";
import { UserRoles } from "models/user";
import { AppRouteHelper } from "routes";
import { I18nFlag } from "views/menu/I18nFlag";
import "views/menu/Menu.scss";
import { MenuItem } from "views/menu/MenuItem";
import { MenuItemGroup, MenuItemType } from "views/menu/MenuItemGroup";

const COLLAPSED = "collapsed";
const EXPANDED = "expanded";
const SIDEBAR_STATE = "sidebar-state";

export default function Menu() {
  const { t } = useTranslation();
  const location = useLocation();
  const { user, userHasSystemRole } = useAuth();
  const titleRef = useRef<HTMLSpanElement | null>(null);
  const sidebarState = localStorage.getItem(SIDEBAR_STATE);
  const URLProps = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const [collapsed, setCollapsed] = useState(sidebarState === COLLAPSED);
  const [focusedClientValue, setFocusedClientValue] = useState(
    URLProps.get("focusedClient")
  );
  const className = `menu d-print-none ${collapsed ? COLLAPSED : ""}`;

  useEffect(() => {
    setFocusedClientValue(URLProps.get("focusedClient"));
  }, [URLProps]);

  if (!sidebarState) {
    localStorage.setItem(SIDEBAR_STATE, COLLAPSED);
  }

  useEffect(() => {
    const checkEllipsis = () => {
      if (titleRef.current) {
        setIsEllipsisActive(
          titleRef.current.scrollWidth > titleRef.current.clientWidth
        );
      }
    };

    checkEllipsis();
    window.addEventListener("resize", checkEllipsis);

    return () => {
      window.removeEventListener("resize", checkEllipsis);
    };
  }, [isEllipsisActive]);

  const menuItemManagement = user?.roles?.includes(
    UserRoles.FEATURES_CUSTOMER_MANAGEMENT
  )
    ? [
        {
          title: t("ROUTES.MANAGEMENT"),
          url: "/management",
          children: [
            {
              title: t("ROUTES.MANAGE_CUSTOMERS"),
              url: "/manage-customers",
              icon: <PeopleSettings24Regular />,
            },
          ],
        },
      ]
    : [];

  const menuItemAdmin =
    userHasSystemRole(AppConfig.USER_ROLES.MITT_LUDVIG_SUPERUSER) ||
    user?.roles?.includes(UserRoles.USERS_SUPER)
      ? [
          {
            title: t("ADMIN"),
            url: "/admin",
            children: [
              {
                title: t("FORTNOX_ADMIN"),
                url: AppRouteHelper.getFortnoxAdmin(),
                icon: <PersonTag24Regular />,
              },
            ],
          },
        ]
      : [];

  const userHasPilotRole = user?.roles?.includes(UserRoles.USERS_PILOT);

  const menuItems: MenuItemType[] = [
    {
      title: t("HOME"),
      url: AppRouteHelper.getHome(),
      icon: <Home24Regular />,
    },
    {
      title: t("NEW_CUSTOMER_NEW"),
      url: AppRouteHelper.getCreateNew(),
      icon: <Add24Regular />,
    },
    {
      title: t("BUSINESS"),
      children: [
        {
          title: t("MY_ASSIGNMENTS"),
          url: AppRouteHelper.getAssignments(focusedClientValue ?? undefined),
          icon: <SlideTransition24Regular />,
        },
        ...(userHasPilotRole
          ? [
              {
                title: `${t("MY_ASSIGNMENTS")}V2`,
                url: AppRouteHelper.getAssignments(
                  focusedClientValue ?? undefined,
                  true
                ),
                icon: <SlideTransition24Regular />,
              },
            ]
          : []),
        {
          title: t("BUSINESS_OPPORTUNITIES"),
          url: AppRouteHelper.getDeals(),
          icon: <Apps24Regular />,
        },
      ],
    },
    ...menuItemManagement,
    ...menuItemAdmin,
    {
      title: t("POWER_BI_REPORTS_HEADER"),
      url: AppRouteHelper.getPowerBiUrl(),
      icon: <DataHistogram24Regular />,
      isExternalLink: true,
    },
    {
      title: t("OTHER_APPLICATIONS.MENU_LINK"),
      url: AppRouteHelper.getOtherApplications(),
      icon: <AppFolder24Regular />,
    },
  ];

  return (
    <div className={className}>
      <div>
        <TooltipV9
          content={AppConfig.APP_TITLE}
          notTranslatable
          enabled={collapsed || (!collapsed && isEllipsisActive)}
        >
          <div className="menu-logo">
            <img
              src={MittLudvigMonogram}
              alt="MittLudvig logo"
              className="p-sm mr-xs"
            />
            {!collapsed && (
              <span ref={titleRef} className="app-title">
                {AppConfig.APP_TITLE}
              </span>
            )}
          </div>
        </TooltipV9>
        <div className="menu-items">
          {menuItems.map((group) => {
            if (group.children) {
              return (
                <MenuItemGroup
                  key={group.title}
                  header={group.title}
                  items={group.children || []}
                  collapsedMenu={collapsed}
                />
              );
            }
            return (
              <Fragment key={group.title}>
                {collapsed && group.title !== "Home" && (
                  <hr className="w-100" />
                )}
                <MenuItem item={group} collapsed={collapsed} />
              </Fragment>
            );
          })}
        </div>
      </div>
      <div className="menu-footer">
        <I18nFlag language="SV" className="m-sm" />
        <I18nFlag language="EN" className="m-sm" />
        <hr className="w-100" />
        <Button
          onClick={() => {
            setCollapsed(!collapsed);
            localStorage.setItem(
              SIDEBAR_STATE,
              collapsed ? EXPANDED : COLLAPSED
            );
          }}
          variant="outline"
          className="p-0 text-start toggle-btn my-md"
        >
          {collapsed && <ChevronRight24Regular />}
          {!collapsed && (
            <>
              <ChevronLeft24Regular className="mr-xs" />
              <span>{t("HIDE_MENU")}</span>
            </>
          )}
        </Button>
      </div>
    </div>
  );
}
