import {
  MessageBar,
  SelectTabData,
  SelectTabEvent,
  Spinner,
  Tab,
  TabList,
} from "@fluentui/react-components";
import { plainToClass } from "class-transformer";
import { addMonths, addQuarters, addWeeks, addYears } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Delete16Regular,
  LockClosed16Regular,
  Print20Regular,
} from "@fluentui/react-icons";

import AppConfig from "app-config";
import ludvigLogo from "assets/LUDVIG_CO_LOGO_RGB_BLUE.jpg";
import BreadcrumbV9, {
  BreadcrumbDefinitionItem,
} from "components/breadcrumbV2";
import { Button } from "components/button";
import { Card } from "components/card";
import { Content, ContentBody } from "components/content/Content";
import { ModalCalendarPicker } from "components/date/Calendar";
import { DropdownV9 } from "components/dropdown/DropdownV9";
import { LayoutBody, LayoutHeader } from "components/layout/Layout";
import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { OverlaySpinner, SpinnerSize } from "components/spinner";
import TooltipV9 from "components/tooltip/TooltipV9";
import { useTranslation } from "hooks/use-translate";
import { TranslationKey } from "i18n";
import { timeout } from "libs/timeout-promise";
import { getTruncatedString } from "libs/truncate-string";
import { isEmptyUUID } from "libs/uuid";
import {
  CustomerDelivery,
  DeliveryFact,
  DeliveryRecurrence,
} from "models/deliveryPlan";
import { User, UserRoles } from "models/user";
import { AppRouteHelper, CustomerDetailsParams } from "routes";
import {
  createCustomerDelivery,
  CREATED_USING_DP,
  createOrUpdateYearlyRecurringDeliveries,
  deleteCustomerDelivery,
  executeCustomerDeliveryPlan,
  fetchAvailableServices,
  fetchCustomerDeliveryPlan,
  fetchCustomerTaxObjects,
  GENERAL_ACCOUNTING_DELIVERY,
  updateCustomerDelivery,
} from "state/customers/actions";
import { useAppDispatch, useAppSelector } from "state/use-app-redux";
import { DeliveryServiceConfigurationForms } from "views/deliveryPlanner/components/configuration";
import { CustomerTaxObjectsModal } from "views/deliveryPlanner/components/CustomerTaxObjectsModal";
import { YearRingChart } from "views/deliveryPlanner/components/yearChart/YearChart";
import { taxObjectName } from "views/deliveryPlanner/libs/format-tax-object-name";
import { getDateFromYearMonth } from "views/deliveryPlanner/libs/get-date-from-year-month";
import { wrapIndex } from "views/deliveryPlanner/libs/wrap-index";

export function addRecurrence(
  recurrence: DeliveryRecurrence,
  date: Date
): Date {
  switch (recurrence) {
    case "WEEKLY":
      return addWeeks(date, 1);
    case "MONTHLY":
      return addMonths(date, 1);
    case "QUARTERLY":
      return addQuarters(date, 1);
    case "YEARLY":
      return addYears(date, 1);
    default:
      throw new Error(`Unknown recurrence: ${recurrence}`);
  }
}

const getServiceAreaTabId = (itemKey: string) => `ServiceArea_${itemKey}`;

const getMonthColor = (index: number, monthsOffset = -1) => {
  const quarterColor = Math.floor(wrapIndex(index, monthsOffset, 12) / 3) / 5;
  const colorFraction = (1 - quarterColor) * 0.75;
  return `${`rgba(0, 49, 87, ${colorFraction})`}`;
};

const MonthSegments = [
  "Januari",
  "Februari",
  "Mars",
  "April",
  "Maj",
  "Juni",
  "Juli",
  "Augusti",
  "September",
  "Oktober",
  "November",
  "December",
].map((month, index) => ({
  index,
  key: month,
  label: month,
  texts: [],
  selected: false,
  fill: getMonthColor((index % 6) + 1),
}));

export default function CustomerDeliveryPlanner() {
  const { customerId } = useParams<CustomerDetailsParams>();
  const { t: translate, language, dateFormatter } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const visibleTaxObjDeliveries = AppConfig.FEATURES.TAX_OBJECTS_DELIVERY;
  const admins = AppConfig.FEATURES.DELIVERY_PLANNER_ADMINS;

  const URLProps = new URLSearchParams(window.location.search);
  const isConveyorBeltV2 = URLProps.get("conveyorBeltV2");

  const customers = useAppSelector((state) => state.customers);
  const availableServices = useAppSelector(
    (state) => state.activities.availableDeliveryServices
  );
  const currentUser = useAppSelector((state) => state.users.currentUser);

  const customerState = customers.data.find(
    (c) => c.customer.customer_number === customerId
  );
  const customer = customerState?.customer;
  const [customerTaxModalOpen, setCustomerTaxModalOpen] = useState(false);
  const [currService, setCurrService] = useState<DeliveryFact>();

  const [selectedClickMonthKey, setClickSelectedMonthKey] = useState("");
  const [selectedHoverMonthKey, setHoverSelectedMonthKey] = useState("");
  const [selectedServiceType, setSelectedDeliveryType] = useState<
    DeliveryFact | undefined
  >(undefined);
  const [selectedServiceArea, setSelectedServiceArea] =
    useState("Affärsrådgivning");
  const [selectedDeliveryYear, setDeliveryYear] = useState(
    new Date().getFullYear()
  );
  const [selectedDelivery, setSelectedDelivery] = useState<
    CustomerDelivery | undefined
  >(undefined);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    (async () => {
      if (!customerState || !customerState.customer) {
        return;
      }

      if (availableServices.data.length === 0 && !availableServices.isLoading) {
        dispatch(fetchAvailableServices({ customer: customerState.customer }));
      }

      if (customerState.hasLoadedDeliveryPlan) {
        return;
      }

      dispatch(fetchCustomerDeliveryPlan({ customer: customerState.customer }));
    })();
  }, [customerState, dispatch, availableServices]);

  useEffect(() => {
    (async () => {
      if (visibleTaxObjDeliveries && customerState?.customer.customer_number) {
        dispatch(
          fetchCustomerTaxObjects(customerState.customer.customer_number)
        );
      }
    })();
  }, [
    customerState?.customer.customer_number,
    visibleTaxObjDeliveries,
    dispatch,
  ]);

  if (availableServices.isLoading || customers.isLoading) {
    return <OverlaySpinner label={translate("LOADING_DELIVERY")} />;
  }

  if (availableServices.data.length === 0) {
    return <>No available services</>;
  }

  if (!customerId) {
    return <>Missing customer route param ID</>;
  }

  if (!customerState || !customer) {
    return <>Missing customer</>;
  }

  const yearsOptions = [
    new Date().getFullYear() - 1,
    new Date().getFullYear(),
    new Date().getFullYear() + 1,
    new Date().getFullYear() + 2,
  ];

  const breadcrumbs: BreadcrumbDefinitionItem[] = [
    {
      text: translate("MY_ASSIGNMENTS"),
      onClick: () =>
        navigate(
          isConveyorBeltV2
            ? AppRouteHelper.getAssignments("", true)
            : AppRouteHelper.getAssignments()
        ),
    },
    {
      text: customer ? customer.name : "...",
      onClick: () =>
        navigate(
          isConveyorBeltV2
            ? AppRouteHelper.getAssignments(customerId, true)
            : AppRouteHelper.getAssignments(customerId)
        ),
    },
    {
      text: translate("DELIVERY_PLANNER"),
    },
  ];

  // TODO: Get these by reducing the available services into groups
  const serviceAreaGroups = [
    "Affärsrådgivning",
    "Ekonomistyrning",
    "Ekonomi",
    "Lön",
    "Skatt",
    "Juridik",
    "Skoglig rådgivning",
    "Fastighetsförmedling",
  ];

  const { deliveryPlan } = customer;
  let selectedYearDeliveryPlan = deliveryPlan.filter((delivery) => {
    if (delivery.start_date?.getFullYear() === selectedDeliveryYear) {
      return delivery;
    }

    return (
      delivery.start_date?.getFullYear() === selectedDeliveryYear - 1 &&
      delivery.start_date?.getMonth() !== 1
    );
  });

  selectedYearDeliveryPlan = selectedYearDeliveryPlan.filter(
    (delivery) =>
      delivery.start_date.getFullYear() === selectedDeliveryYear ||
      selectedYearDeliveryPlan.filter(
        (selectedYearDelivery) =>
          delivery.delivery_name === selectedYearDelivery.delivery_name
      ).length === 1
  );

  const selectedMonthlyServices = selectedYearDeliveryPlan.filter(
    (delivery) => {
      const deliveryTemplate = availableServices.data.find(
        (availableService) =>
          availableService.delivery_name === delivery.delivery_name
      );

      if (!deliveryTemplate) {
        throw new Error(
          `Could not map delivery template for ${delivery.delivery_name}`
        );
      }

      return delivery.recurrence === "MONTHLY";
    }
  );

  const servicesPerMonth = MonthSegments.map((month, monthIndex) => {
    const deliveryPlanMonth = deliveryPlan.filter((delivery) => {
      const service = availableServices.data.find(
        (availableService) =>
          availableService.delivery_name === delivery.delivery_name
      );
      if (!service) {
        throw new Error(
          `Could not match delivery ${delivery.delivery_name} to an available service`
        );
      }

      if (delivery.delivery_dates && Array.isArray(delivery.delivery_dates)) {
        return delivery.delivery_dates.some(
          (deliveryDate) =>
            deliveryDate.getMonth() === monthIndex &&
            deliveryDate.getFullYear() === selectedDeliveryYear
        );
      }
      return undefined;
    });

    return {
      ...month,
      selected: selectedClickMonthKey === month.key,
      values: deliveryPlanMonth.map((delivery) => {
        const service = availableServices.data.find(
          (availableService) =>
            availableService.delivery_name === delivery.delivery_name
        );

        if (!service) {
          throw new Error(
            `Could not match delivery ${delivery.delivery_name} to an available service`
          );
        }

        return { service, delivery };
      }),
    };
  });

  const notConfiguredDeliveries = (() => {
    const notYetAddedSelectedMonthlyDeliveryPlan =
      selectedMonthlyServices.filter((selectedMonthlyDeliveryPlan) => {
        const isMonthlyServiceAlreadyAdded = selectedYearDeliveryPlan.some(
          (service) => service.uuid === selectedMonthlyDeliveryPlan.uuid
        );

        return !isMonthlyServiceAlreadyAdded;
      });

    let result = [
      ...selectedYearDeliveryPlan,
      ...notYetAddedSelectedMonthlyDeliveryPlan,
    ];

    result = result.filter(
      (customerDelivery) =>
        customerDelivery.packages &&
        customerDelivery.packages.length === 0 &&
        DeliveryServiceConfigurationForms.get(customerDelivery.delivery_name)
    );

    return result;
  })();

  const allDeliveriesLockedIn = deliveryPlan
    .filter(
      (delivery) => delivery.start_date?.getFullYear() <= selectedDeliveryYear
    )
    .every((customerDelivery) => customerDelivery.is_locked_in);

  const selectedMonthKey = selectedHoverMonthKey || selectedClickMonthKey;
  const selectedMonthNumber = MonthSegments.findIndex(
    (month) => month.key === selectedMonthKey
  );
  const selectedMonthServices =
    servicesPerMonth.find((month) => month.key === selectedMonthKey)?.values ||
    [];
  const hasSelectedMonthNumber = selectedMonthNumber !== -1;
  const selectedMonthDate = hasSelectedMonthNumber
    ? getDateFromYearMonth(selectedDeliveryYear, selectedMonthNumber + 1)
    : new Date();

  const removeService = (service: DeliveryFact, month?: number) => {
    const foundInstance = deliveryPlan.find((delivery) => {
      const isServiceFirstDeliveryDateInMonth =
        !month ||
        (delivery.delivery_dates.length > 0 &&
          delivery.delivery_dates[0].getMonth() === month);

      const isMatchingService =
        service.delivery_name === delivery.delivery_name;
      return isMatchingService && isServiceFirstDeliveryDateInMonth;
    });

    if (!foundInstance) {
      throw new Error("Missing existing service");
    }

    dispatch(
      deleteCustomerDelivery({
        customer: customerState.customer,
        deliveryId: foundInstance.uuid,
      })
    );
  };

  const executeDeliveryYear = () => {
    dispatch(
      executeCustomerDeliveryPlan({
        customer: customerState.customer,
        deliveryPlan: deliveryPlan
          .filter((delivery) => !delivery.is_locked_in)
          .map((delivery) => {
            const isServiceFirstDeliveryDateInYear =
              (delivery.delivery_name === GENERAL_ACCOUNTING_DELIVERY &&
                delivery.recurrence === "MONTHLY") ||
              (delivery.delivery_dates.length > 0 &&
                delivery.delivery_dates[0].getFullYear() ===
                  selectedDeliveryYear);

            if (isServiceFirstDeliveryDateInYear) {
              return plainToClass(CustomerDelivery, {
                ...delivery,
                is_locked_in: true,
              });
            }

            return delivery;
          }),
      })
    );
  };

  const printDeliveries = async () => {
    setClickSelectedMonthKey("");
    await timeout(1);
    window.print();
    await timeout(1);
    setClickSelectedMonthKey(selectedClickMonthKey);
  };

  const addRecurringService = async (service: DeliveryFact) => {
    let startDate = new Date(
      `${selectedDeliveryYear}-${selectedMonthNumber + 1}-01 12:00`
    );
    if (Number.isNaN(startDate.getTime())) {
      // If date was invalid, then set now
      const now = new Date();
      startDate = new Date(now.getFullYear(), now.getMonth(), 1, 12);
    }
    dispatch(
      createCustomerDelivery({
        customer: customerState.customer,
        delivery: plainToClass(CustomerDelivery, {
          delivery_name: service.delivery_name,
          packages: [],
          created_by_user_uuid: currentUser.graphId,
          start_date: startDate,
          recurrence: service.recurrence,
          is_locked_in: false,
          created_using: CREATED_USING_DP,
          delivery_name_ext: service.delivery_name_ext,
        }),
      })
    );
  };

  const addService = (
    service: DeliveryFact,
    startDate?: Date,
    endDate?: Date
  ) => {
    let deliveryDates;
    let recurrence = "ONEOFF";
    if (service.is_unique_tax_delivery_name && visibleTaxObjDeliveries) {
      const serviceExistsInMonth = selectedMonthServices.find(
        (e) => e.service.delivery_name_ext === service.delivery_name_ext
      );
      if (serviceExistsInMonth) {
        return;
      }
      deliveryDates = endDate
        ? [endDate]
        : [
            new Date(
              `${selectedDeliveryYear}-${selectedMonthNumber + 1}-01 12:00`
            ),
          ];
      recurrence = "YEARLY";
    }
    dispatch(
      createCustomerDelivery({
        customer: customerState.customer,
        delivery: plainToClass(CustomerDelivery, {
          delivery_name: service.delivery_name,
          packages: [],
          created_by_user_uuid: currentUser.graphId,
          start_date:
            startDate ||
            new Date(
              `${selectedDeliveryYear}-${selectedMonthNumber + 1}-01 12:00`
            ),
          recurrence,
          is_locked_in: false,
          created_using: CREATED_USING_DP,
          delivery_name_ext: service.delivery_name_ext,
          delivery_dates: deliveryDates,
        }),
      })
    );
  };
  const addServices = (service: DeliveryFact) => {
    if (service.is_unique_tax_delivery_name && visibleTaxObjDeliveries) {
      setCustomerTaxModalOpen(true);
    } else {
      addService(service);
    }
  };

  const renderServiceCard = (
    service: DeliveryFact,
    isLocked: boolean,
    selectedInYear: CustomerDelivery[],
    selectedInMonth?: CustomerDelivery
  ) => {
    if (service.deactivated || service.hidden) {
      return;
    }

    const monthName = MonthSegments.find(
      (month) => month.key === selectedMonthKey
    )?.label.toLowerCase();
    const hasReachedMaxAmount =
      selectedInYear.length === service.max_instances_per_year;
    const hasDeliveryConfigurationForm = DeliveryServiceConfigurationForms.has(
      service.delivery_name
    );
    const deliveryAddRemove = () => {
      if (isLocked || !hasSelectedMonthNumber) {
        return;
      }
      if (!selectedInMonth && hasReachedMaxAmount) {
        return;
      }

      const showDeliveryConfigurationModal =
        !selectedInMonth && hasDeliveryConfigurationForm;

      if (showDeliveryConfigurationModal) {
        setSelectedDeliveryType(service);
        return;
      }
      setCurrService(service);
      if (selectedInMonth) {
        removeService(service, selectedMonthNumber);
      } else {
        addServices(service);
      }
    };

    return (
      <Card
        key={service.delivery_name}
        style={{
          width: "250px",
          opacity: selectedInMonth ? 0.5 : 1.0,
          maxWidth: "250px",
        }}
        hoverOverlay={
          <div
            className="d-flex align-items-center justify-content-center flex-column text-center w-100 h-100 fw-bold"
            style={{
              color: "white",
              background: "rgba(0, 0, 0, 0.5)",
            }}
            onClick={() => {
              deliveryAddRemove();
            }}
          >
            {hasSelectedMonthNumber && (
              <span
                style={{
                  fontSize: "1.5em",
                }}
              >
                {isLocked && translate("DELIVERY_IS_LOCKED")}
                {!isLocked && selectedInMonth && (
                  <>
                    {translate("REMOVE_FROM")} {monthName}
                  </>
                )}
                {!isLocked && !selectedInMonth && !hasReachedMaxAmount && (
                  <>
                    {translate("ADD_TO")} {monthName}
                  </>
                )}
              </span>
            )}

            {!hasSelectedMonthNumber && (
              <span className="fpx-12">{translate("SELECT_A_MONTH")}</span>
            )}

            {hasReachedMaxAmount && (
              <span className="fpx-12">
                {translate("MAX_NUMBER_OF_SERVICES_INSTANCES_REACHED")}
              </span>
            )}
          </div>
        }
        title={service.description_headline}
        body={<span>{service.description}</span>}
        imageUrl={service.image_url}
        footer={
          <span className="mt-3 d-flex fg-4 justify-content-between align-items-center">
            <span>
              {service.intranet_url && (
                <a
                  className="fpx-12"
                  href={service.intranet_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  Läs mer
                </a>
              )}
            </span>

            <span>
              {!isLocked && selectedInMonth && hasSelectedMonthNumber && (
                <Button
                  size="md"
                  variant="outline"
                  onClick={() => removeService(service, selectedMonthNumber)}
                >
                  {translate("REMOVE_FROM")} {monthName}
                </Button>
              )}

              {!isLocked &&
                !selectedInMonth &&
                hasSelectedMonthNumber &&
                !hasReachedMaxAmount && (
                  <Button
                    size="md"
                    variant="primary"
                    onClick={() => {
                      deliveryAddRemove();
                    }}
                  >
                    {translate("ADD_TO")} {monthName}
                  </Button>
                )}
            </span>
          </span>
        }
      />
    );
  };

  const renderRecurringServiceCard = (
    service: DeliveryFact,
    isLocked: boolean,
    selectedInYear: CustomerDelivery[]
  ) => {
    const isSelectedInYear = selectedInYear.length > 0;
    if (service.deactivated || service.hidden) {
      return;
    }
    return (
      <Card
        key={service.delivery_name}
        label={<b>{translate("RECURRING")}</b>}
        style={{
          width: "250px",
          opacity: isSelectedInYear ? 0.5 : 1.0,
          maxWidth: "250px",
        }}
        hoverOverlay={
          <div
            onClick={() => {
              if (isLocked) {
                return;
              }

              if (isSelectedInYear) {
                removeService(service);
              } else {
                if (!hasSelectedMonthNumber) {
                  return;
                }

                const hasDeliveryConfiguration =
                  DeliveryServiceConfigurationForms.has(service.delivery_name);

                if (hasDeliveryConfiguration) {
                  setSelectedDeliveryType(service);
                  return;
                }

                addRecurringService(service);
              }
            }}
            className="d-flex align-items-center justify-content-center text-center w-100 h-100 fw-bold fpx-12"
            style={{
              color: "white",
              background: "rgba(0, 0, 0, 0.5)",
            }}
          >
            {isLocked && translate("DELIVERY_IS_LOCKED")}
            {!isLocked && isSelectedInYear && translate("REMOVE")}
            {!isLocked &&
              !isSelectedInYear &&
              hasSelectedMonthNumber &&
              (translate("ADD") || translate("SELECT_A_MONTH"))}
          </div>
        }
        title={service.description_headline}
        body={<span>{service.description}</span>}
        imageUrl={service.image_url}
        footer={
          <span className="mt-3 d-flex fg-4 justify-content-between align-items-center">
            <span>
              {service.intranet_url && (
                <a
                  className="fpx-12"
                  href={service.intranet_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {translate("READ_MORE")}
                </a>
              )}
            </span>

            <span>
              {isSelectedInYear && !isLocked && (
                <Button
                  size="md"
                  variant="outline"
                  onClick={() => removeService(service)}
                >
                  {translate("REMOVE")}
                </Button>
              )}

              {!isSelectedInYear && !isLocked && hasSelectedMonthNumber && (
                <Button
                  size="md"
                  variant="primary"
                  onClick={() => {
                    const hasDeliveryConfigurationForm =
                      DeliveryServiceConfigurationForms.has(
                        service.delivery_name
                      );

                    if (hasDeliveryConfigurationForm) {
                      setSelectedDeliveryType(service);
                      return;
                    }

                    addRecurringService(service);
                  }}
                >
                  {translate("ADD")}
                </Button>
              )}
            </span>
          </span>
        }
      />
    );
  };

  const isDeliveryPlannerAdmin = (current: User) => {
    return (
      current?.roles?.includes(UserRoles.USERS_DELIVERY_PLANNER_ADMIN) ||
      admins.includes(current.email)
    );
  };

  const renderSelectedServices = () => (
    <div data-testid="selected-services" className="col-lg-4 col-md-12">
      <div className="d-flex flex-column justify-space-between h-100">
        <div className="flex-1-1">
          <div className="mb-4 d-print-none">
            <h4>{translate("SELECT_YEAR")}</h4>
            <div className="d-flex flex-wrap fg-3">
              <div>
                <div>
                  <DropdownV9
                    className="d-print-none"
                    style={{ maxWidth: "200px" }}
                    selectedOptions={[selectedDeliveryYear.toString()]}
                    options={yearsOptions.map((year) => ({
                      value: year,
                      text: year.toString(),
                    }))}
                    onOptionSelect={(_, { optionValue }) => {
                      if (optionValue) {
                        setDeliveryYear(new Date(optionValue).getFullYear());
                      }
                    }}
                  />
                </div>
              </div>

              <TooltipV9
                enabled={
                  notConfiguredDeliveries.length > 0 || allDeliveriesLockedIn
                }
                hasJSXContent
                content={
                  <div className="text-left">
                    {notConfiguredDeliveries.length > 0 && (
                      <>
                        <p className="m-0">
                          {translate("UNABLE_TO_SAVE_DELIVERY_YEAR")}
                        </p>

                        <ul className="fpx-12 mt-2">
                          {notConfiguredDeliveries.map((uf) => {
                            const deliveryService = availableServices.data.find(
                              (a) => uf.delivery_name === a.delivery_name
                            );

                            return (
                              <li className="mb-2" key={uf.uuid}>
                                {deliveryService?.description_headline}
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    )}

                    {allDeliveriesLockedIn && (
                      <p className="m-0">
                        {translate("ALL_DELIVERIES_LOCKED")}
                      </p>
                    )}
                  </div>
                }
              >
                <Button
                  disabled={
                    notConfiguredDeliveries.length > 0 ||
                    allDeliveriesLockedIn ||
                    customerState.isLoading
                  }
                  className="d-print-none"
                  onClick={() => executeDeliveryYear()}
                >
                  {translate("LOCK_AND_CREATE_ACTIVITIES")}
                </Button>
              </TooltipV9>

              <Button
                className="d-print-none"
                variant="outline"
                onClick={printDeliveries}
              >
                {translate("PRINT")} <Print20Regular />
              </Button>
            </div>
          </div>

          <div className="mb-4">
            <h3 className="fw-bold">{translate("MONTHLY_DELIVERIES")}</h3>
            {selectedMonthlyServices.length === 0 && (
              <p className="fpx-12 p-1 m-0">
                {translate("NO_MONTHLY_DELIVERIES")}
              </p>
            )}{" "}
            <table
              data-testid="monthly-selected-services"
              className="no-border cell-p-1 no-border-first-last"
              style={{ maxWidth: "300px", background: "" }}
            >
              <tbody>
                {selectedMonthlyServices.map((service) => {
                  const serviceDefinition = availableServices.data.find(
                    (availableService) =>
                      availableService.delivery_name === service.delivery_name
                  );

                  const matchingServices = deliveryPlan.filter(
                    (delivery) =>
                      delivery.delivery_name === service.delivery_name
                  );

                  const matchingServicesSortedByStartDate =
                    matchingServices.sort((a, b) =>
                      a?.start_date < b?.start_date ? -1 : 1
                    );

                  return (
                    <tr key={service.uuid}>
                      <td style={{ width: "200px", height: "3em" }}>
                        {serviceDefinition?.description_headline} -{" "}
                        {matchingServicesSortedByStartDate.length > 0 &&
                        selectedDeliveryYear ===
                          matchingServicesSortedByStartDate[0].start_date?.getFullYear()
                          ? `start ${dateFormatter(
                              matchingServicesSortedByStartDate[0].start_date,
                              "yyyy-MM"
                            )}`
                          : "pågående leverans"}
                      </td>
                      <td>
                        {DeliveryServiceConfigurationForms.get(
                          service.delivery_name
                        ) && (
                          <Button
                            disabled={isEmptyUUID(service.uuid)}
                            className="me-4"
                            variant="outline"
                            onClick={() => navigate(`${service.uuid}/packages`)}
                          >
                            {service.packages && service.packages.length === 0
                              ? translate("ENTER_INFORMATION")
                              : translate("CHANGE")}
                          </Button>
                        )}
                      </td>
                      <td>
                        <div className="d-flex fg-3 align-items-center">
                          <TooltipV9
                            enabled={!customerState.isLoading}
                            content="Byt leveransmånad"
                            notTranslatable
                          >
                            <ModalCalendarPicker
                              header={
                                <ModalHeader headerTitleText="SET_DELIVERY_MONTH" />
                              }
                              isDayPickerVisible={false}
                              language={language}
                              translate={translate}
                              disabled={customerState.isLoading}
                              onSelectDate={(date) => {
                                dispatch(
                                  updateCustomerDelivery({
                                    customer,
                                    deliveryId: service.uuid,
                                    update: {
                                      start_date: date,
                                    },
                                  })
                                );
                              }}
                            />
                          </TooltipV9>
                          <TooltipV9
                            content={
                              <span className="fpx-12">
                                {translate("REMOVE_DELIVERY")}
                              </span>
                            }
                          >
                            <Delete16Regular
                              className="cursor-pointer"
                              onClick={() => {
                                if (service.is_locked_in) {
                                  setSelectedDelivery(service);
                                  setShowDeleteModal(true);
                                  return;
                                }

                                dispatch(
                                  deleteCustomerDelivery({
                                    customer,
                                    deliveryId: service.uuid,
                                  })
                                );
                              }}
                            />
                          </TooltipV9>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="mb-4">
            <div className="d-print-none mb-4">
              {!selectedMonthKey && (
                <MessageBar>
                  {translate("USE_POINTER_TO_SEE_MONTH_DETAILS")}
                </MessageBar>
              )}
              {selectedMonthKey && (
                <h3 className="fw-bold">{selectedMonthKey} månad</h3>
              )}
              {selectedMonthKey && selectedMonthServices.length === 0 && (
                <p className="fpx-12 p-1 m-0">{translate("NO_DELIVERIES")}</p>
              )}
              <table
                className="no-border cell-p-1 no-border-first-last"
                style={{ maxWidth: "300px", background: "" }}
              >
                <tbody>
                  {selectedMonthServices?.map((selectedMonthService) => (
                    <tr
                      key={
                        selectedMonthService.delivery.delivery_name_ext
                          ? taxObjectName(
                              selectedMonthService.service.description_headline,
                              selectedMonthService.delivery.delivery_name_ext
                            )
                          : selectedMonthService.delivery.uuid
                      }
                    >
                      <td style={{ width: "200px", height: "3em" }}>
                        {selectedMonthService.delivery.delivery_name_ext
                          ? taxObjectName(
                              selectedMonthService.service.description_headline,
                              selectedMonthService.delivery.delivery_name_ext
                            )
                          : selectedMonthService.service
                              .description_headline}{" "}
                        {customer.name}
                      </td>
                      <td>
                        {DeliveryServiceConfigurationForms.get(
                          selectedMonthService.service.delivery_name
                        ) && (
                          <Button
                            className="me-4"
                            variant="outline"
                            onClick={() =>
                              navigate(
                                `${selectedMonthService.delivery.uuid}/packages`
                              )
                            }
                          >
                            {selectedMonthService.delivery.packages &&
                            selectedMonthService.delivery.packages.length === 0
                              ? translate("ENTER_INFORMATION")
                              : translate("CHANGE")}
                          </Button>
                        )}
                      </td>
                      <td>
                        <div className="d-flex fg-3 align-items-center">
                          <TooltipV9
                            enabled={!customerState.isLoading}
                            content="Byt leveransmåna"
                            notTranslatable
                          >
                            <ModalCalendarPicker
                              header={
                                <ModalHeader headerTitleText="SET_DELIVERY_MONTH" />
                              }
                              isDayPickerVisible={false}
                              language={language}
                              translate={translate}
                              disabled={customerState.isLoading}
                              onSelectDate={(date) => {
                                dispatch(
                                  updateCustomerDelivery({
                                    customer,
                                    deliveryId:
                                      selectedMonthService.delivery.uuid,
                                    update: {
                                      delivery_dates: [
                                        ...selectedMonthService.delivery.delivery_dates.filter(
                                          (deliveryDate) =>
                                            deliveryDate.getMonth() !==
                                            selectedMonthDate.getMonth()
                                        ),
                                        date,
                                      ],
                                    },
                                  })
                                );
                              }}
                            />
                          </TooltipV9>

                          {(!selectedMonthService.service.deletion_disabled ||
                            isDeliveryPlannerAdmin(currentUser)) && (
                            <TooltipV9 content="REMOVE_DELIVERY">
                              <Delete16Regular
                                className="cursor-pointer"
                                onClick={() => {
                                  if (
                                    selectedMonthService.delivery.is_locked_in
                                  ) {
                                    setSelectedDelivery(
                                      selectedMonthService.delivery
                                    );
                                    setShowDeleteModal(true);
                                    return;
                                  }

                                  dispatch(
                                    deleteCustomerDelivery({
                                      customer,
                                      deliveryId:
                                        selectedMonthService.delivery.uuid,
                                    })
                                  );
                                }}
                              />
                            </TooltipV9>
                          )}
                          {selectedMonthService.delivery.is_locked_in && (
                            <TooltipV9 content="DELIVERY_LOCKED_IN">
                              <LockClosed16Regular />
                            </TooltipV9>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {customerState.isLoading && (
            <Spinner
              label={translate("SAVING")}
              size={SpinnerSize.ExtraSmall}
            />
          )}
        </div>
      </div>
    </div>
  );

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedServiceArea(data.value as string);
  };

  const renderServicesCatalog = () => (
    <>
      <div className="d-flex mb-3 d-print-none">
        <h1 className="mt-1 pt-1 me-4 pe-4 fw-bold text-nowrap">
          {translate("SERVICE_AREAS")}
        </h1>

        <TabList
          size="large"
          selectedValue={selectedServiceArea}
          onTabSelect={onTabSelect}
          className="d-print-none"
        >
          {serviceAreaGroups.map((text) => (
            <Tab key={text} value={text}>
              {translate(text as TranslationKey)}
            </Tab>
          ))}
        </TabList>
      </div>

      <div
        aria-labelledby={getServiceAreaTabId(selectedServiceArea)}
        role="tabpanel"
        className="d-print-none"
      >
        {(() => {
          const serviceAreaSelection = availableServices.data.filter(
            (service) => service.service_area_name === selectedServiceArea
          );

          return (
            <div
              className="d-flex fg-3 flex-wrap"
              style={{ minHeight: "250px" }}
            >
              {serviceAreaSelection.length === 0 && (
                <p>
                  {translate(selectedServiceArea as TranslationKey)}{" "}
                  {translate("IS_MISSING_SERVICES").toLowerCase()}
                </p>
              )}

              {serviceAreaSelection
                .sort((a, b) =>
                  a.description_headline > b.description_headline ? 1 : -1
                )
                .map((service) => {
                  const deliveriesInYear = deliveryPlan.filter(
                    (delivery) =>
                      delivery.delivery_name === service.delivery_name &&
                      delivery.start_date.getFullYear() === selectedDeliveryYear
                  );

                  const deliveryInMonth = deliveryPlan.find(
                    (delivery) =>
                      delivery.delivery_name === service.delivery_name &&
                      delivery.start_date.getMonth() === selectedMonthNumber &&
                      delivery.start_date.getFullYear() === selectedDeliveryYear
                  );

                  const isRecurringMonthly = service.recurrence === "MONTHLY";

                  const isLocked =
                    deliveriesInYear.length > 0 &&
                    deliveriesInYear.every((d) => d.is_locked_in);

                  return isRecurringMonthly
                    ? renderRecurringServiceCard(
                        service,
                        isLocked,
                        deliveriesInYear
                      )
                    : renderServiceCard(
                        service,
                        isLocked,
                        deliveriesInYear,
                        deliveryInMonth
                      );
                })}
            </div>
          );
        })()}
      </div>
    </>
  );

  const ServiceConfiguration = DeliveryServiceConfigurationForms.get(
    selectedServiceType ? selectedServiceType.delivery_name : ""
  );

  const handleDeleteDeleviry = () => {
    if (selectedDelivery) {
      dispatch(
        deleteCustomerDelivery({
          customer,
          deliveryId: selectedDelivery.uuid,
        })
      );
    }
    setShowDeleteModal(false);
  };

  return (
    <>
      <LayoutHeader>
        <span />
      </LayoutHeader>
      <LayoutBody>
        {currService && (
          <CustomerTaxObjectsModal
            customer={customerState.customer}
            isOpen={customerTaxModalOpen}
            setOpen={setCustomerTaxModalOpen}
            onSubmit={addService}
            service={currService}
            selectedMonthNumber={selectedMonthNumber}
          />
        )}
        <BreadcrumbV9 items={breadcrumbs} />

        <Modal
          isOpen={showDeleteModal}
          onDismiss={() => {
            setShowDeleteModal(false);
            setSelectedDelivery(undefined);
          }}
          header={<ModalHeader headerTitleText="DELETE_DELIVERY" />}
          footer={
            <ModalFooter
              labelSubmit="REMOVE"
              onCancel={() => {
                setShowDeleteModal(false);
                setSelectedDelivery(undefined);
              }}
              onSave={handleDeleteDeleviry}
              saveButtonVariant="danger"
            />
          }
        >
          <p>{translate("DELETE_DELIVERY_DESCRIPTION")}</p>

          {selectedDelivery && selectedDelivery.delivery_dates.length > 1 && (
            <>
              <p>
                Leveransen är en återkommande ({selectedDelivery.recurrence})
                leverans, följande leveranser kommer att tas bort:
              </p>
              <ul>
                {selectedDelivery.delivery_dates.map((date) => (
                  <li key={date.toISOString()}>
                    {selectedDelivery.delivery_name}{" "}
                    {dateFormatter(date, "yyyy-MM-dd")}
                  </li>
                ))}
              </ul>
            </>
          )}
        </Modal>

        <Modal
          isOpen={!!selectedServiceType}
          onDismiss={() => setSelectedDeliveryType(undefined)}
          header={
            <ModalHeader headerTitleText="Välj inställningar för leverans" />
          }
        >
          {ServiceConfiguration && (
            <ServiceConfiguration
              customer={customer}
              existingDeliveries={
                selectedServiceType
                  ? deliveryPlan.filter(
                      (delivery) =>
                        delivery.delivery_name ===
                        selectedServiceType.delivery_name
                    )
                  : []
              }
              onChange={(packages, recurrence, deliveryDate, startDate) => {
                setSelectedDeliveryType(undefined);
                if (selectedServiceType) {
                  dispatch(
                    createOrUpdateYearlyRecurringDeliveries({
                      customer,
                      packages,
                      startYearDate: startDate || new Date(),
                      yearlyDeliveryDate: startDate || new Date(),
                      recurrence,
                      deliveryType: selectedServiceType.delivery_name,
                      deliveryDates:
                        recurrence === "MONTHLY" ? [] : [deliveryDate],
                      createdUsing: CREATED_USING_DP,
                      lock_in: false,
                    })
                  );
                }
              }}
            />
          )}
        </Modal>

        <Content>
          <ContentBody>
            {!customers.isLoading && !customer && (
              <p className="p-3">
                Vi kunde inte ladda kunden. Om felet består, felanmäl till
                supporten.
              </p>
            )}

            <div className="d-none d-print-block col-lg-4 col-md-12">
              <img
                src={ludvigLogo}
                alt="Ludvig logo"
                style={{ width: "30%", marginBottom: "25px" }}
              />
              <h1>{customer.name}</h1>
              {selectedDeliveryYear && (
                <h3 className="fw-bold">
                  {translate("DELIVERY_YEAR")} {selectedDeliveryYear}
                </h3>
              )}
            </div>

            <div className="d-flex row" style={{ marginBottom: "4em" }}>
              <div className="col-lg-8 col-md-12 d-flex flex-column">
                {deliveryPlan?.length < 3 && (
                  <p>
                    <span className="d-print-none">
                      {translate("DELIVERY_PLANNER_DESCRIPTION")}{" "}
                    </span>
                    <span
                      className="text-decoration-underline cursor-pointer"
                      onClick={() => {
                        const mainWindow = document.querySelector(".main");
                        if (!mainWindow) {
                          return;
                        }

                        mainWindow.scroll({
                          top: mainWindow.scrollHeight,
                          behavior: "smooth",
                        });
                      }}
                    >
                      <span className="d-print-none">
                        {translate("DELIVERY_PLANNER_DESCRIPTION_LINK")}
                      </span>
                    </span>
                  </p>
                )}

                <YearRingChart
                  style={{ width: "100%" }}
                  monthsConfiguration={servicesPerMonth.map((month) => ({
                    key: month.key,
                    fill: month.fill,
                    label: month.label,
                    selected: selectedClickMonthKey === month.key,
                    texts: month.values.map(
                      (value, index) =>
                        `${
                          value.delivery.delivery_name_ext
                            ? taxObjectName(
                              value.service.description_headline,
                              value.delivery.delivery_name_ext
                              )
                            : getTruncatedString(
                                `${value.service.description_headline} ${customer.name}`,
                                35
                              )
                        }`
                    ),
                  }))}
                  onMouseLeave={() => {
                    setHoverSelectedMonthKey("");
                  }}
                  onYearMonthLeave={() => setHoverSelectedMonthKey("")}
                  onYearMonthHover={(month) =>
                    setHoverSelectedMonthKey(month.key)
                  }
                  onYearMonthClick={(month) => {
                    if (month.key === selectedClickMonthKey) {
                      setClickSelectedMonthKey("");
                    } else {
                      setClickSelectedMonthKey(month.key);
                    }
                  }}
                />
              </div>

              {renderSelectedServices()}
            </div>

            {renderServicesCatalog()}
          </ContentBody>
        </Content>
      </LayoutBody>
    </>
  );
}
