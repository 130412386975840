import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import { Picker } from "components/picker";
import { useTranslation } from "hooks/use-translate";
import {
  ACCOUNTING_SERVICE_LINE,
  EKONOMI_BUSINESS_AREA_LABEL,
} from "libs/constants";
import { LoadingStatusEnum } from "models/enums/LoadingStatus.enum";
import { DealCustomer } from "models/offer/Customer";
import { DealContact } from "models/offer/DealContact";
import { AppRouteHelper } from "routes";
import { useGetCompanyTypesQuery } from "services/ludvigApi";
import { EXCLUDE_BUSINESS_AREAS } from "shared/dealConstants";
import { RootState } from "state";
import {
  updateCurrentOffer,
  updateOfferContact,
  updateOfferCustomer,
} from "state/offer/offersSlice";
import { fetchOfferTemplate, resetOffer } from "state/offer/offersThunks";
import { useAppDispatch } from "state/use-app-redux";
import { OfferRouteHelper } from "views/offer/wizard/offerRoutes";
import { CreateCustomerContext } from "../CreateCustomerContext";
import WizardSectionCustomer from "../components/WizardSectionCustomer";

export default function CustomerBusinessAreaPicker() {
  const { ts, t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    businessAreaSelected,
    subServiceSelected,
    toggleBusinessArea: selectBusinessArea,
    toggleSubService: selectSubService,
  } = useContext(CreateCustomerContext);

  // call this RTK query to get the customer-types
  // the response is cached so it wont be called again when we enter the POG flow for example
  useGetCompanyTypesQuery();

  const deals = useSelector((state: RootState) => state.sales);
  const dealsBusinessAreas = deals.dealOptions.data.find(
    (d) => d.name === "affarsomrade_deal_"
  );

  const servicematrixServices = useSelector(
    (state: RootState) => state.servicesDescriptions.data
  );

  const isAccountingSelected = () => {
    return (
      businessAreaSelected[0] === EKONOMI_BUSINESS_AREA_LABEL &&
      subServiceSelected[0] === ACCOUNTING_SERVICE_LINE
    );
  };

  const getNextButtonLabel = () => {
    if (isAccountingSelected()) {
      return "PROCEED_TO_PRICEMODEL";
    }
    return "ADD_CUSTOMER_DETAILS_BUTTON";
  };

  const getPredefinedRoute = () =>
    isAccountingSelected() ? OfferRouteHelper.getRegisterCompany() : undefined;

  const getBusinessAreaToggles = (businessArealabel: string) => {
    const filteredServices = servicematrixServices.filter(
      (service) => service.ServiceAreaSE === businessArealabel
    );
    if (filteredServices) {
      const serviceLines = filteredServices.map(
        (service) => service.ServiceLineGrouping
      );
      const uniqueServiceLines = Array.from(new Set(serviceLines));

      return uniqueServiceLines.map((serviceLine) => {
        return {
          checked: subServiceSelected.includes(serviceLine),
          label: serviceLine,
          value: serviceLine,
          group: serviceLine === ACCOUNTING_SERVICE_LINE ? 1 : 2,
          onToggle() {
            selectSubService(serviceLine);
          },
        };
      });
    }

    return [];
  };

  const handleOnBeforeNext = async () => {
    if (isAccountingSelected()) {
      await dispatch(fetchOfferTemplate());
      await dispatch(resetOffer());
      await dispatch(updateOfferCustomer(new DealCustomer()));
      await dispatch(updateOfferContact(new DealContact()));
      await dispatch(
        updateCurrentOffer({
          new_customer: true,
        })
      );
    }
    return true;
  };

  return (
    <WizardSectionCustomer
      serviceName={translate("DEAL_BUSINESS_AREA")}
      isNextHidden={
        (businessAreaSelected[0] === EKONOMI_BUSINESS_AREA_LABEL &&
          !subServiceSelected.length) ||
        !businessAreaSelected.length
      }
      onBeforeNext={() => handleOnBeforeNext()}
      nextLabel={getNextButtonLabel()}
      predefinedNextRoute={getPredefinedRoute()}
      backLabel="BACK_TO_CREATE_NEW"
      customBackButtonRoute={AppRouteHelper.getCreateNew()}
      subtitles={[translate("REGISTER_CUSTOMER_NO_BUSINESS_AREA")]}
      loadingStatus={
        deals.dealOptions.isLoading
          ? LoadingStatusEnum.PENDING
          : LoadingStatusEnum.IDLE
      }
    >
      <Row className="minHeight-100">
        <Col xl={2} lg={1} className="pt-lg m-auto">
          <div className="horizontal-divider " />
        </Col>
        <Col xl={8} lg={10} className="m-auto">
          <div className="d-flex flex-wrap justify-content-center fg-4">
            {dealsBusinessAreas?.options
              .filter((o) => !EXCLUDE_BUSINESS_AREAS.includes(o.label))
              .map((businessArea) => {
                return (
                  <Picker
                    key={businessArea.label}
                    className="mx-sm"
                    excludingGroups
                    checked={businessAreaSelected[0] === businessArea.label}
                    onToggle={() => selectBusinessArea(businessArea.label)}
                    name={businessArea.label}
                    toggles={getBusinessAreaToggles(businessArea.label)}
                    description={ts(`${businessArea.label}_DESCRIPTION`)}
                  />
                );
              })}
          </div>
        </Col>
        <Col xl={2} lg={1} className="pt-lg m-auto">
          <div className="horizontal-divider" />
        </Col>
      </Row>
    </WizardSectionCustomer>
  );
}
