import { Fragment } from "react";
import { Spinner } from "@fluentui/react-components";
import {
  ChevronRight20Regular,
  ErrorCircle24Regular,
} from "@fluentui/react-icons";

import { useTranslation } from "hooks";
import { LoadingStatus } from "state";
import { LoadingStatusEnum } from "models/enums/LoadingStatus.enum";
import { SpinnerSize } from "components/spinner";

export type Breadcrumb = {
  title: string;
  url?: string;
};

type ContentHeaderProps = {
  title?: string;
  headerShrink?: boolean;
  loadingStatus?: LoadingStatus | undefined;
  subtitles?: string[];
  breadcrumbs?: Breadcrumb[];
  hasDivider?: boolean;
  counter?: number;
  selectionCounter?: number;
  selectionCounterLabel?: string;
  content?: any; // The only content allowed in headers which is also aligned to the right
};

export function ContentHeader({
  title = "",
  loadingStatus,
  subtitles,
  headerShrink,
  hasDivider,
  breadcrumbs = [],
  counter,
  selectionCounter,
  selectionCounterLabel,
  content = null,
}: ContentHeaderProps) {
  if (!title && breadcrumbs.length === 0) {
    console.warn("ContentHeader.tsx: You need at least a title or breadcrumbs");
  } else if (title && breadcrumbs.length > 0) {
    console.warn(
      "ContentHeader.tsx: You need to specify either a title or breadcrumbs"
    );
  } else if (breadcrumbs.length > 0 && counter !== undefined) {
    console.warn(
      "ContentHeader.tsx: You can't have a counter when using breadcrumbs"
    );
  } else if (selectionCounter !== undefined && counter === undefined) {
    console.warn(
      "ContentHeader.tsx: You can't have a selectionCounter without a counter"
    );
  } else if (selectionCounterLabel && selectionCounter === undefined) {
    console.warn(
      "ContentHeader.tsx: You can't have a selectionCounterLabel without a selectionCounter"
    );
  }

  const { t } = useTranslation();

  const renderTitle = (tempTitle: string) => (
    <div key="title" className="contentTitleWrapper d-flex align-items-center">
      <h3 className="contentHeader--title">{tempTitle}</h3>
      {counter !== undefined && (
        <span className="contentHeader--counter ml-md">({counter})</span>
      )}
      {selectionCounter !== undefined && (
        <>
          <div className="vertical-divider mx-md" />
          <span className="contentHeader--selectionCounter">
            {selectionCounterLabel ?? t("SELECTED")} {selectionCounter}
          </span>
        </>
      )}
    </div>
  );

  return (
    <div className="contentHeader d-flex justify-content-between">
      <div className={`w-100 ${headerShrink ? "flex-shrink-2" : ""}`}>
        <div className="contentHeaderTitle">
          {title && renderTitle(title)}

          {!title &&
            breadcrumbs.map((breadcrumb, index) => {
              if (index === 0 || breadcrumbs.length === 1) {
                return renderTitle(breadcrumb.title);
              }

              return (
                <Fragment key={`${breadcrumb.title}-breadcrumb`}>
                  <div>
                    <ChevronRight20Regular />
                  </div>

                  <div className="d-flex">
                    <h4 className="sub-h1 m-0">{breadcrumb.title}</h4>
                  </div>
                </Fragment>
              );
            })}

          {loadingStatus === LoadingStatusEnum.PENDING && (
            <div>
              <Spinner size={SpinnerSize.Tiny} />
            </div>
          )}

          {loadingStatus === LoadingStatusEnum.FAILED && (
            <div>
              <ErrorCircle24Regular className="text-color-red" />
            </div>
          )}
        </div>
        <div className="pt-sm pl-sm">
          {subtitles?.length &&
            subtitles.map((subtitle) => (
              <span
                key={subtitle}
                className="contentSubHeader"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: subtitle }}
              />
            ))}
        </div>
      </div>
      {hasDivider && <div className="vertical-divider" />}
      {content ? (
        <div className="contentHeader--content w-full d-flex w-100 h-100">
          {content}
        </div>
      ) : null}
    </div>
  );
}
