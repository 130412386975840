/* eslint-disable camelcase */
import { Label } from "@fluentui/react-components";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Fade, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ChevronRight20Regular } from "@fluentui/react-icons";

import { Dropdown } from "components/dropdown";
import Modal from "components/modal";
import BreadcrumbV9, {
  BreadcrumbDefinitionItem,
} from "components/breadcrumbV2";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import PendingChangesModal from "components/modal/PendingChangesModal";
import Switch from "components/switch";
import { useTranslation } from "hooks/use-translate";
import { getYearsOptions } from "libs/date/date-format";
import { getMonthsOfOneYear } from "libs/date/generate-month";
import { isEqualSSN } from "libs/is-equal-ssn";
import { uniqByFilter } from "libs/uniqe-filter";
import { LoadingStatusEnum } from "models/enums/LoadingStatus.enum";
import {
  getServiceCategoryFromEconomyServiceArea,
  getFilteredAndSortedServices,
  Service,
  ServiceCategory,
  ServiceGroup,
} from "models/offer/ServiceLine";
import { TaxObjectDetails } from "models/offer/TaxObject";
import { TaxObjectService } from "models/offer/TaxObjectService";
import { RootState } from "state";
import { updateCurrentOffer } from "state/offer/offersSlice";
import {
  sendOfferForCalculation,
  updateCustomerIncomeTaxData,
} from "state/offer/offersThunks";
import { useRoutingForOffer } from "views/offer/components/wizardSection/useRoutingForOffer";
import { WizardSection } from "views/offer/components/wizardSection";
import ServiceDetailsHeader from "views/offer/wizard/components/service-details-header";
import { TaxObjectForm } from "views/offer/wizard/IncomeTax/TaxObjectForm";
import { TaxObjectsList } from "views/offer/wizard/IncomeTax/TaxObjectsList";
import {
  getNextButtonText,
  OfferRouteHelper,
} from "views/offer/wizard/offerRoutes";
import ServicesFormData from "views/offer/wizard/Services/ServicesFormData";
import { SERVICE_GROUP_SMALL_BUSINESS_EF } from "views/offer/wizard/consts/offer-contst";
import { useGetBreadcrumbs } from "views/offer/components/wizardSection/useGetBreadcrumbs";
import { ServicesContext } from "views/offer/wizard/Services/ServicesContextProvider";
import "../Services/ServiceForm.scss";

type SmallBusinessForm = ServicesFormData & {
  yearEndYear: string | null;
  yearEndMonth: string;
  services: Service[];
};

interface SmallBusinessProps {
  serviceLine: string;
  serviceGroup: string;
  serviceCategory: string;
}

function SmallBusiness({
  serviceLine,
  serviceGroup,
  serviceCategory,
}: SmallBusinessProps) {
  const { t: translate, ts } = useTranslation();
  const { getBreadcrumbs } = useGetBreadcrumbs();
  const navigate = useNavigate();
  const { edit } = useParams<{ edit: string }>();
  const dispatch = useDispatch();
  const location = useLocation();
  const { updateServiceGroups, serviceGroups, updateTaxObjects, taxObjects } =
    useContext(ServicesContext);

  const serviceAreas = useSelector(
    (state: RootState) => state.offers.offerTemplate.data?.service_areas
  );
  const user = useSelector((state: RootState) => state.users.currentUser);

  const [showTaxObjects, setShowTaxObjects] = useState(false);
  const [showSmallBusiness, setShowSmallBusiness] = useState(true);

  const { data: currentOffer, status: loadingStatus } = useSelector(
    (state: RootState) => state.offers.currentOffer
  );

  const taxObjectsBackBtnlabel =
    serviceGroup === SERVICE_GROUP_SMALL_BUSINESS_EF
      ? "TAX_OBJECTS.BUTTON.GO_BACK_TO_SMALL_BUSINESS_EF"
      : "TAX_OBJECTS.BUTTON.GO_BACK_TO_SMALL_BUSINESS";

  if (!currentOffer) {
    throw new Error("No active offer");
  }

  if (!serviceAreas) {
    throw new Error("Missing service areas");
  }
  const { service_areas: currentServiceAreas } = currentOffer;

  useEffect(() => {
    const updatedServiceGroup = serviceGroups.find(
      (group) => group.name === serviceGroup
    );

    const updatedTaxObjects =
      serviceGroup === SERVICE_GROUP_SMALL_BUSINESS_EF
        ? taxObjects.smallBusinessEF
        : taxObjects.smallBusiness;

    if (updatedServiceGroup && updatedTaxObjects) {
      const newServiceAreas = ServiceGroup.updateServiceGroupInServiceArea(
        currentServiceAreas,
        updatedServiceGroup
      );
      dispatch(
        updateCurrentOffer({
          service_areas: newServiceAreas,
          tax_objects: updatedTaxObjects,
        })
      );
      const updatedCategory = updatedServiceGroup.service_categories.find(
        (category) => category.name === serviceCategory
      );
      if (updatedCategory) {
        setSmallBusinessData({
          services: updatedCategory.services,
          startDate: updatedCategory.start_date,
          endDate: updatedCategory.end_date,
          projectManager: updatedCategory.project_manager,
          yearEndYear: updatedCategory.year_end_year,
          yearEndMonth: updatedCategory.year_end_month ?? "12",
          frequency: updatedCategory.frequency,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, serviceGroups]);

  const routing = useRoutingForOffer(location.pathname, currentOffer);
  const initialCurrentOffer = useRef(currentOffer);

  const [isDirty, setIsDirty] = useState(false);
  const [modalOpen, setOpenModal] = useState(false);

  const yearEndSelectionDisabled =
    serviceGroup === SERVICE_GROUP_SMALL_BUSINESS_EF;

  const smallBusinessTemplate =
    getServiceCategoryFromEconomyServiceArea(
      serviceLine,
      serviceGroup,
      serviceCategory,
      serviceAreas
    ) ?? ({} as ServiceCategory);

  smallBusinessTemplate.services = getFilteredAndSortedServices(
    smallBusinessTemplate
  );

  const smallBusinessInCurrentOffer =
    getServiceCategoryFromEconomyServiceArea(
      serviceLine,
      serviceGroup,
      serviceCategory,
      currentServiceAreas
    ) ?? ({} as ServiceCategory);

  const { customer } = currentOffer;

  if (!customer) {
    throw new Error("Missing customer");
  }
  const customerTaxObjects = customer.tax_objects ?? [];
  const dealTaxObjects = currentOffer?.tax_objects ?? [];
  const allIncomeTaxServices = Service.getIncomeTaxServicesFromSmallBusiness(
    smallBusinessTemplate.services
  );

  const customerTaxObject =
    customerTaxObjects.find((ctObj) =>
      isEqualSSN(ctObj.social_security_number, customer.org_number)
    ) ?? TaxObjectDetails.getTaxObjectFromCustomerData(customer, user);

  // return a list of uniq headers in the services array
  const serviceHeaders = uniqByFilter(
    smallBusinessTemplate.services.map((s) => s.header)
  );

  const servicesForHeader = (header: string) =>
    Service.sortServices(
      smallBusinessTemplate.services.filter((s) => s.header === header)
    );
  const headersWithSortedServices = serviceHeaders.map((header) => ({
    header,
    services: servicesForHeader(header),
  }));

  const {
    start_date,
    end_date,
    project_manager,
    frequency,
    services: activeServices,
    approved_by_email,
    year_end_year,
    year_end_month,
  } = smallBusinessInCurrentOffer;

  const [smallBusinessData, setSmallBusinessData] = useState<SmallBusinessForm>(
    {
      services: activeServices,
      startDate: start_date !== "" ? start_date : getMonthsOfOneYear()[0],
      endDate: end_date,
      projectManager: project_manager !== "" ? project_manager : user.email,
      yearEndYear: year_end_year,
      yearEndMonth: year_end_month || "12",
      frequency: frequency ?? smallBusinessTemplate.frequency,
    }
  );

  // Store income tax services and objects

  /**
   * getIncomeTaxServices with updated units
   * @param newTaxObjects an array of the selected taxObjectServices
   * @param allServices list of all services from template
   * @returns Service[]
   */
  const getIncomeTaxServicesWithUnits = (
    newTaxObjects: TaxObjectService[],
    allServices: Service[]
  ): Service[] =>
    newTaxObjects.reduce((newServices, taxService, _, selectedTaxServices) => {
      if (
        !newServices.some(
          (newTaxService) =>
            newTaxService.service_matrix_id === taxService.service_matrix_id
        )
      ) {
        const service = allServices.find(
          (s) => s.service_matrix_id === taxService.service_matrix_id
        );

        if (!service) {
          throw new Error("Selected service missing in template service");
        }

        const serviceUnites = selectedTaxServices.filter(
          (stObj) => stObj.service_matrix_id === taxService.service_matrix_id
        ).length;

        const newService: Service = { ...service, units: serviceUnites };

        return [...newServices, newService];
      }
      return newServices;
    }, [] as Service[]);

  function saveSmallBusinessDataToServiceLine(services?: Service[]) {
    // Save the data to the service line in deal object
    const newSmallBusiness: ServiceCategory = {
      ...smallBusinessInCurrentOffer,
      services: services ?? activeServices,
      project_manager: smallBusinessData.projectManager,
      start_date: smallBusinessData.startDate,
      frequency: smallBusinessData.frequency,
      year_end_year: smallBusinessData.yearEndYear,
      year_end_month: smallBusinessData.yearEndMonth,
    };
    const newServiceAreas = ServiceCategory.updateServiceCategoryInServiceArea(
      currentServiceAreas,
      newSmallBusiness
    );

    setIsDirty(true);
    dispatch(updateCurrentOffer({ service_areas: newServiceAreas }));
  }

  async function saveSmallBusinessHeaderDataToServiceLine(
    smallBusinessDataForm?: SmallBusinessForm
  ) {
    const newSmallBusinessData: ServiceCategory = {
      ...smallBusinessInCurrentOffer,
      project_manager:
        smallBusinessDataForm?.projectManager ??
        smallBusinessData.projectManager,
      start_date:
        smallBusinessDataForm?.startDate ?? smallBusinessData.startDate,
      frequency:
        smallBusinessDataForm?.frequency ?? smallBusinessData.frequency,
      year_end_year:
        smallBusinessDataForm?.yearEndYear ?? smallBusinessData.yearEndYear,
      year_end_month:
        smallBusinessDataForm?.yearEndMonth ?? smallBusinessData.yearEndMonth,
    };

    const newServiceAreas = ServiceCategory.updateServiceCategoryInServiceArea(
      currentServiceAreas,
      newSmallBusinessData
    );

    setIsDirty(true);
    await dispatch(updateCurrentOffer({ service_areas: newServiceAreas }));
  }

  function isSelectedService(service: Service): boolean {
    return activeServices.some(
      (selectedServices) =>
        selectedServices.service_matrix_id === service.service_matrix_id
    );
  }

  async function saveIncomeTaxFormData(
    taxObject: TaxObjectDetails,
    taxServices: TaxObjectService[]
  ) {
    // Remove existing taxServices for this ssn/org
    const filteredTaxObjects = dealTaxObjects.filter(
      (dealTaxObject) =>
        !isEqualSSN(
          dealTaxObject.customer_tax_object.social_security_number,
          taxObject.social_security_number
        )
    );

    // Merge the new services for ssn with the existing ones
    const newTaxObjectsServices = [...filteredTaxObjects, ...taxServices];

    setIsDirty(true);
    // Save the new tax_objects_services in deal
    await dispatch(
      updateCurrentOffer({
        tax_objects: newTaxObjectsServices ?? [],
      })
    );

    // Update the customer tax object based on the newTaxObjectServices
    dispatch(
      updateCustomerIncomeTaxData({
        taxDetailsFormData: taxObject,
        newTaxObjectsServices,
      })
    );

    const incomeTaxServicesWithUnits = getIncomeTaxServicesWithUnits(
      newTaxObjectsServices,
      allIncomeTaxServices
    );

    // Remove existing services related to income tax
    const filteredActiveServices = activeServices.filter(
      (activeService) => !activeService.pog_income_tax
    );

    const newSmallBusinessServices = [
      ...filteredActiveServices,
      ...incomeTaxServicesWithUnits,
    ];

    saveSmallBusinessDataToServiceLine(newSmallBusinessServices);
  }

  const isFormDisabled =
    !!approved_by_email && loadingStatus === LoadingStatusEnum.PENDING;
  function renderToggles(services: Service[]): React.ReactNode {
    return services.map((service) => (
      <Switch
        disabled={isFormDisabled || service.input_is_disabled}
        className="m-1"
        key={`${service.service_matrix_id}yearEndService`}
        label={service.name}
        checked={isSelectedService(service)}
        onToggleMethod={(_, { checked }) => {
          if (isSelectedService(service) && !checked) {
            saveSmallBusinessDataToServiceLine(
              activeServices.filter(
                (s) => s.service_matrix_id !== service.service_matrix_id
              )
            );
          } else {
            const activeService: Service = { ...service, units: 1 };
            saveSmallBusinessDataToServiceLine([
              ...activeServices,
              activeService,
            ]);
          }
        }}
      />
    ));
  }

  function updateServiceCategoryData(
    formHeaderData: Partial<SmallBusinessForm>
  ) {
    const newSmallBusinessFormData = {
      ...smallBusinessData,
      ...formHeaderData,
    };
    setSmallBusinessData(newSmallBusinessFormData);
    saveSmallBusinessHeaderDataToServiceLine(newSmallBusinessFormData);
  }

  function hasFiscalYearSelected(): boolean {
    return !!smallBusinessData.yearEndYear;
  }

  const handleOnBeforePrevious = () => {
    if (isDirty) {
      setOpenModal(isDirty);
      return;
    }

    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
  };

  const handleDiscard = () => {
    dispatch(updateCurrentOffer(initialCurrentOffer.current));
    setIsDirty(false);
    setOpenModal(false);
    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
    if (edit !== undefined) {
      navigate(-1);
    }
  };

  const handleSave = async () => {
    const serviceGroupToUpdate =
      currentOffer.service_areas[0].service_lines[0].service_groups.find(
        (group) => group.name === serviceGroup
      );

    await saveSmallBusinessHeaderDataToServiceLine();
    await dispatch(sendOfferForCalculation());

    if (serviceGroupToUpdate) {
      updateServiceGroups(serviceGroupToUpdate);
    }
    const taxObjectsUpdate =
      serviceGroup === SERVICE_GROUP_SMALL_BUSINESS_EF
        ? { smallBusinessEF: currentOffer.tax_objects }
        : { smallBusiness: currentOffer.tax_objects };

    updateTaxObjects(taxObjectsUpdate);

    setIsDirty(false);
    setOpenModal(false);
    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
    if (edit !== undefined) {
      navigate(-1);
    }
  };

  const serviceFormHeader = (
    <ServiceDetailsHeader
      startDateOnChange={(value) => {
        updateServiceCategoryData({
          ...smallBusinessData,
          startDate: value,
        });
      }}
      startDate={smallBusinessData.startDate}
      projectManagerOnChange={(personId) => {
        updateServiceCategoryData({
          ...smallBusinessData,
          projectManager: personId ?? "",
        });
      }}
      projectManager={smallBusinessData.projectManager}
      frequencyOnChange={(value) => {
        updateServiceCategoryData({
          ...smallBusinessData,
          frequency: value,
        });
      }}
      frequencySelectionDisabled={!!smallBusinessTemplate.frequency}
      frequency={smallBusinessData.frequency}
      yearEndData={smallBusinessData}
      yearEndInCurrentOffer={smallBusinessInCurrentOffer}
      yearEndSelectionDisabled={yearEndSelectionDisabled}
      updateServiceCategoryData={(value) => {
        updateServiceCategoryData({
          ...smallBusinessData,
          yearEndMonth: value,
        });
      }}
    />
  );

  const serviceFormContent = (
    <>
      <PendingChangesModal
        isOpen={modalOpen}
        onCancel={() => setOpenModal(false)}
        onSave={() => handleSave()}
        onDiscard={() => handleDiscard()}
        isValid={hasFiscalYearSelected()}
        isLoading={loadingStatus === "pending"}
      />
      <Fade
        in={showSmallBusiness}
        mountOnEnter
        unmountOnExit
        onExited={() => setShowTaxObjects(true)}
      >
        <div>
          <Row>
            <Col md={1} />
            <Col>
              <div className="d-flex flex-row flex justify-content-end">
                <div className="p-md">
                  <span className="text-gray">
                    {translate("TAX_OBJECTS_COUNT", [
                      customerTaxObjects.length.toString(),
                    ])}
                  </span>
                </div>
                <div className="m-md vertical-divider" />
                <div
                  className="p-md d-flex pog-link cursor-pointer align-items-center"
                  onClick={() => setShowSmallBusiness(false)}
                >
                  <span>{translate("TAX_OBJECTS_TITLE")}</span>
                  <ChevronRight20Regular className="ml-sm" />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="py-xl">
            <Col md={3} className=" mb-sm">
              <Row className="pt-xs p-xs">
                <Col>
                  <Label className="p-0">{translate("FISCAL_YEAR")} *</Label>
                  <Dropdown<string>
                    disabled={!!smallBusinessInCurrentOffer.approved_by_email}
                    className="px-0"
                    placeholder={translate("DROPDOWN_PLACEHOLDER")}
                    onChange={(value) => {
                      updateServiceCategoryData({
                        ...smallBusinessData,
                        yearEndYear: value,
                      });
                      setIsDirty(true);
                    }}
                    options={getYearsOptions()}
                    value={smallBusinessData.yearEndYear || undefined}
                    errorMessage={
                      hasFiscalYearSelected()
                        ? undefined
                        : translate("SELECT_CORRECT_FISCAL_YEAR")
                    }
                  />
                </Col>
              </Row>
            </Col>

            <Col md={1} className="d-flex justify-content-center">
              <div className="d-flex">
                <div className="vertical-divider" />
              </div>
            </Col>

            {headersWithSortedServices.map(
              (headerData, index, allServiceHeaders) => {
                const { header } = headerData;
                const { services } = headerData;
                const isLastServiceHeader =
                  index + 1 === allServiceHeaders.length;
                return (
                  <React.Fragment key={header}>
                    <Col>
                      <Row>
                        <Col>
                          <label className="fw-bold">{header}</label>
                        </Col>
                      </Row>
                      {services.some((s) => s.pog_income_tax) ? (
                        <TaxObjectForm
                          isForCompany
                          onSave={(taxObject, taxObjectServices) =>
                            saveIncomeTaxFormData(taxObject, taxObjectServices)
                          }
                          dealTaxServices={currentOffer.tax_objects}
                          taxObject={customerTaxObject}
                          showInModal={false}
                          isFormDisabled={isFormDisabled}
                          incomeTaxServicesTemplate={Service.getIncomeTaxServicesForCompany(
                            smallBusinessTemplate.services
                          )}
                        />
                      ) : (
                        <div className="pt-lg">{renderToggles(services)}</div>
                      )}
                    </Col>

                    {!isLastServiceHeader && (
                      <Col md={1} className="d-flex justify-content-center">
                        <div className="d-flex">
                          <div className="vertical-divider" />
                        </div>
                      </Col>
                    )}
                  </React.Fragment>
                );
              }
            )}
          </Row>
        </div>
      </Fade>

      <Fade
        in={showTaxObjects}
        appear
        mountOnEnter
        unmountOnExit
        onExited={() => setShowSmallBusiness(true)}
      >
        <div>
          <TaxObjectsList
            customerTaxObjects={customerTaxObjects}
            taxServiceObjects={currentOffer.tax_objects}
            incomeTaxServices={allIncomeTaxServices}
            onDismiss={() => setShowTaxObjects(false)}
            onSave={(taxObject, taxObjectServices) =>
              saveIncomeTaxFormData(taxObject, taxObjectServices)
            }
            label={taxObjectsBackBtnlabel}
          />
        </div>
      </Fade>
    </>
  );

  const onBeforeNext = async () => {
    await saveSmallBusinessHeaderDataToServiceLine();
    await dispatch(sendOfferForCalculation());
    return true;
  };

  const renderServiceForm = () => (
    <WizardSection
      onBeforeNext={onBeforeNext}
      isNextHidden={!hasFiscalYearSelected()}
      onBeforePrevious={handleOnBeforePrevious}
      loadingStatus={loadingStatus}
      hideNavigation={showTaxObjects}
      titleBreadCrumb={
        showTaxObjects ? translate("TAX_OBJECT_LABEL") : undefined
      }
      hasDivider
      content={serviceFormHeader}
    >
      {serviceFormContent}
    </WizardSection>
  );

  return edit !== undefined ? (
    <Modal
      className="service-modal"
      isOpen
      onDismiss={() => {
        if (isDirty) {
          setOpenModal(isDirty);
          return;
        }
        setOpenModal(false);
        navigate(-1);
      }}
      size="large"
      header={
        <ModalHeader
          headerTitleContent={
            <div className="modal-title d-flex align-items-center">
              <BreadcrumbV9
                className="ml-sm"
                items={
                  getBreadcrumbs(
                    "",
                    true,
                    undefined,
                    showTaxObjects ? translate("TAX_OBJECT_LABEL") : undefined
                  ) as BreadcrumbDefinitionItem[]
                }
              />
            </div>
          }
        />
      }
      footer={
        <ModalFooter
          labelSubmit={ts(
            getNextButtonText(location.pathname, currentOffer, undefined)
          )}
          onSave={() => {
            onBeforeNext();
            navigate(OfferRouteHelper.getSummary());
          }}
          isDisabled={!hasFiscalYearSelected()}
          isLoading={loadingStatus === LoadingStatusEnum.PENDING}
        />
      }
    >
      <>
        <div className="contentHeader">{serviceFormHeader}</div>
        {serviceFormContent}
      </>
    </Modal>
  ) : (
    renderServiceForm()
  );
}

export default SmallBusiness;
