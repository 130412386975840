import { useSelector } from "react-redux";
import { getDefaultTranslator } from "../i18n";
import { dateLanguageFormatter } from "../libs/date/date-format";
import { RootState } from "../state";

const translator = getDefaultTranslator();

/**
 *
 * @returns {
 * t: (translationKey: TranslationKey | string) => string,}
 */
export function useTranslation() {
  const i18n = useSelector(
    (state: RootState) => state.i18n,
    (left, right) => {
      return left.language === right.language;
    }
  );

  const t = translator.getTranslator(i18n.language).byKey;
  const ts = translator.getTranslator(i18n.language).byString;
  const c = translator.getCounting(i18n.language);

  return {
    t,
    ts,
    c,
    language: i18n.language,
    dateFormatter: dateLanguageFormatter(i18n.language),
  };
}
