import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ChevronUp24Regular } from "@fluentui/react-icons";

import { ServiceInstance } from "models/activities/activity";
import { Customer } from "models/customer";
import { Button } from "components/button";
import { ServiceBoxCompletedV2 } from "./ServiceBoxCompletedV2";
import UnfinishedActivitiesList from "./UnfinishedActivitiesList";
import { ServiceBoxV2 } from "./ServiceBoxV2";

type ServiceBoxListProps = {
  servicesList: ServiceInstance[];
  maxNumberServices?: number;
  customer: Customer;
  isExpanded: boolean;
  isCompleted: boolean;
  unfinishedActivityServices: ServiceInstance[];
};

export default function ServiceBoxListV2({
  servicesList,
  maxNumberServices,
  customer,
  isExpanded,
  isCompleted,
  unfinishedActivityServices,
}: ServiceBoxListProps) {
  const [sortedServicesListByRecurrence, setSortedServicesListByRecurrence] =
    useState<ServiceInstance[]>([]);
  const [serviceListTrimmed, setServiceListTrimmed] = useState<
    ServiceInstance[]
  >([]);
  const [showServices, setShowServices] = useState(serviceListTrimmed);
  const [showingAllServices, setShowingAllServices] = useState(false);
  const [showUnfinishedServices, setShowUnfinishedServices] = useState(false);
  const [numberOfServicesToShow, setNumberOfServicesToShow] = useState(0);

  useEffect(() => {
    function priorityNumberOfRecurrence(recurrence: string) {
      switch (recurrence) {
        case "WEEKLY":
          return 1;
        case "MONTHLY":
          return 2;
        case "QUARTERLY":
          return 3;
        case "YEARLY":
          return 4;
        default:
          return 0;
      }
    }

    const sortedServicesListByRecurrenceObj = servicesList
      .sort((a, b) => (a.week > b.week ? 1 : -1))
      .sort(
        (a, b) =>
          priorityNumberOfRecurrence(a.recurrence) -
          priorityNumberOfRecurrence(b.recurrence)
      );
    setSortedServicesListByRecurrence(sortedServicesListByRecurrenceObj);

    if (!showingAllServices) {
      const serviceListTrimmedObj = maxNumberServices
        ? servicesList.slice(0, maxNumberServices)
        : sortedServicesListByRecurrence;
      setServiceListTrimmed(serviceListTrimmedObj);
      setShowServices(serviceListTrimmedObj);
    }
    if (maxNumberServices) {
      const servicesCount =
        servicesList.length - maxNumberServices >= 0
          ? servicesList.length - maxNumberServices
          : 0;
      const unfinishedCount = isCompleted
        ? 0
        : unfinishedActivityServices.length;
      setNumberOfServicesToShow(servicesCount + unfinishedCount);
    }
  }, [
    servicesList,
    maxNumberServices,
    sortedServicesListByRecurrence,
    showingAllServices,
    isCompleted,
    unfinishedActivityServices.length,
  ]);

  const maxDisplayServicesExceeded =
    maxNumberServices &&
    (sortedServicesListByRecurrence.length > maxNumberServices ||
      (!isCompleted && unfinishedActivityServices.length > 0));

  return (
    <Row className="py-xs">
      {!isExpanded && isCompleted && (
        <ServiceBoxCompletedV2 servicesList={servicesList} />
      )}
      {(isExpanded || !isCompleted) && (
        <>
          <Col md={11}>
            {showServices.map((service) => (
              <ServiceBoxV2
                key={service.service_box_id}
                service={service}
                customerNumber={customer.customer_number}
              />
            ))}
            {showUnfinishedServices && (
              <UnfinishedActivitiesList
                unfinishedActivityServices={unfinishedActivityServices}
                customerNumber={customer.customer_number}
                showUnfinishedServices
              />
            )}
          </Col>
          <Col
            md={1}
            className={`d-flex ${
              showingAllServices
                ? "align-items-end pb-sm"
                : "align-items-start pt-lg"
            } justify-content-center px-0 mx-0`}
          >
            {maxDisplayServicesExceeded && maxNumberServices && (
              <Button
                variant="outline-outline"
                className="mx-0 px-0 text-primary"
                size="md"
                onClick={(event) => {
                  event.stopPropagation();
                  if (showingAllServices) {
                    setShowServices(serviceListTrimmed);
                    setShowUnfinishedServices(false);
                  } else {
                    setShowServices(servicesList);
                    setShowUnfinishedServices(!isCompleted && true);
                  }

                  setShowingAllServices(!showingAllServices);
                }}
              >
                <span
                  className={
                    !isCompleted && unfinishedActivityServices.length > 0
                      ? "color-redLight"
                      : "color-gridLinkColor"
                  }
                >
                  {showingAllServices ? (
                    <ChevronUp24Regular
                      className={
                        !isCompleted && unfinishedActivityServices.length > 0
                          ? "color-redLight"
                          : "color-gridLinkColor"
                      }
                    />
                  ) : (
                    `+ ${numberOfServicesToShow}`
                  )}
                </span>
              </Button>
            )}
          </Col>
        </>
      )}
    </Row>
  );
}
