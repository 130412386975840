/* eslint-disable max-classes-per-file */

import { UUID, isUUID } from "libs/uuid";

// Note: These roles should be added to the AAD App
// Add roles: AAD -> App Registrations -> [Name of app] -> App roles -> Create app role
// Assign role: AAD -> Enterprise Registrations -> [Name of app] -> Users and groups -> Add user/group
export enum UserRoles {
  USERS_PILOT = "Users.Pilot",
  USERS_BO_FILTER_PILOT = "Features.BO.Filter",
  USERS_SUPER = "Users.Super",
  USERS_SUPPORT = "Users.Support",
  USERS_DELIVERY_PLANNER_PILOT = "Users.DeliveryPlanner.Pilot",
  USERS_DELIVERY_PLANNER_ADMIN = "Users.DeliveryPlanner.Admin",
  USERS_CENTRAL_SALES = "Users.CentralSales",
  FEATURES_CURRENT_ACCOUNTING = "Features.CurrentAccounting",
  FEATURES_DEFERRED_PAYMENT = "Features.DeferredPayment",
  FEATURES_REPORTS = "Features.Reports",
  FEATURES_CUSTOMER_MANAGEMENT = "Features.CustomerManagement",
}

export class User {
  id: string | UUID;

  graphId: string;

  email: string;

  title: string;

  firstName: string;

  lastName: string;

  username?: string;

  systemRoles?: string[];

  azureGroups?: AzureGroup[];

  roles?: UserRoles[];

  socialSecurityNumber?: string;

  isEmployee() {
    return !isUUID(this.id);
  }
}

export class AzureGroup {
  id: string;

  description: string;

  displayName: string;

  mail: string;

  mailNickname: string;

  onPremisesSamAccountName: string;
}

export interface UserSnapshot {
  id: string;
  name?: string;
}
