import { Link20Regular, LinkDismiss20Regular } from "@fluentui/react-icons";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { useAuth } from "auth/use-auth";
import { RHFInput } from "components/input/RHFInput";
import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { TextButton } from "components/textButton/textButton";
import { useTranslation } from "hooks/use-translate";
import { TranslationKey } from "i18n";
import { isValidOrganizationNumberOrSSN } from "libs/is-valid-organization-number-or-ssn";
import { CapegoFortnoxConnection } from "models/CapegoFortnoxConnection";
import {
  useDeleteCapegoRemoveFortnoxMutation,
  useGetCapegoFortnoxStatusQuery,
  usePostCapegoAddFortnoxMutation,
} from "services/ludvigApi";
import { appendToastMessage } from "state/notifications";
import { AppDispatch } from "state/use-app-redux";
import { CapegoIdFormField } from "./CapegoIdFormField";

type CapegoFortnoxConnectionModalProps = {
  orgNumber: string;
  onDismiss: () => void;
  isOpen?: boolean;
};

export function CapegoFortnoxConnectionModal({
  orgNumber,
  onDismiss,
  isOpen = true,
}: CapegoFortnoxConnectionModalProps) {
  const { t } = useTranslation();

  const { user } = useAuth();

  const {
    data: capegoFortnoxStatusData,
    isLoading: isLoadingCapegoFortnoxStatus,
  } = useGetCapegoFortnoxStatusQuery(orgNumber);

  const [type, setType] = useState<"connect" | "disconnect">("connect");

  const {
    unregister,
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    watch,
    control,
  } = useForm<CapegoFortnoxConnection>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: new CapegoFortnoxConnection({ orgno: orgNumber }),
  });

  const watchOrgNo = watch("orgno");

  const dispatch = useDispatch<AppDispatch>();

  const [postCapegoAddFortnox, { isLoading: isPostCapegoAddFortnoxLoading }] =
    usePostCapegoAddFortnoxMutation();

  const [deleteCapegoRemoveFortnox, { isLoading: isGetCapegoFortnoxStatus }] =
    useDeleteCapegoRemoveFortnoxMutation();

  useEffect(() => {
    if (!capegoFortnoxStatusData) {
      return;
    }

    const capegoFortnoxConnectedItem = capegoFortnoxStatusData.find(
      (capegoFortnoxItem) => capegoFortnoxItem.connected
    );

    if (capegoFortnoxConnectedItem) {
      setType("disconnect");

      reset({
        capegoId: capegoFortnoxConnectedItem.capego_id,
        orgno: capegoFortnoxConnectedItem.orgno,
        fortnoxDbId: capegoFortnoxConnectedItem.fortnox_db_id,
      });
    }
  }, [capegoFortnoxStatusData, reset]);

  const toggleView = () => {
    setType((prevType) => {
      if (prevType === "connect") {
        unregister("fortnoxDbId");
      }

      return prevType === "connect" ? "disconnect" : "connect";
    });
  };

  const onSubmit = async (data: CapegoFortnoxConnection) => {
    if (!user) {
      return;
    }

    const body = { ...data, username: user.email };

    try {
      if (type === "connect") {
        await postCapegoAddFortnox(body).unwrap();
      } else if (type === "disconnect") {
        await deleteCapegoRemoveFortnox(body).unwrap();
      }

      dispatch(
        appendToastMessage("CAPEGOFORTNOX_CONNECTION_ADJUSTED", "success")
      );

      toggleView();
    } catch (error) {
      // Separate error handling because endpoints have different error responses
      if (type === "connect") {
        const { status, data: errorData } = error as FetchBaseQueryError;
        dispatch(
          appendToastMessage(
            `${status}_${errorData}` as TranslationKey,
            "error"
          )
        );
      } else if (type === "disconnect") {
        dispatch(appendToastMessage("SOMETHING_WENT_WRONG", "error"));
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      size="medium"
      isLoading={
        isLoadingCapegoFortnoxStatus ||
        isPostCapegoAddFortnoxLoading ||
        isGetCapegoFortnoxStatus
      }
      header={
        <ModalHeader
          headerTitleText={`${t(type === "connect" ? "CREATE" : "REMOVE")} ${t(
            "CAPEGOFORTNOX_CONNECTION"
          )}`}
        />
      }
      footer={
        <ModalFooter
          isDisabled={!isValid}
          onCancel={onDismiss}
          onSave={handleSubmit(onSubmit)}
          sendButtonIcon={
            type === "connect" ? <Link20Regular /> : <LinkDismiss20Regular />
          }
          labelSubmit={
            type === "connect"
              ? "CAPEGOFORTNOX_CONNECTION_CONNECT_CLIENT"
              : "CAPEGOFORTNOX_CONNECTION_DISCONNECT_CLIENT"
          }
        />
      }
    >
      <Row className="gy-3 px-md">
        <Col xs={12}>
          <label>{t("ORG_NUM")} *</label>
          <RHFInput
            placeholder={t("ORG_NUM")}
            errorMessage={errors?.orgno?.message}
            register={register("orgno", {
              required: t("ORGANIZATION_NUMBER_REQUIRED"),
              validate: (number) =>
                isValidOrganizationNumberOrSSN(number, true) ||
                t("INVALID_ORGNR_OR_SSN_MESSAGE"),
            })}
          />
        </Col>

        <Col xs={12}>
          <Controller
            name="capegoId"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <CapegoIdFormField
                  value={field.value}
                  orgNumber={watchOrgNo}
                  onChangeCapegoId={(capegoId: string) => {
                    field.onChange(capegoId);
                  }}
                />
              );
            }}
          />
        </Col>

        {type === "connect" && (
          <Col xs={12}>
            <label>{t("CAPEGOFORTNOX_CONNECTION_FORTNOX_ID")} *</label>
            <RHFInput
              placeholder={t("CAPEGOFORTNOX_CONNECTION_FORTNOX_ID")}
              errorMessage={errors?.fortnoxDbId?.message}
              register={register("fortnoxDbId", { required: true })}
            />
          </Col>
        )}

        <Col xs={12}>
          <TextButton onClick={toggleView}>
            {t(
              type === "disconnect"
                ? "CAPEGOFORTNOX_CONNECTION_TOGGLE_CONNECT"
                : "CAPEGOFORTNOX_CONNECTION_TOGGLE_DISCONNECT"
            )}
          </TextButton>
        </Col>
      </Row>
    </Modal>
  );
}
