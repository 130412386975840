import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { TextArea } from "components/textarea";
import { useTranslation } from "hooks";
import { useState } from "react";

type PendingChangesModalProps = {
  dealComment: string;
  onDiscard: () => void;
  onSaveComment: (comment: string) => void;
};

export default function DealCommentDialog({
  dealComment,
  onDiscard,
  onSaveComment,
}: PendingChangesModalProps) {
  const { t: translate } = useTranslation();

  const [comment, setComment] = useState(dealComment);

  const handleOnDiscard = () => {
    onDiscard();
  };

  return (
    <Modal
      className="top-modal"
      onDismiss={handleOnDiscard}
      isOpen
      header={<ModalHeader headerTitleText="COMMENT" />}
      footer={
        <ModalFooter
          onCancel={handleOnDiscard}
          onSave={() => onSaveComment(comment)}
          labelCancel="CANCEL"
          labelSubmit={
            dealComment
              ? "CREATE_NEW.CONTRACT.OFFER_TABLE.COMMENT_UPDATE"
              : "CREATE_NEW.CONTRACT.OFFER_TABLE.COMMENT_ADD"
          }
        />
      }
    >
      <div className="d-flex py-lg">
        <TextArea
          aria-required
          rows={5}
          className="text-area-size"
          placeholder={translate(
            "CREATE_NEW.CONTRACT.OFFER_TABLE.COMMENT_PLACEHOLDER"
          )}
          onChange={(e) => {
            setComment(e.target.value);
          }}
          value={comment}
        />
      </div>
    </Modal>
  );
}
