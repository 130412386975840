import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ArrowRight20Regular, Info20Regular } from "@fluentui/react-icons";
import { Col, Row } from "react-bootstrap";

import Modal from "components/modal";
import { ModalHeader } from "components/modal/ModalHeader";
import { ModalFooter } from "components/modal/ModalFooter";
import { RHFInput } from "components/input/RHFInput";
import { useTranslation } from "hooks/use-translate";

type FinalPriceForm = {
  finalPrice: number | "";
};

type FinalPriceModalProps = {
  finalPrice: number;
  setPriceModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handlePriceCancelModal: (price: number) => void;
  handleProceedToSummary: (price: number) => Promise<void>;
};

const MAX_PRICE = Number.MAX_SAFE_INTEGER;

export default function FinalPriceModal({
  finalPrice,
  setPriceModalOpen,
  handlePriceCancelModal,
  handleProceedToSummary,
}: FinalPriceModalProps) {
  const { t: translate } = useTranslation();

  const {
    register,
    formState: { errors, isValid },
    setValue,
    getValues,
    watch,
  } = useForm<FinalPriceForm>({
    mode: "all",
  });

  useEffect(() => {
    setValue("finalPrice", finalPrice || "");
  }, [finalPrice, setValue]);

  const price = watch("finalPrice");

  const numericInputValue = register("finalPrice", {
    validate: (value) => {
      if (value !== "") {
        const parsedValue = parseInt(value.toString(), 10);
        return parsedValue > 0 || translate("PRICE_TOO_LOW");
      }
    },
  });

  const handleOnCancel = () => {
    let getPrice = getValues("finalPrice");

    if (!getPrice) {
      getPrice = 0;
    }

    handlePriceCancelModal(getPrice);
  };

  const handleOnChange = (value: string) => {
    if (parseInt(value, 10) > MAX_PRICE) {
      setValue("finalPrice", getValues("finalPrice"), {
        shouldValidate: true,
      });
      return;
    }
    const finalPriceValue = value === "" ? "" : parseInt(value, 10);
    setValue("finalPrice", finalPriceValue, {
      shouldValidate: true,
    });
  };

  return (
    <Modal
      size="medium"
      onDismiss={() => setPriceModalOpen(false)}
      isOpen
      header={
        <ModalHeader
          headerTitleText="ESTIMATED_ANNUAL_PRICE"
          subheaderContent={
            <div className="pt-sm d-flex">
              <Info20Regular className="mx-sm text-color-blue" />
              <div>
                {translate("ENTER_ESTIMATED_ANNUAL_PRICE")}
                <br />
                {translate("MAKE_PRICE_CALCULATION_PART_1")}
                <a
                  href="https://intranet.ludvig.se/forsaljning/affarsmojligheter-uppdrag/prissimulering-ekonomi/"
                  rel="noreferrer"
                  target="_blank"
                >
                  {translate("MAKE_PRICE_CALCULATION_PART_2")}
                </a>
                .
              </div>
            </div>
          }
        />
      }
      footer={
        <ModalFooter
          labelCancel="SAVE_DRAFT_OFFER"
          onCancel={handleOnCancel}
          isCancelDisabled={!!price}
          cancelButtonTooltip={{
            content: "DRAFT_PRICE_BELOW_MIN",
            enabled: !!price,
          }}
          labelSubmit="PROCEED_TO_SUMMARY"
          onSave={() => handleProceedToSummary(price as number)}
          isDisabled={price === "" || !isValid}
          sendButtonIcon={<ArrowRight20Regular className="mr-sm" />}
        />
      }
    >
      <Row className="minHeight-100">
        <div className="d-flex align-items-center pb-lg">
          <div className="w-100">
            <Row className="text-center m-auto search-customer w-100 pt-lg">
              <Col md={3} className="m-auto">
                <div className="horizontal-divider" />
              </Col>
              <Col md={6}>
                <div className="text-left">
                  <label className="p-0 fw-bold">{translate("PRICE")} *</label>
                  <br />
                  <RHFInput
                    type="number"
                    placeholder={translate("ENTER_PRICE_ABOVE")}
                    min={0}
                    max={MAX_PRICE}
                    errorMessage={errors.finalPrice?.message}
                    register={numericInputValue}
                    onChange={({ target }) => handleOnChange(target.value)}
                  />
                </div>
              </Col>
              <Col md={3} className="m-auto">
                <div className="horizontal-divider" />
              </Col>
            </Row>
          </div>
        </div>
      </Row>
    </Modal>
  );
}
