import {
  ArrowUpRight16Regular,
  ChevronLeft20Regular,
  ChevronRight20Regular,
} from "@fluentui/react-icons";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button } from "components/button";
import { useTranslation } from "hooks/use-translate";
import { LoadingStatusEnum } from "models/enums/LoadingStatus.enum";
import { AppRouteHelper } from "routes";
import { RootState } from "state";
import CustomerPageContactCard from "./customerPageContactCard";
import { Spinner } from "@fluentui/react-components";
import { SpinnerSize } from "components/spinner";

type CustomerPageProperties = {
  customerId: string;
};
export default function CustomerPageContacts({
  customerId,
}: CustomerPageProperties) {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const contactsStatus = useSelector(
    (state: RootState) => state.settings.contacts.status
  );
  const contacts = useSelector(
    (state: RootState) => state.settings.contacts.data
  );

  const { data: companyInfoData } = useSelector(
    (state: RootState) => state.offers.companyInfo
  );

  const [firstCard, setFirstCard] = useState(0);
  const [lastCard, setLastCard] = useState(4);

  const URLProps = new URLSearchParams(window.location.search);
  const isConveyorBeltV2 = URLProps.get("conveyorBeltV2");

  const openContactsPage = () => {
    navigate(
      isConveyorBeltV2
        ? AppRouteHelper.getCustomerPageContactsPage(customerId, true)
        : AppRouteHelper.getCustomerPageContactsPage(customerId)
    );
  };

  const primaryContact = contacts.find(
    (contact) => contact.id === companyInfoData?.primary_contact_id
  );

  let filteredContacts = contacts;

  if (primaryContact) {
    filteredContacts = filteredContacts.filter(
      (contact) => contact.id !== companyInfoData?.primary_contact_id
    );
    filteredContacts.unshift(primaryContact);
  }

  return (
    <div>
      {contactsStatus && contactsStatus === LoadingStatusEnum.PENDING && (
        <Spinner size={SpinnerSize.Tiny} />
      )}

      {!filteredContacts && (
        <Row>
          <div>{translate("NO_CONTACTS_FOUND")}</div>
        </Row>
      )}
      <div className="d-flex w-100 justify-content-end pb-md">
        <ChevronLeft20Regular
          className={`cursor-pointer mx-md bold-icon ${
            firstCard - 1 === -1 ? "text-gray" : "text-color-blue"
          }`}
          onClick={() => {
            if (firstCard - 1 !== -1) {
              setFirstCard((prev) => prev - 1);
              setLastCard((prev) => prev - 1);
            }
          }}
        />
        <ChevronRight20Regular
          className={`cursor-pointer mx-sm bold-icon ${
            lastCard === filteredContacts.length && filteredContacts.length < 3
              ? "text-gray"
              : "text-color-blue"
          }`}
          onClick={() => {
            if (
              lastCard !== filteredContacts.length &&
              filteredContacts.length > 3
            ) {
              setFirstCard((prev) => prev + 1);
              setLastCard((prev) => prev + 1);
            }
          }}
        />
      </div>
      <Row className="d-flex flex-row">
        <Col md={11}>
          <Row>
            {filteredContacts
              .slice(firstCard, lastCard)
              .map(
                (contact, index) =>
                  contact.id && (
                    <CustomerPageContactCard
                      key={`contacts-${contact.id}`}
                      contact={contact}
                      md={6}
                      lg={6}
                      xl={3}
                      xxl={3}
                      isPrimaryContact={
                        companyInfoData?.primary_contact_id === contact.id
                      }
                    />
                  )
              )}
          </Row>
        </Col>
      </Row>
      <Row className="px-0">
        <div className="d-flex align-items-end mb-xs px-0">
          <Button
            variant="outline-outline"
            className="justify-content-start primary-colored-text"
            onClick={() => openContactsPage()}
          >
            <ArrowUpRight16Regular />
            <span className="d-inline mx-sm fw-bold primary">
              {translate("CONTACTS_NEW_PAGE")}
            </span>
          </Button>
        </div>
      </Row>
    </div>
  );
}
