import { ErrorCircle24Regular } from "@fluentui/react-icons";
import { useTranslation } from "hooks";

export default function CompanyNotFound() {
  const { t: translate } = useTranslation();

  return (
    <div className="text-center minHeight-100 align-content-center">
      <div className="pb-sm justify-content-center">
        <ErrorCircle24Regular />
      </div>
      <div
        className="sub-h1 body-italic-light"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: translate("CUSTOMER_PAGE.CUSTOMER_MISSING_IN_HUBSPOT"),
        }}
      />
    </div>
  );
}
