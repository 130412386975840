import { useEffect, useState } from "react";

type ReturnType<T> = [T, React.Dispatch<React.SetStateAction<T>>];

type ReturnDateType = ReturnType<Date | undefined>;

export const useStickyState = <T>(
  key: string,
  defaultValue: T
): ReturnType<T> => {
  const [value, setValue] = useState<T>(() => {
    const stickyValue = window.localStorage.getItem(key);
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
};

export const useStickyStateForDate = (key: string): ReturnDateType => {
  const [value, setValue] = useState<Date | undefined>(() => {
    const stickyValue = window.localStorage.getItem(key);
    if (stickyValue === null) return undefined;
    const savedTime = parseInt(stickyValue, 10);

    return isNaN(savedTime) ? undefined : new Date(savedTime);
  });

  useEffect(() => {
    window.localStorage.setItem(
      key,
      value !== undefined
        ? JSON.stringify(value.getTime())
        : JSON.stringify(undefined)
    );
  }, [key, value]);
  return [value, setValue];
};
