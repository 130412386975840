import { Spinner } from "@fluentui/react-components";
import { Providers, ProviderState } from "@microsoft/mgt-element";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import { useAuth } from "auth/use-auth";
import { useBugsnagSetUser } from "bugsnag";
import { Layout, LayoutSidebar } from "components/layout/Layout";
import { useSetDocumentTitle, useTranslation } from "hooks";
import routes, { RouteConfig } from "routes";
import { fetchCustomers, fetchCustomersV2 } from "state/customers/actions";
import { fetchCurrentUserAzureGroups, fetchUser } from "state/users/userThunks";
import { fetchActivitiesDefinitions } from "state/activities/actions";
import Menu from "views/menu";
import { ToasterNotification } from "components/toaster/ToasterNotification";
import { LoadingStatusEnum } from "models/enums/LoadingStatus.enum";
import { SpinnerSize } from "components/spinner";
import AppConfig from "app-config";

export const IS_DEBUGGING =
  AppConfig.STAGE === "development" && AppConfig.IS_DEBUGGING === "true";

export default function App() {
  const { user, loadingStatus } = useAuth();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  useBugsnagSetUser(true);
  useSetDocumentTitle();

  useEffect(() => {
    if (user) {
      /**
       * This was removed in this pull-request https://github.com/LRFKonsult/mitt-ludvig/pull/1315/
       * At the moment we need it to be called since without it azureGroups are empty and not fetched
       */
      dispatch(fetchCurrentUserAzureGroups(user.graphId));
      return;
    }

    dispatch(fetchActivitiesDefinitions());
    dispatch(fetchUser());

    // TODO: Remove when CB2 is live
    if (localStorage.getItem("conveyorBeltVersion") === "2") {
      dispatch(fetchCustomersV2(undefined, true));
      dispatch(fetchCustomersV2());
    } else {
      dispatch(fetchCustomers(undefined, true));
      dispatch(fetchCustomers());
    }
  }, [dispatch, user]);

  // this is checking the state from fetchUser
  if (
    loadingStatus === LoadingStatusEnum.IDLE ||
    loadingStatus === LoadingStatusEnum.PENDING
  ) {
    return (
      <div className="p-4 text-center d-flex align-items-center justify-content-center">
        <Spinner
          labelPosition="below"
          appearance="primary"
          size={SpinnerSize.ExtraSmall}
          label={translate("LOGGING_IN")}
        />
      </div>
    );
  }

  // TODO: this should be improved because `user` depends on `fetchUser` while in fact it shouldn't
  if (
    (Providers.globalProvider.state === ProviderState.SignedIn &&
      user === undefined) ||
    loadingStatus === LoadingStatusEnum.FAILED
  ) {
    return (
      <div className="text-center p-3">{translate("FAILED_TO_LOG_IN")}</div>
    );
  }

  const renderRoutes = (route: RouteConfig) => (
    <Route key={route.path} path={route.path} element={route.element}>
      {route.subRoutes.map((subRoute) => renderRoutes(subRoute))}
    </Route>
  );

  return (
    <Layout>
      <LayoutSidebar>
        <Menu />
      </LayoutSidebar>

      <Routes>
        {routes.map(renderRoutes)}
        <Route path="" element={<Navigate to="/home" />} />
      </Routes>

      <ToasterNotification />
    </Layout>
  );
}
