import { ChangeEvent, useState } from "react";
import {
  FolderRegular,
  EditRegular,
  OpenRegular,
  DocumentRegular,
  PeopleRegular,
  DocumentPdfRegular,
  VideoRegular,
} from "@fluentui/react-icons";
import {
  PresenceBadgeStatus,
  Avatar,
  DataGridBody,
  DataGridRow,
  DataGrid,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridCell,
  TableCellLayout,
  TableColumnDefinition,
  createTableColumn,
  Switch,
} from "@fluentui/react-components";
import type {
  SwitchProps,
  SwitchOnChangeData,
} from "@fluentui/react-components";

import { useTranslation } from "hooks";
import { DateFormat, dateLanguageFormatter } from "libs/date/date-format";
import { DateRange } from "components/date/DateRange";
import { SearchInput } from "components/input/search";
import { ExpandableExample } from "./expandable-example";

// first example
type FileCell = {
  label: string;
  icon: JSX.Element;
};

type LastUpdatedCell = {
  label: string;
  timestamp: number;
};

type LastUpdateCell = {
  label: string;
  icon: JSX.Element;
};

type AuthorCell = {
  label: string;
  status: PresenceBadgeStatus;
};

type Item = {
  id: number;
  enabled: boolean;
  file: FileCell;
  author: AuthorCell;
  lastUpdated: LastUpdatedCell;
  lastUpdate: LastUpdateCell;
};

export function FluentSwitch(props: SwitchProps) {
  return <Switch label="This is a switch" {...props} />;
}

const responseItems: Item[] = [
  {
    id: 1,
    enabled: false,
    file: { label: "Meeting notes", icon: <DocumentRegular /> },
    author: { label: "Max Mustermann", status: "available" },
    lastUpdated: { label: "7h ago", timestamp: 1 },
    lastUpdate: {
      label: "You edited this",
      icon: <EditRegular />,
    },
  },
  {
    id: 2,
    enabled: true,
    file: { label: "Thursday presentation", icon: <FolderRegular /> },
    author: { label: "Erika Mustermann", status: "busy" },
    lastUpdated: { label: "Yesterday at 1:45 PM", timestamp: 2 },
    lastUpdate: {
      label: "You recently opened this",
      icon: <OpenRegular />,
    },
  },
  {
    id: 3,
    enabled: false,
    file: { label: "Training recording", icon: <VideoRegular /> },
    author: { label: "John Doe", status: "away" },
    lastUpdated: { label: "Yesterday at 1:45 PM", timestamp: 2 },
    lastUpdate: {
      label: "You recently opened this",
      icon: <OpenRegular />,
    },
  },
  {
    id: 4,
    enabled: false,
    file: { label: "Purchase order", icon: <DocumentPdfRegular /> },
    author: { label: "Jane Doe", status: "offline" },
    lastUpdated: { label: "Tue at 9:30 AM", timestamp: 3 },
    lastUpdate: {
      label: "You shared this in a Teams chat",
      icon: <PeopleRegular />,
    },
  },
];

// the columns and the methods will probably go in the main component so they are able to handle the event change
function handleOnChange(
  ev: ChangeEvent<HTMLInputElement>,
  data: SwitchOnChangeData,
  item: Item
) {
  console.log("--->> on change", ev, data, item);
}

const columns: TableColumnDefinition<Item>[] = [
  createTableColumn<Item>({
    columnId: "",
    renderHeaderCell: () => {
      return "";
    },
    renderCell: (item) => {
      return (
        <FluentSwitch
          onChange={(ev, data) => handleOnChange(ev, data, item)}
          // checked={item.enabled}
        />
      );
    },
  }),
  createTableColumn<Item>({
    columnId: "file",
    compare: (a, b) => {
      return a.file.label.localeCompare(b.file.label);
    },
    renderHeaderCell: () => {
      return "File";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout media={item.file.icon}>
          {item.file.label}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<Item>({
    columnId: "author",
    compare: (a, b) => {
      return a.author.label.localeCompare(b.author.label);
    },
    renderHeaderCell: () => {
      return "Author";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout
          media={
            <Avatar
              aria-label={item.author.label}
              name={item.author.label}
              badge={{ status: item.author.status }}
            />
          }
        >
          {item.author.label}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<Item>({
    columnId: "lastUpdated",
    compare: (a, b) => {
      return a.lastUpdated.timestamp - b.lastUpdated.timestamp;
    },
    renderHeaderCell: () => {
      return "Last updated";
    },

    renderCell: (item) => {
      return item.lastUpdated.label;
    },
  }),
  createTableColumn<Item>({
    columnId: "lastUpdate",
    compare: (a, b) => {
      return a.lastUpdate.label.localeCompare(b.lastUpdate.label);
    },
    renderHeaderCell: () => {
      return "Last update";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout media={item.lastUpdate.icon}>
          {item.lastUpdate.label}
        </TableCellLayout>
      );
    },
  }),
];

export function FirstExample() {
  const [items] = useState(responseItems); // some response

  const { language } = useTranslation();

  const dateFormatter = dateLanguageFormatter(
    language,
    DateFormat.YearDayMonth
  );

  const [dateRange, setDateRange] = useState<{
    fromDate: Date | null;
    toDate: Date | null;
  }>({ fromDate: null, toDate: null });

  return (
    <div style={{ background: "white", overflow: "auto" }}>
      <DateRange
        dateFormatter={dateFormatter}
        dateRange={{
          start: {
            date: dateRange.fromDate,
            onSelectDate: (date) =>
              setDateRange((prev) => ({ ...prev, fromDate: date })),
          },
          end: {
            date: dateRange.toDate,
            onSelectDate: (date) =>
              setDateRange((prev) => ({ ...prev, toDate: date })),
          },
        }}
      />
      Chosen range: {dateRange.fromDate && dateFormatter(dateRange.fromDate)} -{" "}
      {dateRange.toDate && dateFormatter(dateRange.toDate)}
      <hr />
      <div>
        <SearchInput placeholder="SearchInput v2" appearance="outline" />
      </div>
      <DataGrid
        items={items}
        columns={columns}
        sortable
        getRowId={(item) => item.file.label}
        focusMode="composite"
      >
        <DataGridHeader>
          <DataGridRow>
            {({ renderHeaderCell }) => (
              <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
            )}
          </DataGridRow>
        </DataGridHeader>
        <DataGridBody<Item>>
          {({ item, rowId }) => (
            <DataGridRow<Item> key={rowId}>
              {({ renderCell }) => (
                <DataGridCell>{renderCell(item)}</DataGridCell>
              )}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>
      <br />
      <br />
      <br />
      <br />
      <ExpandableExample />
    </div>
  );
}
