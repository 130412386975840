import { Checkmark16Regular, Warning20Regular } from "@fluentui/react-icons";
import { differenceInDays } from "date-fns";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import { RootState } from "state";
import StatusIndicatorIcon from "components/icon/StatusIndicatorIcon";
import { Label, LabelVariant } from "components/label";
import { Persona } from "components/people";
import TooltipV9 from "components/tooltip/TooltipV9";
import { useTranslation } from "hooks/use-translate";
import { TranslationKey } from "i18n";
import { ServiceInstance } from "models/activities/activity";
import { ActivityStatusMap } from "views/activities/serviceActivities";
import "./ServiceBox.scss";

type ServiceBoxProps = {
  service: ServiceInstance;
} & React.HTMLProps<HTMLDivElement>;

export const frequencyLabelMap: { [index: string]: LabelVariant } = {
  WEEKLY: "green",
  MONTHLY: "purple",
  QUARTERLY: "blue",
  YEARLY: "yellow",
};

export function ServiceBox({ service, ...props }: ServiceBoxProps) {
  const { t: translate, language } = useTranslation();
  const { dataMap: definitionsMap } = useSelector(
    (state: RootState) => state.activities.definitions
  );
  const classNames = `service-box ${service.isCompleted() ? "completed" : ""} ${
    service.isOverdue() ? "overdue" : ""
  }`;

  const next = service.getIncompleteSorted()[0];
  const nextDefinition = next && definitionsMap[next.activity_type];
  const nextActivityName = next && next.getTitle(language, nextDefinition);
  const name = service.getTitleWithWeek(language);
  const isComplete = service.isCompleted();
  const isReportCompleted = service.getReportFinished();
  const progress = service.getProgress();
  const statuses = service.getAttentionStatuses();
  const reportDeadline = service.getReportDeadline();
  const deadlineTodayDifference =
    reportDeadline && differenceInDays(reportDeadline, new Date());

  const renderDaysLeftOrOverdue =
    !isReportCompleted &&
    deadlineTodayDifference &&
    deadlineTodayDifference !== 0 ? (
      <Row>
        {deadlineTodayDifference > 0 ? (
          <span className="stringLength o-50">
            {deadlineTodayDifference}
            {` ${translate("DAYS").toLowerCase()} ${translate(
              "LEFT"
            ).toLowerCase()}`}
          </span>
        ) : (
          <span className="stringLength o-50">
            {-deadlineTodayDifference}
            {` ${translate("DAYS").toLowerCase()} ${translate(
              "OVERDUE"
            ).toLowerCase()}`}
          </span>
        )}
      </Row>
    ) : undefined;

  return (
    <div className={classNames} {...props}>
      <Row
        className={
          !isComplete
            ? "service-box-content h-100 px-sm pt-xs"
            : "service-box-content h-100 px-sm"
        }
      >
        <Col className="my-xxs mx-0">
          <Row>
            <div className="d-flex align-items-center">
              <p className="me-auto m-0 fw-600">{name}</p>

              <div className="d-flex justify-content-center pr-xs">
                {!isComplete &&
                  statuses &&
                  statuses.map((status) => (
                    <TooltipV9
                      key={status}
                      hasJSXContent
                      content={
                        <div className="d-flex">
                          <StatusIndicatorIcon
                            status={ActivityStatusMap.get(status)}
                          />
                          <span className="pl-sm">
                            {translate(status as TranslationKey)}
                          </span>
                        </div>
                      }
                    >
                      <StatusIndicatorIcon
                        status={ActivityStatusMap.get(status)}
                      />
                    </TooltipV9>
                  ))}

                {service.isOverdue() && (
                  <TooltipV9
                    hasJSXContent
                    content={
                      <div className="d-flex">
                        <Warning20Regular
                          data-testid="overdue-warning"
                          color="var(--red)"
                        />
                        <span className="pl-sm">
                          {translate("PASSED_DEADLINE")}
                        </span>
                      </div>
                    }
                  >
                    <Warning20Regular
                      data-testid="overdue-warning"
                      color="var(--red)"
                    />
                  </TooltipV9>
                )}
              </div>
              <p className="m-0 p-0 fw-semibold">
                {isComplete && (
                  <Checkmark16Regular
                    data-testid="completed-check"
                    className="ml-sm"
                  />
                )}
              </p>
            </div>
          </Row>
          {!isComplete && (
            <Row className="d-flex">
              <Col md={10} className="pr-xxs">
                <Row className="d-flex pt-xxs">
                  {!isComplete && progress > 0 && (
                    <Col
                      xs={
                        !deadlineTodayDifference ||
                        deadlineTodayDifference === 0
                          ? 12
                          : 6
                      }
                      className="d-flex"
                    >
                      <div className="o-50 pr-xs">{translate("NEXT")}:</div>
                      <TooltipV9 content={nextActivityName} notTranslatable>
                        <div className="d-flex justify-content-between w-100">
                          <span className="stringLength w-100">
                            {nextActivityName}
                          </span>
                        </div>
                      </TooltipV9>
                    </Col>
                  )}
                  <Col
                    xs={!isReportCompleted && progress ? 6 : 12}
                    className={`d-flex ${
                      !isReportCompleted && progress ? "pl-lg" : ""
                    }`}
                  >
                    {!isComplete && progress === 0 && (
                      <div className="o-50 pr-xs">
                        {`${translate("NOT_STARTED")}${
                          renderDaysLeftOrOverdue ? ":" : ""
                        }`}
                      </div>
                    )}

                    {renderDaysLeftOrOverdue}
                  </Col>
                </Row>

                <Row className="d-flex justify-content-between align-items-center pt-xxs">
                  {!isComplete && (
                    <div className="d-flex fg-1 frequency-label">
                      {service.recurrence && (
                        <Label
                          variant={frequencyLabelMap[service.recurrence]}
                          className="o-50 py-0 px-1"
                        >
                          <span className="fw-600">
                            {translate(service.recurrence)}
                          </span>
                        </Label>
                      )}
                    </div>
                  )}
                </Row>
              </Col>

              <Col
                md={2}
                className="align-items-center d-flex justify-content-end pr-sm"
              >
                <span className="align-items-end">
                  <Persona userId={next.assigned_user} />
                </span>
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      {!isComplete && (
        <progress className="service-box-progress-bar" value={progress} />
      )}
    </div>
  );
}
