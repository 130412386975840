import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import React, { useRef, useEffect, useState } from "react";
import { ActivitiesCustomerDetails } from "./CustomerDetails";
import { Customer } from "../../../../models/customer";
import { RootState } from "../../../../state";
import { ServiceInstance } from "../../../../models/activities/activity";
import { useTranslation } from "../../../../hooks/use-translate";
import { TimespanFilter } from "../../components/filters/service-filters";
import { useAppSelector } from "../../../../state/use-app-redux";
import ServicesPerMonthColumn from "./ServicesPerMonthColumn";
import ServicesPerMonthCollapsedColumn from "./ServicesPerMonthCollapsedColumn";
import "./CustomerRowServices.scss";
import CustomerRowNoActivitiesReason from "./CustomerRowNoActivitiesReason";

type CustomerRowProps = {
  openPanel: (customer: Customer) => void;
  filteredServices: ServiceInstance[];
  customer: Customer;
  expanded: boolean;
  isFiltering: boolean;
  showCompleted?: boolean;
  showCollapsedView?: boolean;
};

export function CustomerRowServices({
  openPanel,
  customer,
  expanded,
  filteredServices,
  isFiltering,
  showCompleted = false,
  showCollapsedView = false,
}: CustomerRowProps) {
  const activities = useSelector((state: RootState) => state.activities);
  const { timelineWindow } = activities;
  const { t: translate } = useTranslation();
  const selRef = useRef<HTMLDivElement>();

  const [collapsedRow, setCollapsedRow] = useState(showCollapsedView);

  useEffect(() => {
    const URLProps = new URLSearchParams(window.location.search);
    const focusedClient = URLProps.get("focusedClient");
    if (focusedClient && customer.customer_number === focusedClient) {
      selRef.current?.focus();
    }
    setCollapsedRow(true && showCollapsedView);
  }, [customer, showCollapsedView]);

  const timelineFilter = new TimespanFilter(timelineWindow);

  const servicesInTimeWindow = filteredServices.filter((s) =>
    s.activities.every((a) => timelineFilter.check(a, s))
  );
  const customerState = useAppSelector((state) => state.customers.data).find(
    (data) => data.customer.customer_number === customer.customer_number
  );

  if (isFiltering && filteredServices.length === 0) {
    return null;
  }

  if (!customerState || !customer) {
    return (
      <Row>
        <div>{translate("MISSING_CUSTOMER")}</div>
      </Row>
    );
  }

  const shouldShowServiceInTimeWindow = (
    yearMonth: Date,
    s: ServiceInstance
  ): boolean => {
    return (
      s.year === yearMonth.getFullYear() && s.month === yearMonth.getMonth() + 1
    );
  };

  const completedServices = timelineWindow
    .map((yearMonth) => {
      const windowServices = servicesInTimeWindow.filter((s) =>
        shouldShowServiceInTimeWindow(yearMonth, s)
      );
      const completedWidnowServices = windowServices.filter((s) =>
        s.isCompleted()
      );
      return completedWidnowServices;
    })
    .flat();

  const completedServicesClass = () => {
    if (!expanded) {
      return "no-completed-services-list-height";
    }
    switch (completedServices.length) {
      case 0:
        return "no-completed-services-list-height";
      case 1:
        return "one-completed-service-list-height";
      default:
        return "min-completed-services-list-height";
    }
  };

  const customerIsFromSearch = customerState && customerState.isFromSearch;

  const customerMissingFunctionsFromSearch = () => {
    return (
      <CustomerRowNoActivitiesReason
        message={translate("CUSTOMER_IS_FROM_SEARCH")}
        testid="missing-activities-in-window-warning-for-customer-search-result"
        key={`${customer.customer_number}-${translate(
          "CUSTOMER_IS_FROM_SEARCH"
        )}`}
      />
    );
  };

  if (
    servicesInTimeWindow.length === 0 &&
    (showCollapsedView || !showCompleted)
  ) {
    return null;
  }

  return (
    <Row
      className="no-hover fade-in border-bottom-gray pt-xs pb-xs min-width-1400"
      tabIndex={0}
      ref={selRef}
      onClick={() => showCollapsedView && setCollapsedRow(!collapsedRow)}
    >
      <Col md={2} className="min-width-300 pl-md">
        <ActivitiesCustomerDetails
          customerIsFromSearch={
            customerIsFromSearch ?? customerMissingFunctionsFromSearch()
          }
          toggleInfo={() => openPanel(customer)}
          customer={customer}
          expanded={expanded && !showCollapsedView}
          isCollapsedView={showCollapsedView}
          isCollapsedRow={collapsedRow}
        />
      </Col>

      <Col
        className={`${
          expanded && !showCollapsedView ? "" : "xs-table-border-spacing"
        } border-collapse-separate`}
      >
        {!activities.hasFailed && (
          <Row
            className={collapsedRow ? "cursor-pointer hover-collapsed-row" : ""}
          >
            {timelineWindow.map((yearMonth) => {
              const windowServices = servicesInTimeWindow.filter((service) =>
                shouldShowServiceInTimeWindow(yearMonth, service)
              );
              const completedWidnowServices = windowServices.filter((service) =>
                service.isCompleted()
              );
              const incompleteWindowServices = windowServices.filter(
                (service) => !service.isCompleted()
              );
              const allWidnowServicesEmpty = !windowServices.length;

              const overdueWindowServices = windowServices.filter((service) =>
                service.isOverdue()
              );

              return (
                <React.Fragment
                  key={`${customer.customer_number}-customerRowServices-${yearMonth}-fragment`}
                >
                  <Col
                    md={3}
                    key={`${customer.customer_number}-customerRowServices-${yearMonth}`}
                  >
                    {(() => {
                      if (showCollapsedView && collapsedRow) {
                        return (
                          <ServicesPerMonthCollapsedColumn
                            yearMonth={yearMonth}
                            completedServices={completedWidnowServices.length}
                            allServices={windowServices.length}
                            allEmpty={allWidnowServicesEmpty}
                            hasOverdueServices={
                              overdueWindowServices.length > 0
                            }
                          />
                        );
                      }

                      if (allWidnowServicesEmpty) {
                        return (
                          <Row
                            className={`${
                              showCompleted
                                ? "min-services-list-height"
                                : "min-services-list-height-alt"
                            }`}
                          >
                            <ServicesPerMonthColumn
                              services={[]}
                              customer={customer}
                              yearMonth={yearMonth}
                              completed
                              allEmpty
                              expanded={expanded}
                            />
                          </Row>
                        );
                      }

                      return (
                        <>
                          {showCompleted && (
                            <Row
                              className={`${completedServicesClass()} lh-lg`}
                            >
                              <ServicesPerMonthColumn
                                services={completedWidnowServices}
                                customer={customer}
                                yearMonth={yearMonth}
                                completed
                                expanded={expanded}
                              />
                            </Row>
                          )}
                          <Row
                            className={`${
                              showCompleted
                                ? "min-incomplete-services-list-height"
                                : "min-incomplete-services-list-height-alt mb-sm"
                            }`}
                          >
                            <ServicesPerMonthColumn
                              services={incompleteWindowServices}
                              customer={customer}
                              yearMonth={yearMonth}
                              completed={false}
                              showDivider={showCompleted}
                              allEmpty={allWidnowServicesEmpty}
                              expanded={expanded}
                            />
                          </Row>
                        </>
                      );
                    })()}
                  </Col>
                </React.Fragment>
              );
            })}
          </Row>
        )}
      </Col>
    </Row>
  );
}
