import { useState } from "react";
import { Add20Filled } from "@fluentui/react-icons";

import { useTranslation } from "hooks/use-translate";
import useWindowDimensions from "hooks/use-window-dimensions";
import { Deal } from "models/deals/deal";
import { FiltersListItem } from "views/activities/overview/components/FilterSortBox";
import { useDealsFilters } from "./useDealsFilters";
import { ActiveFilters } from "..";

type DealFilterListProps = {
  activeFilters: ActiveFilters;
  setActiveFilters: (activeFilters: ActiveFilters) => void;
  setFilteredDeals: (filteredDeals: Deal[]) => void;
};

export function DealsFilterList({
  activeFilters,
  setActiveFilters,
  setFilteredDeals,
}: DealFilterListProps) {
  const { t: translate } = useTranslation();

  const { filterByDateOptions, filterByInboundOutboundOptions } =
    useDealsFilters({
      activeFilters,
      setFilteredDeals,
    });

  const listCount = activeFilters.selectedDateRange ? 1 : 0;

  const allFiltersList: {
    id: string;
    labelText: string;
    onClick: () => void;
  }[] = [];

  if (activeFilters.selectedDateRange) {
    allFiltersList.push({
      id: activeFilters.selectedDateRange,
      labelText: filterByDateOptions.filter(
        (item) => activeFilters.selectedDateRange === item.value
      )[0].text,
      onClick: () =>
        setActiveFilters({
          ...activeFilters,
          selectedDateRange: undefined,
        }),
    });
  }

  if (activeFilters.inboundOutboundDeals) {
    allFiltersList.push({
      id: activeFilters.inboundOutboundDeals,
      labelText: filterByInboundOutboundOptions.filter(
        (item) => activeFilters.inboundOutboundDeals === item.value
      )[0].text,
      onClick: () =>
        setActiveFilters({
          ...activeFilters,
          inboundOutboundDeals: undefined,
        }),
    });
  }

  let listCountAdjustable = 0;
  const additionalCount = allFiltersList.length - 3;
  const [dropdownShown, setDropdownShown] = useState(true);
  const { windowWidth } = useWindowDimensions();
  const isSmallScreen = windowWidth < 1800;
  const dropdownAdditionalFiltersList = isSmallScreen
    ? allFiltersList
    : allFiltersList.slice(3);

  return (
    <>
      {!isSmallScreen &&
        allFiltersList.map(({ id, labelText, onClick }) => {
          listCountAdjustable += 1;

          return (
            listCountAdjustable <= 3 && (
              <FiltersListItem
                key={id}
                labelText={labelText}
                onClick={onClick}
                labelClassName="filter-item-label filter-item-label-short-view pills-left"
              />
            )
          );
        })}

      {(isSmallScreen ? allFiltersList.length > 0 : additionalCount > 0) && (
        <>
          <label
            key={listCount}
            className="ml-sm fg-xs px-sm filter-item-label pills-right"
            onClick={() => setDropdownShown(!dropdownShown)}
          >
            {isSmallScreen ? (
              <span className="pr-xs fw-600 text-color-purple fpx-12">
                {translate("ACTIVE_FILTERS")} : {allFiltersList.length}
              </span>
            ) : (
              <>
                <Add20Filled className="fw-600 px-xs" />
                <span className="pr-xs fw-600 text-color-purple fpx-12">
                  {additionalCount}
                </span>
              </>
            )}
          </label>

          <div hidden={dropdownShown} className="mt-md filters-list-dropdown">
            {dropdownAdditionalFiltersList.map(({ id, labelText, onClick }) => {
              return (
                <div className="pb-xs" key={id}>
                  <FiltersListItem
                    keyId={id}
                    labelText={labelText}
                    onClick={onClick}
                    labelClassName="w-100"
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
}