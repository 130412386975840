import { createContext, ReactNode, useContext, useMemo } from "react";
import {
  CustomerActivitiesServices,
  ServiceInstance,
} from "models/activities/activity";

interface AssignmentManagementContextProps {
  children: ReactNode;
  servicesFilter: (
    customerNumber: string,
    activitiesToFilter?: CustomerActivitiesServices[]
  ) => ServiceInstance[];
}

interface AssignmentManagementContextValue {
  servicesFilter: (
    customerNumber: string,
    activitiesToFilter?: CustomerActivitiesServices[]
  ) => ServiceInstance[];
}

const AssignmentManagementContext = createContext<
  AssignmentManagementContextValue | undefined
>(undefined);

export function AssignmentManagementContextProvider({
  children,
  servicesFilter,
}: AssignmentManagementContextProps) {
  const value = useMemo(() => ({ servicesFilter }), [servicesFilter]);

  return (
    <AssignmentManagementContext.Provider value={value}>
      {children}
    </AssignmentManagementContext.Provider>
  );
}

export const useServicesFilter = (): AssignmentManagementContextValue => {
  const context = useContext(AssignmentManagementContext);
  if (!context) {
    throw new Error(
      "useServicesFilter must be used within an AssignmentManagementContextProvider"
    );
  }
  return context;
};
