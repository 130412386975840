import { FormProvider, UseFormReturn } from "react-hook-form";

import { useTranslation } from "hooks/use-translate";
import { Deal } from "models/deals/deal";
import { Stage } from "models/deals/stage";
import { useFieldsConfiguration } from "../configuration/fields-config";

type DealInformationRequiredFormProps = {
  deal?: Deal;
  toStage?: Stage;
  formMethods: UseFormReturn<Partial<Deal>>;
};

export function DealInformationRequiredForm({
  deal,
  toStage,
  formMethods,
}: DealInformationRequiredFormProps) {
  const { t: translate } = useTranslation();
  const fieldConfigurations = useFieldsConfiguration();

  return (
    <form>
      {deal &&
        toStage?.configuration?.RequiredFields?.split(";").map((field) => {
          const fieldConfiguration = fieldConfigurations.fields.find(
            (fc) => fc.property === field
          );

          if (!fieldConfiguration) {
            throw new Error(
              `Missing field configuration for ${field}, please add it to deals field configuration`
            );
          }

          if (!fieldConfiguration?.isValid) {
            throw new Error(
              `Missing field isValid property, please add it to deals field configuration`
            );
          }

          return (
            <FormProvider {...formMethods} key={field}>
              <div className="mb-2">
                <label>
                  {fieldConfiguration.label}
                  {!fieldConfiguration.isValid(deal) &&
                    ` (${translate(
                      "DEAL_REQUIRED_FIELDS_DIALOG.PLEASE_SPECIFY"
                    )})`}
                </label>
                {fieldConfiguration.render(deal, toStage)}
              </div>
            </FormProvider>
          );
        })}
    </form>
  );
}
