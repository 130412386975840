/* eslint-disable camelcase */
import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Label } from "@fluentui/react-components";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Dropdown } from "components/dropdown";
import Switch from "components/switch";
import { RootState } from "state";
import {
  getServiceCategoryFromEconomyServiceArea,
  getFilteredAndSortedServices,
  Service,
  ServiceCategory,
  ServiceGroup,
} from "models/offer/ServiceLine";
import { updateServicesAndPostDealData } from "state/offer/offersThunks";
import { getMonthsOfOneYear } from "libs/date/generate-month";
import { useTranslation } from "hooks/use-translate";
import { getYearsOptions } from "libs/date/date-format";
import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import BreadcrumbV9, {
  BreadcrumbDefinitionItem,
} from "components/breadcrumbV2";
import { updateCurrentOffer } from "state/offer/offersSlice";
import PendingChangesModal from "components/modal/PendingChangesModal";
import { LoadingStatusEnum } from "models/enums/LoadingStatus.enum";
import { ACCOUNTING_SERVICE_LINE } from "libs/constants";
import { useRoutingForOffer } from "views/offer/components/wizardSection/useRoutingForOffer";
import ServiceDetailsHeader from "views/offer/wizard/components/service-details-header";
import ServicesFormData from "views/offer/wizard/Services/ServicesFormData";
import {
  getNextButtonText,
  OfferRouteHelper,
} from "views/offer/wizard/offerRoutes";
import { WizardSection } from "views/offer/components/wizardSection";
import { useGetBreadcrumbs } from "views/offer/components/wizardSection/useGetBreadcrumbs";
import { ServicesContext } from "views/offer/wizard/Services/ServicesContextProvider";
import {
  SERVICE_CATEGORY_ANNUAL_ACCOUNTS,
  SERVICE_GROUP_ANNUAL_ACCOUNTS,
} from "../consts/offer-contst";
import "../Services/ServiceForm.scss";

type YearEndForm = ServicesFormData & {
  yearEndYear: string | null;
  yearEndMonth: string;
};

export default function YearlyStatement() {
  const { t: translate, ts } = useTranslation();
  const navigate = useNavigate();
  const { getBreadcrumbs } = useGetBreadcrumbs();
  const { edit } = useParams<{ edit: string }>();
  const dispatch = useDispatch();
  const location = useLocation();
  const { updateServiceGroups, serviceGroups } = useContext(ServicesContext);

  const serviceAreas = useSelector(
    (state: RootState) => state.offers.offerTemplate.data?.service_areas
  );
  const user = useSelector((state: RootState) => state.users.currentUser);

  const { data: currentOffer, status: loadingStatus } = useSelector(
    (state: RootState) => state.offers.currentOffer
  );

  if (!currentOffer) {
    throw new Error("No active offer");
  }

  if (!serviceAreas) {
    throw new Error("Missing service areas");
  }

  const { service_areas: currentServiceAreas } = currentOffer;

  const routing = useRoutingForOffer(location.pathname, currentOffer);
  const initialCurrentOffer = useRef(currentOffer);
  const [isDirty, setIsDirty] = useState(false);
  const [modalOpen, setOpenModal] = useState(false);

  const yearEnd =
    getServiceCategoryFromEconomyServiceArea(
      ACCOUNTING_SERVICE_LINE,
      "Annual accounts",
      "YearEnd",
      serviceAreas
    ) ?? ({} as ServiceCategory);

  const services = getFilteredAndSortedServices(yearEnd ?? []);

  const yearEndInCurrentOffer =
    getServiceCategoryFromEconomyServiceArea(
      ACCOUNTING_SERVICE_LINE,
      "Annual accounts",
      "YearEnd",
      currentServiceAreas
    ) ?? ({} as ServiceCategory);

  const {
    start_date,
    end_date,
    project_manager,
    year_end_year,
    year_end_month,
    frequency,
    services: activeServices,
  } = yearEndInCurrentOffer;

  const [yearEndData, setYearEndData] = useState<YearEndForm>({
    startDate: start_date !== "" ? start_date : getMonthsOfOneYear()[0],
    endDate: end_date,
    projectManager: project_manager !== "" ? project_manager : user.email,
    yearEndYear: year_end_year,
    yearEndMonth: year_end_month || "12",
    frequency: frequency ?? yearEnd.frequency,
  });

  const [yearEndServices, setYearEndServices] = useState<Service[]>([
    ...activeServices,
  ]);

  useEffect(() => {
    const updatedServiceGroup = serviceGroups.find(
      (group) => group.name === SERVICE_GROUP_ANNUAL_ACCOUNTS
    );
    if (updatedServiceGroup) {
      const newServiceAreas = ServiceGroup.updateServiceGroupInServiceArea(
        currentServiceAreas,
        updatedServiceGroup
      );
      dispatch(updateCurrentOffer({ service_areas: newServiceAreas }));
      const updatedCategory = updatedServiceGroup.service_categories.find(
        (category) => category.name === SERVICE_CATEGORY_ANNUAL_ACCOUNTS
      );
      if (updatedCategory) {
        setYearEndData({
          startDate: updatedCategory.start_date,
          endDate: updatedCategory.end_date,
          projectManager: updatedCategory.project_manager,
          yearEndYear: updatedCategory.year_end_year,
          yearEndMonth: updatedCategory.year_end_month || "12",
          frequency: updatedCategory.frequency,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, serviceGroups]);

  function isSelectedService(service: Service): boolean {
    return yearEndServices.some(
      (selectedServices) =>
        selectedServices.service_matrix_id === service.service_matrix_id
    );
  }

  const handleToggle = (service: Service, checked: boolean) => {
    setIsDirty(true);
    if (isSelectedService(service) && !checked) {
      setYearEndServices(
        yearEndServices.filter(
          (s) => s.service_matrix_id !== service.service_matrix_id
        )
      );
    } else {
      const activeService: Service = { ...service, units: 1 };
      setYearEndServices([...yearEndServices, activeService]);
    }
  };

  function renderToggles(servicesToRender: Service[]): React.ReactNode {
    return servicesToRender.map((service, index) => (
      <Switch
        disabled={
          !!yearEndInCurrentOffer.approved_by_email || service.input_is_disabled
        }
        className="m-1"
        key={`${service.service_matrix_id}yearEndService`}
        label={service.name}
        checked={isSelectedService(service)}
        onToggleMethod={(_, { checked }) => {
          handleToggle(service, checked);
        }}
      />
    ));
  }

  const getServiceLineWithYearEndData = (rowYearEnd = yearEndData) => {
    // Save the data to the service line in deal object
    const newYearEnd: ServiceCategory = {
      ...yearEndInCurrentOffer,
      services: yearEndServices,
      project_manager: rowYearEnd.projectManager,
      start_date: rowYearEnd.startDate,
      year_end_year: rowYearEnd.yearEndYear,
      year_end_month: rowYearEnd.yearEndMonth,
      frequency: rowYearEnd.frequency,
    };
    const newServiceAreas = ServiceCategory.updateServiceCategoryInServiceArea(
      currentServiceAreas,
      newYearEnd
    );

    return { service_areas: newServiceAreas };
  };

  function updateServiceCategoryData(formHeaderData: Partial<YearEndForm>) {
    const newYearEndFormData = { ...yearEndData, ...formHeaderData };
    setYearEndData(newYearEndFormData);
    const updatedServiceLine =
      getServiceLineWithYearEndData(newYearEndFormData);
    dispatch(updateCurrentOffer(updatedServiceLine));
    setIsDirty(true);
  }

  function hasFiscalYearSelected(): boolean {
    return !!yearEndData.yearEndYear;
  }

  const handleOnBeforePrevious = () => {
    if (isDirty) {
      setOpenModal(isDirty);
      return;
    }

    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
  };

  const handleDiscard = () => {
    dispatch(updateCurrentOffer(initialCurrentOffer.current));
    setIsDirty(false);
    setOpenModal(false);
    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
    if (edit !== undefined) {
      navigate(-1);
    }
  };

  const handleSave = async () => {
    const serviceGroupToUpdate =
      currentOffer.service_areas[0].service_lines[0].service_groups.find(
        (group) => group.name === SERVICE_GROUP_ANNUAL_ACCOUNTS
      );

    await dispatch(
      updateServicesAndPostDealData(getServiceLineWithYearEndData())
    );

    if (serviceGroupToUpdate) {
      updateServiceGroups(serviceGroupToUpdate);
    }
    setIsDirty(false);
    setOpenModal(false);
    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
    if (edit !== undefined) {
      navigate(-1);
    }
  };

  const serviceFormHeader = (
    <ServiceDetailsHeader
      startDateOnChange={(value) => {
        updateServiceCategoryData({ ...yearEndData, startDate: value });
      }}
      startDate={yearEndData.startDate}
      projectManagerOnChange={(personId) => {
        updateServiceCategoryData({
          ...yearEndData,
          projectManager: personId ?? "",
        });
      }}
      projectManager={yearEndData.projectManager}
      frequencyOnChange={(value) => {
        updateServiceCategoryData({ ...yearEndData, frequency: value });
      }}
      frequencySelectionDisabled={!!yearEnd.frequency}
      frequency={yearEndData.frequency}
      updateServiceCategoryData={(value) => {
        updateServiceCategoryData({
          ...yearEndData,
          yearEndMonth: value,
        });
      }}
      yearEndData={yearEndData}
      yearEndInCurrentOffer={yearEndInCurrentOffer}
    />
  );

  const onBeforeNext = async () => {
    await dispatch(
      updateServicesAndPostDealData(getServiceLineWithYearEndData())
    );
    return true;
  };

  const serviceFormContent = (
    <>
      <PendingChangesModal
        isOpen={modalOpen}
        onCancel={() => setOpenModal(false)}
        onSave={() => handleSave()}
        onDiscard={() => handleDiscard()}
        isValid={hasFiscalYearSelected()}
        isLoading={loadingStatus === LoadingStatusEnum.PENDING}
      />

      <Row className="py-xs">
        <Col md={3} className="mb-xxs">
          <Row className="p-1">
            {" "}
            <Label>{translate("FISCAL_YEAR")} *</Label>
            <Dropdown<string>
              disabled={!!yearEndInCurrentOffer.approved_by_email}
              placeholder={translate("DROPDOWN_PLACEHOLDER")}
              onChange={(value) => {
                updateServiceCategoryData({
                  ...yearEndData,
                  yearEndYear: value,
                });
              }}
              options={getYearsOptions()}
              value={yearEndData.yearEndYear || undefined}
              // manual validation here note to be refactored with standardized validation
              errorMessage={
                hasFiscalYearSelected()
                  ? undefined
                  : translate("SELECT_CORRECT_FISCAL_YEAR")
              }
            />
          </Row>
        </Col>
        <Col md={1} className="d-flex justify-content-center">
          <div className="d-flex">
            <div className="vertical-divider" />
          </div>
        </Col>
        <Col md={8} className="mb-xxs">
          <Row className="py-xs">
            {services.length > 0 && (
              <>
                <Col>{renderToggles(services.slice(0, 4))}</Col>
                <Col>{renderToggles(services.slice(4, 8))}</Col>
                <Col>{renderToggles(services.slice(8))}</Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );

  const renderServiceForm = () => (
    <WizardSection
      loadingStatus={loadingStatus}
      isNextHidden={!hasFiscalYearSelected()}
      onBeforeNext={onBeforeNext}
      onBeforePrevious={handleOnBeforePrevious}
      hasDivider
      content={serviceFormHeader}
    >
      {serviceFormContent}
    </WizardSection>
  );
  return edit !== undefined ? (
    <Modal
      className="service-modal"
      isOpen={edit !== undefined}
      onDismiss={() => {
        if (isDirty) {
          setOpenModal(isDirty);
          return;
        }
        setOpenModal(false);
        navigate(-1);
      }}
      size="large"
      header={
        <ModalHeader
          headerTitleContent={
            <div className="modal-title d-flex align-items-center">
              <BreadcrumbV9
                className="ml-sm"
                items={getBreadcrumbs("", true) as BreadcrumbDefinitionItem[]}
              />
            </div>
          }
        />
      }
      footer={
        <ModalFooter
          labelSubmit={ts(
            getNextButtonText(location.pathname, currentOffer, undefined)
          )}
          onSave={() => {
            onBeforeNext();
            navigate(OfferRouteHelper.getSummary());
          }}
          isDisabled={!hasFiscalYearSelected()}
          isLoading={loadingStatus === LoadingStatusEnum.PENDING}
        />
      }
    >
      <>
        <div className="contentHeader">{serviceFormHeader}</div>
        {serviceFormContent}
      </>
    </Modal>
  ) : (
    renderServiceForm()
  );
}
