import AppConfig from "app-config";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import BreadcrumbV9, {
  BreadcrumbDefinitionItem,
} from "components/breadcrumbV2";
import {
  LayoutFooter,
  LayoutHeader,
  LayoutWrapper,
} from "components/layout/Layout";
import Stepper from "components/stepper";
import { useTranslation } from "hooks/use-translate";
import { RootState } from "state";
import {
  fetchDealProperties,
  fetchPipelines,
  fetchProducts,
  resetCurrentDeal,
} from "state/sales/actions";
import { fetchServicesDescriptions } from "state/servicesDescriptions/actions";
import { AppDispatch } from "state/use-app-redux";
import { CreateCustomerContext } from "./CreateCustomerContext";
import {
  getAvailableWizardStepsForCreateCustomer,
  sortedCustomerWizardSteps,
  useStepperItemsForCreateCustomer,
} from "./components/StepperHelper";
import "./wizardPages/CustomerFlow.scss";
import { AppRouteHelper } from "routes";

export default function CreateNewCustomer() {
  const location = useLocation();
  const { t: translate } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const { newCustomer, businessAreaSelected } = useContext(
    CreateCustomerContext
  );

  const servicesDescriptions = useSelector(
    (state: RootState) => state.servicesDescriptions
  );
  const {
    dealsByOrgNumber: { data: potentialDeals },
    dealOptions: { data: dealOptions },
    pipelines,
    products,
  } = useSelector((state: RootState) => state.sales);

  const dealsBusinessAreas = dealOptions.find(
    (d) => d.name === "affarsomrade_deal_"
  );

  const filteredPotentialDeals = potentialDeals
    .filter(
      (deal) =>
        // eslint-disable-next-line no-underscore-dangle
        deal.affarsomrade_deal_ ===
        dealsBusinessAreas?.options.find(
          (area) => area.label === businessAreaSelected?.[0]
        )?.value
    )
    .filter(
      (deal) => deal.dealstage !== AppConfig.FEATURES.DEALS.DEAL_STAGE_LOST
    );

  useEffect(() => {
    const init = async () => {
      if (!pipelines.data.length) {
        dispatch(fetchPipelines());
      }

      if (!servicesDescriptions.data.length) {
        dispatch(fetchServicesDescriptions());
      }
      if (!dealOptions.length) {
        dispatch(fetchDealProperties());
      }
      if (!products.data.length) {
        dispatch(fetchProducts());
      }
    };

    init();
  }, [
    dealOptions.length,
    dispatch,
    pipelines.data.length,
    products.data.length,
    servicesDescriptions.data.length,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetCurrentDeal());
    };
  }, [dispatch]);

  const availableSteps = getAvailableWizardStepsForCreateCustomer(
    !!filteredPotentialDeals.length,
    newCustomer?.Type ?? undefined
  );
  const steps = useStepperItemsForCreateCustomer(
    location.pathname,
    availableSteps
  );
  const sortedSteps = sortedCustomerWizardSteps(steps);

  const breadcrumbs: BreadcrumbDefinitionItem[] = [
    {
      text: translate("CREATE_NEW"),
      onClick: () => navigate(AppRouteHelper.getCreateNew()),
    },
    {
      text: translate("CUSTOMER"),
    },
  ];

  return (
    <>
      <LayoutHeader>
        <BreadcrumbV9 items={breadcrumbs} />
      </LayoutHeader>
      <LayoutWrapper>
        <Outlet />
      </LayoutWrapper>
      <LayoutFooter>
        <Stepper steps={sortedSteps} />
      </LayoutFooter>
    </>
  );
}
