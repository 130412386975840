import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import { Picker } from "components/picker";
import { useTranslation } from "hooks";
import { EXCLUDE_BUSINESS_AREAS } from "shared/dealConstants";
import { RootState } from "state";
import { CreateBusinessOpportunityContext } from "../CreateBusinessOpportinityContext";
import WizardSectionBusinessOpportunity from "../WizardSectionBusinessOpportinity";

export default function BussinessAreaPicker() {
  const { ts, t: translate } = useTranslation();
  const { updateNewDeal, newDeal } = useContext(
    CreateBusinessOpportunityContext
  );
  const deals = useSelector((state: RootState) => state.sales);
  const dealsBusinessAreas = deals.dealOptions.data.find(
    (d) => d.name === "affarsomrade_deal_"
  );

  const selectBusinessArea = (businessArea: string) => {
    updateNewDeal({
      affarsomrade_deal_:
        newDeal.affarsomrade_deal_ === businessArea ? "" : businessArea,
    });
  };

  return (
    <WizardSectionBusinessOpportunity
      subtitles={[translate("BO_BUSINESS_AREA_SUBHEADER")]}
      isNextDisabled={!newDeal.affarsomrade_deal_}
      nextLabel="ADD_BUSINESS_DETAILS"
      backLabel="BACK_TO_CONTACT_DETAILS"
      disabledBackMessage={translate(
        "BUSINESS_DETAILS_DISABLE_BACK_MESSAGE_CREATE"
      )}
    >
      <Row className="minHeight-100">
        <Col xl={2} lg={1} className="pt-lg m-auto">
          <div className="horizontal-divider " />
        </Col>
        <Col xl={8} lg={10} className="m-auto">
          <div className="d-flex flex-wrap justify-content-center fg-4">
            {dealsBusinessAreas?.options
              .filter((o) => !EXCLUDE_BUSINESS_AREAS.includes(o.label))
              .map((businessArea) => {
                return (
                  <Picker
                    key={businessArea.label}
                    className="mx-sm"
                    excludingGroups
                    checked={newDeal.affarsomrade_deal_ === businessArea.value}
                    onToggle={() => selectBusinessArea(businessArea.value)}
                    name={businessArea.label}
                    toggles={[]}
                    description={ts(`${businessArea.label}_DESCRIPTION`)}
                  />
                );
              })}
          </div>
        </Col>
        <Col xl={2} lg={1} className="pt-lg m-auto">
          <div className="horizontal-divider" />
        </Col>
      </Row>
    </WizardSectionBusinessOpportunity>
  );
}
