import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { startOfMonth } from "date-fns";
import { ChevronUp24Regular } from "@fluentui/react-icons";

import { useTranslation } from "hooks/use-translate";
import { ServiceInstance } from "models/activities/activity";
import { Customer } from "models/customer";
import { Button } from "components/button";
import ServiceBoxListV2 from "./ServiceBoxListV2";
import { unfinishedActivitiesLength } from "./helpers";
import UnfinishedActivitiesList from "./UnfinishedActivitiesList";
import "./ServicesPerMonthColumnV2.scss";

const MAX_COMPLETED_SERVICES = 3;
const MAX_INCOMPLETE_SERVICES_EXPANDED = 2;
const MAX_INCOMPLETE_SERVICES = 1;

type ServicesPerMonthColumnProps = {
  services: ServiceInstance[];
  customer: Customer;
  yearMonth: Date;
  isCompleted: boolean;
  isExpanded: boolean;
  allEmpty?: boolean;
  showDivider?: boolean;
  unfinishedActivityServices: ServiceInstance[];
  showCompleted: boolean;
};

export default function ServicesPerMonthColumnV2({
  services,
  customer,
  yearMonth,
  isCompleted,
  isExpanded,
  allEmpty = false,
  showDivider = true,
  unfinishedActivityServices,
  showCompleted,
}: ServicesPerMonthColumnProps) {
  const { t: translate } = useTranslation();

  const CurrentMonthStart = startOfMonth(new Date());
  const backgroundColorClass =
    yearMonth.getMonth() === CurrentMonthStart.getMonth()
      ? "bg-currentMonthColumn2"
      : "bg-monthColumn2";
  const [showUnfinishedServices, setShowUnfinishedServices] = useState(false);

  const maxServices = () => {
    if (isCompleted) {
      return MAX_COMPLETED_SERVICES;
    }
    if (isExpanded && !showDivider) {
      return MAX_INCOMPLETE_SERVICES_EXPANDED;
    }
    return MAX_INCOMPLETE_SERVICES;
  };

  let altMessage = isCompleted
    ? translate("NO_COMPLETED_ACTIVITIES_FOR_MONTH")
    : translate("NO_INCOMPLETE_ACTIVITIES_FOR_MONTH");
  if (allEmpty) {
    altMessage = translate("NO_ACTIVITIES_FOR_MONTH");
  }

  return (
    <Col className={`d-flex ${backgroundColorClass}`}>
      <Row
        className={`flex-1 px-sm cursor-pointer w-100 ${
          isCompleted ? "" : "pb-sm"
        }d-flex ${backgroundColorClass}${
          !showDivider ? "mt-sm" : ""
        } align-items-center h-100`}
      >
        <Col className="d-flex flex-direction-column h-100">
          {!isCompleted && showDivider && (
            <Row className="divider-border my-xxs ml-xxs mr-xl" />
          )}
          {services.length ? (
            <ServiceBoxListV2
              servicesList={services}
              maxNumberServices={maxServices()}
              customer={customer}
              isExpanded={isExpanded}
              isCompleted={isCompleted}
              unfinishedActivityServices={unfinishedActivityServices}
            />
          ) : (
            <Row className="h-100 pt-sm">
              <Col md={11}>
                {unfinishedActivityServices.length > 0 &&
                (!isCompleted || allEmpty) ? (
                  <UnfinishedActivitiesList
                    unfinishedActivityServices={unfinishedActivityServices}
                    customerNumber={customer.customer_number}
                    showUnfinishedServices={showUnfinishedServices}
                  />
                ) : (
                  showCompleted && (
                    <label className="d-flex ml-sm h-100 align-items-center justify-content-center">
                      {altMessage}
                    </label>
                  )
                )}
              </Col>
              <Col
                md={1}
                className={`d-flex ${
                  showUnfinishedServices
                    ? "align-items-end pb-lg"
                    : "align-items-center"
                } justify-content-center px-0 mx-0`}
              >
                {unfinishedActivityServices.length > 0 &&
                  (!isCompleted || allEmpty) && (
                    <Button
                      variant="outline-outline"
                      className="mx-0 px-0 text-primary"
                      size="md"
                      onClick={(event) => {
                        event.stopPropagation();
                        setShowUnfinishedServices(!showUnfinishedServices);
                      }}
                    >
                      <span
                        className={
                          unfinishedActivityServices.length > 0
                            ? "color-redLight"
                            : "color-gridLinkColor"
                        }
                      >
                        {showUnfinishedServices ? (
                          <ChevronUp24Regular
                            className={
                              unfinishedActivityServices.length > 0
                                ? "color-redLight"
                                : "color-gridLinkColor"
                            }
                          />
                        ) : (
                          unfinishedActivitiesLength(unfinishedActivityServices)
                        )}
                      </span>
                    </Button>
                  )}
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Col>
  );
}
