import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import { CreateCustomer } from "../../../../models/customer";

interface CreateCustomerContextProps {
  businessAreaSelected: string[];
  subServiceSelected: string[];
  toggleBusinessArea: (businessArea: string) => void;
  toggleSubService: (subService: string) => void;
  newCustomer: CreateCustomer;
  updateNewCustomer: (customer: Partial<CreateCustomer>) => CreateCustomer;
  resetContext: () => void;
  dealIdToUpdate: string;
  toggleDealIdToUpdate: (dealId: string) => void;
}

export const CreateCustomerContext = createContext<CreateCustomerContextProps>({
  businessAreaSelected: [],
  subServiceSelected: [],
  toggleBusinessArea: () => {},
  toggleSubService: () => {},
  newCustomer: new CreateCustomer(),
  updateNewCustomer: () => new CreateCustomer(),
  resetContext: () => {},
  dealIdToUpdate: "",
  toggleDealIdToUpdate: () => {},
});

interface IRegistrationProvider {
  children: ReactNode;
}

export function CreateCustomerContextProvider({
  children,
}: IRegistrationProvider) {
  const [businessAreaSelected, setBusinessAreaSelected] = useState<string[]>(
    []
  );
  const [subServiceSelected, setSubServiceSelected] = useState<string[]>([]);

  const [newCustomer, setNewCustomer] = useState<CreateCustomer>(
    new CreateCustomer()
  );

  const [dealIdToUpdate, setDealIdToUpdate] = useState<string>("");

  const selectBusinessArea = useCallback(
    (businessArea: string) => {
      if (businessAreaSelected.includes(businessArea)) {
        setBusinessAreaSelected([]);
        return;
      }
      setBusinessAreaSelected([businessArea]);
      setSubServiceSelected([]);
    },
    [setBusinessAreaSelected, businessAreaSelected]
  );

  const selectSubService = useCallback(
    (subService: string) => {
      if (subServiceSelected.includes(subService)) {
        setSubServiceSelected(
          subServiceSelected.filter((ss) => ss !== subService)
        );
      } else {
        setSubServiceSelected([...subServiceSelected, subService]);
      }
    },
    [setSubServiceSelected, subServiceSelected]
  );

  const updateNewCustomer = useCallback(
    (customer: Partial<CreateCustomer>) => {
      setNewCustomer({ ...newCustomer, ...customer });
      return { ...newCustomer, ...customer };
    },
    [newCustomer, setNewCustomer]
  );

  const toggleDealIdToUpdate = useCallback((dealId: string) => {
    setDealIdToUpdate(dealId);
  }, []);

  const resetContext = useCallback(() => {
    setNewCustomer(new CreateCustomer());
    setBusinessAreaSelected([]);
    setSubServiceSelected([]);
  }, []);

  const initContextState = useMemo<CreateCustomerContextProps>(
    () => ({
      businessAreaSelected,
      subServiceSelected,
      toggleBusinessArea: selectBusinessArea,
      toggleSubService: selectSubService,
      newCustomer,
      updateNewCustomer,
      resetContext,
      dealIdToUpdate,
      toggleDealIdToUpdate,
    }),
    [
      businessAreaSelected,
      subServiceSelected,
      selectBusinessArea,
      selectSubService,
      newCustomer,
      updateNewCustomer,
      resetContext,
      dealIdToUpdate,
      toggleDealIdToUpdate,
    ]
  );

  return (
    <CreateCustomerContext.Provider value={initContextState}>
      {children}
    </CreateCustomerContext.Provider>
  );
}
