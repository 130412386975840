import {
  Activity,
  ActivityInstance,
  ActivityStatus,
  CustomerActivitiesServices,
  ActivityDefinition,
  FormQuestion,
  ServiceTypeDefinition,
  FormAnswer,
  FormQuestionAnswerAllowedTypes,
  ServiceInstance,
  ActivitiesTeam,
} from "models/activities/activity";
import { DeliveryFact } from "models/deliveryPlan";
import { EditActivityStatus } from "./actions";

export const LOADING_SERVICES_ACTIVITIES_FAILED =
  "activities/LOADING_SERVICES_ACTIVITIES_FAILED";
export const SET_SERVICES_ACTIVITIES = "activities/SET_SERVICE_ACTIVITIES";
export const SET_AVAILABLE_SERVICES = "delivery_planner/SET_AVAILABLE_SERVICES";
export const SET_AVAILABLE_SERVICES_LOADING =
  "delivery_planner/SET_AVAILABLE_SERVICES_LOADING";
export const SET_ACTIVITIES_DEFINITIONS =
  "activities/SET_ACTIVITIES_DEFINITIONS";
export const SET_ACTIVITIES_DEFINITIONS_LOADING =
  "activities/SET_ACTIVITIES_DEFINITIONS_LOADING";
export const LOADING_ACTIVITIES_DEFINITIONS_FAILED =
  "activities/LOADING_ACTIVITIES_DEFINITIONS_FAILED";
export const APPEND_SERVICES_ACTIVITY = "activities/APPEND_SERVICES_ACTIVITY";
export const APPEND_SERVICES_ACTIVITIES =
  "activities/APPEND_SERVICES_ACTIVITIES";
export const SET_LOADING_SERVICE_ACTIVITIES =
  "activities/SET_LOADING_SERVICE_ACTIVITIES";
export const SET_TIMELINE_WINDOW = "activites/SET_TIMELINE_WINDOW";
export const SET_TIMELINE_WINDOW2 = "activites/SET_TIMELINE_WINDOW2";

export const SET_CURRENT_SERVICE = "activities/SET_CURRENT_SERVICE";

export const FETCH_ACTIVITY = "activities/FETCH_ACTIVITY";
export const LOADING_SERVICE_ACTIVITY = "activities/LOADING_ACTIVITY";
export const LOADING_SERVICE_ACTIVITY_FAILED =
  "activities/LOADING_ACTIVITY_FAILED";
export const UPDATE_ACTIVITY = "activities/UPDATE_ACTIVITY";
export const UPDATE_ACTIVITY_V2 = "activities/UPDATE_ACTIVITY_V2";
export const UPDATE_ACTIVITY_TYPE_ASSIGNED_USER =
  "activities/UPDATE_ACTIVITY_TYPE_ASSIGNED_USER";
export const UPDATE_ACTIVITY_TYPE_ASSIGNED_USER_V2 =
  "activities/UPDATE_ACTIVITY_TYPE_ASSIGNED_USER_V2";
export const UPDATE_SERVICE_TYPE_DEFAULT_ASSIGNED_USER =
  "activities/UPDATE_SERVICE_TYPE_DEFAULT_ASSIGNED_USER";
export const UPDATE_SERVICE_TYPE_DEFAULT_ASSIGNED_USER_V2 =
  "activities/UPDATE_SERVICE_TYPE_DEFAULT_ASSIGNED_USER_V2";
export const UPDATE_ACTIVITY_TYPES_ASSIGNED_USER =
  "activities/UPDATE_ACTIVITY_TYPES_ASSIGNED_USER";

export const UPDATE_CUSTOMER_DEFAULT_ASSIGNED_USER =
  "activities/UPDATE_CUSTOMER_DEFAULT_ASSIGNED_USER";
export const SET_OPTIONAL_ACTIVITIES = "activities/SET_OPTIONAL_ACTIVITIES";
export const SET_LOADING_OPTIONAL_ACTIVITIES =
  "activities/SET_LOADING_OPTIONAL_ACTIVITIES";
export const UPDATE_OPTIONAL_ACTIVITIES =
  "activities/UPDATE_OPTIONAL_ACTIVITIES";
export const SET_ACTIVITY_COMPLETED = "activities/SET_ACTIVITY_COMPLETED";
export const SET_ACTIVITY_UNCOMPLETED = "activities/SET_ACTIVITY_UNCOMPLETED";
export const UPDATE_ACTIVITY_DEADLINE = "activities/ACTIVITY_DEADLINE_UPDATED";
export const UPDATE_ACTIVITY_DEADLINE_V2 =
  "activities/ACTIVITY_DEADLINE_UPDATED_V2";
export const APPEND_ACTIVITY_INSTANCE_MESSAGE =
  "activities/APPEND_ACTIVITY_INSTANCE_MESSAGE";
export const APPEND_ACTIVITY_INSTANCE_MESSAGE_V2 =
  "activities/APPEND_ACTIVITY_INSTANCE_MESSAGE_V2";
export const SET_ACTIVITY_INSTANCE_LOADING =
  "activities/SET_ACTIVITY_INSTANCE_LOADING";
export const SET_ACTIVITY_INSTANCE_LOADING_V2 =
  "activities/SET_ACTIVITY_INSTANCE_LOADING_V2";
export const SET_FORM_QUESTIONS = "activities/SET_FORM_QUESTIONS";
export const SET_FORM_QUESTION_ANSWER = "activities/SET_FORM_QUESTION_ANSWER";
export const SET_SERVICE_TYPES_LIST = "activities/SET_SERVICE_TYPES_LIST";
export const SET_FORM_ANSWERS = "activities/SET_FORM_ANSWERS";
export const RESET_FORM_ANSWERS = "activities/RESET_FORM_ANSWERS";
export const SYNC_CUSTOMER_ACTIVITIES = "activities/SYNC_CUSTOMER_ACTIVITIES";
export const APPEND_ACTIVITIES_TEAM = "activities/APPEND_ACTIVITIES_TEAM";
export const SET_LOADING_ACTIVITIES_TEAM =
  "activities/SET_LOADING_ACTIVITIES_TEAM";
export const LOADING_ACTIVITIES_TEAM_FAILED =
  "activities/LOADING_ACTIVITIES_TEAM_FAILED";
export const APPEND_UNFINISHED_ACTIVITIES =
  "activities/APPEND_UNFINISHED_ACTIVITIES";
export const SET_LOADING_UNFINISHED_ACTIVITIES =
  "activities/SET_LOADING_UNFINISHED_ACTIVITIES";
export const LOADING_UNFINISHED_ACTIVITIES_FAILED =
  "activities/LOADING_UNFINISHED_ACTIVITIES_FAILED";
export const SET_SERVICE_BOX_ACTIVITIES =
  "activities/SET_SERVICE_BOX_ACTIVITIES";
export const SET_LOADING_SERVICE_BOX_ACTIVITIES =
  "activities/SET_LOADING_SERVICE_BOX_ACTIVITIES";
export const LOADING_SERVICE_BOX_ACTIVITIES_FAILED =
  "activities/LOADING_SERVICE_BOX_ACTIVITIES_FAILED";
export const UPDATE_SERVICE_ACTIVITIES = "activities/UPDATE_SERVICE_ACTIVITIES";

export interface SetCurrentService {
  type: typeof SET_CURRENT_SERVICE;
  payload: CustomerActivitiesServices[];
}

// TODO: Replace with version 2
export interface UpdateActivityInstance {
  type: typeof UPDATE_ACTIVITY;
  payload: ActivityInstance;
}

export interface UpdateActivityInstanceV2 {
  type: typeof UPDATE_ACTIVITY_V2;
  payload: ActivityInstance;
}

// TODO: Replace with version 2
export interface ActivityDeadlineUpdated {
  type: typeof UPDATE_ACTIVITY_DEADLINE;
  payload: {
    customerOrgNumber: string;
    activityId: string;
    deadlineDate: Date;
    setDeadlineUpcoming: boolean;
  };
}

export interface ActivityDeadlineUpdatedV2 {
  type: typeof UPDATE_ACTIVITY_DEADLINE_V2;
  payload: {
    customerOrgNumber: string;
    activityId: string;
    deadlineDate: Date;
    setDeadlineUpcoming: boolean;
  };
}

// TODO: Replace with version 2
export interface AppendActivityInstanceMessage {
  type: typeof APPEND_ACTIVITY_INSTANCE_MESSAGE;
  payload: {
    customerOrgNumber: string;
    activityId: string;
    messageContent: string;
    status: ActivityStatus;
    teamsConversationUrl?: string;
    teamsConversationId?: string;
  };
}

export interface AppendActivityInstanceMessageV2 {
  type: typeof APPEND_ACTIVITY_INSTANCE_MESSAGE_V2;
  payload: {
    customerOrgNumber: string;
    activityId: string;
    messageContent: string;
    status: ActivityStatus;
    teamsConversationUrl?: string;
    teamsConversationId?: string;
  };
}

// TODO: Replace with version 2
export interface SetLoadingActivityInstance {
  type: typeof SET_ACTIVITY_INSTANCE_LOADING;
  payload: {
    customerOrgNumber: string;
    activityId: string;
    isLoading: boolean;
  };
}

export interface SetLoadingActivityInstanceV2 {
  type: typeof SET_ACTIVITY_INSTANCE_LOADING_V2;
  payload: {
    customerOrgNumber: string;
    activityId: string;
    isLoading: boolean;
  };
}

export interface SetTimelineWindow {
  type: typeof SET_TIMELINE_WINDOW;
  payload?: Date[];
}

export interface SetTimelineWindow2 {
  type: typeof SET_TIMELINE_WINDOW2;
  payload?: Date[];
}

// TODO: Replace with version 2
export interface UpdateActivityTypeAssignedUser {
  type: typeof UPDATE_ACTIVITY_TYPE_ASSIGNED_USER;
  payload: {
    userId: string;
    customerNumber: string;
    originalId: string;
    affectedActivities?: string[];
  };
}

export interface UpdateActivityTypeAssignedUserV2 {
  type: typeof UPDATE_ACTIVITY_TYPE_ASSIGNED_USER_V2;
  payload: {
    userId: string;
    customerNumber: string;
    originalId: string;
    affectedActivities?: string[];
  };
}

// TODO: Replace with version 2
export interface UpdateServiceTypeAssignedUser {
  type: typeof UPDATE_SERVICE_TYPE_DEFAULT_ASSIGNED_USER;
  payload: {
    serviceType: string;
    userId: string;
    customerOrgNumber: string;
  };
}

export interface UpdateServiceTypeAssignedUserV2 {
  type: typeof UPDATE_SERVICE_TYPE_DEFAULT_ASSIGNED_USER_V2;
  payload: {
    serviceType: string;
    userId: string;
    customerOrgNumber: string;
  };
}

export interface UpdateCustomerDefaultAssignedUser {
  type: typeof UPDATE_CUSTOMER_DEFAULT_ASSIGNED_USER;
  payload: {
    customerOrgNumber: string;
    userId: string;
    packageName?: string;
  };
}

export interface SetActivityInstanceCompleted {
  type: typeof SET_ACTIVITY_COMPLETED;
  payload: ActivityInstance;
}

export interface SetActivityInstanceUncompleted {
  type: typeof SET_ACTIVITY_UNCOMPLETED;
  payload: ActivityInstance;
}

export interface SetCustomersServicesActivities {
  type: typeof SET_SERVICES_ACTIVITIES;
  payload: CustomerActivitiesServices[];
}

export interface AppendCustomerServicesActivity {
  type: typeof APPEND_SERVICES_ACTIVITY;
  payload: CustomerActivitiesServices;
}

export interface AppendCustomerServicesActivities {
  type: typeof APPEND_SERVICES_ACTIVITIES;
  payload: CustomerActivitiesServices[];
}

export interface SetLoadingServicesActivities {
  type: typeof SET_LOADING_SERVICE_ACTIVITIES;
  payload: boolean;
}

export interface SetLoadingServicesActivitiesFailed {
  type: typeof LOADING_SERVICES_ACTIVITIES_FAILED;
}

export interface SetOptionalActivities {
  type: typeof SET_OPTIONAL_ACTIVITIES;
  payload: Activity[];
}

export interface SetLoadingOptionalActivities {
  type: typeof SET_LOADING_OPTIONAL_ACTIVITIES;
  payload: boolean;
}

export interface SetActivitiesDefinitions {
  type: typeof SET_ACTIVITIES_DEFINITIONS;
  payload: ActivityDefinition[];
}

export interface SetActivitiesDefinitionsLoading {
  type: typeof SET_ACTIVITIES_DEFINITIONS_LOADING;
  payload: boolean;
}

export interface LoadingActivitiesDefinitionsFailed {
  type: typeof LOADING_ACTIVITIES_DEFINITIONS_FAILED;
}

export interface SetAvailableServices {
  type: typeof SET_AVAILABLE_SERVICES;
  payload: DeliveryFact[];
}

export interface SetAvailableServicesLoading {
  type: typeof SET_AVAILABLE_SERVICES_LOADING;
  payload: boolean;
}

export interface UpdateOptionalActivities {
  type: typeof UPDATE_OPTIONAL_ACTIVITIES;
  payload: { added: EditActivityStatus[]; removed: EditActivityStatus[] };
}

export const SET_SERVICES = "services/SET_SERVICES";
export const LOADING_SERVICES = "services/LOADING_SERVICES";

export interface LoadingServicesAction {
  type: typeof LOADING_SERVICES;
}

export interface SetFormQuestions {
  type: typeof SET_FORM_QUESTIONS;
  payload: { formQuestions: FormQuestion[]; removeDefaultAnswer?: boolean };
}

export interface SetFormQuestionAnswer {
  type: typeof SET_FORM_QUESTION_ANSWER;
  payload: {
    answer: FormQuestionAnswerAllowedTypes;
    questionID: string;
    comment?: string;
  };
}

export interface SetFormAnswers {
  type: typeof SET_FORM_ANSWERS;
  payload: { formAnswers: FormAnswer[] };
}

export interface ResetFormAnswers {
  type: typeof RESET_FORM_ANSWERS;
}

export interface SetServiceTypesList {
  type: typeof SET_SERVICE_TYPES_LIST;
  payload: { serviceTypes: ServiceTypeDefinition[] };
}

export interface AppendActivitiesTeam {
  type: typeof APPEND_ACTIVITIES_TEAM;
  payload: ActivitiesTeam[];
}

export interface SetLoadingActivitiesTeam {
  type: typeof SET_LOADING_ACTIVITIES_TEAM;
  payload: boolean;
}

export interface SetLoadingActivitiesTeamFailed {
  type: typeof LOADING_ACTIVITIES_TEAM_FAILED;
}
export interface AppendCustomerUnfinishedActivities {
  type: typeof APPEND_UNFINISHED_ACTIVITIES;
  payload: CustomerActivitiesServices[];
}

export interface SetLoadingUnfinishedActivities {
  type: typeof SET_LOADING_UNFINISHED_ACTIVITIES;
  payload: boolean;
}

export interface SetLoadingUnfinishedActivitiesFailed {
  type: typeof LOADING_UNFINISHED_ACTIVITIES_FAILED;
}

export interface SetServiceBoxActivities {
  type: typeof SET_SERVICE_BOX_ACTIVITIES;
  payload: { serviceBoxActivities: ServiceInstance[]; customerId: string };
}

export interface SetLoadingServiceBoxActivities {
  type: typeof SET_LOADING_SERVICE_BOX_ACTIVITIES;
  payload: boolean;
}

export interface SetLoadingServiceBoxActivitiesFailed {
  type: typeof LOADING_SERVICE_BOX_ACTIVITIES_FAILED;
}

export interface UpdateServiceActivities {
  type: typeof UPDATE_SERVICE_ACTIVITIES;
  payload: { service: ServiceInstance; customerId: string };
}

export type ActivitiesAction =
  | UpdateActivityInstance
  | SetLoadingServicesActivities
  | SetLoadingServicesActivitiesFailed
  | SetCustomersServicesActivities
  | SetActivityInstanceCompleted
  | SetActivityInstanceUncompleted
  | UpdateActivityTypeAssignedUser
  | UpdateServiceTypeAssignedUser
  | AppendActivityInstanceMessage
  | SetLoadingActivityInstance
  | SetTimelineWindow
  | SetTimelineWindow2
  | UpdateCustomerDefaultAssignedUser
  | SetOptionalActivities
  | LoadingServicesAction
  | ActivityDeadlineUpdated
  | UpdateOptionalActivities
  | SetLoadingOptionalActivities
  | AppendCustomerServicesActivity
  | AppendCustomerServicesActivities
  | SetActivitiesDefinitions
  | SetActivitiesDefinitionsLoading
  | LoadingActivitiesDefinitionsFailed
  | SetAvailableServices
  | SetAvailableServicesLoading
  | SetCurrentService
  | SetFormQuestions
  | SetFormQuestionAnswer
  | SetServiceTypesList
  | SetFormAnswers
  | ResetFormAnswers
  | AppendActivitiesTeam
  | SetLoadingActivitiesTeam
  | SetLoadingActivitiesTeamFailed
  | AppendCustomerUnfinishedActivities
  | SetLoadingUnfinishedActivities
  | SetLoadingUnfinishedActivitiesFailed
  | SetServiceBoxActivities
  | SetLoadingServiceBoxActivities
  | SetLoadingServiceBoxActivitiesFailed
  | UpdateServiceActivities
  | SetLoadingActivityInstanceV2
  | AppendActivityInstanceMessageV2
  | ActivityDeadlineUpdatedV2
  | UpdateActivityInstanceV2
  | UpdateActivityTypeAssignedUserV2
  | UpdateServiceTypeAssignedUserV2;
