import {
  Person20Regular,
  PersonAdd20Regular,
  PersonEdit20Regular,
  Dismiss20Filled,
} from "@fluentui/react-icons";

import { useTranslation } from "hooks";
import TooltipV9 from "components/tooltip/TooltipV9";
import "./PersonPicker.scss";

export enum CircleIconOptions {
  add,
  edit,
  remove,
  close,
  default,
}

type CircleIconWrapperProps = {
  iconName: CircleIconOptions;
  personName?: string;
  fadeInOnHover?: boolean;
  persist?: boolean;
  disabled?: boolean;
} & React.HTMLProps<HTMLDivElement>;

export function CircleIconWrapper({
  children,
  iconName,
  personName,
  fadeInOnHover = false,
  persist = false,
  disabled = false,
  onClick,
}: CircleIconWrapperProps) {
  const { t: translate } = useTranslation();
  const className = fadeInOnHover ? "on-hover-fade-in" : "";
  const isPersistIcon = persist ? "persistent" : "";

  const renderIcon = () => {
    switch (iconName) {
      case CircleIconOptions.add:
        return (
          <TooltipV9 content="PERSON_PICKER.ADD_USER">
            <PersonAdd20Regular />
          </TooltipV9>
        );
      case CircleIconOptions.edit:
        return (
          <TooltipV9 content="PERSON_PICKER.EDIT_USER">
            <PersonEdit20Regular />
          </TooltipV9>
        );
      case CircleIconOptions.remove:
        return (
          <TooltipV9
            content={
              personName
                ? translate("PERSON_PICKER.REMOVE_SELECTED_USER", [personName])
                : translate("PERSON_PICKER.REMOVE_USER")
            }
            hasJSXContent
            notTranslatable
          >
            <Dismiss20Filled />
          </TooltipV9>
        );
      case CircleIconOptions.close:
        return (
          <TooltipV9 content="PERSON_PICKER.CLOSE_SEARCH">
            <Dismiss20Filled />
          </TooltipV9>
        );
      case CircleIconOptions.default:
        return (
          <TooltipV9 content="PERSON_PICKER.SEARCH" enabled={!disabled}>
            <Person20Regular />
          </TooltipV9>
        );
      default:
        return (
          <TooltipV9 content="PERSON_PICKER.SEARCH" enabled={!disabled}>
            <Person20Regular />
          </TooltipV9>
        );
    }
  };

  return (
    <div
      className={`position-relative d-flex align-items-center mr-sm ${
        disabled ? "disabled" : ""
      }`}
      onClick={onClick}
    >
      <div className="position-absolute w-100">{children}</div>
      <div className="user-avatar small">
        {(!children || (children && !disabled)) && (
          <div className={`circle-icon ${className} ${isPersistIcon}`}>
            {renderIcon()}
          </div>
        )}
      </div>
    </div>
  );
}
