import AppConfig from "app-config";
import { EKONOMI_BUSINESS_AREA_LABEL } from "libs/constants";
import { useServiceMatrix } from "libs/service-matrix";
import { Deal } from "models/deals/deal";
import { useSelector } from "react-redux";
import { RootState } from "state";
import { hasValidOfferURL } from "views/deals/libs/is-pog-deal";

export function useFilterPotentialDeals() {
  const { data: offerTemplate } = useSelector(
    (state: RootState) => state.offers.offerTemplate
  );

  const {
    dealsByOrgNumber: { data: potentialDeals },
    dealOptions: { data: dealOptions },
    products: { data: products },
  } = useSelector((state: RootState) => state.sales);

  const { GetAllServiceGroupsFlattened } = useServiceMatrix();

  const dealsBusinessAreas = dealOptions.find(
    (dealOption) => dealOption.name === "affarsomrade_deal_"
  );

  const serviceGroups = GetAllServiceGroupsFlattened(offerTemplate);

  const mappedServiceGroups = serviceGroups
    .filter((group) => group.name)
    .map((group) => group.name.toLowerCase());

  const isDealWithEkonomiBusinessArea = (deal: Deal) => {
    return (
      dealsBusinessAreas?.options.find(
        // eslint-disable-next-line no-underscore-dangle
        (businessArea) => businessArea.value === deal.affarsomrade_deal_
      )?.label === EKONOMI_BUSINESS_AREA_LABEL
    );
  };

  const getMappedDealProducts = (deal: Deal) => {
    return deal.productIds.map(
      (productId) =>
        products
          ?.find((product) => product.id === productId)
          ?.description?.toLowerCase() ?? ""
    );
  };

  const areAllDealProductsSupportedInServiceMatrix = (deal: Deal) => {
    return getMappedDealProducts(deal).every((productDescription) =>
      mappedServiceGroups.includes(productDescription.toLowerCase())
    );
  };

  const filteredPotentialDeals = potentialDeals.filter(
    (deal) =>
      isDealWithEkonomiBusinessArea(deal) &&
      areAllDealProductsSupportedInServiceMatrix(deal) &&
      deal.dealstage !== AppConfig.FEATURES.DEALS.DEAL_STAGE_LOST &&
      !hasValidOfferURL(deal) &&
      !deal.pog_created
  );

  return filteredPotentialDeals;
}
